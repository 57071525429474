import { defineStore } from 'pinia'

import { useClientStore } from './clientStore'

const useRecurrentOrderStore = defineStore('recurrentOrder', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
        recurrentToDuplicate: null,
    }),
    actions: {
        searchRecurrentOrders(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // if (type.dbField === 'OrderClient.IdUniverse') {
                    if (type.dbField === 'IdUniverse') {
                        const universesIds = []
                        type.value.forEach((universe) => {
                            universesIds.push(universe.value)
                        })
                        if (universesIds.length > 0) {
                            query.push(
                                `{"IdUniverse":[{"operator":"IN","value":[${universesIds}]}]}`
                            )
                        }
                    } else if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                    // }
                })
            }
            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/recurrent/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/recurrent/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const orders = []
                            res.data.Records.forEach((order) => {
                                let formattedOrder = null
                                const currentSteps = this.setCurrentSteps(
                                    order.OrderSteps
                                )
                                // console.log(order)
                                let recurrenceEnd = this.i18n.global.t('never')
                                if (
                                    order.OccurrenceNb !== 0 &&
                                    order.OccurrenceNb !== '0' &&
                                    order.DateEndRecurrent === null
                                ) {
                                    recurrenceEnd = `${
                                        order.OccurrenceCount
                                            ? order.OccurrenceCount
                                            : 0
                                    } / ${
                                        order.OccurrenceNb
                                    } ${this.i18n.global.t(
                                        'done',
                                        order.OccurrenceCount <= 1 ? 1 : 2
                                    )} `
                                }
                                if (
                                    order.DateEndRecurrent !== null &&
                                    order.DateEndRecurrent !== '0000-00-00'
                                ) {
                                    recurrenceEnd = new Date(
                                        order.DateEndRecurrent
                                    ).toLocaleDateString(
                                        this.i18n.global.locale
                                    )
                                }

                                if (
                                    order.DateEndRecurrent === '0000-00-00' &&
                                    order.OccurrenceNb === 0
                                ) {
                                    recurrenceEnd = this.i18n.global.t('never')
                                }
                                if (order.OccurrenceNb > 0) {
                                    recurrenceEnd = `${this.i18n.global.t(
                                        'ends_after'
                                    )} ${
                                        order.OccurrenceNb
                                    } ${this.i18n.global.t('times')} - ${
                                        order.OccurrenceCount === null
                                            ? 0
                                            : order.OccurrenceCount
                                    } ${this.i18n.global.t(
                                        'done',
                                        order.OccurrenceCount <= 1 ? 1 : 2
                                    )}`
                                }
                                const groupedPackages = []
                                if (order.OrderPackages) {
                                    order.OrderPackages.forEach((op, index) => {
                                        op.Quantity = 1

                                        const indexInGroupedPackages =
                                            groupedPackages.findIndex(
                                                (gp) =>
                                                    gp.IdContainer ===
                                                        op.IdContainer &&
                                                    gp.Weight === op.Weight &&
                                                    gp.Value === op.Value &&
                                                    gp.Height === op.Height &&
                                                    gp.Length === op.Length &&
                                                    gp.Width === op.Width &&
                                                    gp.TemperatureOption ===
                                                        op.TemperatureOption
                                            )
                                        if (
                                            groupedPackages.length === 0 ||
                                            indexInGroupedPackages === -1
                                        ) {
                                            groupedPackages.push(op)
                                        } else {
                                            groupedPackages[
                                                indexInGroupedPackages
                                            ].Quantity += 1
                                        }
                                    })
                                }

                                formattedOrder = {
                                    id: +order.Id,
                                    name: order.Name,
                                    active: +order.Active,
                                    end_date: order.DateEndRecurrent,
                                    start_date: order.DateStartRecurrent,
                                    externalId: order.IdExternal,
                                    recurrence_end: recurrenceEnd,
                                    roundTrip: +order.RoundTrip === 1,
                                    idUniverse: +order.IdUniverse,

                                    client: order.Client
                                        ? order.Client.Name
                                        : order.IdClient,
                                    current_driver: order.OrderSteps
                                        ? order.OrderSteps[0].IdDriver
                                        : '',
                                    // drivers: order.OrderSteps[0]
                                    //     ? `${order.Driver.LastName} ${order.Driver.FirstName}`
                                    //     : order.OrderSteps[0].IdDriver
                                    //     ? order.OrderSteps[0].IdDriver
                                    //     : null,

                                    number_of_parcels: +order.PacketNumber,
                                    currentStepsToDisplay: currentSteps,

                                    delivery_mode: order.Method,
                                    vehicle_type: order.VehicleType
                                        ? order.VehicleType
                                        : '',

                                    contact: order.Contact
                                        ? `${order.Contact.LastName} ${order.Contact.FirstName}`
                                        : null,
                                    steps: order.OrderSteps,
                                    packages: order.OrderPackages,
                                    groupedPackages,
                                    // pack_num: order.OrderPackages
                                    //     ? order.OrderPackages[0].Quantity
                                    //     : 0,
                                    idClient: order.IdClient,
                                    internal_comment: order.CommentInternal,
                                    days: order.Repetition,
                                    occ_count_to_do: order.OccurrenceNb,
                                    frequency: order.OccurrenceFrequency,
                                    occ_count_done: order.OccurrenceCount,

                                    creation_date: order.CreationDate,
                                    modification_date: order.ModificationDate,
                                    orderGroup: order.RecurrentTour,
                                    sales: order.Sales,
                                    onus: order.ONUs,
                                    hasHazardousMaterial: order.ONUs.length > 0,

                                }
                                if (order.Contact) {
                                    formattedOrder.subInfoForListDisplay =
                                        order.Contact.Email
                                    formattedOrder.subInfoText = 'contact'
                                }
                                orders.push(formattedOrder)
                            })
                            this.all =
                                +res.data.TotalResults > 0 ? orders : null
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        updateRecurrentStep(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/recurrentOrderStep/${payload.id}`,
                        payload.form,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        setCurrentSteps(steps) {
            let formattedSteps = steps

            if (formattedSteps && formattedSteps.length > 2) {
                const currentSteps = []
                steps.forEach((step, index) => {
                    if (
                        currentSteps.length === 0 &&
                        step.Type === 'pickup' &&
                        (index + 1) % 2
                    ) {
                        let vehicleIcon = ''
                        if (
                            step.IdVehicle &&
                            step.IdVehicle !== '' &&
                            step.IdVehicle !== '0' &&
                            step.Vehicle
                        ) {
                            if (step.Vehicle.Type === 'truck') {
                                vehicleIcon = {
                                    title: 'truck',
                                    icon: 'truck',
                                }
                            }
                            if (step.Vehicle.Type === 'bike') {
                                vehicleIcon = {
                                    title: 'bike',
                                    icon: 'person-biking',
                                }
                            }
                            if (step.Vehicle.Type === 'car') {
                                vehicleIcon = {
                                    title: 'car',
                                    icon: 'car',
                                }
                            }
                            if (step.Vehicle.Type === 'van') {
                                vehicleIcon = {
                                    icon: 'van',
                                    title: 'van',
                                }
                            }
                            if (step.Vehicle.Type === 'boat') {
                                vehicleIcon = {
                                    title: 'boat',
                                    icon: 'ship',
                                }
                            }
                            if (step.Vehicle.Type === 'deliveryTricycle') {
                                vehicleIcon = {
                                    icon: 'deliveryTricycle',
                                    title: 'delivery_tricycle',
                                }
                            }
                            if (step.Vehicle.Type === 'cargoBike') {
                                vehicleIcon = {
                                    icon: 'cargoBike',
                                    title: 'cargo_bike',
                                }
                            }
                            if (
                                step.Vehicle.Type === '' ||
                                step.Vehicle.Type === null
                            ) {
                                vehicleIcon = {
                                    icon: null,
                                }
                            }
                        }

                        step.icon = vehicleIcon

                        currentSteps.push(step)
                        currentSteps.push(steps[index + 1])
                    }
                })
                formattedSteps = currentSteps
            }
            if (
                formattedSteps &&
                formattedSteps[0].IdDriver &&
                formattedSteps[0].IdDriver !== '' &&
                formattedSteps[0].IdDriver !== '0'
            ) {
                formattedSteps[0].Driver = { Id: formattedSteps[0].IdDriver }
            }
            return formattedSteps
        },
        fetchOneRecurrent(payload) {
            this.current = null

            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/recurrent/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const order = res.data
                        // const groupedPackages = []
                        const groupedPackages = []

                        order.OrderPackages.forEach((op, index) => {
                            op.Quantity =
                                +op.Quantity === 0 || op.Quantity === null
                                    ? 1
                                    : +op.Quantity
                            op.packageTypeErrors = []
                            op.packageValueErrors = []
                            op.packageWeightErrors = []
                            op.packageReferenceErrors = []
                            op.packageWidthErrors = []
                            op.packageLengthErrors = []
                            op.packageHeightErrors = []
                            op.containerNameError = false
                            op.packageQuantityErrors = []
                            const indexInGroupedPackages =
                                groupedPackages.findIndex(
                                    (gp) =>
                                        gp.IdContainer === op.IdContainer &&
                                        gp.Weight === op.Weight &&
                                        gp.Value === op.Value &&
                                        gp.Height === op.Height &&
                                        gp.Length === op.Length &&
                                        gp.Width === op.Width &&
                                        gp.TemperatureOption ===
                                            op.TemperatureOption
                                )
                            if (
                                groupedPackages.length === 0 ||
                                indexInGroupedPackages === -1
                            ) {
                                groupedPackages.push(op)
                            } else {
                                groupedPackages[
                                    indexInGroupedPackages
                                ].Quantity += 1
                                groupedPackages[
                                    indexInGroupedPackages
                                ].SameOpIndexes.push(index)
                            }
                        })
                        const current = {
                            begin_date: order.DateStartRecurrent,
                            end_date: order.DateEndRecurrent,
                            id: +order.Id,
                            client_id: +order.IdClient,
                            // driver_id: +order.IdDriver,
                            id_external: order.IdExternal,
                            vehicle_id: order.IdVehicle,

                            comment_internal: order.CommentInternal,
                            order_packages: groupedPackages ?? [],

                            order_steps: order.OrderSteps,
                            idUniverse: +order.IdUniverse,

                            method: order.Method,
                            vehicle_type: order.VehicleType,

                            roundTrip: +order.RoundTrip === 1,
                            user_contact_id: order.IdUserContact,
                            creation_date: order.CreationDate,
                            modification_date: order.ModificationDate,
                            creation_user_id: +order.CreationUserId,
                            modification_user_id: +order.ModificationUserId,
                            contact: order.Contact,

                            client: order.Client,
                            OccurrenceNb: order.OccurrenceNb,
                            OccurrencecCount: order.OccurrenceNb,
                            OccurrenceFrequency: order.OccurrenceFrequency,
                            Repetition: order.Repetition,
                            RepetitionFrequency: order.RepetitionFrequency,
                            Active: +order.Active,
                            Name: order.Name,
                            sales: order.Sales,
                            onus: order.ONUs,
                            hasHazardousMaterial: order.ONUs.length > 0,


                            orderGroup: order.RecurrentTour,
                            id_pricing: order.IdPricing
                                ? order.IdPricing
                                : null,
                            forced_price: order.ForcedPrice
                                ? +order.ForcedPrice
                                : 0,
                            price: order.Price ? +order.Price : 0,
                            with_vat: order.WithVAT ? +order.WithVAT : 0,
                        }
                        this.current = current
                        const clientStore = useClientStore()

                        clientStore.$patch({
                            current: order.Client,
                            currentContacts: null,
                        })
                        clientStore.fetchContainers(order.IdClient).then(() => {
                            clientStore
                                .fetchClientContacts({
                                    clientId: order.IdClient,
                                    fromOrders: true,
                                })
                                .then(() => {
                                    resolve(res)
                                })
                        })

                        // resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        duplicateRecurrentOrder(payload) {
            return new Promise((resolve, reject) => {
                this.fetchOneRecurrent(payload)
                    .then((res) => {
                        this.recurrentToDuplicate = structuredClone(
                            this.current
                        )
                        const clientStore = useClientStore()

                        const clientsPayload = {
                            query: [],
                            filter: [],
                            perPage: 10000,
                            page: 1,
                            sortField: 'Name',
                            sortDirection: 'ASC',
                        }
                        clientStore.search(clientsPayload)
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()

                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }

                        reject(err)
                    })
            })
        },

        createRecurrentOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/recurrent', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(err)
                    })
            })
        },
        updateRecurrentOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/recurrent/${payload.id}`,
                        payload.recurrentForm,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        if (payload.duplicate === true) {
                            const order = res.data.Id
                            this.fetchOneRecurrent(order).then((resClient) => {
                                this.recurrentToDuplicate = structuredClone(
                                    this.current
                                )
                                const clientStore = useClientStore()
                                const clientsPayload = {
                                    query: [],
                                    filter: [],
                                    perPage: 10000,
                                    page: 1,
                                    sortField: 'Name',
                                    sortDirection: 'ASC',
                                }
                                clientStore.search(clientsPayload)
                                this.current = null
                                resolve(res)
                            })
                        } else {
                            resolve(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteRecurrentStep(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/recurrentOrderStep/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.currentStep = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deactivateRecurrent(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/recurrent/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        fetchOneRecurrentDate(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/recurrentDate/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateAndCreateRecurrentDate(payload) {
            return new Promise((resolve, reject) => {
                const form = {
                    Date: payload.Date,
                    IdRecurrent: payload.IdRecurrent,
                    CreationUserId: payload.userId,
                    ModificationUserId: payload.userId,
                    Status: 'added',
                }
                this.axios
                    .post('/api/v1/recurrentDate', form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((resCreate) => {
                        this.axios
                            .put(
                                `/api/v1/recurrentDate/${payload.Id}`,
                                {
                                    Status: 'deleted',
                                    ModificationUserId: payload.userId,
                                },
                                {
                                    headers: {
                                        Authorization: `Bearer ${this.cookies.get(
                                            'user_session'
                                        )}`,
                                    },
                                    signal: this.controller.signal,
                                }
                            )
                            .then((res) => {
                                resolve(resCreate)
                            })
                            .catch((err) => {
                                console.log(err)
                                // err.toJSON()
                                // const error = {
                                //     data: {
                                //         status: err.response.status,
                                //         message: err.response.data.message,
                                //     },
                                // }
                                reject(err)
                            })
                    })
                    .catch((errCreate) => {
                        // errCreate.toJSON()

                        // const error = {
                        //     data: {
                        //         status: errCreate.response.status,
                        //         message: errCreate.response.data.message,
                        //     },
                        // }

                        reject(errCreate)
                    })
            })
        },
        plannedToDeleted(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/recurrentDate/${payload.Id}`,
                        {
                            Status: 'deleted',
                            ModificationUserId: payload.userId,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },

        fetchRecurrentGroups() {
            return new Promise((resolve, reject) => {
                this.axios
                    .get('/api/v1/recurrentTour/search', {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateRecurrentGroup(payload) {
            this.current = null
            if (payload) {
                return new Promise((resolve, reject) => {
                    this.axios
                        .put(`/api/v1/recurrentTour/${payload.Id}`, payload, {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        })
                        .then((res) => {
                            resolve(res)
                        })
                        .catch((err) => {
                            console.log(err)

                            reject(err)
                        })
                })
            }
            return true
        },
    },
})
export { useRecurrentOrderStore }
