<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'roles'" />
            <filtrable-table
                v-else
                id="usersList"
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                :items="roles"
                :columns="columns"
                :storeToSort="'roles'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @removeAuthorizations="removeRoleAuth($event)"
                @addAuthsToRoles="showAddAuthsToRoles($event)"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="toCreateRole()"
                @clear="clearFilter($event)"
                @update="goToUpdateOne($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
            >
            </filtrable-table>
        </div>
        <modal
            v-if="showAddAuthsModal"
            :headerText="'add_authorisations'"
            :buttonText="'save'"
            :creating="searchingCursor"
            id="addModal"
            :mxWidth="'w-1/2'"
            :buttonAlign="'justify-center'"
            @closeModal="showAddAuthsToRoles()"
            @sendFromModal="addAuthsToRoles()"
        >
            <template #body>
                <div class="mb-3 mt-2 px-8">
                    <span class="items-center justify-center mt-2 flex pb-2">
                        {{
                            `${rolesToAddAuthsIds.length} ${$t(
                                'selected_roles',
                                rolesToAddAuthsIds.length > 1 ? 2 : 1,
                            )}`
                        }}
                    </span>
                    <div
                        class="h-96 overflow-y-auto border-t-2 border-b-2 themed-border"
                    >
                        <div
                            v-for="(auth, index) in authorisations"
                            :key="index"
                            :class="
                                index === authorisations.length - 1
                                    ? 'mb-1'
                                    : ''
                            "
                        >
                            <div class="flex items-center mt-1 justify-start">
                                <input
                                    :id="`checkbox-${index}`"
                                    type="checkbox"
                                    v-model="isCheckedAuth(auth.Id).value"
                                    @change="
                                        checkAuth(
                                            auth,
                                            !isCheckedAuth(auth.Id).value,
                                        )
                                    "
                                    class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                />
                                <label
                                    :for="`checkbox-${index}`"
                                    class="ml-2 font-medium"
                                >
                                    {{
                                        `
                                            ${auth.Name} :  ${auth.Description}`
                                    }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
        <modal
            v-if="showRoleModal"
            :headerText="roleModalHeaderText"
            :buttonText="'save'"
            :creating="creating"
            id="addModal"
            :mxWidth="'w-1/2'"
            :buttonAlign="'justify-center'"
            @closeModal="closeRoleModal()"
            @sendFromModal="submitRoleModal()"
        >
            <template #body>
                <div class="mb-3 mt-2 px-8">
                    <span class="grid grid-cols-2 gap-1">
                        <div class="col-span-1">
                            <label
                                class="basis-1/4 text-gray-600 pb-1 mr-2 block"
                                >{{ $t('name') + ' *' }} <br />
                            </label>
                            <input
                                v-model="roleFormData.Name"
                                autocomplete="name"
                                maxlength="100"
                                @blur="v$.roleFormData.Name.$touch"
                                class="border rounded px-3 py-2 mt-1 w-full"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.roleFormData.Name.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-span-1">
                            <label
                                class="basis-1/4 text-gray-600 pb-1 mr-2 block"
                                >{{ $t('role_code') + ' *' }} <br />
                            </label>
                            <input
                                v-model="roleFormData.Code"
                                :title="
                                    editRole === true
                                        ? $t('role_code_not_editable')
                                        : ''
                                "
                                :disabled="editRole === true"
                                maxLength="20"
                                :placeholder="'ex.: CODE'"
                                @blur="v$.roleFormData.Code.$touch"
                                class="border rounded px-3 py-2 mt-1 w-full"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.roleFormData.Code.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </span>
                    <label class="basis-1/4 text-gray-600 pb-1 pt-2 mr-2 block"
                        >{{ $t('description') + ' *' }} <br />
                    </label>
                    <textarea
                        v-model="roleFormData.Description"
                        @blur="v$.roleFormData.Description.$touch"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.roleFormData.Description.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                    <span class="items-center justify-center mt-2 flex pb-2">
                        {{ `${$t('authorisations')}` }}
                    </span>
                    <div
                        class="border-t-2 text-sm border-b-2 themed-border h-44 overflow-y-auto"
                    >
                        <div
                            v-for="(auth, index) in authorisations"
                            :key="index"
                            :class="
                                index === authorisations.length - 1
                                    ? 'mb-1'
                                    : ''
                            "
                        >
                            <div class="flex items-center mt-1 justify-start">
                                <input
                                    :id="`checkbox-${index}`"
                                    type="checkbox"
                                    v-model="isCheckedAuth(auth.Id).value"
                                    @change="
                                        checkAuth(
                                            auth,
                                            !isCheckedAuth(auth.Id).value,
                                        )
                                    "
                                    class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                />
                                <label
                                    :for="`checkbox-${index}`"
                                    class="ml-2 font-medium"
                                >
                                    {{ `${auth.Name} : ${auth.Description}` }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <span class="items-center justify-center mt-2 flex pb-2">
                        {{ `${$t('add_role_to_users')}` }}
                    </span>
                    <div class="border-t-2 text-sm border-b-2 themed-border">
                        <div class="border-b-2 mt-2 pb-2">
                            <div
                                class="flex items-center mt-1 justify-center flex-wrap"
                            >
                                <span
                                    v-for="(userType, index) in userTypes"
                                    :key="index"
                                    class="flex items-center"
                                >
                                    <input
                                        :id="`checkbox-${userType.name}`"
                                        type="checkbox"
                                        v-model="userType.value"
                                        @change="checkByType(userType)"
                                        class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                    />
                                    <label
                                        :for="`checkbox-${userType.name}`"
                                        class="ml-1 mr-4 font-medium"
                                    >
                                        {{ $t(userType.name) }}
                                    </label>
                                </span>
                            </div>
                            <div
                                class="text-xs italic mt-1 flex justify-center"
                            >
                                {{ $t('already_selected_info') }}
                            </div>
                        </div>
                        <div class="h-56 overflow-y-auto">
                            <div
                                v-for="(user, index) in users"
                                :key="index"
                                :class="
                                    index === users.length - 1 ? 'mb-1' : ''
                                "
                            >
                                <div
                                    class="flex items-center mt-1 justify-start"
                                >
                                    <input
                                        :id="`checkbox-${index}`"
                                        type="checkbox"
                                        v-model="isCheckedUser(user.id).value"
                                        @change="checkUser(user.id)"
                                        class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                    />
                                    <label
                                        :for="`checkbox-${index}`"
                                        class="ml-2 font-medium"
                                    >
                                        {{
                                            `${$t(user.type)} - ${
                                                user.firstname
                                            }  ${user.lastname}`
                                        }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span
                    class="flex mt-1 italic text-xs items-center justify-center"
                >
                    {{ $t('required_fields') }}
                </span>
            </template>
        </modal>
        <modal
            v-if="showDeleteConfirmModal"
            :headerText="deleteModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :mxWidth="'max-w-2xl'"
            :buttonAlign="'justify-center'"
            @closeModal="deleteConfirmation(false)"
            @sendFromModal="deleteConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center mt-8">
                        {{ $t(deleteModalText) }}
                    </div>

                    <div
                        v-for="(toDelete, index) in elementToDelete"
                        :key="index"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span class="mr-1">
                                    {{ toDelete }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="flex text-xs italic justify-center mt-4">
                        {{ $t('delete_modal_generic_text_2') }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'
import userRoleAuth from '@/mixins/userRoleAuth'
import useVuelidate from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

import Modal from '@/components/atoms/Modal.vue'

import FetchDataError from '@/components/atoms/FetchDataError.vue'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useAllUsersStore } from '@/stores/allUsersStore'
import { useAuthorisationStore } from '@/stores/authorisationStore'
import { useRoleStore } from '@/stores/roleStore'
import { useUserStore } from '@/stores/userStore'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'RolesList',
    mixins: [filterList, userRoleAuth],

    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            columns: [],
            refresh: 0,
            rolesToAddAuthsIds: null,
            showAddAuthsModal: false,
            authsToAddIds: [],
            showDeleteConfirmModal: false,
            confirmDelete: null,
            elementToDelete: [],
            deleteModalHeader: '',
            deleteModalText: '',
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'ModificationDate',
            perPage: 10,
            page: 1,
            rolesInterval: null,
            filter: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                {
                    name: this.$t('ModificationDate'),
                    value: 'ModificationDate',
                },
            ],

            roleFormData: {
                Name: '',
                Id: '',
                Code: null,
                CreationUserId: '',
                Description: '',
            },
            showRoleModal: false,
            roleModalHeaderText: 'new_role',
            roleAuthIds: [],
            searchingCursor: false,
            userRoleIds: [],
            // userTypes: [
            //     {
            //         name: 'all',
            //         value: false,
            //         type: 'all',
            //     },
            //     {
            //         name: 'operators',
            //         value: false,
            //         type: 'operator',
            //     },
            //     {
            //         name: 'drivers',
            //         value: false,
            //         type: 'driver',
            //     },
            //     {
            //         name: 'clients',
            //         value: false,
            //         type: 'client',
            //     },
            //     {
            //         name: 'admins',
            //         value: false,
            //         type: 'admin',
            //     },
            //     {
            //         name: 'visitors',
            //         value: false,
            //         type: 'visitor',
            //     },
            // ],
        }
    },
    watch: {
        roles() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        this.columns= [
                {
                    name: 'Name',
                    filtrable: true,
                    isLink: checkAccess(
                        this.userStore,
                        this.rolesActionsAccess.update,
                    ),
                    // routeName: 'update_role',

                    dbField: 'Name',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'Code',
                    filtrable: true,
                    dbField: 'Code',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'Authorizations',
                    filtrable: false,
                    type: 'search',
                    sortable: false,
                    enum: true,
                    enumColNumber: 'grid-cols-5',
                    canRemoveEnum: checkAccess(
                        this.userStore,
                        this.rolesActionsAccess.delete,
                    ),
                },
                {
                    name: 'ModificationDate',
                    sortable: true,
                    dbField: 'ModificationDate',
                },
            ]
        
        const filteredList = this.$cookies.get('filteredRoles')
        this.searchUserNAuths()
        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            
        }
        this.setResults()

        window.scrollTo(0, 0)
    },
    validations() {
        return {
            roleFormData: {
                Name: { required: requiredIf(this.showRoleModal === true) },
                Description: {
                    required: requiredIf(this.showRoleModal === true),
                },
                Code: {
                    required: requiredIf(this.showRoleModal === true),
                },
            },
        }
    },
    computed: {
        rolesActionsAccess() {
            return actionsAccess.roles
        },
        ...mapStores(useUserStore),
        ...mapState(useRoleStore, {
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapWritableState(useRoleStore, {
            current: 'current',
            roles: 'all',
            count: 'count',
        }),
        authorisations() {
            return this.userStore.current.type === 'superadmin'
                ? this.allAuthorisations
                : this.userStore.current.Authorizations
        },
        ...mapWritableState(useAuthorisationStore, {
            allAuthorisations: 'all',
        }),
        ...mapWritableState(useAllUsersStore, {
            users: 'all',
        }),

       
        itemActions() {
            const actions = []
            if (checkAccess(this.userStore, this.rolesActionsAccess.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (checkAccess(this.userStore, this.rolesActionsAccess.delete)) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (checkAccess(this.userStore, this.rolesActionsAccess.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.rolesActionsAccess.assignAuthToRole,
                )
            ) {
                actions.push({
                    name: 'add_authorisations',
                    action: 'addAuthsToRoles',
                    icon: 'user-lock',
                })
            }

            return actions
        },
    },

    methods: {
        upFilter(event) {
            clearInterval(this.rolesInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(this.userStore, this.rolesActionsAccess.create)
        },
        ...mapActions(useRoleStore, {
            searchRoles: 'search',
            addRoleAuthorisation: 'addRoleAuthorisation',
            deleteRoleAuthorisation: 'deleteRoleAuthorisation',
            delete: 'delete',
            fetchOne: 'fetchOne',
            update: 'update',
            create: 'create',
        }),
        ...mapActions(useAuthorisationStore, {
            searchAuthorisations: 'search',
        }),
        ...mapActions(useAllUsersStore, {
            searchUsers: 'search',
        }),
        searchUserNAuths() {
            const search = {
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                perPage: 100000,
                page: this.page,
                query: [],
                filter: [],
            }
            const searchAuth = {
                sortDirection: 'ASC',
                sortField: 'Name',
                perPage: 100000,
                page: this.page,
                query: [],
                filter: [],
            }
            this.searchAuthorisations(searchAuth)

            this.searchUsers(search)
        },
        sendLink(id) {
            const user = this.users.find(
                (userToResetPW) => userToResetPW.id === id,
            )
            this.userStore.forgotPassword({
                Email: user.email,
            })
        },
        toCreateRole() {
            this.current = null
            this.roleModalHeaderText = 'new_role'
            this.showRoleModal = true
        },
        goToUpdateOne(id) {
            this.fetchOne(id).then((res) => {
                this.roleFormData = {
                    Authorizations: this.current.Authorizations,
                    Id: this.current.Id,
                    Name: this.current.Name,
                    Code: this.current.Code,
                    CreationUserId: this.current.CreationUserId,
                    CreationDate: this.current.CreationDate,
                    Description: this.current.Description,
                }
                this.current.Authorizations.forEach((auth) => {
                    this.roleAuthIds.push(auth.Id)
                })
                this.current.Users.forEach((user) => {
                    this.userRoleIds.push(user.Id)
                })
                this.roleModalHeaderText = 'update_role'
                this.showRoleModal = true
                this.editRole = true
            })
        },

        async submitRoleModal() {
            this.creating = true
            const codeIsAvailable = !this.notPickableCodes(
                this.count,
                this.roles,
                this.roleFormData.Id,
            ).includes(this.roleFormData.Code)

            if (codeIsAvailable === false) {
                this.creating = false
                this.$toast.error(this.$t('code_already_exists'))
                return
            }

            const isValid = await this.v$.$validate()

            if (isValid === true) {
                this.roleFormData.Authorizations = []
                this.roleAuthIds.forEach((id) => {
                    this.roleFormData.Authorizations.push({ Id: id })
                })
                this.roleFormData.Users = []
                this.userRoleIds.forEach((id) => {
                    this.roleFormData.Users.push({ Id: id })
                })
                if (this.editRole === true) {
                    this.roleFormData.ModificationUserId =
                        this.userStore.current.id
                    const response = await this.update({
                        id: this.roleFormData.Id,
                        form: this.roleFormData,
                    })
                    if (response.status === 200) {
                        this.creating = false

                        this.setResults()
                        this.searchUserNAuths()
                        this.$toast.success(this.$t('update_ok'))
                        this.closeRoleModal()
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('update_ko'))
                    }
                } else {
                    this.roleFormData.CreationUserId = this.userStore.current.id

                    const response = await this.create(this.roleFormData)
                    if (response.status === 201) {
                        this.creating = false

                        this.setResults()
                        this.searchUserNAuths()

                        this.$toast.success(this.$t('create_ok'))

                        this.closeRoleModal()
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('create_ko'))
                    }
                }
            } else {
                this.creating = false
                this.$toast.error(this.$t('form_errors'))
            }
        },
        closeRoleModal() {
            this.current = null
            this.roleAuthIds = []
            this.userRoleIds = []
            this.creating = false
            this.v$.roleFormData.$reset()
            this.showRoleModal = false
            this.editRole = false
            this.roleFormData = {
                Name: '',
                Id: '',
                Code: null,
                CreationUserId: '',
                Description: null,
            }
        },
        async deleteOne(id) {
            this.confirmDelete = null

            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = []

            const toDelete = this.roles.find((role) => role.Id === id)
            this.elementToDelete.push(toDelete.Name)

            const deleteInterval = setInterval(async () => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.searchingCursor = true

                        const response = await this.delete(id)
                        if (response.status === 200) {
                            this.setResults()
                            this.searchUserNAuths()
                            this.searchingCursor = false
                            this.$toast.success(this.$t('delete_ok'))
                            this.confirmDelete = null
                        } else {
                            this.searchingCursor = false
                            this.confirmDelete = null

                            this.$toast.error(this.$t('delete_ko'))
                        }
                    }
                }
            }, 150)
        },
        removeRoleAuth(auth) {
            this.confirmDelete = null

            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = []

            this.elementToDelete.push(
                `${this.$t('authorisation')} : ${auth.Name}`,
            )

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.searchingCursor = true

                        this.deleteRoleAuthorisation(auth.IdRoleAuthorization)
                            .then(() => {
                                this.setResults()
                                this.searchUserNAuths()
                                this.searchingCursor = false
                                this.confirmDelete = null

                                this.$toast.success(this.$t('deleted_ok'))
                            })
                            .catch(() => {
                                this.searchingCursor = false
                                this.confirmDelete = null

                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    }
                }
            }, 150)
        },
        deleteConfirmation(confirmDelete) {
            this.confirmDelete = confirmDelete
            this.searchingCursor = confirmDelete
            this.showDeleteConfirmModal = false
        },
        deleteAll(elementsTodelete) {
            this.confirmDelete = null

            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text_plural'
            this.elementToDelete = []
            elementsTodelete.forEach((el) => {
                const toDelete = this.roles.find((role) => role.Id === el)
                this.elementToDelete.push(toDelete.Name)
            })

            const deleteInterval = setInterval(async () => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.searchingCursor = true

                        elementsTodelete.forEach((el, index) => {
                            this.searchingCursor = true

                            this.delete(el).then(() => {
                                if (index === elementsTodelete.length - 1) {
                                    this.searchingCursor = false
                                    this.setResults()
                                    this.searchUserNAuths()
                                    this.confirmDelete = null
                                }
                            })
                        })
                    }
                }
            }, 150)
        },
        showAddAuthsToRoles(rolesIds = []) {
            this.authsToAddIds = []
            this.rolesToAddAuthsIds = rolesIds

            this.showAddAuthsModal = !this.showAddAuthsModal
        },
        addAuthsToRoles() {
            this.searchingCursor = true

            this.rolesToAddAuthsIds.forEach((roleId, index) => {
                this.authsToAddIds.forEach((authId) => {
                    const payload = {
                        IdRole: roleId,
                        IdAuthorization: authId,
                        CreationUserId: this.userStore.current.id,
                        ModificationUserId: this.userStore.current.id,
                    }
                    this.addRoleAuthorisation(payload)
                })
                if (index === this.rolesToAddAuthsIds.length - 1) {
                    this.searchingCursor = false
                    this.setResults()
                    this.showAddAuthsToRoles()
                    this.searchUserNAuths()
                }
            })
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.rolesInterval)
            this.$cookies.remove('filteredRoles')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.rolesInterval)
            this.$cookies.remove('filteredRoles')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)

            this.searchRoles(search).then(() => {
                this.refresh += 1
                this.searchingCursor = false
            })

            this.$cookies.set('filteredRoles', search)
            this.rolesInterval = setInterval(() => {
                this.searchRoles(search)
            }, 20000)

            window.scrollTo(0, 0)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.rolesInterval)
            this.$cookies.remove('filteredRoles')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },
        setResults() {
            clearInterval(this.rolesInterval)
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
    unmounted() {
        clearInterval(this.rolesInterval)
        this.current = null
        this.users = null
        this.roles = null
        this.count = null
        this.authorisations = null
    },
}
</script>
