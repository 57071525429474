<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'harbors'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="harborsList"
                :items="harbors"
                :columns="columns"
                :storeToSort="'harbors'"
                :page="page"
                :count="count"
                :loading="loading"
                :summaryMap="true"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @clear="clearFilter($event)"
                @create="openModal(null, true)"
                @update="openModal($event)"
                @delete="doDelete($event)"
                @deleteMany="doDeleteMany($event)"
                @removedocks="doDeleteDock($event)"
                @openMap="openSummaryMap($event)"
            >
            </filtrable-table>
        </div>
        <!-- :headerText="isUpdate ? $t('update_contract') : $t('add_contract')"
        :buttonText="isUpdate ? 'update' : 'save'"
        :spin="creatingContract"
        :creating="creatingContract" -->
        <!-- @closeModal="resetContractForm()"
        @sendFromModal="isUpdate ? updateCurrentContract() : saveContract()" -->
        <modal
            v-if="show"
            id="addModal"
            :mxWidth="'w-2/3'"
            :canCancel="false"
            :showSubmit="false"
            :mxHeight="'max-h-screen'"
            :buttonAlign="'justify-center'"
        >
            <template #body
                ><create-or-update-slider
                    :isUpdate="isUpdate"
                    :harborId="harborId"
                    @cancel="openModal()"
                    @save="openModal()"
                ></create-or-update-slider></template
        ></modal>
        <modal
            v-if="showSummaryMap"
            :showSubmit="false"
            :mxHeight="'max-h-screen'"
            :cancelText="'close'"
            :mxWidth="'w-2/3'"
            id="summarymodal"
            :buttonAlign="'justify-center'"
            @closeModal="openSummaryMap()"
        >
            <template #body>
                <div class="p-4">
                    <h2 class="text-lg theme-color font-bold mb-4">
                        {{
                            `${currentHarbor.name} - ${currentHarbor.description} - ${currentHarbor.address} ${currentHarbor.country}`
                        }}
                    </h2>
                    <div class="grid grid-cols-4 gap-2">
                        <div
                            v-for="(dock, index) in currentHarbor.docks"
                            :key="index"
                            class="text-xs col-span-1 flex items-center overflow-auto"
                        >
                            <span
                                @click="drawLines(dock, index)"
                                class="border-2 hover:font-semibold rounded p-2 w-full h-full cursor-pointer"
                            >
                                <div
                                    class="font-semibold mb-1"
                                    :title="$t('name')"
                                >
                                    {{ `${dock.Name}` }}
                                </div>

                                <div
                                    v-if="dock.Description"
                                    class="italic"
                                    :title="$t('description')"
                                >
                                    {{ `${dock.Description}` }}
                                </div>
                                <div class="italic" :title="$t('defenses')">
                                    <fa-icon icon="shield" class="mr-1 mt-2" />
                                    {{
                                        `${dock.Defenses && dock.Defenses.length > 0 ? dock.Defenses.length : 0} ${$t(
                                            'def_tc',
                                            dock.Defenses.length <= 1 ? 1 : 2,
                                        )}`
                                    }}
                                </div>
                                <div
                                    v-if="
                                        currentHarbor.inspections &&
                                        currentHarbor.inspections.length > 0
                                    "
                                    class="italic text-xs"
                                >
                                    <fa-icon
                                        icon="magnifying-glass"
                                        class="mr-1 mt-2"
                                    />
                                    {{
                                        ` ${
                                            $t('last_inspection') +
                                            ' ' +
                                            formattedDate(
                                                getDockInscpection(dock.Id)
                                                    .CreationDate,
                                                true,
                                            ) +
                                            ' - ' +
                                            $t(
                                                getDockInscpection(dock.Id)
                                                    .Status,
                                            )
                                        }`
                                    }}
                                </div>
                            </span>
                        </div>
                    </div>
                    <div id="summaryMap" class="h-[600px] w-full mt-4"></div>
                </div>
            </template>
        </modal>
        <modal
            v-if="showConfirmModal"
            :headerText="confirmModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :buttonAlign="'justify-center'"
            @closeModal="actionConfirmation(false)"
            @sendFromModal="actionConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center font-semibold mt-4">
                        {{ $t(confirmModalText) }}
                    </div>
                    <div class="flex justify-center font-semibold mt-4">
                        {{
                            $t(
                                entityTypeToDelete,
                                elementsToConfirm.length === 1 ? 1 : 2,
                            )
                        }}
                    </div>
                    <div
                        v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                        :key="iToConfirm"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span v-if="toConfirm.name" class="mr-1">
                                    {{
                                        `${toConfirm.name} - ${toConfirm.description}`
                                    }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="flex items-center justify-center mt-4">
                        {{ $t(confirmModalText3) }}
                    </div>
                    <div
                        class="flex text-xs italic items-center error-msg justify-center mt-4"
                    >
                        <fa-icon
                            :icon="'triangle-exclamation'"
                            class="mr-2 fa-md"
                        ></fa-icon>
                        {{ $t(confirmModalText2) }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import colors from '@/resources/colors'
import { mapActions, mapStores, mapWritableState } from 'pinia'

import filterList from '@/mixins/filterList'
import gmapStyles from '@/mixins/gmapStyles'
import googleScriptLoader from '@/mixins/googleScript'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useHarborStore } from '@/stores/harborStore'
import { useUserStore } from '@/stores/userStore'
import CreateOrUpdateSlider from '@/views/harbors/CreateOrUpdateSlider.vue'

let lines = []
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Harbors',
    mixins: [filterList, gmapStyles, googleScriptLoader],
    components: {
        FiltrableTable,
        FetchDataError,
        CreateOrUpdateSlider,
        Modal,
    },
    data() {
        return {
            entityTypeToDelete: 'harbor_tc',
            showSummaryMap: false,
            harborId: null,
            isUpdate: false,
            show: false,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            harborsInterval: null,
            formData: {},
            filter: [],
            columns: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
            showConfirmModal: false,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModalText: 'confirm_modal_text',
            confirmModalText2: 'confirm_modal_text',
        }
    },
    watch: {
        harbors() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filteredHarbors')
        this.columns = [
            {
                name: 'name',
                filtrable: true,
                dbField: 'Name',
                type: 'search',
                sortable: true,
                isLink: checkAccess(
                    this.userStore,
                    this.harborsActionsAccess.update,
                ),
            },
            {
                name: 'country',
                type: 'search',
                dbField: 'Country',
                filtrable: true,
                sortable: true,
            },
            {
                name: 'description',
                filtrable: false,

                dbField: 'Description',
                sortable: false,
            },
            {
                name: 'address',
                filtrable: false,
                sortable: false,
            },
            {
                name: 'docks',
                filtrable: false,
                sortable: false,
                enum: true,
                canRemoveEnum: checkAccess(
                    this.userStore,
                    this.harborsActionsAccess.update,
                ),
                enumColNumber: 'grid-cols-3',
            },
            // {
            //     name: 'creation_date',
            //     sortable: true,
            //     dbField: 'CreationDate',
            // },
        ]

        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        this.setResults()
        this.loadGoogleScript()
    },

    unmounted() {
        clearInterval(this.harborsInterval)
    },
    computed: {
        harborsActionsAccess() {
            return actionsAccess.harbors
        },
        ...mapWritableState(useHarborStore, {
            currentHarbor: 'current',
            harbors: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),

        ...mapStores(useUserStore),

        itemActions() {
            const actions = []
            if (checkAccess(this.userStore, this.harborsActionsAccess.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (checkAccess(this.userStore, this.harborsActionsAccess.delete)) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (checkAccess(this.userStore, this.harborsActionsAccess.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteMany',
                    icon: 'trash-can',
                })
            }
            return actions
        },
    },
    methods: {
        ...mapActions(useHarborStore, [
            'searchHarbors',
            'deleteHarbor',
            'deleteDock',
            'deleteManyHarbors',
        ]),
        canAddNew() {
            return checkAccess(this.userStore, this.harborsActionsAccess.create)
        },
        upFilter(event) {
            clearInterval(this.harborsInterval)
            this.updateFilter(event)
        },
        getDockInscpection(dockId) {
            return this.currentHarbor.inspections.find(
                (insp) => insp.IdDock === dockId,
            )
        },
        formattedDate(date, withTime = false) {
            let formatted = new Date(date).toLocaleDateString(this.$i18n.locale)

            if (withTime) {
                formatted = new Date(date).toLocaleTimeString(
                    this.$i18n.locale,
                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    },
                )
            }

            return `${formatted}`
        },
        openSummaryMap(harbor = null) {
            this.currentHarbor = null

            if (harbor) {
                this.currentHarbor = harbor

                const markers = []

                const labels = {
                    harbor: {
                        text: '\ue532',
                        fontFamily: 'Material Icons',
                        color: '#000000',
                        fontSize: '18px',
                    },
                    dock: {
                        text: '\uf1cd',
                        fontFamily: 'Material Icons',
                        color: '#000000',
                        fontSize: '18px',
                    },
                    defense: {
                        text: '\ue9e0',
                        fontFamily: 'Material Icons',
                        color: '#000000',
                        fontSize: '18px',
                    },
                }
                this.showSummaryMap = true

                // push dans markers tous les docks et les defenses
                markers.push({
                    position: {
                        lat: parseFloat(this.currentHarbor.latitude),
                        lng: parseFloat(this.currentHarbor.longitude),
                    },
                    label: labels.harbor,
                    title: `${this.$t('harbor')} - ${this.currentHarbor.name}`,
                })
                this.currentHarbor.docks.forEach((dock) => {
                    console.log(dock)
                    markers.push({
                        position: {
                            lat: parseFloat(dock.Latitude),
                            lng: parseFloat(dock.Longitude),
                        },
                        label: labels.dock,
                        title: `${this.$t('dock')} - ${dock.Name}`,
                    })
                    dock.Defenses.forEach((defense) => {
                        markers.push({
                            position: {
                                lat: parseFloat(defense.Latitude),
                                lng: parseFloat(defense.Longitude),
                            },
                            label: labels.defense,

                            title: `${this.$t('defense')} - ${defense.Name}`,
                        })
                    })
                })
                const googleInterval = setInterval(() => {
                    if (document.getElementById('summaryMap') && google) {
                        clearInterval(googleInterval)

                        this.map = new google.maps.Map(
                            document.getElementById('summaryMap'),
                            {
                                center: {
                                    lat: parseFloat(
                                        this.currentHarbor.latitude,
                                    ),

                                    lng: parseFloat(
                                        this.currentHarbor.longitude,
                                    ),
                                },
                                zoom: 12,
                            },
                        )

                        markers.forEach((mark) => {
                            const marker = new google.maps.Marker({
                                animation: google.maps.Animation.DROP,
                                position: mark.position,
                                label: mark.label,
                                title: mark.title,
                                draggable: false,
                                clickable: false,
                            })
                            marker.setMap(this.map)
                        })
                        this.setGoogleMapStyle(false)
                    }
                }, 20)
            } else {
                this.showSummaryMap = false
                lines = []
            }
        },
        openModal(id = null, create = false) {
            this.isUpdate = false
            this.harborId = null
            clearInterval(this.harborsInterval)
            if (id) {
                this.harborId = id
                this.isUpdate = true
            } else {
                this.currentHarbor = null
                if (create === false) {
                    this.setResults()
                }
            }
            this.show = !this.show
        },
        drawLines(dock, index) {
            if (lines.find((l) => l[`${dock.Name}-${index}`])) {
                lines.forEach((line) => {
                    line[`${dock.Name}-${index}`].setVisible(false)
                    // line[`${dock.Name}-${index}`].setMap(this.maps['summaryMap'])
                    line[`${dock.Name}-${index}`].setMap(null)
                    lines.splice(index, 1)
                })
            } else {
                const coordinatesForPolylines = []
                let tourPath = null

                const harborPosition = {
                    lat: parseFloat(this.currentHarbor.latitude),
                    lng: parseFloat(this.currentHarbor.longitude),
                }
                coordinatesForPolylines.push(harborPosition)

                const dockPosition = {
                    lat: parseFloat(dock.Latitude),
                    lng: parseFloat(dock.Longitude),
                }
                coordinatesForPolylines.push(dockPosition)

                dock.Defenses.forEach((defense, index) => {
                    coordinatesForPolylines.push({
                        lat: parseFloat(defense.Latitude),
                        lng: parseFloat(defense.Longitude),
                    })
                })
                let polyline = null

                tourPath = {
                    path: coordinatesForPolylines,
                    // geodesic: true, // hearth curves
                    strokeColor: colors[index].main.hex,
                    strokeOpacity: 1.0,
                    fillOpacity: 0,
                    strokeWeight: 3,
                }
                polyline = new google.maps.Polyline(tourPath)
                polyline.setMap(this.map)
                lines.push({ [`${dock.Name}-${index}`]: polyline })
            }
        },
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.showConfirmModal = false
        },
        doDelete(id) {
            clearInterval(this.harborsInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            this.entityTypeToDelete = 'harbor_tc'

            const harbor = this.harbors.find((h) => h.id === id)
            this.elementsToConfirm.push(harbor)
            this.showConfirmModal = true

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteHarbor(id)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        doDeleteMany(ids) {
            clearInterval(this.harborsInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.entityTypeToDelete = 'harbor_tc'

            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text_plural'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            this.showConfirmModal = true

            ids.forEach((id) => {
                const harbor = this.harbors.find((h) => h.id === id)
                this.elementsToConfirm.push(harbor)
            })

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteManyHarbors(ids)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },

        doDeleteDock(dock) {
            clearInterval(this.harborsInterval)
            clearInterval(this.harborsInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.entityTypeToDelete = 'dock_tc'
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            dock.description = dock.Description
            dock.name = dock.Name
            this.elementsToConfirm.push(dock)
            this.showConfirmModal = true

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteDock(dock.Id)
                            .then(() => {
                                this.setResults()
                                this.$toast.success(this.$t('delete_ok'))
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('delete_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.harborsInterval)
            this.$cookies.remove('filteredHarbors')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.harborsInterval)
            this.$cookies.remove('filteredHarbors')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.searchHarbors(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filteredHarbors', search)
            this.harborsInterval = setInterval(() => {
                this.searchHarbors(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.harborsInterval)
            this.$cookies.remove('filteredHarbors')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
