import { useHarborStore } from '@/stores/harborStore'
import { mapActions } from 'pinia'

export default {
    name: 'multiselectDebouncer',
    data() {
        return {
            debounceHarbor: null,
            debounceDock: null,
            dockSearchQuery: '',
            dockOptions: [],
            harborSearchQuery: '',
            harborOptions: [],
            noHarborError: false,
            noDockError: false,
        }
    },
    methods: {
        ...mapActions(useHarborStore, ['searchHarbors', 'searchDocks']),
        searchHarborsQuery(query) {
            this.noHarborError = false
            this.harborSearchQuery = query
            if (query !== '') {
                clearTimeout(this.debounceHarbor)
                const filter = []

                this.debounceHarbor = setTimeout(() => {
                    this.searchHarbors({
                        query: [
                            { dbField: 'Name', term: this.harborSearchQuery },
                        ],
                        filter,
                        sortDirection: 'ASC',
                        sortField: 'Name',
                        perPage: 10000,
                        page: 1,
                    })
                        .then((res) => {
                            this.harborOptions = []

                            res.data.Records.forEach((harbor) => {
                                const option = {
                                    name: `${harbor.Name}`,
                                    value: +harbor.Id,
                                    Id: harbor.Id,
                                    Name: harbor.Name,
                                    Description: harbor.Description,
                                    Latitude: harbor.Latitude,
                                    Longitude: harbor.Longitude,
                                    Address1: harbor.Address1,
                                    Address2: harbor.Address2,
                                    City: harbor.City,
                                    Country: harbor.Country,
                                    PostalCode: harbor.PostalCode,
                                    Docks: harbor.Docks,
                                }
                                this.harborOptions.push(option)
                            })
                        })
                        .catch((err) => {
                            this.noHarborError = true
                        })
                }, 200)
            }
        },
        searchDocksQuery(query) {
            this.noDockError = false
            this.harborSearchQuery = query
            if (query !== '') {
                clearTimeout(this.debounceDock)
                const filter = []

                this.debounceDock = setTimeout(() => {
                    this.searchDocks({
                        query: [
                            { dbField: 'Name', term: this.dockSearchQuery },
                        ],
                        filter,
                        sortDirection: 'ASC',
                        sortField: 'Name',
                        perPage: 10000,
                        page: 1,
                    })
                        .then((res) => {
                            this.dockOptions = []

                            res.data.Records.forEach((dock) => {
                                const option = {
                                    name: `${dock.Name}`,
                                    value: +dock.Id,
                                    Id: dock.Id,
                                    Name: dock.Name,
                                    Description: dock.Description,
                                    Latitude: dock.Latitude,
                                    Longitude: dock.Longitude,
                                    Defenses: dock.Defenses,
                                }
                                this.dockOptions.push(option)
                            })
                        })
                        .catch((err) => {
                            this.noDockError = true
                        })
                }, 200)
            }
        },
    },
}
