import { defineStore } from 'pinia'

const useDashboardStore = defineStore('dashboard', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        counts: [],
        notifications: null,
        drivers: null,
        clockedInCount: 0,
        ordersCounts: null,
        billsCounts: null,
        vehiclesMaintenancesCounts: null,
        lateVehiclesMaintenancesCounts: null,
    }),

    actions: {
        getDashboard(userType, universes) {
            this.loading = true
            this.fetchError = false

            const type = userType === 'client' ? 'client' : 'operator'
            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/dashboard/${type}?universes=${universes}`,

                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        if (res.data) {
                            const formattedCounts = []
                            res.data.counts.forEach((count) => {
                                let bgColor = 'bg-red-100'
                                let color = 'error-msg'

                                let icon = 'pen'
                                if (count.name === 'waiting_for_web_payment') {
                                    icon = {
                                        iconA: 'euro-sign',
                                        iconB: 'hourglass',
                                        layer: true,
                                    }
                                }
                                if (count.name === 'unaffected_orders') {
                                    icon = {
                                        stack: true,
                                        iconA: 'ban',
                                        iconB: 'route',
                                    }
                                }
                                if (count.name === 'imported_from_file') {
                                    bgColor = 'bg-indigo-100'
                                    color = 'indigo-msg'
                                    icon = 'file-export'
                                }

                                const formattedCount = {
                                    value: count.count,
                                    bgColor,
                                    color,
                                    name: count.name,
                                    icon,
                                }
                                formattedCounts.push(formattedCount)
                            })
                            this.counts = formattedCounts
                            const formattedOrdersCounts = []
                            res.data.orders.forEach((count) => {
                                let bgColor = 'bg-red-100'
                                let color = 'error-msg'

                                let icon = 'pen'
                                if (
                                    count.name !== 'todays_total_orders' &&
                                    !count.global
                                ) {
                                    if (count.name === 'todays_total_tours') {
                                        // dark mode le texte est noir ne va pas avec le bg
                                        bgColor = 'bg-zinc-100'
                                        color = 'text-black'
                                        icon = {
                                            iconA: 'route',
                                            iconB: 'clock',
                                            smaller: true,
                                            layer: true,
                                        }
                                    }
                                    if (count.name === 'waiting_orders') {
                                        bgColor = 'bg-orange-100'
                                        color = 'warning-msg'
                                        icon = 'hourglass-half'
                                    }

                                    if (count.name === 'in_progress_orders') {
                                        bgColor = 'bg-indigo-100'
                                        color = 'indigo-msg'
                                        icon = 'route'
                                    }
                                    if (count.name === 'finished_orders') {
                                        bgColor = 'bg-green-100'
                                        color = 'success-msg'
                                        icon = 'flag-checkered'
                                    }
                                    const formattedCount = {
                                        value: count.count,
                                        bgColor,
                                        color,
                                        name: count.name,
                                        icon,
                                    }
                                    formattedOrdersCounts.push(formattedCount)
                                } else {
                                    this.orderGlobalCount = {
                                        value: count.count,
                                        bgColor: 'bg-zinc-100',
                                        color: 'text-black',
                                        name: count.name,
                                        icon: 'cart-shopping',
                                    }
                                }
                            })
                            this.ordersCounts = formattedOrdersCounts

                            const formattedBillsCounts = []
                            res.data.invoices.forEach((count) => {
                                const bgColor = 'bg-zinc-100'
                                const color = 'text-black'

                                let icon = {
                                    iconA: 'file-invoice-dollar',
                                    iconB: 'xmark',
                                    layer: true,
                                }

                                if (count.name === 'invoiced_not_paid') {
                                    icon = {
                                        iconA: 'file-invoice-dollar',
                                        iconB: 'hourglass-half',
                                        layer: true,
                                    }
                                }

                                const formattedCount = {
                                    value: count.count,
                                    bgColor,
                                    color,
                                    name: count.name,
                                    icon,
                                }
                                formattedBillsCounts.push(formattedCount)
                            })
                            this.billsCounts = formattedBillsCounts

                            this.vehiclesMaintenancesCounts =
                                res.data.vehiclesMaintenances
                            this.lateVehiclesMaintenancesCounts =
                                res.data.lateMaintenances
                            const formattedNotifications = []
                            res.data.notifications.forEach((count) => {
                                const bgColor = 'bg-red-100'
                                const color = 'error-msg'

                                let icon = {
                                    iconA: 'file-export',
                                    iconB: 'triangle-exclamation',
                                    layer: true,
                                }
                                let subtext = 'imports_errors_subtext'

                                if (count.name === 'simulations_errors') {
                                    icon = {
                                        iconA: 'road',
                                        iconB: 'triangle-exclamation',
                                        layer: true,
                                    }
                                    subtext = 'simulations_errors_subtext'
                                }

                                const formattedCount = {
                                    value: count.count,
                                    bgColor,
                                    color,
                                    name: count.name,
                                    subtext,
                                    icon,
                                }
                                formattedNotifications.push(formattedCount)
                            })

                            this.notifications = formattedNotifications
                            this.drivers = res.data.drivers
                            this.clockedInCount = 0

                            this.drivers.forEach((driver) => {
                                if (driver.clockedIn === true) {
                                    this.clockedInCount += 1
                                }
                            })
                        }
                        this.count = true
                        this.loading = false
                        this.fetchError = false
                        resolve(res)
                    })
                    .catch((err) => {
                        this.loading = false
                        this.count = null

                        this.fetchError = true
                        reject(err)
                    })
            })
        },
        fetchClientDashboard() {},
    },
})

export { useDashboardStore }
