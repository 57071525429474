import checkAccess from '@/resources/accessChecker'
import { useUserStore } from '@/stores/userStore'
import { createRouter, createWebHistory } from 'vue-router'
import { useToast } from 'vue-toast-notification'
import { useCookies } from 'vue3-cookies'
import routes from './index'

const router = createRouter({
    history: createWebHistory(),
    routes,
})
const { cookies } = useCookies()
const toast = useToast()
// const canAccess = true // set to false when authorisations are up

router.beforeEach((to, from, next) => {
    to.meta.map
        ? document.body.classList.add('overflow-hidden')
        : document.body.classList.remove('overflow-hidden')

    let canAccess = false

    const userStore = useUserStore()
    // set to uncomment when authorisations are up
    // if (canAccess === false && to.meta.authorisations) {
    canAccess = checkAccess(userStore, to.meta.authorisations)
    // }

    if (to.name === 'logout') {
        cookies.keys().forEach((cookie) => {
            if (
                cookie !== 'logo' &&
                cookie !== 'nightMode' &&
                cookie !== 'locale'
            ) {
                cookies.remove(cookie)
            }
        })

        return next({ name: 'login' })
    }

    if (to.name === 'login') {
        if (userStore.isLoggedIn === true || cookies.get('user_session')) {
            const accessibleRoute = routes.find(
                (route) =>
                    route.name !== 'resetPassword' &&
                    route.name !== 'login' &&
                    route.name !== 'sign_up' &&
                    route.name !== 'logout' &&
                    !route.path.includes('/:') &&
                    checkAccess(userStore, route.meta.authorisations),
            )
            return userStore.isB2C === true ||
                (to.name === 'dashboard' && canAccess === false)
                ? next({
                      name: accessibleRoute.name,
                  })
                : next({ name: 'dashboard' })
        }

        cookies.keys().forEach((cookie) => {
            if (
                cookie !== 'logo' &&
                cookie !== 'nightMode' &&
                cookie !== 'locale'
            ) {
                cookies.remove(cookie)
            }
        })
        return next()
    }
    if (
        to.name !== 'resetPassword' &&
        to.name !== 'login' &&
        to.name !== 'sign_up' &&
        !cookies.get('user_session')
    ) {
        cookies.keys().forEach((cookie) => {
            if (
                cookie !== 'logo' &&
                cookie !== 'nightMode' &&
                cookie !== 'locale'
            ) {
                cookies.remove(cookie)
            }
        })

        return next({ name: 'login' })
    }
    if (userStore.isLoggedIn === true && canAccess === false) {
        // toast.info(i18n.global.t('not_authorised'))

        const accessibleRoute = routes.find(
            (route) =>
                route.name !== 'resetPassword' &&
                route.name !== 'login' &&
                route.name !== 'sign_up' &&
                route.name !== 'logout' &&
                !route.path.includes('/:') &&
                checkAccess(userStore, route.meta.authorisations),
        )
        return next({ name: accessibleRoute.name })
    }
    if (
        (userStore.isLoggedIn === true || cookies.get('user_session')) &&
        (canAccess === true || to.meta.admin)
    ) {
        // if (
        //     (cookies.get('is_B2C') === 'true' || cookies.get('is_B2C') === true) &&
        //     to.name !== 'orders' &&
        //     to.name !== 'delivery_addresses' &&
        //     to.name !== 'new_order' &&
        //     to.name !== 'update_order' &&
        //     to.name !== 'new_address' &&
        //     to.name !== 'update_address' &&
        //     to.name !== 'my_account' &&
        //     to.name !== 'billings' &&
        //     to.name !== 'containers'
        // ) {
        //     return next({ name: 'orders' })
        // }

        if (
            !to.query.date &&
            !from.params.id &&
            !from.params.client_id &&
            from.name &&
            !from.name.includes('new') &&
            !to.params.id &&
            !to.params.client_id &&
            to.name &&
            !to.name.includes('new') &&
            to.name !== 'client_delivery_addresses' &&
            to.name !== 'contacts' &&
            !to.name.includes('tracking') &&
            !from.name.includes('tracking')
            // && from.name !== 'client_delivery_addresses' &&
            // from.name !== 'contacts'
        ) {
            cookies.remove('filtered')
        }
        if (
            // !from.params.id &&
            // !from.params.client_id &&
            from.name &&
            !from.name.includes('new') &&
            !from.name.includes('update') &&
            !to.params.id &&
            to.name &&
            !to.name.includes('new')
            // && from.name !== 'client_delivery_addresses' &&
            // from.name !== 'contacts'
        ) {
            cookies.remove('filtered_addresses')
            cookies.remove('filtered_clients_contacts')
        }
        return next()
    }
    // console.log(cookies.get('user'))
    // if (cookies.get('user')) {
    //     // IL FAUT AUSSI QUE LE REFRESH DE LA PAGE PERMETTE D'ACCEDER RECUPERER LE COOKIE ET VERIFIER LES ROLES
    //     // ajouter un autre param au routes accessCode : []
    //     // si le user.roles contien le access code alors canAccess === true
    // }
    if (userStore.isLoggedIn === false) {
        return next({ name: 'logout' })
    }

    return next({ name: 'login' })
})

export default router
