<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'sales'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="salesList"
                :items="sales"
                :columns="columns"
                :storeToSort="'sales'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddSales"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="toCreateSale()"
                @clear="clearFilter($event)"
                @update="toUpdateSale($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
                @generateBillForManySales="
                    generateBillSales($event, true, false)
                "
                @generateBillSales="generateBillSales($event)"
                @generateProformaSales="generateBillSales($event, false, true)"
                @generateProformaForManySales="
                    generateBillSales($event, true, true)
                "
            >
                <!-- @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)" -->
            </filtrable-table>
        </div>
        <modal
            v-if="show"
            :headerText="modalHeaderText"
            :buttonText="'save'"
            :creating="creating"
            id="addModal"
            :mxWidth="'w-1/2'"
            :buttonAlign="'justify-center'"
            @closeModal="closeModal()"
            @sendFromModal="submitModal()"
        >
            <template #body>
                <span class="mx-4">
                    <div class="justify-center flex my-2 mx-4 items-center">
                        <div
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                        >
                            {{ $t('name') + ' *' }}
                        </div>
                        <input
                            type="text"
                            v-model="formData.Name"
                            class="border rounded px-3 py-2 h-10 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.formData.Name.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="justify-center flex my-2 mx-4 items-center">
                        <div
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                        >
                            {{ $t('client') + ' *' }}
                        </div>
                        <Multiselect
                            v-if="!noClientError"
                            id="clientMS"
                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                            :class="
                                isDuplicate === true
                                    ? 'cursor-not-allowed'
                                    : 'cursor-pointer'
                            "
                            v-model="formData.IdClient"
                            :options="clientOptions"
                            label="name"
                            :key="refreshMS"
                            track-by="name"
                            :disabled="isDuplicate === true"
                            :searchable="true"
                            :placeholder="$t('select_client_info')"
                            :allow-empty="false"
                            :can-clear="false"
                            @select="
                                fetchContactsAddressesAndContainers($event)
                            "
                            @search-change="searchClientsQuery($event)"
                        ></Multiselect>
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.formData.IdClient.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <span
                        v-for="(sale, saleIndex) in formData.SaleDetails"
                        :key="saleIndex"
                    >
                        <span
                            class="grid grid-cols-12 gap-1 mt-5 mx-4 items-center"
                        >
                            <div
                                :class="
                                    sale.articleIcon === 'i-cursor'
                                        ? 'col-span-3'
                                        : 'col-span-4'
                                "
                            >
                                <span class="whitespace-nowrap text-sm">
                                    {{ $t('sale_name') }}
                                </span>
                                <div
                                    :key="refreshSales"
                                    class="articleMs flex px-3 darky p-2 rounded items-center"
                                    :class="'border'"
                                    :style="''"
                                >
                                    <fa-icon
                                        :key="refreshSales"
                                        :icon="sale.articleIcon"
                                        :disabled="!canAddSales"
                                        class="theme-color fa-xs fa-fade"
                                        :class="
                                            articleOptions.length > 0
                                                ? 'cursor-pointer'
                                                : ''
                                        "
                                        style="--fa-animation-duration: 2s"
                                        @click="
                                            articleOptions.length > 0
                                                ? selectOrCreateArticle(
                                                      sale.articleIcon,
                                                      saleIndex,
                                                  )
                                                : ''
                                        "
                                    ></fa-icon>
                                    <!--  i-cursor-->

                                    <input
                                        v-if="sale.articleIcon === 'i-cursor'"
                                        type="text"
                                        v-model="sale.Designation"
                                        :disabled="!canAddSales"
                                        class="text-xs ml-1 outline-none w-fit overflow-auto py-0.5"
                                    />
                                    <Multiselect
                                        v-else
                                        class="darky py-0.5"
                                        v-model="tempArticleId[saleIndex]"
                                        :options="articleOptions"
                                        :disabled="!canAddSales"
                                        :key="refreshSales"
                                        :searchable="true"
                                        :placeholder="$t('pick_article')"
                                        label="name"
                                        track-by="name"
                                        :can-clear="false"
                                        :can-deselect="false"
                                        :allow-empty="false"
                                        :object="true"
                                        @select="setArticle($event, saleIndex)"
                                    >
                                    </Multiselect>
                                </div>
                            </div>
                            <div class="col-span-2">
                                <span class="text-sm">
                                    {{ $t('quantity') }}
                                </span>
                                <input
                                    type="number"
                                    :disabled="!canAddSales"
                                    v-model="sale.Quantity"
                                    :min="1"
                                    step="1"
                                    class="border rounded px-3 py-2 h-10 w-full"
                                />
                            </div>

                            <div
                                v-if="sale.articleIcon === 'i-cursor'"
                                class="col-span-2"
                            >
                                <span class="text-sm">
                                    {{ $t('vat') }}
                                </span>
                                <div class="vatMS">
                                    <Multiselect
                                        class="darky"
                                        :disabled="!canAddSales"
                                        v-model="sale.VAT"
                                        :options="vatOptions"
                                        :key="refreshMS"
                                        :searchable="false"
                                        :can-clear="false"
                                        :can-deselect="false"
                                        :allow-empty="false"
                                    >
                                    </Multiselect>
                                </div>
                            </div>
                            <div
                                v-if="sale.articleIcon === 'i-cursor'"
                                class="col-span-2"
                            >
                                <span class="text-sm mx-2 whitespace-nowrap">
                                    {{ `${$t('total_ttc')}` }}
                                </span>
                                <input
                                    type="number"
                                    :disabled="
                                        !canAddSales ||
                                        (sale.TotalHT > 0 &&
                                            $route.name === 'new_order')
                                    "
                                    v-model="sale.TotalTTC"
                                    step="0.01"
                                    class="border rounded px-3 py-2 h-10 w-full"
                                    @change="sale.TotalHT = 0"
                                />
                            </div>
                            <div
                                v-if="sale.articleIcon === 'i-cursor'"
                                class="col-span-2"
                            >
                                <span class="text-sm mx-2 whitespace-nowrap">
                                    {{ `${$t('total_ht')}` }}
                                </span>
                                <input
                                    type="number"
                                    :disabled="
                                        !canAddSales ||
                                        (sale.TotalTTC > 0 &&
                                            $route.name === 'new_order')
                                    "
                                    v-model="sale.TotalHT"
                                    step="0.01"
                                    class="border rounded px-3 py-2 h-10 w-full"
                                    @change="sale.TotalTTC = 0"
                                />
                            </div>
                            <div
                                v-if="sale.articleIcon === 'basket-shopping'"
                                class="col-span-5 text-xs italic mt-2 overflow-auto"
                            >
                                {{ sale.article }}
                            </div>

                            <div class="col-span-1 mt-5">
                                <icon-button
                                    v-if="canDeleteSales"
                                    :icon="'trash-can'"
                                    class="justify-end"
                                    :noStyle="true"
                                    :iconStyle="'fa-md cursor-pointer error-msg'"
                                    :title="$t('delete')"
                                    @click.prevent="
                                        deleteSale(
                                            saleIndex,
                                            sale.Id === '' ? null : sale.Id,
                                        )
                                    "
                                />
                            </div>
                        </span>
                        <div
                            v-if="saleIndex === formData.SaleDetails.length - 1"
                            class="mt-4 flex justify-center"
                        >
                            <icon-button
                                :icon="'plus'"
                                class=""
                                :noStyle="true"
                                :iconStyle="'fa-lg cursor-pointer theme-color'"
                                :title="$t('add')"
                                @click.prevent="addSale()"
                            />
                        </div>
                    </span>
                </span>

                <span
                    class="flex mt-3 italic text-xs items-center justify-center"
                >
                    {{ $t('required_fields') }}
                </span>
            </template>
        </modal>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import Modal from '@/components/atoms/Modal.vue'
import clientMultiselectDebouncer from '@/mixins/clientMultiselectDebouncer'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useArticleStore } from '@/stores/articleStore'
import { useClientStore } from '@/stores/clientStore'
import { useSaleStore } from '@/stores/saleStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapState, mapStores } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'SalesList',
    mixins: [filterList, clientMultiselectDebouncer],

    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
        Multiselect,
        IconButton,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            colulmns: [],
            articleOptions: [],
            refresh: 0,
            isDefault: false,
            editSale: false,
            modalHeaderText: 'new_sale',
            creating: false,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            show: false,
            saleInterval: null,
            filter: [],
            sortableFields: [
                { name: this.$t('client'), value: 'ClientName' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],

            formData: {
                Name: '',
                IdClient: 0,
                SoloSale: true,
                IdClientAddress: 0,
                SaleDetails: [],
            },
        }
    },
    validations() {
        return {
            formData: {
                Name: { required },
                IdClient: { required },
                SoloSale: true,
                SaleDetails: { required },
            },
        }
    },
    watch: {
        sales() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    mounted() {
        this.isDefault = false
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filteredSales')
        this.columns = [
            // gérer le islink pour b2C
            {
                name: 'name',
                filtrable: true,

                isLink: checkAccess(
                    this.userStore,
                    this.salesActionsAccess.update,
                ),
                // routeName: 'update_sale',

                dbField: 'Name',
                type: 'search',
                sortable: true,
            },
            {
                name: 'client',
                filtrable: true,
                dbField: 'ClientName',
                type: 'search',
                sortable: true,
            },

            {
                name: 'ht',
                filtrable: false,
                sortable: false,
            },
            {
                name: 'ttc',
                filtrable: false,
                sortable: false,
            },
            {
                name: 'details',
                enum: true,
                isSales: true,
                filtrable: false,
                sortable: false,
            },

            {
                name: 'creation_date',
                sortable: true,
                dbField: 'CreationDate',
            },
        ]

        if (filteredList) {
            this.search = filteredList.query
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }

        this.setResults()
    },

    unmounted() {
        clearInterval(this.saleInterval)
    },
    computed: {
        billingsActionsAccess() {
            return actionsAccess.billings
        },
        salesActionsAccess() {
            return actionsAccess.orders.sales
        },
        canAccessSales() {
            return checkAccess(this.userStore, this.salesActionsAccess.view)
        },
        canAddSales() {
            return checkAccess(this.userStore, this.salesActionsAccess.create)
        },
        canUpdateSales() {
            return checkAccess(this.userStore, this.salesActionsAccess.update)
        },
        canDeleteSales() {
            return checkAccess(this.userStore, this.salesActionsAccess.delete)
        },
        ...mapStores(useUserStore),
        ...mapState(useSaleStore, {
            sales: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
            current: 'current',
        }),
        ...mapState(useArticleStore, {
            articles: 'all',
        }),
        ...mapStores(useSaleStore),
        vatOptions() {
            let vatOptions = [0, 2.1, 5.5, 10, 20]
            if (
                window.location.host.substring(
                    0,
                    window.location.host.indexOf('.'),
                ) === 'demo01'
            ) {
                vatOptions = [0, 1.05, 1.75, 2.5, 8.5]
            }
            return vatOptions
        },

        itemActions() {
            const actions = []
            if (checkAccess(this.userStore, this.salesActionsAccess.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(this.userStore, this.billingsActionsAccess.create)
            ) {
                actions.push({
                    name: 'generate_bill',
                    action: 'generateBillSales',
                    icon: 'file-invoice-dollar',

                    hide: false,
                })
                actions.push({
                    name: 'generate_proforma',
                    action: 'generateProformaSales',
                    icon: 'file-lines',

                    hide: false,
                })
            }

            if (checkAccess(this.userStore, this.salesActionsAccess.delete)) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.billingsActionsAccess.create)
            ) {
                actions.push({
                    name: 'generate_bill',
                    action: 'generateBillSales',
                    icon: 'file-invoice-dollar',

                    hide: false,
                })
                actions.push({
                    name: 'generate_proforma',
                    action: 'generateProformaSales',
                    icon: 'file-lines',

                    hide: false,
                })
            }
            if (checkAccess(this.userStore, this.salesActionsAccess.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }
            return actions
        },
    },
    methods: {
        ...mapActions(useArticleStore, {
            searchArticles: 'search',
        }),
        ...mapActions(useClientStore, {
            searchClients: 'search',
        }),
        upFilter(event) {
            clearInterval(this.saleInterval)
            this.updateFilter(event)
        },
        selectOrCreateArticle(currentIcon, extraSaleIndex) {
            this.formData.SaleDetails[extraSaleIndex].articleIcon =
                currentIcon === 'basket-shopping'
                    ? 'i-cursor'
                    : 'basket-shopping'
            if (
                this.formData.SaleDetails[extraSaleIndex].articleIcon ===
                'i-cursor'
            ) {
                this.formData.SaleDetails[extraSaleIndex].IdArticle = 0
                this.formData.SaleDetails[extraSaleIndex].article = 0

                this.tempArticleId[extraSaleIndex].value = null
            }
        },
        setArticle(article, extraSaleIndex) {
            const currentArticle = this.articles.find(
                (art) => +art.id === +article.value,
            )
            if (currentArticle !== -1) {
                if (!article.name) {
                    this.tempArticleId[extraSaleIndex].name =
                        currentArticle.name
                }
                let complString = ''
                if (currentArticle.ht) {
                    complString += ` • ${this.$t('ht')}: ${currentArticle.ht}`
                }
                if (currentArticle.ttc) {
                    complString += ` • ${this.$t('ttc')}: ${currentArticle.ttc}`
                }
                if (currentArticle.vat) {
                    complString += ` • ${this.$t('vat')}: ${currentArticle.vat}`
                }
                if (currentArticle.price_percent) {
                    complString += ` • ${this.$t('price_percent')}: ${currentArticle.price_percent}`
                }
                if (currentArticle.price_pack_ht) {
                    complString += ` • ${this.$t('price_pack_ht')}: ${currentArticle.price_pack_ht}`
                }
                if (currentArticle.coef) {
                    complString += ` • ${this.$t('coef')}: ${currentArticle.coef}`
                }
                this.formData.SaleDetails[extraSaleIndex].article =
                    `${currentArticle.description} ${complString}`
                // this.extraSales[extraSaleIndex].tempArticleId.value = +currentArticle.id
                this.formData.SaleDetails[extraSaleIndex].IdArticle =
                    +currentArticle.id

                this.refreshSales += 1
            }
        },
        generateBillSales(sales, many = false, proforma = false) {
            const salesPayload = []
            if (many === false) {
                salesPayload.push({ Id: sales })
            } else {
                if (!Array.isArray(sales)) {
                    sales = [sales]
                }
                sales.forEach((sale) => {
                    salesPayload.push({ Id: sale })
                })
            }
            this.saleStore
                .generateSalesInvoice({
                    Sales: salesPayload,
                    Proforma: proforma,
                })
                .then((res) => {
                    this.$toast.success(this.$t('invoice_generation_launched'))
                    if (proforma === true) {
                        this.axios
                            .get(`/api/v1/invoice/${res.data.Id}/pdf`, {
                                responseType: 'blob',
                                headers: {
                                    Authorization: `Bearer ${this.$cookies.get(
                                        'user_session',
                                    )}`,
                                },
                                signal: this.$controller.signal,
                            })
                            .then((res) => {
                                const url = URL.createObjectURL(res.data)
                                const link = document.createElement('a')
                                link.href = url
                                link.target = '_external'
                                document.body.appendChild(link)
                                link.click()
                                document.body.removeChild(link)
                                URL.revokeObjectURL(url)
                                // link.setAttribute('download', file)

                                // document.body.appendChild(link)
                                // link.click()
                                // document.body.removeChild(link)
                                // URL.revokeObjectURL(url)
                            })
                    }
                    this.setResults()
                })
                .catch((err) => {
                    this.$toast.error(this.$t('invoice_generation_failed'))
                    if (err.response.data.message) {
                        this.$toast.warning(this.$t(err.response.data.message))
                    }
                })
        },
        async deleteOne(sale) {
            this.searchingCursor = true

            const response = await this.saleStore.delete(sale)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('deleted_ok'))
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('deleted_ko'))
            }
        },

        toCreateSale() {
            this.articleOptions = []
            this.tempArticleId = []
            this.searchArticles({
                query: [],
                filter: [],
                perPage: 10000,
                page: 1,
                sortField: 'Name',
                sortDirection: 'ASC',
            }).then((res) => {
                if (res.status === 204) {
                    this.articleOptions = []
                } else {
                    res.data.Records.forEach((article) => {
                        this.articleOptions.push({
                            name: article.Name,
                            value: +article.Id,
                        })
                    })
                }
                this.formData.SaleDetails.push({
                    Designation: '',
                    VAT: 0,
                    TotalAmountVAT: 0,
                    Quantity: 1,
                    Reference: '',
                    UnitPriceHT: 0,
                    TotalHT: 0,
                    TotalTTC: 0,
                    CreationUserId: this.userStore.current.id,
                    Id: '',
                    IdSale: '',
                    IdArticle: 0,

                    articleIcon:
                        this.articleOptions.length > 0
                            ? 'basket-shopping'
                            : 'i-cursor',
                    article: null,
                })

                this.refreshMS += 1
                this.refreshSales += 1
                // }
            })
            clearInterval(this.saleInterval)
            // this.$router.push({ name: 'new_container' })
            this.saleStore.$patch({ current: null })
            this.modalHeaderText = 'new_sale'
            this.show = true
        },
        deleteSale(saleIndex, id = null) {
            this.formData.SaleDetails.splice(saleIndex, 1)
            if (id) {
                // TODO delete la sale dans la bdd avec l'id
            }
        },
        addSale() {
            this.articleOptions = []

            this.formData.SaleDetails.push({
                Designation: '',
                VAT: 0,
                TotalAmountVAT: 0,
                Quantity: 1,
                Reference: '',
                UnitPriceHT: 0,
                TotalHT: 0,
                TotalTTC: 0,
                CreationUserId: this.userStore.current.id,
                Id: '',
                IdSale: '',
                IdArticle: 0,

                articleIcon:
                    this.articleOptions && this.articleOptions.length > 0
                        ? 'basket-shopping'
                        : 'i-cursor',
                article: null,
            })
        },
        toUpdateSale(sale) {
            this.articleOptions = []
            this.isDefault = false
            clearInterval(this.saleInterval)

            this.modalHeaderText = 'update_sale'
            let id = sale
            if (sale.id) {
                id = sale.id
            }

            this.saleStore.$patch({ current: null })
            this.saleStore.fetchOne(id).then(() => {
                const saleInt = setInterval(() => {
                    if (this.saleStore.current !== null) {
                        clearInterval(saleInt)
                        this.searchArticles({
                            query: [],
                            filter: [],
                            perPage: 10000,
                            page: 1,
                            sortField: 'Name',
                            sortDirection: 'ASC',
                        }).then((res) => {
                            if (res.status === 204) {
                                this.articleOptions = []
                            } else {
                                res.data.Records.forEach((article) => {
                                    this.articleOptions.push({
                                        name: article.Name,
                                        value: +article.Id,
                                    })
                                })
                            }
                            this.formData.Name = this.saleStore.current.Name
                            this.formData.Id = this.saleStore.current.Id
                            this.formData.IdClientAddress =
                                this.saleStore.current.IdClientAddress ?? 0
                            this.formData.IdClient =
                                this.saleStore.current.IdClient

                            this.formData.SaleDetails = []
                            this.tempArticleId = []

                            if (this.saleStore.current.SaleDetails.length > 0) {
                                this.formData.SaleDetails = structuredClone(
                                    this.saleStore.current.SaleDetails,
                                )
                                this.formData.SaleDetails.forEach(
                                    (s, index) => {
                                        if (s.IdArticle && s.IdArticle > 0) {
                                            s.articleIcon = 'basket-shopping'

                                            const evt = {
                                                value: +s.IdArticle,
                                            }
                                            this.tempArticleId.push({
                                                value: +s.IdArticle,
                                            })
                                            this.setArticle(evt, index)
                                        } else {
                                            s.articleIcon = 'i-cursor'
                                            this.tempArticleId.push({
                                                value: null,
                                            })
                                        }
                                    },
                                )
                                this.refreshMS += 1
                                this.refreshSales += 1
                            }
                        })

                        // if (+this.current.IdClient === 0) {
                        //     this.$toast.warning(
                        //         this.$t('container_not_editable')
                        //     )
                        // } else {
                        this.editSale = true

                        // this.$router.push({
                        //     name: 'update_sale',
                        //     params: { id: this.current.Id },
                        // })

                        this.show = true
                    }
                    // }
                })
            })
        },
        closeModal() {
            this.isDefault = false

            this.creating = false
            this.v$.$reset()
            this.show = false
            this.editSale = false
            this.formData = {
                Name: '',
                IdClient: '',
                Id: '',
                IdClientAddress: 0,
                SoloSale: true,
                CreationUserId: '',
                SaleDetails: [],
            }
            this.setResults()
        },
        async submitModal() {
            this.searchingCursor = true

            this.creating = true
            const isValid = await this.v$.$validate()
            if (isValid === true) {
                if (this.editSale === true) {
                    this.formData.ModificationUserId = this.userStore.current.id

                    const response = await this.saleStore.update({
                        id: this.formData.Id,
                        form: this.formData,
                    })
                    if (response.status === 200) {
                        this.searchingCursor = false

                        this.$toast.success(this.$t('update_ok'))
                        // this.$router.push({ name: 'sales' })
                        this.setResults()
                        this.closeModal()
                    } else {
                        this.searchingCursor = false

                        this.$toast.error(this.$t('update_ko'))
                    }
                } else {
                    this.formData.CreationUserId = this.userStore.current.id

                    const response = await this.saleStore.create(this.formData)
                    if (response.status === 201) {
                        this.searchingCursor = false

                        this.setResults()

                        this.closeModal()
                    } else {
                        this.searchingCursor = false

                        this.$toast.error(this.$t('create_ko'))
                    }
                }
            } else {
                this.creating = false
                this.searchingCursor = false

                this.$toast.error(this.$t('form_errors'))
                return false
            }
        },
        deleteAll(elToDelete) {
            elToDelete.forEach((el) => {
                this.saleStore.delete(el)
            })
        },

        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.saleInterval)
            this.$cookies.remove('filteredSales')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.saleInterval)
            this.$cookies.remove('filteredSales')
            this.search = search.query

            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
            this.saleStore.search(search).then(() => {
                this.searchingCursor = false
                this.refresh += 1
            })
            this.saleInterval = setInterval(() => {
                this.saleStore.search(search)
            }, 20000)

            this.$cookies.set('filteredSales', search)

            this.refresh += 1
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            this.perPage = perPage
            this.page = 1
            clearInterval(this.saleInterval)
            this.$cookies.remove('filteredSales')
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
<style lang="scss" scoped>
.selectMS ::v-deep(.multiselect) {
    .multiselect-placeholder {
        font-size: small !important;
    }
}
</style>
