<template>
    <main style="width: 96vw" class="px-4 rounded-md h-full">
        <div class="flex darky bg-white pt-1 px-2 items-center mt-14">
            <ul
                v-for="(tab, index) in tabs"
                :key="index"
                class="flex flex-wrap -mb-px text-sm font-bold text-center text-zinc-400"
            >
                <li class="mr-2 cursor-pointer">
                    <div
                        :title="$t(tab.name)"
                        class="p-2 rounded-t border-b-2 border-r-2 border-t-2 tab-highlight group"
                        @click.stop="tabToShow = tab.name"
                        :class="
                            tabToShow === tab.name
                                ? 'nav-tab rounded-t border-b-2  active'
                                : ''
                        "
                    >
                        <!-- <fa-con :icon="tab.icon" class="mr-2"></fa-con> -->
                        <div class="hidden xl:inline nav-tab-text">
                            <fa-icon
                                :icon="tab.icon"
                                class="fa-md mr-2"
                            ></fa-icon>
                            <span>
                                {{ $t(tab.name) }}
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <span v-if="tabToShow === 'clients'">
            <div class="grid grid-cols-12 gap-2">
                <div class="col-span-4">
                    <div
                        class="mt-3 py-4 px-1 items-center flex justify-center"
                    >
                        <Multiselect
                            :key="refreshMS"
                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                            v-model="clientConfigForm.ClientData.Id"
                            :options="clientOptions"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :placeholder="$t('select_client_info')"
                            :allow-empty="false"
                            :can-clear="false"
                            @select="setClient($event)"
                            @search-change="searchClientsQuery($event)"
                        ></Multiselect>
                    </div>
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.clientConfigForm.ClientData.Id
                            .$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                    <div class="mt-1 p-1 justify-center">
                        <label
                            class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2"
                            >{{ $t('processor_to_use') + ' *' }}
                        </label>
                        <input
                            type="text"
                            :placeholder="$t('processor_placeholder')"
                            v-model="
                                clientConfigForm.ClientData.ProcessorImportId
                            "
                            @blur="
                                v$.clientConfigForm.ClientData.ProcessorImportId.$touch()
                            "
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                    </div>

                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.clientConfigForm.ClientData
                            .ProcessorImportId.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <div class="col-span-8">
                    <div class="justify-center mt-1">
                        <label
                            class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                            >{{ $t('client_keys') }}
                        </label>
                        <textarea
                            v-model="clientConfigForm.ClientKeyWords"
                            :placeholder="$t('keywords_placeholder')"
                            class="border rounded px-3 py-2 mt-1 w-full h-32"
                        />
                    </div>
                </div>
            </div>
            <button
                type="submit"
                @click="saveClientImportConfig()"
                class="themed-button text-base mt-4 w-1/2 focus:outline-none px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
            >
                {{ $t('save') }}
            </button>
        </span>

        <span v-else>
            <div class="grid grid-cols-12 gap-2">
                <div class="col-span-4">
                    <div
                        class="items-center mb-4 pt-7 pb-2 px-1 flex justify-center"
                    >
                        <Multiselect
                            :key="refreshMS"
                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                            v-model="addressConfigForm.IdClient"
                            :options="clientOptions"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :placeholder="$t('select_client_info')"
                            :allow-empty="false"
                            :can-clear="false"
                            @select="setClient($event)"
                            @search-change="searchClientsQuery($event)"
                        ></Multiselect>
                    </div>
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.addressConfigForm.IdClient.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                    <div
                        v-if="addressConfigForm.IdClient !== 0"
                        class="items-center p-1 justify-center"
                    >
                        <label
                            class="font-semibold basis-1/4 text-gray-600 text-sm mt-1 pb-1 mr-2"
                            >{{ $t('address') + ' *' }}
                        </label>
                        <Multiselect
                            :key="refreshMS"
                            class="h-10 rounded px-3 py-2 w-full darky"
                            v-model="addressConfigForm.Id"
                            :options="addressOptions"
                            :loading="addressOptions.length === 0"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :placeholder="$t('select_address_info')"
                            :allow-empty="false"
                            :can-clear="false"
                            @select="setAddress($event)"
                        ></Multiselect>
                    </div>
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.addressConfigForm.Id.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <div class="col-span-8">
                    <div
                        v-if="addressConfigForm.IdClient !== 0"
                        class="justify-center mt-1"
                    >
                        <label
                            class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                            >{{ $t('address_keys') }}
                        </label>
                        <textarea
                            v-model="addressConfigForm.KeyWords"
                            :placeholder="$t('keywords_placeholder')"
                            class="border rounded px-3 py-2 mt-1 h-32 w-full"
                        />
                    </div>
                </div>
            </div>
            <button
                type="submit"
                @click="saveAddressKeywords()"
                class="themed-button mt-4 text-base w-1/2 focus:outline-none px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
            >
                {{ $t('save') }}
            </button>
        </span>
    </main>
</template>

<script>
// import { SwaggerUIStandalonePreset } from 'swagger-ui-dist'

import clientMultiselectDebouncer from '@/mixins/clientMultiselectDebouncer'
import { useClientStore } from '@/stores/clientStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapState } from 'pinia'

export default {
    name: 'ImportConfig',
    setup() {
        return { v$: useVuelidate() }
    },
    components: {
        Multiselect,
    },

    mixins: [clientMultiselectDebouncer],
    data() {
        return {
            selectedClient: 0,
            selectedAddress: 0,
            refreshMS: 0,
            clientConfigForm: {
                ClientData: {
                    Id: 0,
                    ProcessorImportId: '',
                },
                ClientKeyWords: [],
            },
            addressOptions: [],
            addressConfigForm: {
                Id: 0,
                KeyWords: [],
                IdClient: 0,
            },
            tabs: [
                { name: 'clients', icon: 'users' },
                { name: 'delivery_addresses', icon: 'address-book' },
            ],
            tabToShow: 'clients',
        }
    },
    computed: {
        ...mapState(useClientStore, { currentClient: 'current' }),
    },
    validations() {
        return {
            clientConfigForm: {
                ClientData: {
                    Id: {
                        required,
                    },
                    ProcessorImportId: {
                        required,
                    },
                },
            },
            addressConfigForm: {
                Id: {
                    required,
                },
                IdClient: {
                    required,
                },
            },
        }
    },
    watch: {
        tabToShow() {
            this.v$.$reset()
            if (
                this.tabToShow === 'delivery_addresses' &&
                this.clientConfigForm.ClientData.Id > 0
            ) {
                this.addressConfigForm = {
                    Id: 0,
                    KeyWords: [],
                    IdClient: this.clientConfigForm.ClientData.Id,
                }
                this.addressOptions = []
                const addressesPayload = {
                    query: [],
                    clientId: this.clientConfigForm.ClientData.Id,
                    filter: [], // { dbField: 'Active', value: 1 }
                    perPage: 10000,
                    page: 1,
                    sortField: 'Name',
                    sortDirection: 'ASC',
                }

                this.searchAddresses(addressesPayload).then((res) => {
                    if (res && res.status === 200) {
                        res.data.Records.forEach((address) => {
                            const name = `${address.Name}`
                            const option = {
                                name,
                                value: +address.Id,
                            }
                            if (!this.addressOptions.includes(option)) {
                                this.addressOptions.push(option)
                            }
                        })
                        this.refreshMS += 1
                    }
                })
            } else if (
                this.tabToShow === 'clients' &&
                this.addressConfigForm.IdClient > 0
            ) {
                this.clientConfigForm.ClientData.Id =
                    this.addressConfigForm.IdClient

                this.setClient(this.addressConfigForm.IdClient)
            }
        },
    },
    mounted() {},
    methods: {
        ...mapActions(useClientStore, {
            searchClients: 'search',
            fetchOneClient: 'fetchOne',
            fetchClientAddresses: 'fetchAddresses',
            addOrUpdateKeysAndProcessor: 'addOrUpdateKeysAndProcessor',
            searchAddresses: 'fetchAddresses',
            addOrUpdateAddressKeys: 'addOrUpdateAddressKeys',
            fetchOneAddress: 'fetchOneAddress',
        }),
        setClient(client) {
            this.refreshMS += 1

            this.fetchOneClient(client).then((res) => {
                this.clientConfigForm.ClientKeyWords = []
                if (res.data.KeyWords && res.data.KeyWords.length > 0) {
                    res.data.KeyWords.forEach((keyWord) => {
                        this.clientConfigForm.ClientKeyWords.push(keyWord)
                    })
                    this.clientConfigForm.ClientKeyWords =
                        this.clientConfigForm.ClientKeyWords.join(', ')
                }
                this.clientConfigForm.ClientData.ProcessorImportId =
                    res.data.ProcessorImportId ?? ''
                // this.clientConfigForm.ClientData.Id = res.data.Id
            })
            if (this.tabToShow === 'delivery_addresses') {
                const addressesPayload = {
                    query: [],
                    clientId: client,
                    filter: [], // { dbField: 'Active', value: 1 }
                    perPage: 10000,
                    page: 1,
                    sortField: 'Name',
                    sortDirection: 'ASC',
                }
                this.searchAddresses(addressesPayload).then((res) => {
                    if (res && res.status === 200) {
                        res.data.Records.forEach((address) => {
                            const name = `${address.Name}`
                            const option = {
                                name,
                                value: +address.Id,
                            }
                            if (!this.addressOptions.includes(option)) {
                                this.addressOptions.push(option)
                            }
                        })
                        this.refreshMS += 1
                    }
                })
            }
        },
        setAddress(address) {
            this.refreshMS += 1

            this.fetchOneAddress({ id: address }).then((res) => {
                this.addressConfigForm.KeyWords = []
                if (res.data.KeyWords && res.data.KeyWords.length > 0) {
                    res.data.KeyWords.forEach((keyWord) => {
                        this.addressConfigForm.KeyWords.push(keyWord)
                    })
                    this.addressConfigForm.KeyWords =
                        this.addressConfigForm.KeyWords.join(', ')
                }
            })
        },
        saveClientImportConfig() {
            this.v$.clientConfigForm.$reset()
            this.v$.clientConfigForm.$validate()
            if (this.v$.$error) {
                this.$toast.error(this.$t('form_errors'))

                return
            }
            if (!Array.isArray(this.clientConfigForm.ClientKeyWords)) {
                this.clientConfigForm.ClientKeyWords =
                    this.clientConfigForm.ClientKeyWords.split(',')
            }
            this.addOrUpdateKeysAndProcessor(this.clientConfigForm)
                .then((res) => {
                    this.setClient(this.clientConfigForm.ClientData.Id)
                })
                .catch((err) => {
                    this.$toast.warning(this.$t('error_occured'))
                    console.log(err)
                })
        },
        saveAddressKeywords() {
            this.v$.addressConfigForm.$reset()
            this.v$.addressConfigForm.$validate()
            if (this.v$.$error) {
                this.$toast.error(this.$t('form_errors'))
                return
            }
            if (!Array.isArray(this.addressConfigForm.KeyWords)) {
                this.addressConfigForm.KeyWords =
                    this.addressConfigForm.KeyWords.split(',')
            }
            // this.addressConfigForm.KeyWords =
            //     this.addressConfigForm.KeyWords.split(',')
            this.addOrUpdateAddressKeys(this.addressConfigForm)
                .then((res) => {
                    this.setAddress(this.addressConfigForm.Id)
                })
                .catch((err) => {
                    this.$toast.warning(this.$t('error_occured'))

                    console.log(err)
                })
        },
    },
}
</script>
