<template>
    <div class="container mx-auto px-4 py-4">
        <div class="grid grid-cols-3 gap-4">
            <div
                v-for="(image, index) in images"
                :key="index"
                class="cursor-pointer hover:z-100 "
                @click="selectImage(image)"
            >
                <img
                    :src="image.URL"
                    :alt="`Gallery image ${index + 1}`"
                    class="w-full rounded-lg max-h-full object-scale-down duration-500 hover:scale-200  transition transform"
                />
                <div class="p-2 text-xs">
                    {{ image.Information }}
                </div>
            </div>
        </div>

        <div
            v-if="selectedImage"
            class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-200 overflow-auto"
            @click="closeModal"
        >
            <div class="h-screen" @click="closeModal">
                <div class="p-2 text-base font-semibold text-white">
                    {{ selectedImage.Information }}
                </div>
                <img
                    :src="selectedImage.URL"
                    alt="Enlarged view"
                    class="max-w-full max-h-full object-scale-down rounded-lg "
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
    images: {
        type: Array,
        required: true,
    },
})

const selectedImage = ref(null)

const selectImage = (image) => {
    selectedImage.value = image
}

const closeModal = () => {
    selectedImage.value = null
}
</script>
