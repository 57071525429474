<template>
    <div
        class="w-[400px] bg-gainsboro-200 flex px-2 flex-col items-start justify-start tracking-[normal] h-full"
        :class="updatingNote ? 'cursor-wait' : 'cursor-pointer'"
    >
        <section
            class="self-stretch bg-whitesmoke flex flex-col items-start justify-start pt-5 px-[20px] pb-[5px] gap-[30px] text-left text-[18px] text-darkslategray-100 font-circular-std"
        >
            <div
                class="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap"
            >
                <div class="flex flex-col items-start justify-end gap-[5px]">
                    <h3
                        class="m-0 relative text-inherit font-medium font-inherit"
                    >
                        {{ $t('your_notes') }}
                    </h3>
                    <div class="relative text-sm text-darkslategray-400">
                        {{
                            `${
                                !clientStore.current
                                    ? 0
                                    : clientStore.current.notes.length
                            } ${$t(
                                'note',
                                clientStore.current?.notes?.length <= 1 ||
                                    !clientStore.current
                                    ? 1
                                    : 2,
                            )}`
                        }}
                    </div>
                </div>
                <button
                    v-if="createOrUpdateNoteAccess"
                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded flex-row items-center font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                    @click.prevent="openNoteForm(null)"
                >
                    <fa-icon class="fa-xs mr-2" icon="plus"> </fa-icon>

                    <div
                        class="relative text-xs font-medium font-circular-std text-left"
                    >
                        {{ $t('new_note') }}
                    </div>
                </button>
            </div>
            <div
                class="asInput-generated self-stretch h-10 rounded-lg bg-darkslategray-200 flex flex-row items-center justify-start py-0 pr-[17px] pl-[15px] box-border gap-[10px]"
            >
                <img
                    class="h-5 w-5 relative overflow-hidden shrink-0"
                    alt=""
                    src="/img/generated/inputssearch.svg"
                />
                <input
                    class="w-[72px] [border:none] [outline:none] font-circular-std text-sm bg-[transparent] h-[23px] relative leading-[23px] text-darkslategray-100 text-left flex items-center p-0"
                    :placeholder="$t('search')"
                    type="text"
                    v-model="search"
                    @input="searchNotes"
                />
            </div>
        </section>
        <section
            :key="refresh"
            class="self-stretch h-[833px] bg-whitesmoke overflow-y-auto shrink-0 flex flex-col items-start justify-start pt-[30px] px-[20px] pb-0 box-border gap-[9.95454545454529px] max-w-full text-left text-sm text-darkslategray-500 font-circular-std mq450:h-auto"
        >
            <div
                v-if="clientStore.current?.notes.length === 0"
                class="flex items-center justify-center"
            >
                {{ $t('no_notes_yet') }}
            </div>
            <div v-else>
                <div
                    class="flex flex-row items-start pt-[0.1999999999998181px] pb-[0.3000000000001819px] pr-0 pl-6 relative gap-[5px] shrink-0 text-darkslategray-100"
                >
                    <img
                        class="h-full w-[18.5px] !m-[0] absolute top-[0px] bottom-[0px] left-[0px] max-h-full object-contain"
                        loading="lazy"
                        alt=""
                        src="/img/generated/iconpin@2x.png"
                    />
                    <div class="relative">{{ $t('pinned') }}</div>
                </div>
                <span v-if="pinned.length">
                    <span v-for="note in pinned" :key="note.Id">
                        <card-note
                            :note="note"
                            @openNoteForm="openNoteForm($event)"
                            :pinIcon="'/img/generated/component-19@2x.png'"
                            @refetchNotes="searchNotes"
                        />
                    </span>
                </span>
                <div v-else class="flex items-center justify-center">
                    {{ $t('no_pinned_notes_yet') }}
                </div>
                <div
                    class="self-stretch h-5 shrink-0 flex flex-row items-start justify-start py-2.5 px-0 box-border max-w-full"
                >
                    <div
                        class="h-px flex-1 relative box-border max-w-full border-t-[0.5px] border-solid border-darkslategray-300"
                    />
                </div>
                <span v-for="note in notPinned" :key="note.Id">
                    <card-note
                        :note="note"
                        @openNoteForm="openNoteForm($event)"
                        @refetchNotes="searchNotes"
                    />
                </span>
                <span v-if="archived.length">
                    <div
                        class="self-stretch h-5 shrink-0 flex flex-row items-start justify-start py-2.5 px-0 box-border max-w-full"
                    >
                        <div
                            class="h-px flex-1 relative box-border max-w-full border-t-[0.5px] border-solid border-darkslategray-300"
                        />
                    </div>
                    <div
                        v-if="archived.length"
                        class="flex flex-row items-start pt-[0.1999999999998181px] pb-[0.3000000000001819px] pr-0 pl-6 relative gap-[5px] shrink-0 text-darkslategray-100"
                    >
                        <fa-icon
                            class="h-full w-[18.5px] !m-[0] absolute top-[0px] bottom-[0px] left-[0px] max-h-full object-contain"
                            loading="lazy"
                            alt=""
                            icon="box-archive"
                        />
                        <div class="relative">{{ $t('arch_tc', 2) }}</div>
                    </div>
                    <span v-if="archived.length">
                        <span v-for="note in archived" :key="note.Id">
                            <card-note
                                :note="note"
                                :unarchiveOnly="true"
                                @openNoteForm="openNoteForm($event, true)"
                                @refetchNotes="searchNotes"
                            />
                        </span>
                    </span>
                </span>
            </div>
        </section>
    </div>
</template>
<script>
import { mapStores } from 'pinia'

import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useContactClientHistoryStore } from '@/stores/contactClientHistoryStore'
import { useUserStore } from '@/stores/userStore'
import { defineComponent } from 'vue'
import CardNote from './CardNote.vue'

export default defineComponent({
    name: 'SideContainerNote',
    components: { CardNote },
    data() {
        return {
            updatingNote: false,
            search: '',
            debounce: null,
            refresh: 0,
        }
    },

    computed: {
        ...mapStores(
            useClientStore,
            useUserStore,
            useContactClientHistoryStore,
        ),
        createOrUpdateNoteAccess() {
            return (
                checkAccess(
                    this.userStore,
                    actionsAccess.clients.contactClientHistory.create,
                ) ||
                checkAccess(
                    this.userStore,
                    actionsAccess.clients.contactClientHistory.update,
                )
            )
        },
        pinned() {
            if (
                !this.contactClientHistoryStore.all ||
                (this.contactClientHistoryStore.all &&
                    !this.contactClientHistoryStore.all.length)
            )
                return []

            const result = this.contactClientHistoryStore?.all.filter(
                (note) => +note.pinned === 1 && +note.active === 1,
            )
            return result
        },
        notPinned() {
            if (
                !this.contactClientHistoryStore.all ||
                (this.contactClientHistoryStore.all &&
                    !this.contactClientHistoryStore.all.length)
            )
                return []
            const result = this.contactClientHistoryStore?.all.filter(
                (note) => +note.pinned === 0 && +note.active === 1,
            )
            return result
        },
        archived() {
            if (
                !this.contactClientHistoryStore.all ||
                (this.contactClientHistoryStore.all &&
                    !this.contactClientHistoryStore.all.length)
            )
                return []
            const result = this.contactClientHistoryStore?.all.filter(
                (note) => +note.active === 0,
            )
            return result
        },
    },
    methods: {
        openNoteForm(id, disabled) {
            if (this.$route.name === 'new_client') {
                this.$emit('saveClient')
            } else {
                this.$emit('openNoteForm', { id, disabled })
            }
        },
        searchNotes() {
            this.updatingNote = true
            const payload = {
                query: [{ dbField: 'Title', term: this.search }],
                filter: [
                    { dbField: 'IdClient', value: this.clientStore.current.id },
                ],
                perPage: 10000000,
                page: 1,
                sortField: 'MeetingDate',
                sortDirection: 'ASC',
            }
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                this.contactClientHistoryStore.search(payload).then(() => {
                    this.refresh += 1
                    this.updatingNote = false
                })
            }, 1000)
        },
    },
})
</script>
