import { createI18n } from 'vue-i18n'
import messages from './translations'

const currentLocale =
    window.navigator.languages[0].length > 2
        ? window.navigator.languages[0].substring(0, 2)
        : window.navigator.languages[0]
const i18n = createI18n({
    locale: currentLocale.charAt(0).toUpperCase() + currentLocale.slice(1), // set locale
    fallbackLocale: 'Fr', // set fallback locale
    messages,
    legacy: false,
    globalInjection: true,
})
export default i18n
