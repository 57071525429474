<template>
    <div id="canvas" class="border-2 rounded p-2">
        <h1 class="text-2xl flex justify-start font-bold mb-4">
            {{ $t('aggregatedStats') }}
        </h1>
        <div
            v-if="data === null"
            class="flex items-center text-zinc-500 justify-center pb-6 mt-20"
        >
            {{ $t('no_stats_found') }}
        </div>
        <canvas v-else id="aggregatedStatsChart"></canvas>
    </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

Chart.register(...registerables)

export default {
    props: {
        data: Object,
        refresh: Number,
    },
    setup(props) {
        onMounted(() => {
            const { t } = useI18n({})
            if (!props.data) {
                return null
            }
            const labels = Object.keys(props.data).filter(
                (key) => key !== 'range',
            )
            labels.forEach((label, index) => {
                labels[index] = t(`email_status.${label}.chart`)
            })
            const ctx = document
                .getElementById('aggregatedStatsChart')
                .getContext('2d')
            const chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels,
                    datasets: [
                        {
                            data: Object.values(props.data).filter(
                                (value) => typeof value === 'number',
                            ),
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(199, 199, 199, 0.2)',
                                'rgba(83, 102, 255, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                            ],
                            borderColor: [
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                                'rgba(199, 199, 199, 1)',
                                'rgba(83, 102, 255, 1)',
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                                'rgba(255, 99, 132, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                },

                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        y: {
                            title: {
                                display: true,
                                text: t('email_status.requests.chart'), // this.$t('requests'),
                            },
                            beginAtZero: true,
                            ticks: {
                                // forces step size to be 1 unit
                                stepSize: 1,
                            },
                        },
                    },
                },
            })
        })
    },
}
</script>
<style scoped>
canvas {
    height: 550px !important;
    /* width: 90% !important; */
}
</style>
