<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'tours'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="tourList"
                :items="tours"
                :columns="columns"
                :storeToSort="'tours'"
                :page="page"
                :count="count"
                :loading="loading"
                :multiActions="multiActions"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :itemActions="itemActions"
                @update="tourView($event)"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="$emit('create')"
                :canAddNew="false"
                @clear="clearFilter($event)"
                @oldView="goToTour($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
            >
                <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useTourStore } from '@/stores/tourStore'
import { useUserStore } from '@/stores/userStore'
import { mapActions, mapState, mapStores } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Tours',
    mixins: [filterList],

    components: {
        FiltrableTable,
        FetchDataError,
    },
    data() {
        return {
            columns: [],
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            filter: [],
            toursInterval: null,

            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
        }
    },
    watch: {
        tours() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    created() {
        window.scrollTo(0, 0)
        this.columns = [
            {
                name: 'tours_date',
                dbField: 'Date',
                sortable: true,
                isLink: checkAccess(
                    this.userStore,
                    this.toursActionsAccess.map,
                ),
                routeName: 'tour_view',
            },
            {
                name: 'steps_count',
                dbField: 'NbSteps',
            },

            {
                name: 'tours_count',
                dbField: 'NbTours',
            },
            {
                name: 'distance',
                dbField: 'Distance',
            },
            {
                name: 'duration',
                dbField: 'Duration',
            },

            {
                name: 'report',
                filtrable: false,
                sortable: false,
            },

            {
                name: 'creation_date',
                sortable: true,
                dbField: 'CreationDate',
            },
        ]

        const filteredList = this.$cookies.get('filtered')
        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        this.setResults()
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    unmounted() {
        clearInterval(this.toursInterval)
    },

    computed: {
        ...mapState(useTourStore, {
            tours: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapStores(useUserStore),
        toursActionsAccess() {
            return actionsAccess.tours
        },

        multiActions() {
            const actions = []
            if (checkAccess(this.userStore, this.toursActionsAccess.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        itemActions() {
            const actions = []

            if (checkAccess(this.userStore, this.toursActionsAccess.map)) {
                actions.push({
                    name: 'map',
                    action: 'update',
                    icon: 'map',
                })
            }
            if (checkAccess(this.userStore, this.toursActionsAccess.delete)) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }
            return actions
        },
    },
    methods: {
        upFilter(event) {
            clearInterval(this.toursInterval)
            this.updateFilter(event)
        },
        ...mapActions(useTourStore, {
            searchTour: 'search',
            delete: 'delete',
        }),
        goToTour(id) {
            clearInterval(this.toursInterval)

            this.$emit('goToTour', id)
        },
        tourView(id) {
            this.$emit('tourView', id)
        },
        async deleteOne(id) {
            this.searchingCursor = true

            const response = await this.delete(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$emit('deleted')
                this.$toast.success(this.$t('tour_delete_ok'))
                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('tour_delete_ko'))
            }
        },
        deleteAll(elToDelete) {
            this.searchingCursor = true

            elToDelete.forEach((el, index) => {
                this.delete(el)
                if (index === elToDelete.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.toursInterval)
            this.$cookies.remove('filtered')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
            window.scrollTo(0, 0)
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.toursInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)

            this.searchTour(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filtered', search)
            this.toursInterval = setInterval(() => {
                this.searchTour(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.toursInterval)
            this.$cookies.remove('filtered')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
