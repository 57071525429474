<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'suppliers'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="suppliersList"
                :items="suppliers"
                :columns="columns"
                :storeToSort="'suppliers'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :itemActions="itemActions"
                :multiActions="multiActions"
                :canAddNew="canAddNew()"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @update="goToUpdateOne($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
            >
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'
import { useSupplierStore } from '@/stores/supplierStore'
import { mapActions, mapState, mapStores } from 'pinia'

import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useUserStore } from '@/stores/userStore'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'SuppliersList',
    mixins: [filterList],
    components: {
        FiltrableTable,
        FetchDataError,
    },
    data() {
        return {
            columns: [],
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            suppliersInterval: null,
            filter: [],

            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('city'), value: 'City' },

                { name: this.$t('postal_code'), value: 'PostalCode' },

                { name: this.$t('address'), value: 'Address1' },

                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
        }
    },
    watch: {
        areas() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        window.scrollTo(0, 0)
        this.columns = [
                {
                    name: 'name',
                    filtrable: true,
                    isLink: checkAccess(
                        this.userStore,
                        this.suppliersActionsAccess.update,
                    ),
                    routeName: 'update_supplier',

                    dbField: 'Name',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'address',
                    filtrable: true,
                    dbField: 'Address1',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'postal_code',
                    filtrable: true,
                    dbField: 'PostalCode',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'city',
                    filtrable: true,
                    dbField: 'City',
                    type: 'search',
                    sortable: true,
                },

                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
            ]
        
        const filteredList = this.$cookies.get('filtered')
        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        this.setResults()
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    unmounted() {
        clearInterval(this.suppliersInterval)
    },
    computed: {
        ...mapStores(useUserStore),
        ...mapState(useSupplierStore, {
            suppliers: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        suppliersActionsAccess() {
            return actionsAccess.suppliers
        },
       
        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.suppliersActionsAccess.update)
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(this.userStore, this.suppliersActionsAccess.delete)
            ) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.suppliersActionsAccess.delete)
            ) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }

            return actions
        },
    },
    methods: {
        upFilter(event) {
            clearInterval(this.suppliersInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.suppliersActionsAccess.create,
            )
        },
        ...mapActions(useSupplierStore, {
            delete: 'delete',
            searchSupplier: 'search',
        }),
        goToUpdateOne(id) {
            this.$emit('update', id)
        },
        async deleteOne(id) {
            this.searchingCursor = true

            const response = await this.delete(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('supplier_delete_ok'))
                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('supplier_delete_ko'))
            }
        },
        deleteAll(elToDelete) {
            this.searchingCursor = true

            elToDelete.forEach((el, index) => {
                this.delete(el)
                if (index === elToDelete.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.suppliersInterval)
            this.$cookies.remove('filtered')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.suppliersInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)

            this.searchSupplier(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })
            this.$cookies.set('filtered', search)
            this.suppliersInterval = setInterval(() => {
                this.searchSupplier(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.suppliersInterval)
            this.$cookies.remove('filtered')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
