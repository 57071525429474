<template>
    <main
        class="px-4 pt-2 rounded-md h-full"
        style="width: 96vw"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error
                v-if="fetchError"
                :dataType="'orders'"
                :multiTranslation="userStore.isB2C === true ? 2 : 1"
            />
            <div v-else>
                <span
                    class="flex justfy-center"
                    :class="
                        showAlertReminderError === true ||
                        showAlertSetAsPaidError === true
                            ? 'mt-14'
                            : ''
                    "
                >
                    <alert-info
                        v-if="showAlertReminderError === true"
                        :color="'red'"
                        :canClose="true"
                        @close="resetAlertError('mail')"
                        class="w-full mb-1"
                    >
                        <template #body>
                            <p
                                class="text-base mb-2 alert-error text-red-700 font-semibold"
                            >
                                {{ $t('reminder_not_sent') }}
                            </p>

                            <ul
                                v-for="(err, index) in reminderSendingErrors"
                                class="text-red-700 text-sm"
                                :key="index"
                            >
                                <li
                                    class="flex text-sm items-center whitespace-nowrap mb-2"
                                >
                                    <fa-icon
                                        icon="circle"
                                        class="fa-2xs mr-2"
                                    />
                                    <span v-if="err.user">
                                        {{ `${err.user} - ` }}
                                    </span>
                                    <span v-if="err.order_ref">
                                        {{
                                            $t('order_ref') +
                                            ': ' +
                                            err.order_ref +
                                            ' / '
                                        }}
                                    </span>
                                    <span class="mr-1">
                                        {{
                                            $t('order_id') +
                                            ': ' +
                                            err.order_id +
                                            ' - '
                                        }}
                                    </span>
                                    <span class="font-semibold">
                                        {{ $t(err.msg) }}
                                    </span>
                                </li>
                            </ul>
                        </template>
                    </alert-info>
                    <alert-info
                        v-if="showAlertSetAsPaidError === true"
                        :color="'red'"
                        :canClose="true"
                        @close="resetAlertError('paid')"
                        class="w-full"
                    >
                        <template #body>
                            <p
                                class="text-base mb-2 text-red-700 alert-error font-semibold"
                            >
                                {{ $t('order_not_set_as_paid') }}
                            </p>

                            <ul
                                v-for="(err, index) in setAsPaidErrors"
                                class="text-red-700 text-sm"
                                :key="index"
                            >
                                <li
                                    class="flex text-sm items-center whitespace-nowrap mb-2"
                                >
                                    <fa-icon
                                        icon="circle"
                                        class="fa-2xs mr-2"
                                    />
                                    <span v-if="err.client">
                                        {{ err.client + ' / ' }}
                                    </span>
                                    <span
                                        v-if="
                                            err.order_ref &&
                                            err.order_ref !== null
                                        "
                                        class="ml-1"
                                    >
                                        {{
                                            $t('order_ref') +
                                            ': ' +
                                            err.order_ref +
                                            ' / '
                                        }}
                                    </span>
                                    <span class="ml-1">
                                        {{
                                            $t('unique_id') +
                                            ': ' +
                                            err.id +
                                            ' / '
                                        }}
                                    </span>
                                    <span class="font-semibold ml-1">
                                        {{ $t(err.msg) }}
                                    </span>
                                </li>
                            </ul>
                        </template>
                    </alert-info>
                </span>
                <filtrable-table
                    id="unpaidOrdersList"
                    :class="searchingCursor === true ? 'cursor-progress' : ''"
                    :items="orders"
                    :columns="columns"
                    :storeToSort="'orders'"
                    :page="page"
                    :count="count"
                    :loading="loading"
                    :multiActions="multiActions"
                    @perPageChanged="changePerPage($event)"
                    @updateSort="displaySearchResults($event)"
                    :perPage="perPage"
                    :sortDirection="sortDirection"
                    :sortableFields="sortableFields"
                    :totalPages="totalPages"
                    :searchQuery="searchQuery"
                    :refresh="refresh"
                    @changeSearch="changeSearch($event)"
                    @checkboxFilterUpdate="checkboxFilter($event)"
                    @dateFilterUpdate="setDateFilter($event)"
                    :hoverableItems="userStore.isB2C === false"
                    :itemActions="itemActions"
                    :resetCheckedItems="resetCheckedItems"
                    :canAddNew="false"
                    @pageChanged="setPage($event)"
                    @searched="debounceSearch"
                    @printAllLabels="printLabels($event)"
                    @orderTracking="trackParcels($event)"
                    @sortDirectionChanged="updateSortDirection($event)"
                    @selected="upFilter($event)"
                    @create="$emit('create')"
                    @clear="clearFilter($event)"
                    @updatedAddress="$emit('updated', $event)"
                    @update="goToUpdateOne($event)"
                    @setOrdersAsPaid="setOrdersAsPaid($event)"
                    @sendMail="sendMail($event)"
                    @delete="deleteOne($event)"
                    @deleteAll="deleteAll($event)"
                >
                    <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
                </filtrable-table>
            </div>
            <modal
                v-if="showConfirmModal"
                :headerText="confirmModalHeader"
                :buttonText="'confirm'"
                id="deletemodal"
                :mxWidth="'max-w-2xl'"
                :buttonAlign="'justify-center'"
                @closeModal="actionConfirmation(false)"
                @sendFromModal="actionConfirmation(true)"
            >
                <template #body>
                    <div class="mx-8">
                        <div class="flex justify-center font-semibold mt-4">
                            {{ $t(confirmModalText) }}
                        </div>

                        <div
                            v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                            :key="iToConfirm"
                            class="flex justify-center mt-2"
                        >
                            <ul>
                                <li
                                    class="flex text-sm items-center whitespace-nowrap mb-2"
                                >
                                    <fa-icon
                                        icon="circle"
                                        class="fa-2xs mr-2"
                                    />
                                    <span v-if="toConfirm.Client" class="mr-1">
                                        {{ toConfirm.Client + ' - ' }}
                                    </span>
                                    <span v-if="toConfirm.Ref">
                                        {{
                                            $t('order_ref') +
                                            ': ' +
                                            toConfirm.Ref
                                        }}
                                    </span>
                                </li>
                                <span
                                    v-if="
                                        confirmModalText ===
                                        'confirm_set_as_paid'
                                    "
                                >
                                    <div class="mb-2">
                                        <Multiselect
                                            id="forcePaymentReason"
                                            :object="true"
                                            class="h-2 rounded p-2 w-full darky"
                                            v-model="
                                                toConfirm.ForcePaymentReason
                                            "
                                            :placeholder="
                                                $t('force_payment_reason')
                                            "
                                            :searchable="true"
                                            :can-clear="false"
                                            :options="forcePaymentReasonOptions"
                                            label="name"
                                            track-by="name"
                                        ></Multiselect>
                                    </div>
                                    <Multiselect
                                        id="oneTimePayment"
                                        :object="true"
                                        class="h-2 rounded p-2 w-full darky"
                                        v-model="toConfirm.PaymentType"
                                        :placeholder="$t('payment_type')"
                                        :searchable="true"
                                        :can-clear="false"
                                        :options="oneTimePaymentOptions"
                                        label="name"
                                        track-by="name"
                                    ></Multiselect>

                                    <div
                                        v-if="
                                            toConfirm.ForcePaymentReason
                                                .value === 'to_month_invoice'
                                        "
                                        class="flex text-xs items-center mt-2 justify-center theme-color"
                                    >
                                        <fa-icon
                                            icon="circle-info"
                                            class="mr-2"
                                        ></fa-icon>
                                        {{ $t('applied_to_all_orders') }}
                                    </div>
                                    <div class="flex mt-3 items-center">
                                        <input
                                            type="checkbox"
                                            v-model="toConfirm.GenerateInvoice"
                                            name="toggle-generate"
                                            id="toggle-generate"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="toggle-generate"
                                            class="ml-2 font-medium"
                                            >{{ $t('generate_invoice') }}</label
                                        >
                                    </div>
                                    <div
                                        v-if="
                                            toConfirm.GenerateInvoice === true
                                        "
                                        class="flex mt-2 items-center"
                                    >
                                        <input
                                            type="checkbox"
                                            v-model="
                                                toConfirm.GenerateInvoiceAsPaid
                                            "
                                            name="toggle-generateaspaid"
                                            id="toggle-generateaspaid"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="toggle-generateaspaid"
                                            class="ml-2 font-medium"
                                            >{{
                                                $t('generate_invoice_as_paid')
                                            }}</label
                                        >
                                    </div>
                                </span>
                            </ul>
                        </div>
                        <div
                            class="flex text-xs italic items-center error-msg justify-center mt-4"
                        >
                            <fa-icon
                                :icon="'triangle-exclamation'"
                                class="mr-2 fa-md"
                            ></fa-icon>
                            {{ $t(confirmModalText2) }}
                        </div>
                    </div></template
                ></modal
            >
        </div>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import AlertInfo from '@/components/atoms/AlertInfo.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useOrderStore } from '@/stores/orderStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

export default {
    name: 'UnpaidOrders',
    mixins: [filterList],
    inject: ['eventBus'],
    components: {
        FiltrableTable,
        FetchDataError,
        AlertInfo,
        Modal,
        Multiselect,
    },
    data() {
        return {
            columns: [],
            confirmAction: null,
            showConfirmModal: false,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModaltext: 'confirm_modal_text',
            confirmModaltext2: 'confirm_modal_text',
            showAlertReminderError: false,
            showAlertSetAsPaidError: false,
            unpaidInterval: null,
            dateFilter: [],
            resetCheckedItems: false,
            // pickupTodayOnly: false,
            // deliveryTodayOnly: false,
            searchByName: true,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            applyToAllOrders: true,
            forcePaymentReasonOptions: [
                { name: this.$t('one_time_force_payment'), value: 'one_time' },
                {
                    name: this.$t('set_client_to_month_invoice'),
                    value: 'to_month_invoice',
                },
            ],
            oneTimePaymentOptions: [
                { name: this.$t('paid_by_credit_card'), value: 'creditcard' },
                { name: this.$t('paid_by_check'), value: 'check' },
                { name: this.$t('paid_by_cash'), value: 'cash' },
                { name: this.$t('paid_by_transfer'), value: 'transfer' },
                { name: this.$t('offered'), value: 'none' },
            ],

            filter: [
                {
                    value: 'waitingforpayment',
                    dbField: 'Status',
                },
            ],
            ordersInterval: null,
            sortableFields: [
                {
                    name: this.$t('client'),
                    value:
                        this.searchByName === true
                            ? 'Client.Name'
                            : 'OrderClient.Reference',
                },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('reference'), value: 'Reference' },
            ],
        }
    },
    watch: {
        orders(oldVal, newVal) {
            if (
                this.page === 1 &&
                oldVal !== newVal &&
                (localStorage.getItem('popoverOpen') === null ||
                    localStorage.getItem('popoverOpen') === false)
            ) {
                this.refresh += 1
            }
        },
        // reminderSendingErrors() {
        //     if (this.reminderSendingErrors.length > 0)
        //         this.showAlertReminderError = true
        // },
    },
    // *************************
    // TODO le search saute après navigation FINIR ICI
    // reste à faire refunds recurrentes becycle et ICI
    created() {
        clearInterval(this.ordersInterval)

        const filteredList = this.$cookies.get('unpaidFiltered')

        clearInterval(this.unpaidInterval)

        window.scrollTo(0, 0)
        this.reminderSendingErrors = []

        const isB2CInterval = setInterval(() => {
            if (this.userStore.isB2C !== null) {
                this.columns = [
                    {
                        name: 'client',
                        showRef: this.searchByName === false,
                        filtrable: true,
                        animated: true,

                        dbField:
                            this.searchByName === true
                                ? 'Client.Name'
                                : 'OrderClient.Reference',
                        isLink: false,
                        // checkAccess(
                        //     this.userStore,
                        //     this.unpaidOrdersActionsAccess.update
                        // ),
                        // routeName: 'update_order',
                        searchIcon:
                            this.searchByName === true ? 'user' : 'hashtag',
                        type: 'search',
                        sortable: true,
                        hide: false,
                        subInfo: true,
                    },

                    {
                        name: 'status',
                        filtrable: false,
                        dbField: 'Status',

                        withIconNoText: false,
                        sortable: false,
                        translate: true,
                        hide: false,
                    },

                    {
                        name: 'delivery_mode',
                        filtrable: true,
                        type: 'icon-select',
                        withIconNoText: true,
                        dbField: 'Method',

                        sortable: false,
                        hide: false,

                        options: this.methodOptions,
                    },

                    {
                        name: 'pick_up',
                        filtrable: false,
                        notCentered: false,
                        range: true,
                        sortable: false,
                        dbField: 'DateBegin',
                        datePicker: true,
                        withButton: false,
                        pickedDate: '',
                        // notColored: true,
                        // buttonValue: this.pickupTodayOnly,
                        canUpdate: false,
                        hide: false,
                    },
                    {
                        name: 'delivery',
                        filtrable: false,
                        notCentered: false,
                        withButton: false,
                        canUpdate: false,
                        range: true,
                        // notColored: true,

                        // buttonValue: this.deliveryTodayOnly,
                        dbField: 'DateEnd',
                        datePicker: true,
                        pickedDate: '',
                        sortable: false,
                        hide: false,
                    },
                    {
                        name: 'additional_info',
                        filtrable: false,
                        sortable: false,
                        packages: true,
                        hide: false,
                    },

                    {
                        name: 'creation_date',
                        sortable: true,
                        dbField: 'CreationDate',
                        hide: false,
                    },
                ]

                clearInterval(isB2CInterval)
                if (filteredList) {
                    // this.search.push(this.$cookies.get('query'))
                    this.search = filteredList.query
                    // this.searchQuery = filteredList.query[0]
                    //     ? filteredList.query[0].term
                    //     : ''
                    this.sortDirection = filteredList.sortDirection
                    this.filter = filteredList.filter
                    this.sortField = filteredList.sortField
                    this.perPage = filteredList.perPage
                    this.page = filteredList.page
                    // this.pickupTodayOnly = filteredList.pickupTodayOnly
                    // this.deliveryTodayOnly = filteredList.deliveryTodayOnly

                    this.dateFilter = filteredList.dateFilter
                    this.searchByName = filteredList.searchByName
                }
                const universesInterval = setInterval(() => {
                    if (this.universesOptions.length > 0) {
                        clearInterval(universesInterval)
                        if (this.userStore.isB2C === false) {
                            // if (!filteredList) {

                            //     this.setRealSelected('Status', 2, true)
                            // }

                            if (
                                !this.filter.find(
                                    (f) =>
                                        f.dbField === 'OrderClient.IdUniverse',
                                )
                            ) {
                                const universeFilter = {
                                    dbField: 'OrderClient.IdUniverse',
                                    value:
                                        this.selectedUniverses.length > 0
                                            ? this.selectedUniverses
                                            : this.universesOptions,
                                }
                                this.filter.push(universeFilter)
                            }
                            const payload = {
                                query: [],
                                filter: [],
                                perPage: 10000,
                                page: 1,
                                sortField: 'Name',
                                sortDirection: 'ASC',
                            }
                            this.searchContainers(payload)

                            this.setResults()
                            this.eventBus.on(
                                'trigger-universe-search',
                                (param) => {
                                    this.searchCustomUniverses(param)
                                },
                            )
                            this.eventBus.on(
                                'remove-from-selected-universe',
                                (index) => {
                                    this.removeFromSelectedUniverses(index)
                                },
                            )
                        }
                    }
                }, 10)
            }
        }, 20)
        if (this.$cookies.get('currentDeliveryId'))
            this.$cookies.remove('currentDeliveryId')
    },

    unmounted() {
        this.eventBus.all.clear()
        clearInterval(this.ordersInterval)
        clearInterval(this.unpaidInterval)
    },

    computed: {
        unpaidOrdersActionsAccess() {
            return actionsAccess.orders.unpaid
        },
        canAccessGroupsCreate() {
            return checkAccess(
                this.userStore,
                actionsAccess.orders.groups.create,
            )
        },
        canAccessGroupsUpdate() {
            return checkAccess(
                this.userStore,
                actionsAccess.orders.groups.update,
            )
        },
        methodOptions() {
            const options = [
                {
                    name: 'normal',
                    value: 'normal',
                    icon: 'gauge',
                    style: 'color:green;',
                    dbField: 'Method',
                },
                {
                    name: 'fast',
                    value: 'fast',
                    icon: 'gauge-high',
                    style: 'color:orange;',
                    dbField: 'Method',
                },
                {
                    name: 'express',
                    value: 'express',
                    icon: 'rocket',
                    style: 'color: red;',
                    dbField: 'Method',
                },
            ]
            if (this.canAccessGroupsCreate || this.canAccessGroupsUpdate) {
                options.push({
                    name: 'order_group',
                    value: 'orderGrouping',
                    icon: 'arrows-to-circle',
                    style: 'color: blue;',
                    dbField: 'Method',
                })
            }
            return options
        },

        multiActions() {
            const actions = []
            if (
                checkAccess(
                    this.userStore,
                    this.unpaidOrdersActionsAccess.update,
                )
            ) {
                actions.push({
                    name: 'set_as_paid',
                    action: 'setOrdersAsPaid',
                    icon: 'file-invoice-dollar',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.unpaidOrdersActionsAccess.sendMail,
                )
            ) {
                actions.push({
                    name: 'send_reminder_mail',
                    action: 'sendMail',
                    icon: 'envelope',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.unpaidOrdersActionsAccess.delete,
                )
            ) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }
            return actions
        },
        itemActions(orderStatus) {
            const actions = []

            // ENVOYER UN MAIL AU CLIENT
            // PASSER LA COMMANDE EN REGLEE
            if (
                checkAccess(
                    this.userStore,
                    this.unpaidOrdersActionsAccess.sendMail,
                )
            ) {
                actions.push({
                    name: 'send_reminder_mail',
                    action: 'sendMail',
                    icon: 'envelope',
                    hide: false,
                    notAffectedOnly: true,
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.unpaidOrdersActionsAccess.update,
                )
            ) {
                actions.push({
                    name: 'set_as_paid',
                    action: 'setOrdersAsPaid',
                    icon: 'file-invoice-dollar',
                    hide: false,
                    notAffectedOnly: true,
                })
            }

            if (
                checkAccess(
                    this.userStore,
                    this.unpaidOrdersActionsAccess.delete,
                )
            ) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                    notAffectedOnly: true,
                    hide: false,
                })
            }

            return actions
        },
        ...mapState(useOrderStore, {
            orders: 'allUnpaid',
            count: 'countAllUnpaid',
            fetchError: 'fetchError',
            totalPages: 'totalPagesAllUnpaid',
            loading: 'loading',
        }),
        ...mapWritableState(useOrderStore, {
            reminderSendingErrors: 'reminderSendingErrors',
            setAsPaidErrors: 'setAsPaidErrors',
        }),
        ...mapStores(useUserStore, useOrderStore),

        ...mapState(useClientStore, {
            currentClient: 'current',
        }),
        ...mapState(useUniverseStore, {
            universes: 'all',
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
    },
    methods: {
        ...mapActions(useClientStore, [
            'searchContainersByClient',
            'searchContainers',
        ]),
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
        }),
        ...mapActions(useOrderStore, [
            'deleteOrderClient',
            'deleteManyOrders',
            'searchOrders',
            'sendReminder',
            'setAsPaid',
        ]),
        searchCustomUniverses(clear = false) {
            clearInterval(this.ordersInterval)
            clearInterval(this.unpaidInterval)
            let filter = null
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'OrderClient.IdUniverse',
                    value: this.universesOptions,
                }
            } else {
                filter = {
                    dbField: 'OrderClient.IdUniverse',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.ordersInterval)
            clearInterval(this.unpaidInterval)
            this.searchCustomUniverses()
        },
        resetAlertError(entity) {
            if (entity === 'mail') {
                this.showAlertReminderError = false
                this.reminderSendingErrors = []
            }
            if (entity === 'paid') {
                this.showAlertSetAsPaidError = false

                this.setAsPaidErrors = []
            }
        },
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.searchingCursor = confirmDelete
            this.showConfirmModal = false
        },
        setOrdersAsPaid(ids) {
            // ajouter un ms de justification de l'action de set as paid
            this.confirmAction = null
            this.elementsToConfirm = []
            this.showAlertSetAsPaidError = false
            this.setAsPaidErrors = []
            const formattedIds = []
            if (ids.id) {
                const orderInfo = {
                    ModificationUserId: this.userStore.current.id,
                    Id: ids.id,
                    Ref: ids.reference,
                    IdClient: ids.idClient,

                    Client: ids.client,
                    Status: 'new',
                    ForcePaymentReason: {
                        name: this.$t('one_time_force_payment'),
                        value: 'one_time',
                    },
                    GenerateInvoice: false,
                    GenerateInvoiceAsPaid: false,
                    PaymentType: {
                        name: this.$t('paid_by_credit_card'),
                        value: 'creditcard',
                    },
                }
                formattedIds.push(orderInfo)
                this.elementsToConfirm.push(orderInfo)
            } else {
                ids.forEach((id) => {
                    const fullOrder = this.orders.find(
                        (order) => order.id === id,
                    )
                    const orderInfo = {
                        ModificationUserId: this.userStore.current.id,
                        Id: id,
                        Status: 'new',
                        Client: fullOrder.client,
                        IdClient: fullOrder.idClient,
                        Ref: fullOrder.reference,
                        ForcePaymentReason: {
                            name: this.$t('one_time_force_payment'),
                            value: 'one_time',
                        },
                        GenerateInvoice: false,
                        GenerateInvoiceAsPaid: false,
                        PaymentType: {
                            name: this.$t('paid_by_credit_card'),
                            value: 'creditcard',
                        },
                    }

                    formattedIds.push(orderInfo)
                    this.elementsToConfirm.push(orderInfo)
                })
            }
            this.showConfirmModal = true
            this.confirmModalHeader = 'action_confirm_generic_header'
            this.confirmModalText = 'confirm_set_as_paid'
            this.confirmModalText2 = 'irreversible_action'

            const confirmInterval = setInterval(() => {
                if (this.confirmAction !== null) {
                    clearInterval(confirmInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.setAsPaid(formattedIds)
                            .then(() => {
                                if (this.setAsPaidErrors.length === 0) {
                                    this.$toast.success(
                                        this.$t('success_setting_as_paid'),
                                        formattedIds.length === 1 ? 1 : 2,
                                    )
                                } else {
                                    this.showAlertSetAsPaidError = true
                                    this.$toast.error(
                                        this.$t('error_setting_as_paid'),
                                        formattedIds.length === 1 ? 1 : 2,
                                    )
                                }
                            })
                            .catch(() => {
                                this.showAlertSetAsPaidError = true

                                this.$toast.error(
                                    this.$t('error_setting_as_paid'),
                                    formattedIds.length === 1 ? 1 : 2,
                                )
                            })
                            .finally(() => {
                                this.setResults()
                                this.searchingCursor = false
                            })
                    } else {
                        this.confirmAction = null
                        this.searchingCursor = false

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },

        sendMail(contacts) {
            this.showAlertReminderError = false
            this.reminderSendingErrors = []

            // mettre dans filtrable table le item.multiactionparam pour rencoyer les infos nécessaires
            const formattedContacts = []
            if (contacts.id) {
                const fContact = {
                    user: contacts.contact.Email,
                    orders: [{ id: contacts.id, ref: contacts.reference }],
                }
                formattedContacts.push(fContact)
            } else {
                contacts.forEach((contact) => {
                    const fullOrder = this.orders.find(
                        (order) => order.id === contact,
                    )

                    if (!fullOrder) {
                        this.reminderSendingErrors.push({
                            user: null,
                            order_ref: null,
                            order_id: contact,
                            msg: 'order_not_found',
                        })
                        // this.showAlertReminderError = true
                    } else if (!fullOrder.contact) {
                        this.reminderSendingErrors.push({
                            user: fullOrder.client,
                            order_ref: fullOrder.reference,
                            order_id: fullOrder.id,
                            msg: 'no_contact_for_order',
                        })
                        // this.showAlertReminderError = true
                    } else if (
                        formattedContacts.length === 0 ||
                        formattedContacts.findIndex(
                            (c) => c.user === fullOrder.contact.Email,
                        ) === -1
                    ) {
                        const fContact = {
                            user: fullOrder.contact.Email,
                            orders: [
                                { id: fullOrder.id, ref: fullOrder.reference },
                            ],
                            many: true,
                        }
                        formattedContacts.push(fContact)
                    } else if (
                        formattedContacts.length === 0 ||
                        formattedContacts.findIndex(
                            (c) => c.user === fullOrder.contact.Email,
                        ) !== -1
                    ) {
                        const index = formattedContacts.findIndex(
                            (c) => c.user === fullOrder.contact.Email,
                        )
                        formattedContacts[index].many = true // si plusieurs commandes pour un même contact
                        formattedContacts[index].orders.push({
                            id: fullOrder.id,
                            ref: fullOrder.reference,
                        })
                    }
                })
            }

            this.sendReminder(formattedContacts)
                .then((res) => {
                    if (this.reminderSendingErrors.length === 0) {
                        this.$toast.success(
                            this.$t(
                                'success_sending_reminder_mail',
                                formattedContacts.length === 1 ? 1 : 2,
                            ),
                        )
                    } else {
                        this.$toast.info(
                            this.$t(
                                'error_sending_reminder_mail',
                                this.reminderSendingErrors.length === 1 ? 1 : 2,
                            ),
                        )
                        this.showAlertReminderError = true
                    }
                })
                .catch((err) => {
                    this.showAlertReminderError = true

                    this.$toast.error(
                        this.$t(
                            'error_sending_reminder_mail',
                            this.reminderSendingErrors.length === 1 ? 1 : 2,
                        ),
                    )
                })
        },
        changeSearch(columnIndex = null) {
            this.searchByName = !this.searchByName
            const searchFilters = JSON.parse(JSON.stringify(this.search))
            searchFilters.forEach((filter, index) => {
                if (filter.dbField === 'Client.Name') {
                    this.search[index].dbField = 'OrderClient.Reference'
                } else if (filter.dbField === 'OrderClient.Reference') {
                    this.search[index].dbField = 'Client.Name'
                }
            })
            if (columnIndex !== null) {
                this.columns[columnIndex] = {
                    name:
                        this.searchByName === false
                            ? 'client_n_reference'
                            : 'client',
                    showRef: this.searchByName === false,
                    filtrable: true,

                    dbField:
                        this.searchByName === true
                            ? 'Client.Name'
                            : 'OrderClient.Reference',

                    routeName: 'update_order',
                    searchIcon: this.searchByName === true ? 'user' : 'hashtag',
                    type: 'search',
                    animated: true,
                    sortable: false,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                    subInfo: this.searchByName === true,

                }
                this.refresh += 1
            }
            this.setResults()
        },
        goToUpdateOne(id) {
            clearInterval(this.ordersInterval)

            this.$emit('goToUpdateOrder', id)
        },
        goToUpdateStep(event) {
            clearInterval(this.ordersInterval)

            this.$emit('goToUpdateStep', event)
        },
        upFilter(event) {
            clearInterval(this.ordersInterval)
            this.updateFilter(event)
        },

        async deleteOne(order) {
            this.searchingCursor = true
            this.confirmAction = null
            this.elementsToConfirm = []
            let response = null
            const toastSuccessMessage = 'order_delete_ok'
            const toastErrorMessage = 'order_delete_ko'
            this.showConfirmModal = true
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            // const fullOrder = this.orders.find((order) => order.id === id)
            const orderInfo = {
                Client: order.client,
                Ref: order.reference,
            }
            this.elementsToConfirm.push(orderInfo)
            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null

                        const id = order.idOrderClient
                        response = await this.deleteOrderClient(id)

                        if (response.status === 200) {
                            this.setResults()
                            this.searchingCursor = false

                            this.$toast.success(this.$t(toastSuccessMessage))
                        } else {
                            this.searchingCursor = false

                            this.$toast.error(this.$t(toastErrorMessage))
                        }
                    } else {
                        this.confirmAction = null
                        this.searchingCursor = false

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        async deleteAll(elToDelete) {
            const elements = []
            let response = null
            this.searchingCursor = true
            this.confirmAction = null
            this.elementsToConfirm = []

            this.showConfirmModal = true
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text_plural'
            this.confirmModalText2 = 'irreversible_action'
            elToDelete.forEach((id) => {
                const fullOrder = this.orders.find((order) => order.id === id)
                elements.push({ Id: fullOrder.idOrderClient })
                const orderInfo = {
                    Client: fullOrder.client,
                    Ref: fullOrder.reference,
                }
                this.elementsToConfirm.push(orderInfo)
            })
            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []

                    if (this.confirmAction === true) {
                        this.confirmAction = null

                        response = await this.deleteManyOrders(elements)

                        if (response.status === 200) {
                            this.setResults()
                            this.resetCheckedItems = true
                            this.$toast.success(this.$t('delete_many_ok'))
                            this.searchingCursor = false
                        } else {
                            this.$toast.error(this.$t('delete_many_ko'))
                            this.searchingCursor = false
                        }
                        this.resetCheckedItems = false
                    } else {
                        this.confirmAction = null
                        this.searchingCursor = false

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        // checkboxFilter(dbField) {
        //     this.searchingCursor = true
        //     clearInterval(this.ordersInterval)
        //     const filtToRemoveIndex = this.dateFilter.findIndex(
        //         (filt) => filt.dbField === dbField
        //     )
        //     if (dbField === 'DateBegin') {
        //         this.pickupTodayOnly = !this.pickupTodayOnly
        //         if (this.pickupTodayOnly === true) {
        //             if (filtToRemoveIndex !== -1) {
        //                 this.dateFilter.splice(filtToRemoveIndex, 1)
        //             }
        //         }
        //     }
        //     if (dbField === 'DateEnd') {
        //         this.deliveryTodayOnly = !this.deliveryTodayOnly
        //         if (this.deliveryTodayOnly === true) {
        //             if (filtToRemoveIndex !== -1) {
        //                 this.dateFilter.splice(filtToRemoveIndex, 1)
        //             }
        //         }
        //     }
        //     this.setResults()
        // },
        setDateFilter(dFilter) {
            this.searchingCursor = true

            // if (dFilter.dbField === 'DateBegin') {
            //     this.pickupTodayOnly = false
            // }
            // if (dFilter.dbField === 'DateEnd') {
            //     this.deliveryTodayOnly = false
            // }
            const filterExistsIndex = this.dateFilter.findIndex(
                (df) => df.dbField === dFilter.dbField,
            )
            if (filterExistsIndex !== -1) {
                this.dateFilter[filterExistsIndex] = dFilter
            } else {
                this.dateFilter.slice(filterExistsIndex, 1)
                this.dateFilter.push({
                    dbField: dFilter.dbField,
                    value: dFilter.value,
                })
            }

            this.setResults()
        },
        setPage(isNext) {
            clearInterval(this.ordersInterval)
            this.$cookies.remove('unpaidFiltered')

            this.searchingCursor = true
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.ordersInterval)
            this.$cookies.remove('unpaidFiltered')

            this.search = search.query
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            search.unpaid = true
            search.searchByName = this.searchByName

            // const query = search.query

            this.$cookies.set('unpaidFiltered', search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })
            this.setRealSearch(this.search)

            // console.log(this.$cookies.get('query'))

            // this.$router.replace({
            //     path: this.$router.currentRoute.value.fullPath,
            //     query: { search: this.$cookies.get('query').term },
            // })
            search.clientId =
                this.userStore.isB2C === true
                    ? this.userStore.current.idClient
                    : ''

            this.searchOrders(search)
            this.searchingCursor = false
            this.ordersInterval = setInterval(() => {
                this.searchOrders(search)
            }, 20000)

            this.refresh += 1
        },
        changePerPage(perPage) {
            clearInterval(this.ordersInterval)

            this.$cookies.remove('unpaidFiltered')
            this.searchingCursor = true
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            clearInterval(this.ordersInterval)

            const search = {
                query: this.search,
                clientId:
                    this.userStore.isB2C === true
                        ? this.userStore.current.idClient
                        : '',
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            if (this.userStore.isB2C === false) {
                // search.deliveryTodayOnly = this.deliveryTodayOnly
                // search.pickupTodayOnly = this.pickupTodayOnly
                search.dateFilter = this.dateFilter
            }
            this.displaySearchResults(search)
        },
    },
    beforeUnmount() {
        clearInterval(this.ordersInterval)
        this.orderStore.$patch({
            allUnpaid: null,
            countAllUnpaid: 0,
            totalPagesAllUnpaid: 0,
        })
    },
}
</script>
