import { useClientStore } from '@/stores/clientStore'
import { mapActions } from 'pinia'

export default {
    name: 'multiselectDebouncer',
    data() {
        return {
            debounceClient: null,
            clientSearchQuery: '',
            universeFilterForClientSearch: null,
            clientOptions: [],
            noClientError: false,
            complementaryFilter: [],
        }
    },
    methods: {
        ...mapActions(useClientStore, {
            searchClients: 'search',
        }),
        searchClientsQuery(query, dbFieldForOption = 'IdCLient') {
            this.noClientError = false
            this.clientSearchQuery = query
            if (query !== '') {
                clearTimeout(this.debounceClient)
                let filter = []
                if (this.universeFilterForClientSearch !== null) {
                    filter = [this.universeFilterForClientSearch].concat(
                        this.complementaryFilter,
                    )
                } else {
                    filter = this.complementaryFilter
                }
                this.debounceClient = setTimeout(() => {
                    this.searchClients({
                        query: [
                            { dbField: 'Name', term: this.clientSearchQuery },
                        ],
                        filter,
                        sortDirection: 'ASC',
                        sortField: 'Name',
                        perPage: 10000,
                        page: 1,
                    })
                        .then((res) => {
                            this.clientOptions = []

                            res.data.Records.forEach((client) => {
                                const option = {
                                    name: `${client.Name}`,
                                    value: +client.Id,
                                    dbField: dbFieldForOption,
                                    ext_id: +client.IdExternal,
                                    client_type:
                                        client.ClientType ?? 'occasional',
                                    paymentType: client.PaymentType,
                                    hasProcessor: !!client.ProcessorImportId,
                                }
                                this.clientOptions.push(option)
                            })
                        })
                        .catch((err) => {
                            this.noClientError = true
                        })
                }, 200)
            }
        },
    },
}
