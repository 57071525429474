import MapView from '../views/tours/MapView.vue'
import SimulationGeneration from '../views/tours/SimulationGeneration.vue'
import Wrapper from '../views/tours/Wrapper.vue'

const tours = [
    {
        path: '/view/daily-live',
        name: 'daily_view',
        component: MapView,
        meta: {
            map: true,
            isTour: false,
            daily: true,
            layout: 'full-layout',

            authorisations: ['FULL_ACCESS', 'DAILY_FULL', 'DAILY_MAPVIEW'],
            showUniverseSearch: true,
        },
    },
    {
        path: '/tours',
        name: 'tours',
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'TOURS_FULL', 'TOURS_LIST'],
            showUniverseSearch: false,
        },

        children: [
            // {
            //     path: 'detail/:id',
            //     name: 'tour_detail',
            //     component: TourDetail,
            //     meta: {
            //         authorisations: ['FULL_ACCESS', 'TOURS_FULL'],

            //         // userStore.userRoles.includes('BIIIM_ADMIN') ||
            //         // userStore.userRoles.includes('AREA_CREATE'),
            //     },
            // },
            {
                path: 'view/:id',
                name: 'tour_view',
                component: MapView,
                meta: {
                    map: true,
                    isTour: true,
                    daily: false,
                    authorisations: [
                        'FULL_ACCESS',
                        'TOURS_FULL',
                        'TOURS_MAPVIEW',
                    ],
                    showUniverseSearch: true,
                },
            },
        ],
    },
    {
        path: '/simulations',
        name: 'simulations',
        component: Wrapper,
        meta: {
            authorisations: [
                'FULL_ACCESS',
                'SIMULATIONS_FULL',
                'SIMULATIONS_LIST',
            ],
            showUniverseSearch: false,

            // userStore.userRoles.includes('BIIIM_ADMIN') ||
            // userStore.userRoles.includes('AREA_CREATE'),
        },
        children: [
            {
                path: 'generate_simulation',
                name: 'simulation_generation',
                component: SimulationGeneration,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'SIMULATIONS_FULL',
                        'SIMULATIONS_GENERATE',
                    ],
                    showUniverseSearch: true,

                    // userStore.userRoles.includes('BIIIM_ADMIN') ||
                    // userStore.userRoles.includes('AREA_CREATE'),
                },
            },
            // {
            //     path: 'detail/:id',
            //     name: 'simulation_detail',
            //     component: SimulationDetail,
            //     meta: {
            //         authorisations: ['FULL_ACCESS', 'SIMULATIONS_FULL'],

            //         // userStore.userRoles.includes('BIIIM_ADMIN') ||
            //         // userStore.userRoles.includes('AREA_CREATE'),
            //     },
            // },
            {
                path: 'view/:id',
                name: 'simulation_view',
                component: MapView,
                meta: {
                    map: true,
                    authorisations: [
                        'FULL_ACCESS',
                        'SIMULATIONS_FULL',
                        'SIMULATIONS_MAPVIEW',
                    ],

                    isTour: false,
                    isSimulation: true,
                    daily: false,
                    showUniverseSearch: true,
                },
            },
        ],
    },
]

export default tours
