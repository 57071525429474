<template>
    <div class="mx-auto bg-white">
        <stepper
            :key="refresh"
            :givenStep="currentStep"
            :disableNext="
                currentStep === 1 && !formData.Latitude && !formData.Longitude
            "
            @created="initMap()"
            @prev="reloadMaps('previous')"
            @next="reloadMaps('next')"
            @submit="saveHarbor()"
            @cancel="resetForm()"
        >
            <template v-if="currentStep === 1" #step1>
                <div class="space-y-4">
                    <h2 class="text-2xl font-bold mb-4">
                        {{ $t('harbor') }}
                    </h2>
                    <div class="grid grid-cols-2 gap-4">
                        <div
                            v-if="newFromDock === true || newFromDef === true"
                            class="col-span-2"
                        >
                            <Multiselect
                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                v-model="formData.Id"
                                :options="harborOptions"
                                label="name"
                                track-by="name"
                                :searchable="true"
                                :placeholder="$t('select_harbor')"
                                :allow-empty="true"
                                :can-clear="true"
                                @search-change="searchHarborsQuery($event)"
                                @select="setHarbor($event)"
                                @clear="setHarbor()"
                            ></Multiselect>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-if="noHarborError"
                            >
                                <div class="error-msg">
                                    {{ $t('no_results') }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="
                                !isUpdate &&
                                (newFromDock === true || newFromDef === true)
                            "
                            class="col-span-2 text-base font-bold"
                        >
                            {{ $t('or') + ' ' + $t('create') }}
                        </div>
                        <div>
                            <label class="block mb-2">{{
                                $t('harbor_form.name')
                            }}</label>
                            <input
                                v-model="formData.Name"
                                @blur="v$.formData.Name.$touch"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('harbor_form.name')"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.Name.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="block mb-2">{{
                                $t('harbor_form.description')
                            }}</label>
                            <input
                                v-model="formData.Description"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('harbor_form.description')"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.Description.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="block mb-2">{{
                                $t('harbor_form.address1')
                            }}</label>
                            <input
                                v-model="formData.Address1"
                                id="autocomplete"
                                autocomplete="address-level1"
                                @blur="v$.formData.Address1.$touch"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('harbor_form.address1')"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.Address1.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="block mb-2">{{
                                $t('harbor_form.address2')
                            }}</label>
                            <input
                                v-model="formData.Address2"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('harbor_form.address2')"
                            />
                        </div>
                    </div>
                    <div class="grid grid-cols-3 gap-4">
                        <div>
                            <label class="block mb-2">{{
                                $t('harbor_form.postalCode')
                            }}</label>
                            <input
                                v-model="formData.PostalCode"
                                @blur="v$.formData.PostalCode.$touch"
                                class="w-full px-3 py-2 border rounded-md"
                                autocomplete="postal-code"
                                :placeholder="$t('harbor_form.postalCode')"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.PostalCode.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="block mb-2">{{
                                $t('harbor_form.city')
                            }}</label>
                            <input
                                v-model="formData.City"
                                @blur="v$.formData.City.$touch"
                                autocomplete="city"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('harbor_form.city')"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.City.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="block mb-2">{{
                                $t('harbor_form.country')
                            }}</label>
                            <!-- :object="true" -->
                            <Multiselect
                                id="countries"
                                @blur="v$.formData.Country.$touch"
                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                v-model="formData.Country"
                                :placeholder="$t('harbor_form.country')"
                                :searchable="true"
                                :options="countriesOptions"
                                label="name"
                                track-by="name"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.Country.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="harborMap" class="h-[400px] w-full"></div>
                </div>
            </template>
            <template v-if="currentStep === 2" #step2>
                <div class="space-y-4">
                    <h2 class="text-2xl font-bold mb-4">
                        {{ $t('dock_form.title') }}
                    </h2>
                    <h2
                        class="text-base theme-color font-bold mb-4 whitespace-nowrap"
                    >
                        {{
                            `${formData.Name} - ${formData.Description} - ${formData.Address1} ${formData.PostalCode} ${formData.City} ${formData.Country}`
                        }}
                    </h2>
                    <div
                        class="grid gap-2"
                        :class="
                            isCurrentDockSaved ? 'grid-cols-12' : 'grid-cols-6'
                        "
                    >
                        <span class="border-2 col-span-6 rounded p-2">
                            <div class="space-y-4">
                                <div
                                    v-if="formData.Docks.length > 0"
                                    class="mt-2"
                                >
                                    <h3 class="text-xl font-semibold mb-2">
                                        {{ $t('dock_form.existingDocks') }}
                                    </h3>
                                    <div class="grid grid-cols-3 gap-2">
                                        <div
                                            v-for="(
                                                dock, index
                                            ) in formData.Docks"
                                            :key="index"
                                            class="flex items-center text-sm border-2 justify-between p-2 rounded mb-2"
                                            :class="
                                                currentDock &&
                                                dock.Id === currentDock.Id
                                                    ? 'themed-bg border-black'
                                                    : ''
                                            "
                                        >
                                            <span
                                                @click="setDock(dock.Id, true)"
                                                class="cursor-pointer hover:font-semibold"
                                                ><fa-icon
                                                    icon="pen"
                                                    class="mr-1 fa-sm"
                                                ></fa-icon
                                                >{{ dock.Name }} -
                                                {{ dock.Description }}</span
                                            >
                                            <button
                                                @click="removeDock(index)"
                                                class="text-red-500 hover:text-red-700 rounded-md"
                                            >
                                                <fa-icon
                                                    icon="trash-can"
                                                    class="fa-sm"
                                                ></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div
                                    class="mb-4 mt-2 flex items-center justify-end"
                                >
                                    <span
                                        class="flex items-center justify-center"
                                    >
                                        <button
                                            @click="addDock"
                                            class="px-4 text-sm py-2 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center"
                                        >
                                            <fa-icon
                                                icon="floppy-disk"
                                                class="mr-2 fa-beat-fade"
                                            ></fa-icon>
                                            {{
                                                $t(
                                                    currentDock.Id === ''
                                                        ? 'dock_form.add'
                                                        : 'dock_form.update',
                                                )
                                            }}
                                        </button>
                                        <button
                                            v-if="
                                                currentDock.Id === '' ||
                                                (currentDock.Id !== '' &&
                                                    currentDock.Defenses
                                                        .length === 0)
                                            "
                                            @click="addDock(true)"
                                            class="px-4 ml-2 text-sm py-2 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center"
                                        >
                                            <fa-icon
                                                icon="floppy-disk"
                                                class="mr-2 fa-beat-fade"
                                            ></fa-icon>
                                            {{
                                                $t(
                                                    currentDock.Id === ''
                                                        ? 'dock_form.add_n_def'
                                                        : 'dock_form.update_n_def',
                                                )
                                            }}
                                        </button>
                                        <button
                                            v-if="currentDock.Id !== ''"
                                            @click="cancelUpdateDock()"
                                            class="text-sm ml-2 px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-md flex items-center"
                                        >
                                            <fa-icon
                                                icon="xmark"
                                                class="mr-2"
                                            ></fa-icon>
                                            {{ $t('dock_form.cancel_update') }}
                                        </button>
                                    </span>
                                </div>
                                <div class="grid grid-cols-2 gap-4">
                                    <div
                                        v-if="newFromDef === true"
                                        class="col-span-2"
                                    >
                                        <Multiselect
                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                            v-model="currentDock.Id"
                                            :options="dockOptions"
                                            label="name"
                                            track-by="name"
                                            :searchable="true"
                                            :placeholder="$t('select_dock')"
                                            :allow-empty="true"
                                            :can-clear="true"
                                            @search-change="
                                                searchDocksQuery($event)
                                            "
                                            @select="setDock($event)"
                                            @clear="setDock()"
                                        ></Multiselect>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-if="noDockError"
                                        >
                                            <div class="error-msg">
                                                {{ $t('no_results') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="!isUpdate && newFromDef === true"
                                        class="col-span-2 text-base font-bold"
                                    >
                                        {{ $t('or') + ' ' + $t('create') }}
                                    </div>
                                    <div>
                                        <label class="block mb-2">{{
                                            $t('dock_form.name')
                                        }}</label>
                                        <input
                                            v-model="currentDock.Name"
                                            @blur="v$.currentDock.Name.$touch"
                                            class="w-full px-3 py-2 border rounded-md"
                                            :placeholder="$t('dock_form.name')"
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.currentDock.Name
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label class="block mb-2">{{
                                            $t('dock_form.description')
                                        }}</label>
                                        <input
                                            v-model="currentDock.Description"
                                            class="w-full px-3 py-2 border rounded-md"
                                            :placeholder="
                                                $t('dock_form.description')
                                            "
                                        />
                                    </div>
                                    <div>
                                        <label class="block mb-2">{{
                                            $t('dock_form.latitude')
                                        }}</label>
                                        <input
                                            v-model="currentDock.Latitude"
                                            @blur="
                                                v$.currentDock.Latitude.$touch
                                            "
                                            id="autocompleteDock"
                                            type="text"
                                            class="w-full px-3 py-2 border rounded-md"
                                            :placeholder="
                                                $t('dock_form.latitude')
                                            "
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.currentDock
                                                .Latitude.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label class="block mb-2">{{
                                            $t('dock_form.longitude')
                                        }}</label>
                                        <input
                                            v-model="currentDock.Longitude"
                                            type="text"
                                            @blur="
                                                v$.currentDock.Longitude.$touch
                                            "
                                            class="w-full px-3 py-2 border rounded-md"
                                            :placeholder="
                                                $t('dock_form.longitude')
                                            "
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.currentDock
                                                .Longitude.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="my-1 italic text-sm flex justify-center"
                                >
                                    {{ $t('drag_pin_or_enter_coords') }}
                                </div>
                                <div
                                    id="dockMap"
                                    class="h-[400px] w-full"
                                ></div>
                            </div>
                        </span>
                        <span
                            v-if="isCurrentDockSaved"
                            class="border-2 rounded col-span-6 p-2"
                        >
                            <div class="space-y-4">
                                <div
                                    v-if="currentDock.Defenses.length > 0"
                                    class="mt-2"
                                >
                                    <h3 class="text-xl font-semibold mb-2">
                                        {{
                                            $t('defense_form.existingDefenses')
                                        }}
                                    </h3>
                                    <div class="grid grid-cols-3 gap-2">
                                        <div
                                            v-for="(
                                                defense, index
                                            ) in currentDock.Defenses"
                                            :key="index"
                                            class="flex items-center border-2 text-sm justify-between p-2 rounded mb-2"
                                            :class="
                                                currentDefense &&
                                                defense.Id === currentDefense.Id
                                                    ? 'themed-bg border-black '
                                                    : ''
                                            "
                                        >
                                            <span
                                                @click="setDefense(defense.Id)"
                                                class="cursor-pointer hover:font-semibold"
                                                ><fa-icon
                                                    icon="pen"
                                                    class="mr-1 fa-sm"
                                                ></fa-icon
                                                >{{
                                                    `${defense.Name} - ${defense.Description}`
                                                }}</span
                                            >
                                            <button
                                                @click="removeDefense(index)"
                                                class="text-red-500 hover:text-red-700 rounded-md"
                                            >
                                                <fa-icon
                                                    icon="trash-can"
                                                    class="fa-sm"
                                                ></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div
                                    class="mb-4 mt-2 flex items-center justify-end"
                                >
                                    <span
                                        class="flex items-center justify-center"
                                    >
                                        <button
                                            @click="addDefense"
                                            class="px-4 py-2 text-sm bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center"
                                        >
                                            <fa-icon
                                                icon="floppy-disk"
                                                class="mr-2 fa-beat-fade"
                                            ></fa-icon>
                                            {{
                                                $t(
                                                    currentDefense.Id === ''
                                                        ? 'defense_form.add'
                                                        : 'defense_form.update',
                                                )
                                            }}
                                        </button>
                                        <!-- boutton dannula tion de l'update defense et faire idem pour dock-->
                                        <button
                                            v-if="currentDefense.Id !== ''"
                                            @click="cancelUpdateDefense()"
                                            class="ml-2 text-sm px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-md flex items-center"
                                        >
                                            <fa-icon
                                                icon="xmark"
                                                class="mr-2"
                                            ></fa-icon>
                                            {{
                                                $t('defense_form.cancel_update')
                                            }}
                                        </button>
                                    </span>
                                </div>
                                <div class="grid grid-cols-2 gap-4">
                                    <div>
                                        <label class="block mb-2">{{
                                            $t('defense_form.name')
                                        }}</label>
                                        <input
                                            v-model="currentDefense.Name"
                                            @blur="
                                                v$.currentDefense.Name.$touch
                                            "
                                            class="w-full px-3 py-2 border rounded-md"
                                            :placeholder="
                                                $t('defense_form.name')
                                            "
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.currentDefense
                                                .Name.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label class="block mb-2">{{
                                            $t('defense_form.description')
                                        }}</label>
                                        <input
                                            v-model="currentDefense.Description"
                                            class="w-full px-3 py-2 border rounded-md"
                                            :placeholder="
                                                $t('defense_form.description')
                                            "
                                        />
                                    </div>
                                    <div>
                                        <label class="block mb-2">{{
                                            $t('defense_form.latitude')
                                        }}</label>
                                        <input
                                            v-model="currentDefense.Latitude"
                                            id="autocompleteDef"
                                            @blur="
                                                v$.currentDefense.Latitude
                                                    .$touch
                                            "
                                            type="text"
                                            class="w-full px-3 py-2 border rounded-md"
                                            :placeholder="
                                                $t('defense_form.latitude')
                                            "
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.currentDefense
                                                .Latitude.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label class="block mb-2">{{
                                            $t('defense_form.longitude')
                                        }}</label>
                                        <input
                                            v-model="currentDefense.Longitude"
                                            type="text"
                                            @blur="
                                                v$.currentDefense.Longitude
                                                    .$touch
                                            "
                                            class="w-full px-3 py-2 border rounded-md"
                                            :placeholder="
                                                $t('defense_form.longitude')
                                            "
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.currentDefense
                                                .Longitude.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="my-1 italic text-sm flex justify-center"
                                >
                                    {{ $t('drag_pin_or_enter_coords') }}
                                </div>
                                <div
                                    id="defenseMap"
                                    class="h-[400px] w-full"
                                ></div>
                            </div>
                        </span>
                    </div>
                </div>
            </template>
            <template v-if="currentStep === 3" #step3>
                <h2 class="text-2xl font-bold mb-4">
                    {{ $t('summary') }}
                </h2>
                <h2
                    class="text-xl theme-color font-bold mb-4 whitespace-nowrap"
                >
                    {{
                        `${formData.Name} - ${formData.Description} - ${formData.Address1} ${formData.PostalCode} ${formData.City} ${formData.Country}`
                    }}
                </h2>
                <div class="grid grid-cols-4 gap-2">
                    <div
                        v-for="(dock, index) in formData.Docks"
                        :key="index"
                        class="text-xs col-span-1 flex items-center overflow-auto"
                    >
                        <span
                            @click="drawLines(dock, index)"
                            class="border-2 hover:text-[theme-color] rounded p-2 w-full h-full cursor-pointer"
                        >
                            <div class="font-semibold mb-1" :title="$t('name')">
                                {{ `${dock.Name}` }}
                            </div>

                            <div
                                v-if="dock.Description"
                                class="italic"
                                :title="$t('description')"
                            >
                                {{ `${dock.Description}` }}
                            </div>
                            <div class="italic" :title="$t('defenses')">
                                <fa-icon icon="shield" class="mr-1 mt-2" />
                                {{
                                    `${dock.Defenses && dock.Defenses.length > 0 ? dock.Defenses.length : 0} ${$t(
                                        'def_tc',
                                        dock.Defenses.length <= 1 ? 1 : 2,
                                    )}`
                                }}
                            </div>
                        </span>
                    </div>
                </div>
                <div id="summaryMap" class="h-[400px] w-full mt-4"></div>
            </template>
        </stepper>
    </div>
</template>

<script>
import Stepper from '@/components/atoms/Stepper.vue'
import gmapStyles from '@/mixins/gmapStyles'
import googleScriptLoader from '@/mixins/googleScript'
import harborMultiselectDebouncer from '@/mixins/harborMultiselectDebouncer'
import colors from '@/resources/colors'
import { useHarborStore } from '@/stores/harborStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapState, mapWritableState } from 'pinia'
import countries from '../../resources/countries'

// const isZipCode = helpers.regex(
//     /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/,
// )
let mark = null
const lines = []
export default {
    name: 'CreateOrUpdateSlider',
    components: {
        Stepper,
        Multiselect,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    mixins: [gmapStyles, googleScriptLoader, harborMultiselectDebouncer],
    data() {
        return {
            countriesOptions: countries,
            refresh: 0,
            currentDefense: {
                Id: '',
                Name: '',
                Latitude: '',
                Longitude: '',
            },
            currentDock: {
                Name: '',
                Id: '',
                Description: '',
                Latitude: '',
                Longitude: '',
                Defenses: [],
            },
            formData: {
                Id: '',
                Name: '',
                Description: '',
                Address1: '',
                Address2: '',
                PostalCode: '',
                City: '',
                Country: '',
                Latitude: '',
                Longitude: '',
                Docks: [],
                DefensesToRemove: [],
                DocksToRemove: [],
            },
            currentStep: 1,
            isCurrentDockSaved: false,
        }
    },
    props: {
        dockId: {
            type: Number,
            default: null,
        },
        harborId: {
            type: Number,
            default: null,
        },
        defenseId: {
            type: Number,
            default: null,
        },
        isUpdate: {
            type: Boolean,
            default: false,
        },
        newFromDock: {
            type: Boolean,
            default: false,
        },
        newFromDef: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(useUserStore, { user: 'current' }),
        ...mapWritableState(useHarborStore, { harbor: 'current' }),
    },

    created() {
        this.multiMaps = true
        this.maps = []
    },
    mounted() {
        if (this.isUpdate === true && this.harborId) {
            this.fetchOne(this.harborId).then((res) => {
                this.currentStep = 1

                this.formData = structuredClone(res.data)
                // this.currentDock = structuredClone(this.formData.Docks[0])
                // this.currentDefense = structuredClone(
                //     this.currentDock.Defenses[0],
                // )

                if (this.dockId !== null) {
                    // this.loadGoogleScript()

                    this.currentStep = 2
                    this.currentDock = structuredClone(
                        this.formData.Docks.find(
                            (dock) => dock.Id === this.dockId,
                        ),
                    )
                    // this.currentDefense = structuredClone(
                    //     this.currentDock?.Defenses[0],
                    // )
                    this.initMap('dockMap', 10)
                    // this.initMap('summaryMap', 10)

                    const dockOption = {
                        value: this.currentDock.Id,
                        name: this.currentDock.Name,
                        Name: this.currentDock.Name,
                        Id: this.currentDock.Id,
                        Description: this.currentDock.Description,
                        Latitude: this.currentDock.Latitude,
                        Longitude: this.currentDock.Longitude,
                        Defenses: this.currentDock.Defenses,
                    }
                    this.dockOptions.push(dockOption)
                    this.isCurrentDockSaved = true

                    if (this.defenseId !== null) {
                        this.currentDefense = structuredClone(
                            this.currentDock.Defenses.find(
                                (defense) => defense.Id === this.defenseId,
                            ),
                        )
                    }
                    this.initMap('defenseMap', 10)
                }
                const harborOption = {
                    value: this.formData.Id,
                    name: this.formData.Name,
                    Name: this.formData.Name,
                    Id: this.formData.Id,
                    Description: this.formData.Description,
                    Address1: this.formData.Address1,
                    Address2: this.formData.Address2,
                    PostalCode: this.formData.PostalCode,
                    City: this.formData.City,
                    Country: this.formData.Country,
                    Latitude: this.formData.Latitude,
                    Longitude: this.formData.Longitude,
                    Docks: this.formData.Docks,
                }
                this.harborOptions.push(harborOption)

                // if (this.defenseId !== null) {
                //     this.currentStep = 3
                //     this.currentDefense = this.formData.Docks.Defenses.find(
                //         (defense) => defense.Id === this.defenseId,
                //     )
                // }
                this.refresh += 1
            })
            // } else {
            //     // this.loadGoogleScript('autocomplete')

            //     this.initMap()
        }
    },
    validations() {
        return {
            formData: {
                Name: {
                    required,
                },

                Address1: {
                    required,
                },
                PostalCode: {
                    required,
                    // isZipCode: helpers.withMessage(
                    //     this.$t('bad_zip_format'),
                    //     isZipCode,
                    // ),
                },
                City: {
                    required,
                },
                Country: {
                    required,
                },
                Description: {
                    required,
                },
            },
            currentDock: {
                Name: {
                    required,
                },
                Description: {
                    required,
                },
                Latitude: {
                    required,
                },
                Longitude: {
                    required,
                },
            },
            currentDefense: {
                Name: {
                    required,
                },
                Description: {
                    required,
                },
                Latitude: {
                    required,
                },
                Longitude: {
                    required,
                },
            },
        }
    },
    methods: {
        ...mapActions(useHarborStore, ['create', 'update', 'fetchOne']),

        drawLines(dock, index) {
            if (lines.find((l) => l[`${dock.Name}-${index}`])) {
                lines.forEach((line) => {
                    line[`${dock.Name}-${index}`].setVisible(false)
                    // line[`${dock.Name}-${index}`].setMap(this.maps['summaryMap'])
                    line[`${dock.Name}-${index}`].setMap(null)
                    lines.splice(index, 1)
                })
            } else {
                const coordinatesForPolylines = []
                let tourPath = null

                const harborPosition = {
                    lat: parseFloat(this.formData.Latitude),
                    lng: parseFloat(this.formData.Longitude),
                }
                coordinatesForPolylines.push(harborPosition)

                const dockPosition = {
                    lat: parseFloat(dock.Latitude),
                    lng: parseFloat(dock.Longitude),
                }
                coordinatesForPolylines.push(dockPosition)

                dock.Defenses.forEach((defense, index) => {
                    coordinatesForPolylines.push({
                        lat: parseFloat(defense.Latitude),
                        lng: parseFloat(defense.Longitude),
                    })
                })
                let polyline = null

                tourPath = {
                    path: coordinatesForPolylines,
                    // geodesic: true, // hearth curves
                    strokeColor: colors[index].main.hex,
                    strokeOpacity: 1.0,
                    fillOpacity: 0,
                    strokeWeight: 3,
                }
                polyline = new google.maps.Polyline(tourPath)
                polyline.setMap(this.maps.summaryMap)
                lines.push({ [`${dock.Name}-${index}`]: polyline })
            }
        },
        resetForm() {
            this.formData = {
                Id: '',
                Name: '',
                Description: '',
                Address1: '',
                Address2: '',
                PostalCode: '',
                City: '',
                Country: '',
                Latitude: '',
                Longitude: '',
                Docks: [],
                DefensesToRemove: [],
                DocksToRemove: [],
            }
            this.currentDock = {
                Name: '',
                Id: '',
                Description: '',
                Latitude: '',
                Longitude: '',
                Defenses: [],
            }
            this.currentDefense = {
                Id: '',
                Name: '',
                Latitude: '',
                Longitude: '',
            }
            this.currentStep = 1
            this.isCurrentDockSaved = false
            this.$emit('cancel')
        },
        setDock(dockId = null, fromExisting = false) {
            let dock = null
            if (dockId === null) {
                this.currentDock = {
                    Name: '',
                    Id: '',
                    Description: '',
                    Latitude: '',
                    Longitude: '',
                    Defenses: [],
                }
                this.isCurrentDockSaved = false
                this.initMap('dockMap', 10)
                this.initMap('defenseMap', 10)
                return
            }
            if (fromExisting) {
                dock = this.formData.Docks.find((dck) => dck.Id === dockId)
            } else {
                dock = this.dockOptions.find((dck) => dck.Id === dockId)
            }
            this.currentDock = structuredClone(dock)
            this.isCurrentDockSaved = true
            this.initMap('dockMap', 10)
            this.initMap('defenseMap', 10)
        },
        setDefense(defenseId) {
            const defense = this.currentDock.Defenses.find(
                (def) => def.Id === defenseId,
            )
            this.currentDefense = structuredClone(defense)
            this.initMap('defenseMap', 10)
        },
        setHarbor(harborId = null) {
            if (harborId === null) {
                this.formData = {
                    Id: '',
                    Name: '',
                    Description: '',
                    Address1: '',
                    Address2: '',
                    PostalCode: '',
                    City: '',
                    Country: '',
                    Latitude: '',
                    Longitude: '',
                    Docks: [],
                    DefensesToRemove: [],
                    DocksToRemove: [],
                }
                this.currentDock = {
                    Name: '',
                    Id: '',
                    Description: '',
                    Latitude: '',
                    Longitude: '',
                    Defenses: [],
                }
                this.currentDefense = {
                    Id: '',
                    Name: '',
                    Latitude: '',
                    Longitude: '',
                }
                this.currentStep = 1
                this.isCurrentDockSaved = false
                return
            }
            const harbor = this.harborOptions.find(
                (harb) => harb.Id === harborId,
            )
            this.formData = harbor
        },
        initMap(mapId = 'harborMap', zoom = 12) {
            let entity = 'harbor'
            let latitude = this.harbor
                ? this.formData.Latitude
                : this.user.env.latitude
            let longitude = this.harbor
                ? this.formData.Longitude
                : this.user.env.longitude
            const markers = []
            // boat e532
            // defense e9e0
            // dock f1cd
            const labels = {
                harbor: {
                    text: '\ue532',
                    fontFamily: 'Material Icons',
                    color: '#000000',
                    fontSize: '18px',
                },
                dock: {
                    text: '\uf1cd',
                    fontFamily: 'Material Icons',
                    color: '#000000',
                    fontSize: '18px',
                },
                defense: {
                    text: '\ue9e0',
                    fontFamily: 'Material Icons',
                    color: '#000000',
                    fontSize: '18px',
                },
            }
            if (mapId === 'dockMap') {
                this.loadGoogleScript()

                latitude = this.currentDock?.Latitude || this.formData.Latitude
                longitude =
                    this.currentDock?.Longitude || this.formData.Longitude
                entity = 'dock'
            } else if (mapId === 'defenseMap') {
                latitude =
                    this.currentDefense?.Latitude || this.formData.Latitude
                longitude =
                    this.currentDefense?.Longitude || this.formData.Longitude
                entity = 'defense'
            } else if (mapId === 'summaryMap') {
                latitude = this.formData.Latitude
                longitude = this.formData.Longitude
                // push dans markers tous les docks et les defenses
                markers.push({
                    position: {
                        lat: parseFloat(this.formData.Latitude),
                        lng: parseFloat(this.formData.Longitude),
                    },
                    label: labels.harbor,
                    title: `${this.$t('harbor')} - ${this.formData.Name}`,
                })
                this.formData.Docks.forEach((dock) => {
                    markers.push({
                        position: {
                            lat: parseFloat(dock.Latitude),
                            lng: parseFloat(dock.Longitude),
                        },
                        label: labels.dock,
                        title: `${this.$t('dock')} - ${dock.Name}`,
                    })
                    dock.Defenses.forEach((defense) => {
                        markers.push({
                            position: {
                                lat: parseFloat(defense.Latitude),
                                lng: parseFloat(defense.Longitude),
                            },
                            label: labels.defense,

                            title: `${this.$t('defense')} - ${defense.Name}`,
                        })
                    })
                })
            } else {
                this.loadGoogleScript('autocomplete')
            }

            const googleInterval = setInterval(() => {
                if (document.getElementById(mapId) && google) {
                    if (this.isUpdate === false) {
                        clearInterval(googleInterval)

                        this.maps[mapId] = new google.maps.Map(
                            document.getElementById(mapId),
                            {
                                center: {
                                    lat: parseFloat(latitude),
                                    lng: parseFloat(longitude),
                                },
                                zoom,
                            },
                        )
                        if (mapId !== 'summaryMap') {
                            const marker = new google.maps.Marker({
                                animation: google.maps.Animation.DROP,
                                position: {
                                    lat: parseFloat(latitude),
                                    lng: parseFloat(longitude),
                                },
                                draggable: true,
                            })
                            marker.setMap(this.maps[mapId])
                            const eventScreen =
                                'ontouchstart' in document.documentElement
                                    ? 'touchend'
                                    : 'dragend'

                            marker.addListener(eventScreen, (evt) => {
                                this.markerDragged(evt, entity)
                            })
                        } else {
                            markers.forEach((mark) => {
                                const marker = new google.maps.Marker({
                                    animation: google.maps.Animation.DROP,
                                    position: mark.position,
                                    label: mark.label,
                                    title: mark.title,
                                    draggable: false,
                                    clickable: false,
                                })
                                marker.setMap(this.maps[mapId])
                            })
                        }
                    } else {
                        clearInterval(googleInterval)

                        this.maps[mapId] = new google.maps.Map(
                            document.getElementById(mapId),
                            {
                                center: {
                                    lat: !Number.isNaN(latitude)
                                        ? parseFloat(latitude)
                                        : 45.75,
                                    lng: !Number.isNaN(longitude)
                                        ? parseFloat(longitude)
                                        : 4.85,
                                },
                                zoom: 15,
                            },
                        )
                        if (
                            !Number.isNaN(latitude) &&
                            !Number.isNaN(longitude)
                        ) {
                            if (mapId !== 'summaryMap') {
                                const marker = new google.maps.Marker({
                                    animation: google.maps.Animation.DROP,
                                    position: {
                                        lat: parseFloat(latitude),

                                        lng: parseFloat(longitude),
                                    },
                                    draggable: true,
                                })
                                marker.setMap(this.maps[mapId])
                                const eventScreen =
                                    'ontouchstart' in document.documentElement
                                        ? 'touchend'
                                        : 'dragend'

                                marker.addListener(eventScreen, (evt) => {
                                    this.markerDragged(evt, entity)
                                })
                            } else {
                                markers.forEach((mark) => {
                                    const marker = new google.maps.Marker({
                                        animation: google.maps.Animation.DROP,
                                        position: mark.position,
                                        label: mark.label,
                                        title: mark.title,
                                        draggable: false,
                                        clickable: false,
                                    })
                                    marker.setMap(this.maps[mapId])
                                })
                            }
                        }
                    }
                    this.setGoogleMapStyle(false)
                }
            }, 20)
        },
        async reloadMaps(direction) {
            this.v$.formData.$reset()
            let isValid = false
            if (this.currentStep === 1) {
                isValid = await this.v$.formData.$validate()
            }
            if (isValid === true || this.currentStep > 1) {
                if (this.currentDock.Id !== '') {
                    const formDock = this.formData.Docks.find(
                        (dock) => dock.Id === this.currentDock.Id,
                    )
                    if (
                        !formDock ||
                        JSON.stringify(this.currentDock) !==
                            JSON.stringify(formDock)
                    ) {
                        this.currentStep = 2
                        this.$toast.warning(this.$t('dock_updates_not_saved'))
                        return
                    }
                    if (this.currentDefense.Id !== '') {
                        // find
                        const defDock = formDock.Defenses.find(
                            (defense) => defense.Id === this.currentDefense.Id,
                        )

                        if (
                            !defDock ||
                            JSON.stringify(this.currentDefense) !==
                                JSON.stringify(defDock)
                        ) {
                            this.currentStep = 2

                            this.$toast.warning(
                                this.$t('defense_updates_not_saved'),
                            )
                            return
                        }
                    }
                }
                if (direction === 'next') {
                    this.currentStep += 1
                } else {
                    this.currentStep -= 1
                }
                if (this.currentStep === 2) {
                    this.initMap('dockMap', 10)
                    if (this.currentDock.Id !== '') {
                        this.initMap('defenseMap', 10)
                    }
                } else if (this.currentStep === 3) {
                    this.initMap('summaryMap', 10)
                } else if (this.currentStep === 1) {
                    this.initMap()
                }
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },
        markerDragged(position, entity) {
            if (entity === 'harbor') {
                this.formData.Latitude = position.latLng.lat()
                this.formData.Longitude = position.latLng.lng()
            } else if (entity === 'dock') {
                this.currentDock.Latitude = position.latLng.lat()
                this.currentDock.Longitude = position.latLng.lng()
            } else if (entity === 'defense') {
                this.currentDefense.Latitude = position.latLng.lat()
                this.currentDefense.Longitude = position.latLng.lng()
            }
        },
        fillInAddress(autocomplete, unique) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            this.formData.Address2 = ''
            this.formData.Address1 = ''

            this.formData.PostalCode = ''
            this.formData.City = ''

            const userInput = this.formData.Address1
            this.formData.Latitude = place.geometry.location.lat()
            this.formData.Longitude = place.geometry.location.lng()
            if (mark !== null) {
                mark.setMap(null)
            }

            this.maps.harborMap.setCenter({
                lat: this.formData.Latitude,
                lng: this.formData.Longitude,
            })

            this.maps.harborMap.setZoom(15)

            mark = new google.maps.Marker({
                animation: google.maps.Animation.DROP,
                position: {
                    lat: this.formData.Latitude,

                    lng: this.formData.Longitude,
                },
                draggable: true,
            })
            mark.setMap(this.maps.harborMap)
            const eventScreen =
                'ontouchstart' in document.documentElement
                    ? 'touchend'
                    : 'dragend'

            mark.addListener(eventScreen, (evt) => {
                this.markerDragged(evt, 'harbor')
            })

            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name
                if (addressType === 'postal_code') {
                    this.formData.PostalCode = val
                } else if (addressType === 'locality') {
                    this.formData.City = val
                } else if (addressType === 'country') {
                    const selectedCountry = this.countriesOptions.find(
                        (country) => country.code2 === val,
                    )
                    this.formData.Country = selectedCountry.value
                } else if (addressType === 'street_number') {
                    this.formData.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.formData.Address1 =
                        this.formData.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.formData.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    this.formData.Address2 = `${val}`
                }
                if (
                    (place.types[0] !== 'street_number' ||
                        place.types[0] !== 'route') &&
                    addressType === 'locality' &&
                    this.formData.Address1 === userInput
                ) {
                    this.formData.Address1 = ` ${val}`
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'premise' &&
                    this.formData.Address2 === ''
                ) {
                    this.formData.Address2 = place.name
                }
                // if (
                //     place.types[0] !== 'street_address' &&
                //     place.types[0] !== 'route' &&
                //     place.types[0] !== 'locality' &&
                //     this.formData.Name === ''
                // ) {
                //     this.formData.Name = place.name
                // }
            })
        },
        async addDock(andDefense = false) {
            // si le dock est déjà dans la liste on ne l'ajoute pas on le met à jour
            this.v$.currentDock.$reset()
            this.isCurrentDockSaved = false
            if (!this.currentDock.Id || this.currentDock.Id === '') {
                this.currentDock.Id = `fake-${this.currentDock.Name}`
            }
            const isValid = await this.v$.currentDock.$validate()
            if (isValid === true) {
                if (
                    this.formData.Docks.find(
                        (dock) => dock.Id === this.currentDock.Id,
                    )
                ) {
                    const dockIndex = this.formData.Docks.findIndex(
                        (dock) => dock.Id === this.currentDock.Id,
                    )
                    this.formData.Docks[dockIndex] = { ...this.currentDock }
                } else {
                    this.formData.Docks.push({ ...this.currentDock })
                }
                this.v$.currentDock.$reset()
                if (andDefense === true) {
                    this.isCurrentDockSaved = true
                    this.initMap('defenseMap', 10)
                } else {
                    this.currentDock = {
                        Id: '',
                        Name: '',
                        Description: '',
                        Latitude: '',
                        Longitude: '',
                        Defenses: [],
                    }
                }
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },

        removeDock(index) {
            const dock = this.formData.Docks[index]
            if (this.currentDock.Id === dock.Id) {
                this.currentDock = {
                    Id: '',
                    Name: '',
                    Description: '',
                    Latitude: '',
                    Longitude: '',
                    Defenses: [],
                }
                this.isCurrentDockSaved = false
            }
            this.formData.Docks.splice(index, 1)
            this.formData.DocksToRemove.push(dock.Id)
        },
        cancelUpdateDock() {
            this.isCurrentDockSaved = false

            this.currentDock = {
                Id: '',
                Name: '',
                Description: '',
                Latitude: '',
                Longitude: '',
                Defenses: [],
            }
        },
        cancelUpdateDefense() {
            this.currentDefense = {
                Id: '',
                Name: '',
                Description: '',
                Latitude: '',
                Longitude: '',
            }
        },
        async addDefense() {
            this.v$.currentDefense.$reset()
            const isValid = await this.v$.currentDefense.$validate()
            if (
                !this.formData.Docks.findIndex(
                    (dock) => dock === this.currentDock,
                )
            ) {
                this.$toast.warning(this.$t('select_dock_warning'))
                return
            }
            if (isValid === true) {
                if (!this.currentDefense.Id || this.currentDefense.Id === '') {
                    this.currentDefense.Id = `fake-${this.currentDefense.Name}`
                }
                //   si la défense est déjà dans la liste on ne l'ajoute pas on la met à jour
                if (
                    this.currentDock.Defenses.find(
                        (defense) => defense.Id === this.currentDefense.Id,
                    )
                ) {
                    const defenseIndex = this.currentDock.Defenses.findIndex(
                        (defense) => defense.Id === this.currentDefense.Id,
                    )
                    this.currentDock.Defenses[defenseIndex] = {
                        ...this.currentDefense,
                    }
                } else {
                    this.currentDock.Defenses.push({
                        ...this.currentDefense,
                    })
                }
                this.v$.currentDefense.$reset()
                // mettre à jour le dock dans la liste formData.Docks
                const dockIndex = this.formData.Docks.findIndex(
                    (dck) => dck.Id === this.currentDock.Id,
                )
                this.formData.Docks[dockIndex] = { ...this.currentDock }
                this.currentDefense = {
                    Id: '',
                    Description: '',
                    Name: '',
                    Latitude: '',
                    Longitude: '',
                }
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },

        removeDefense(index) {
            const dockIndex = this.formData.Docks.findIndex(
                (dock) => dock.Id === this.currentDock.Id,
            )
            const defense = this.formData.Docks[dockIndex].Defenses[index]
            this.formData.Docks[dockIndex].Defenses.splice(index, 1)
            this.currentDock.Defenses.splice(index, 1)
            this.formData.DefensesToRemove.push(defense.Id)
        },
        saveHarbor() {
            // si la currentDefense ne correspond pas à celle enregitrée dans le formdata on retourne une erreur

            this.formData.Docks.forEach((dock) => {
                dock.Defenses.forEach((defense) => {
                    if (defense.Id === `fake-${defense.Name}`) {
                        delete defense.Id
                    }
                })
                if (dock.Id === `fake-${dock.Name}`) delete dock.Id
            })

            if (this.isUpdate === false) {
                this.create(this.formData)
                    .then(() => {
                        this.$emit('save')
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                this.update(this.formData)
                    .then(() => {
                        this.$emit('save')
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
    },
    unmounted() {
        this.harbor = null
    },
}
</script>

<style scoped>
/* Styles Tailwind déjà intégrés dans les classes */
</style>
