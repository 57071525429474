<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <fetch-data-error v-if="fetchError" :dataType="'Maintenances'" />
        <div
            v-if="fetchError"
            class="flex items-center text-zinc-500 justify-center pb-6 mt-20"
        >
            <button
                type="button"
                @click="newMaintenance = true"
                class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
            >
                <fa-icon icon="plus" class="fa-sm mr-2"></fa-icon>

                {{ $t('add_maintenance') }}
            </button>
        </div>

        <span v-else>
            <div>
                <div class="flex items-center justify-center mt-14 mb-4">
                    <input
                        id="container-for-all-checkbox"
                        type="checkbox"
                        v-model="onlyLatePlus30Todo"
                        @change="onlyLatePlus30TodoChanged()"
                        class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                    />
                    <label
                        for="container-for-all-checkbox"
                        class="ml-2 font-medium theme-color"
                        >{{ $t('only_late_plus_todo_30') }}</label
                    >
                </div>
            </div>
            <filtrable-table
                id="maintenanceList"
                :items="maintenances"
                :smallMT="true"
                :columns="columns"
                :storeToSort="'vehicles'"
                :page="page"
                :count="maintenancesCount"
                :loading="loading"
                :multiActions="multiActions"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew && newMaintenance === false"
                :itemActions="itemActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="displayCreateMaintenance()"
                @clear="clearFilter($event)"
                @update="setMaintenanceToUpdate($event)"
                @setAsDone="setAsDone($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
            >
            </filtrable-table>
        </span>
        <modal
            v-if="newMaintenance === true"
            :headerText="
                isUpdate ? $t('update_maintenance') : $t('add_maintenance')
            "
            :buttonText="isUpdate ? 'update' : 'save'"
            :spin="creatingMaintenance"
            :creating="creatingMaintenance"
            id="addModal"
            :mxWidth="'max-w-8xl'"
            :mxHeight="'max-h-screen'"
            :buttonAlign="'justify-center'"
            @closeModal="resetMaintenanceForm()"
            @sendFromModal="
                isUpdate ? updateCurrentMaintenance() : saveMaintenance()
            "
        >
            <template #body>
                <div class="grid grid-cols-8 gap-2 mt-2 px-4">
                    <div class="col-span-2 mb-3 items-center">
                        <label
                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                            >{{ $t('title') + ' *' }}</label
                        >
                        <input
                            type="text"
                            v-model="maintenanceFormData.Title"
                            @blur="v$.maintenanceFormData.Title.$touch"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.maintenanceFormData.Title
                                .$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="col-span-2 mb-3 items-center">
                        <label
                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                            >{{ $t('start_date') + ' *' }}</label
                        >
                        <input
                            type="datetime-local"
                            :min="
                                new Date(
                                    new Date().getTime() -
                                        new Date().getTimezoneOffset() * 60000,
                                )
                                    .toISOString()
                                    .substr(0, 16)
                            "
                            v-model="maintenanceFormData.DateBegin"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-if="badTimeRange"
                        >
                            <div class="error-msg">
                                {{ $t('bad_time_range') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-span-2 mb-3 items-center">
                        <label
                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                            >{{ $t('end_date') + ' *' }}</label
                        >
                        <input
                            type="datetime-local"
                            :min="maintenanceFormData.DateBegin"
                            v-model="maintenanceFormData.DateEnd"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                    </div>
                    <div class="col-span-2 mb-3 flex items-center">
                        <label
                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                            >{{ $t('done_date') }}</label
                        >
                        <input
                            type="datetime-local"
                            :min="
                                new Date(
                                    new Date().getTime() -
                                        new Date().getTimezoneOffset() * 60000,
                                )
                                    .toISOString()
                                    .substr(0, 16)
                            "
                            v-model="maintenanceFormData.DoneDate"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-4 gap-2 mt-2 p-4 items-center">
                    <div class="col-span-2 items-center flex justify-center">
                        <Multiselect
                            :key="refreshMS"
                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                            v-model="maintenanceFormData.IdVehicle"
                            :options="vehicleOptions"
                            :loading="vehicleOptions.length === 0"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :placeholder="$t('vehicle')"
                            :allow-empty="false"
                            :can-clear="true"
                        ></Multiselect>
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.maintenanceFormData.IdVehicle
                                .$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="col-span-2 items-center flex justify-center">
                        <input
                            id="set-active-checkbox"
                            type="checkbox"
                            v-model="maintenanceFormData.Urgent"
                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                        />
                        <label
                            for="set-active-checkbox"
                            class="ml-2 font-medium"
                            >{{ $t('urgent') }}</label
                        >
                    </div>
                </div>

                <div class="mb-3 items-center px-4">
                    <label
                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                        >{{ $t('informations') }}</label
                    >
                    <textarea
                        v-model="maintenanceFormData.Informations"
                        class="border rounded px-3 py-2 w-full"
                    />
                </div>
                <span
                    class="flex mt-1 italic text-xs items-center justify-center"
                >
                    {{ $t('required_fields') }}
                </span>
            </template>
        </modal>
    </main>
</template>
<script>
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import FiltrableTable from '@/components/FiltrableTable.vue'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useUserStore } from '@/stores/userStore'
import { useVehicleStore } from '@/stores/vehicleStore'

export default {
    name: 'Maintenances',
    components: { FiltrableTable, FetchDataError, Modal, Multiselect },
    setup() {
        return { v$: useVuelidate() }
    },
    mixins: [filterList],

    created() {
        const filteredList = this.$cookies.get('filteredMaintenancesList')
        this.columns = [
            {
                name: 'vehicle',
                filtrable: true,
                sortable: false,
                vehicles: true,
                type: 'select',
                dbField: 'IdVehicle',
                options: this.vehicleOptions,
                canSearch: true,
            },
            {
                name: 'title',
                filtrable: true,
                dbField: 'Title',
                type: 'search',
                sortable: true,
                isLink: true,
            },
            {
                name: 'status',
                filtrable: true,
                type: 'select',
                dbField: 'Status',
                translate: true,
                sortable: false,
                canSearch: false,
                multi: true,
                selectionAllowed: this.onlyLatePlus30Todo === false,
                options: this.statusOptions,
            },

            {
                name: 'start_date',
                dbField: 'DateBegin',
                sortable: true,
            },
            {
                name: 'end_date',
                dbField: 'DateEnd',
                sortable: true,
            },
            {
                name: 'done_date',
                dbField: 'DoneDate',
                sortable: true,
            },

            {
                name: 'urgent',
                filtrable: true,
                dbField: 'Urgent',
                archive: true,
                type: 'select',
                sortable: false,
                withIconNoText: true,

                options: [
                    {
                        name: 'not_urgent',
                        value: 0,
                        dbField: 'Urgent',
                    },
                    {
                        name: 'urgent',
                        value: 1,
                        dbField: 'Urgent',
                    },
                ],
            },
            {
                name: 'informations',
                filtrable: false,
                sortable: false,
            },
            {
                name: 'creation_date',
                sortable: true,
                dbField: 'CreationDate',
            },
        ]

        window.scrollTo(0, 0)
        clearInterval(this.maintenanceInterval)
        const vehiclesPayload = {
            query: [],
            filter: [], // { dbField: 'Active', value: 1 }
            perPage: 100000000000,
            page: 1,
            sortField: 'Name',
            sortDirection: 'ASC',
        }
        this.vehicleOptions = []
        this.searchVehicles(vehiclesPayload).then((res) => {
            if (res && res.status === 200) {
                res.data.Records.forEach((vehicle) => {
                    const name = `${vehicle.Name} - ${this.$t(vehicle.Type)}`
                    const option = {
                        name,
                        value: +vehicle.Id,
                        dbField: 'IdVehicle',
                    }
                    if (!this.vehicleOptions.includes(option)) {
                        this.vehicleOptions.push(option)
                    }
                })
                this.refreshMS += 1
            }
        })
        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.onlyLatePlus30Todo = filteredList.onlyLatePlus30Todo
        } else if (this.onlyLatePlus30Todo === false) {
            this.filter.push({
                name: 'todo',
                value: 'todo',
                dbField: 'Status',
            })
            this.filter.push({
                name: 'late',
                value: 'late',
                dbField: 'Status',
            })
        }
        this.setResults()
    },
    data() {
        return {
            columns: [],
            onlyLatePlus30Todo: true,
            searchingCursor: false,
            maintenanceInterval: null,
            vehicleOptions: [],
            refresh: 0,
            refreshMS: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'DateBegin',
            perPage: 100,
            page: 1,
            filter: [],
            statusOptions: [
                { name: 'todo', value: 'todo', dbField: 'Status' },
                { name: 'done', value: 'done', dbField: 'Status' },
                { name: 'late', value: 'late', dbField: 'Status' },
            ],
            maintenanceFormData: {
                Title: '',
                DateBegin: new Date(
                    new Date().getTime() -
                        new Date().getTimezoneOffset() * 60000,
                )
                    .toISOString()
                    .substr(0, 16),
                DateEnd: new Date(
                    new Date().getTime() -
                        new Date().getTimezoneOffset() * 60000,
                )
                    .toISOString()
                    .substr(0, 16),
                CreationDate: '',
                ModificationDate: '',
                CreationUserId: '',
                ModificationUserId: '',
                Informations: '',
                IdVehicle: '',
                DoneDate: null,
                Urgent: false,
                Status: 'todo',
                Id: '',
            },
            newMaintenance: false,

            sortableFields: [
                { name: this.$t('title'), value: 'Title' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('start_date'), value: 'DateBegin' },
                { name: this.$t('end_date'), value: 'DateEnd' },
            ],
            contact: null,
            creating: false,
            isUpdate: false,

            creatingMaintenance: false,
        }
    },
    validations() {
        return {
            maintenanceFormData: {
                Title: { required },
                DateBegin: { required },
                DateEnd: { required },
                IdVehicle: { required },
            },
        }
    },
    computed: {
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.maintenanceActionAccess.create,
            )
        },
        ...mapState(useVehicleStore, {
            maintenances: 'maintenances',
            maintenancesCount: 'maintenancesCount',
            count: 'count',
            totalPages: 'totalPages',
            loading: 'loading',
            fetchError: 'fetchError',
        }),
        maintenanceActionAccess() {
            return actionsAccess.vehicles.maintenances
        },
        ...mapWritableState(useVehicleStore, {
            currentVehicle: 'current',
        }),
        ...mapState(useUserStore, {
            user: 'current',
        }),
        ...mapStores(useUserStore),
        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.maintenanceActionAccess.update)
            ) {
                actions.push(
                    {
                        name: 'edit',
                        action: 'update',
                        icon: 'pen',
                    },
                    {
                        name: 'set_as_done',
                        action: 'setAsDone',
                        icon: 'check',
                    },
                )
            }
            if (
                checkAccess(this.userStore, this.maintenanceActionAccess.delete)
            ) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.maintenanceActionAccess.delete)
            ) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }
            return actions
        },
    },
    watch: {
        maintenances() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    methods: {
        ...mapActions(useVehicleStore, [
            'deleteMaintenance',
            'addMaintenance',
            'updateMaintenance',
            'updateMaintenanceStatus',
            'searchMaintenances',
        ]),
        ...mapActions(useVehicleStore, {
            searchVehicles: 'search',
        }),
        onlyLatePlus30TodoChanged() {
            this.searchingCursor = true
            clearInterval(this.maintenanceInterval)
            this.$cookies.remove('filteredMaintenancesList')
            if (this.onlyLatePlus30Todo === true) {
                clearInterval(this.maintenanceInterval)

                this.clearFilter({ dbField: 'Status', multi: true })
            } else if (
                this.onlyLatePlus30Todo === false &&
                !this.filter.find((fl) => fl.dbField === 'Status')
            ) {
                clearInterval(this.maintenanceInterval)

                this.setResults()
            }
        },
        setAsDone(id) {
            const payload = {
                Id: id,
                Status: 'done',
                ModificationUserId: this.user.id,
            }
            this.updateMaintenanceStatus(payload)
                .then(() => {
                    this.$toast.success(this.$t('maintenance_update_ok'))

                    this.setResults()
                })
                .catch(() => {
                    this.$toast.error(this.$t('maintenance_update_ko'))
                })
        },

        checkTimes() {
            if (
                this.maintenanceFormData.DateEnd <
                    this.maintenanceFormData.DateBegin ||
                this.maintenanceFormData.DateEnd ===
                    this.maintenanceFormData.DateBegin
            ) {
                this.badTimeRange = true
                return true
            }
            return false
        },
        displayCreateMaintenance() {
            clearInterval(this.maintenanceInterval)
            this.newMaintenance = true
            this.isUpdate = false
        },
        async deleteOne(id) {
            const response = await this.deleteMaintenance(id)
            if (response.status === 200) {
                this.setResults()
                this.$toast.success(this.$t('maintenance_delete_ok'))
            } else {
                this.$toast.error(this.$t('maintenance_delete_ko'))
            }
        },
        deleteAll(elToDelete) {
            elToDelete.forEach((el) => {
                this.deleteMaintenance(el)
            })
            this.setResults()
        },
        async saveMaintenance() {
            this.creatingMaintenance = true
            this.badTimeRange = false
            this.maintenanceFormData.CreationUserId = this.user.id
            this.maintenanceFormData.ModificationUserId = this.user.id

            const formIsValid = await this.v$.maintenanceFormData.$validate()
            if (formIsValid === true && !this.checkTimes()) {
                try {
                    const response = await this.addMaintenance(
                        this.maintenanceFormData,
                    )
                    if (response) {
                        this.resetMaintenanceForm()
                        this.$toast.success(this.$t('maintenance_create_ok'))
                    }
                } catch (err) {
                    console.log(err)
                    this.creatingMaintenance = false
                    this.$toast.error(`${this.$t('maintenance_create_ko')}`)
                }
            } else {
                this.creatingMaintenance = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateCurrentMaintenance() {
            this.creatingMaintenance = true
            this.badTimeRange = false

            this.maintenanceFormData.ModificationUserId = this.user.id

            const formIsValid = await this.v$.maintenanceFormData.$validate()
            if (formIsValid === true && !this.checkTimes()) {
                try {
                    const response = await this.updateMaintenance({
                        id: this.maintenanceFormData.Id,
                        form: this.maintenanceFormData,
                    })
                    if (response) {
                        this.resetMaintenanceForm()

                        this.$toast.success(this.$t('maintenance_update_ok'))
                    }
                } catch (err) {
                    console.log(err)
                    this.creatingMaintenance = false
                    this.$toast.error(`${this.$t('maintenance_update_ko')}`)
                }
            } else {
                this.creatingMaintenance = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async setMaintenanceToUpdate(maintenanceId) {
            const maintenanceToUpdate = this.maintenances.find(
                (maintenance) => maintenance.id === maintenanceId,
            )
            this.isUpdate = true
            this.newMaintenance = true

            const start = new Date(
                new Date(maintenanceToUpdate.start_date).getTime() -
                    new Date(
                        maintenanceToUpdate.start_date,
                    ).getTimezoneOffset() *
                        60000,
            ).toISOString()

            const end = new Date(
                new Date(maintenanceToUpdate.end_date).getTime() -
                    new Date(maintenanceToUpdate.end_date).getTimezoneOffset() *
                        60000,
            ).toISOString()
            this.maintenanceFormData = {
                Title: maintenanceToUpdate.title,
                DateBegin: start.substr(0, 16),
                DateEnd: end.substr(0, 16),
                CreationDate: maintenanceToUpdate.creation_date,
                CreationUserId: maintenanceToUpdate.creation_user_id,
                Informations: maintenanceToUpdate.informations,
                Urgent: maintenanceToUpdate.urgent,
                DoneDate: maintenanceToUpdate.done_date,
                Status: maintenanceToUpdate.status,
                IdVehicle: +maintenanceToUpdate.vehicleId,
                ModificationDate: '',
                ModificationUserId: this.user.id,
                Id: maintenanceToUpdate.id,
            }
        },
        upFilter(event) {
            clearInterval(this.maintenanceInterval)

            this.updateFilter(event)
        },
        resetMaintenanceForm() {
            this.newMaintenance = false
            this.isUpdate = false
            this.creatingMaintenance = false
            this.badTimeRange = false

            const start = new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000,
            )
                .toISOString()
                .substr(0, 16)
            const end = new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000,
            )
                .toISOString()
                .substr(0, 16)
            this.maintenanceFormData = {
                Title: '',
                DateBegin: start,
                DateEnd: end,
                CreationDate: '',
                CreationUserId: '',
                Informations: '',
                IdVehicle: this.$route.params.id,
            }
            this.setResults()
        },

        setPage(isNext) {
            this.searchingCursor = true
            clearInterval(this.maintenanceInterval)
            this.$cookies.remove('filteredMaintenancesList')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            clearInterval(this.maintenanceInterval)
            this.$cookies.remove('filteredMaintenancesList')
            this.search = search.query

            this.sortDirection = search.sortDirection
            this.filter = search.filter

            this.sortField =
                search.sortField === 'client' ? '' : search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.onlyLatePlus30Todo = search.onlyLatePlus30Todo
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })
            this.setRealSearch(this.search)
            this.searchMaintenances(search).then(() => {
                this.searchingCursor = false
                this.$cookies.set('filteredMaintenancesList', search)
                this.maintenanceInterval = setInterval(() => {
                    this.searchMaintenances(search)
                }, 20000)
            })
            this.refresh += 1
        },
        changePerPage(perPage) {
            clearInterval(this.maintenanceInterval)
            this.$cookies.remove('filteredMaintenancesList')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            clearInterval(this.maintenanceInterval)
            if (
                this.filter.find((fl) => fl.dbField === 'Status') &&
                this.onlyLatePlus30Todo === true
            ) {
                this.onlyLatePlus30Todo = false
            }
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
                onlyLatePlus30Todo: this.onlyLatePlus30Todo,
            }
            this.displaySearchResults(search)
        },
    },
    unmounted() {
        clearInterval(this.maintenanceInterval)
    },
}
</script>
