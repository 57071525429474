<template>
    <div class="mx-auto pt-4 px-4  bg-white">
        <div
            class="flex items-center"
            :class="currentStep === 1 ? 'justify-end' : 'justify-between'"
        >
            <button
                v-if="currentStep > 1"
                @click="prevStep"
                class="px-4 py-2 border rounded-md themed-button text-white flex items-center"
            >
                <fa-icon icon="chevron-left" class="mr-2"></fa-icon>
                {{ $t('previous') }}
            </button>
            <span class="flex items-center">
                <button
                    @click="$emit('cancel')"
                    class="px-4 py-2 mr-2 text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-500 rounded-md"
                >
                    {{ $t('cancel') }}
                </button>
                <button
                    v-if="currentStep < totalStepsCount.length"
                    :disabled="disableNext"
                    @click="nextStep"
                    class="ml-auto px-4 py-2 rounded-md text-white themed-button flex items-center"
                >
                    {{ $t('next') }}
                    <fa-icon icon="chevron-right" class="ml-2"></fa-icon>
                </button>
                <button
                    v-if="currentStep === totalStepsCount.length"
                    @click="submit()"
                    class="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                    {{ $t('save') }}
                </button>
            </span>
        </div>

        <span v-for="step in totalStepsCount" :key="step">
            <slot :name="`step${step}`"> </slot>
        </span>
    </div>
</template>

<script>
import gmapStyles from '@/mixins/gmapStyles'
import googleScriptLoader from '@/mixins/googleScript'

export default {
    name: 'Stepper',

    mixins: [gmapStyles, googleScriptLoader],

    data() {
        return {
            // currentStep: this.givenStep,
        }
    },
    props: {
        givenStep: {
            type: Number,
            default: 1,
        },
        disableNext: {
            type: Boolean,
            default: false,
        },
        totalStepsCount: {
            type: Array,
            default: () => [1, 2, 3],
        },
    },
    created() {
        this.$emit('created')
    },
    mounted() {
        // this.currentStep = this.givenStep
    },
    computed: {
        currentStep() {
            return this.givenStep
        },
    },
    methods: {
        nextStep() {
            if (this.currentStep < this.totalStepsCount.length) {
                this.$emit('next')
                // this.currentStep += 1
            }
        },
        prevStep() {
            if (this.currentStep > 1) {
                this.$emit('prev')
                // this.currentStep -= 1
            }
        },
        submit() {
            this.$emit('submit')
        },
    },
}
</script>
