<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md darky h-full">
        <back-to @back="back()" />

        <div
            id="create-address"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div class="p-5">
                <form
                    class="mb-2"
                    @keydown.enter.prevent
                    @submit.prevent="
                        $route.params.id ? updateDriver() : createDriver()
                    "
                >
                    <div>
                        <div class="grid grid-cols-6 gap-4 mt-2">
                            <div class="rounded-md col-span-3 border-2 p-4">
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{ $t('driver_information') }}
                                        <hr />
                                    </span>
                                </div>
                                <div class="grid grid-cols-4 gap-2 mt-2">
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('firstname') + ' *' }}</label
                                        >
                                        <input
                                            type="text"
                                            v-model="formData.FirstName"
                                            autocomplete="name"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData
                                                .FirstName.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('lastname') + ' *' }}</label
                                        >
                                        <input
                                            type="text"
                                            v-model="formData.LastName"
                                            autocomplete="name"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData.LastName
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid grid-cols-4 gap-2 mt-2">
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('phone_perso') }}</label
                                        >
                                        <input
                                            type="tel"
                                            v-model="formData.PhonePerso"
                                            @blur="
                                                v$.formData.PhonePerso.$touch
                                            "
                                            autocomplete="tel"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData
                                                .PhonePerso.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('phone_pro') }}</label
                                        >
                                        <input
                                            type="tel"
                                            v-model="formData.PhonePro"
                                            @blur="v$.formData.PhonePro.$touch"
                                            autocomplete="tel"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData.PhonePro
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('address') + ' 1 *' }}
                                    </label>
                                    <input
                                        v-model="formData.Address1"
                                        id="autocomplete"
                                        @blur="v$.formData.Address1.$touch"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.formData.Address1
                                        .$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>

                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('address') }} 2</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.Address2"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('postal_code') + ' *' }}</label
                                    >
                                    <input
                                        id="postal_code"
                                        type="text"
                                        autocomplete="postal-code"
                                        v-model="formData.PostalCode"
                                        @blur="v$.formData.PostalCode.$touch"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.formData.PostalCode
                                        .$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                                <div class="mb-3 items-center flex flex-row">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('city') + ' *' }}</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.City"
                                        id="locality"
                                        @blur="v$.formData.City.$touch"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.formData.City.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                                <div class="grid-cols-2 grid gap-2">
                                    <div class="mb-3 col-span-1 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                            >{{ $t('contract_hour') }}</label
                                        >
                                        <input
                                            type="number"
                                            min="0"
                                            step="0.5"
                                            v-model="formData.ContractHour"
                                            class="border w-2/3 rounded px-3 py-2 mt-1"
                                        />
                                    </div>
                                    <div
                                        class="flex col-span-1 items-center mt-5 justify-center"
                                    >
                                        <input
                                            id="set-active-checkbox"
                                            type="checkbox"
                                            v-model="formData.Active"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="set-active-checkbox"
                                            class="ml-2 font-medium"
                                            >{{ $t('activate_driver') }}</label
                                        >
                                    </div>
                                </div>
                                <div class="mt-6 mb-6 flex">
                                    <span class="text-lg">
                                        {{ $t('emergency_contact') }}
                                        <hr />
                                    </span>
                                </div>
                                <div class="grid grid-cols-4 gap-2 mt-2">
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('firstname') }}</label
                                        >
                                        <input
                                            type="text"
                                            v-model="
                                                formData.ContactToWarnFirstName
                                            "
                                            autocomplete="name"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('lastname') }}</label
                                        >
                                        <input
                                            type="text"
                                            v-model="
                                                formData.ContactToWarnLastName
                                            "
                                            autocomplete="name"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                </div>
                                <div class="grid grid-cols-4 gap-2 mt-2">
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('phone') }}</label
                                        >
                                        <input
                                            type="tel"
                                            v-model="
                                                formData.ContactToWarnPhone1
                                            "
                                            @blur="
                                                v$.formData.ContactToWarnPhone1.$touch()
                                            "
                                            autocomplete="tel"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData
                                                .ContactToWarnPhone1.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('phone_secondary') }}</label
                                        >
                                        <input
                                            type="tel"
                                            v-model="
                                                formData.ContactToWarnPhone2
                                            "
                                            @blur="
                                                v$.formData.ContactToWarnPhone1.$touch()
                                            "
                                            autocomplete="tel"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData
                                                .ContactToWarnPhone2.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md col-span-3 border-2 p-4">
                                <div class="px-3">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('work_information') }}
                                            <hr />
                                        </span>
                                    </div>
                                    <span v-if="createUser === false">
                                        <div
                                            class="grid grid-cols-7 gap-2 mt-2"
                                        >
                                            <div
                                                class="mb-3 items-center col-span-3"
                                            >
                                                <!-- :class="
                                                    $route.params.id
                                                        ? 'col-span-7'
                                                        : 'col-span-3'
                                                " -->
                                                <label
                                                    class="font-semibold py-2 text-gray-600 basis-1/4 pb-1 mr-2"
                                                    >{{
                                                        $t('mobile_account')
                                                    }}
                                                    *</label
                                                >
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="formData.IdUser"
                                                    :options="userOptions"
                                                    :key="refreshMs"
                                                    label="name"
                                                    track-by="name"
                                                    :searchable="true"
                                                    :placeholder="
                                                        $t('select_account')
                                                    "
                                                    :allow-empty="false"
                                                    :can-clear="false"
                                                ></Multiselect>
                                            </div>
                                            <div
                                                class="col-span-1 py-8 items-center"
                                            >
                                                {{ $t('or') }}
                                            </div>
                                            <div
                                                class="col-span-3 py-2 items-center"
                                            >
                                                <button
                                                    type="button"
                                                    @click="
                                                        createUser = !createUser
                                                    "
                                                    class="themed-button text-base ml-2 focus:outline-none px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                >
                                                    {{
                                                        $t(
                                                            'create_user_account',
                                                        )
                                                    }}
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.formData.IdUser
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </span>
                                    <span v-else>
                                        <div
                                            class="grid grid-cols-3 gap-2 mt-2"
                                        >
                                            <div
                                                class="col-span-2 items-center"
                                            >
                                                <div
                                                    id="email"
                                                    class="items-center"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 mr-2 block 'text-gray-600'"
                                                        >{{
                                                            ` ${$t(
                                                                'email',
                                                            )} / ${$t(
                                                                'login_only',
                                                            )} *`
                                                        }}
                                                    </label>
                                                    <div class="text-xs italic">
                                                        {{
                                                            $t(
                                                                'create_user_rp_info',
                                                            )
                                                        }}
                                                    </div>
                                                    <input
                                                        type="email"
                                                        autocomplete="email"
                                                        @focus="
                                                            userAlreadyExists = false
                                                        "
                                                        @blur="
                                                            v$.formData.Email.$touch()
                                                        "
                                                        v-model="formData.Email"
                                                        class="border rounded px-3 py-2 w-full"
                                                        :class="
                                                            userAlreadyExists
                                                                ? 'border-red-500 text-red-500'
                                                                : ''
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.formData
                                                        .Email.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="col-span-1 py-10 items-center"
                                            >
                                                <button
                                                    type="button"
                                                    @click="
                                                        createUser = !createUser
                                                    "
                                                    class="themed-button text-base ml-2 focus:outline-none px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                >
                                                    {{ $t('cancel') }}
                                                </button>
                                            </div>
                                        </div>
                                    </span>
                                    <div class="grid grid-cols-6 gap-2 mt-2">
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('area') }}</label
                                            >
                                            <Multiselect
                                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                v-model="formData.IdSector"
                                                :options="areaOptions"
                                                label="name"
                                                track-by="name"
                                                :searchable="true"
                                                :placeholder="$t('select_area')"
                                                :allow-empty="false"
                                                :can-clear="false"
                                            ></Multiselect>
                                        </div>
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('supplier') }}</label
                                            >
                                            <Multiselect
                                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                v-model="formData.IdSupplier"
                                                :options="supplierOptions"
                                                label="name"
                                                track-by="name"
                                                :searchable="true"
                                                :placeholder="
                                                    $t('select_supplier')
                                                "
                                                :allow-empty="false"
                                                :can-clear="false"
                                            ></Multiselect>
                                        </div>
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('vehicle') }}</label
                                            >
                                            <Multiselect
                                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                v-model="formData.IdVehicle"
                                                :options="vehicleOptions"
                                                label="name"
                                                track-by="name"
                                                :searchable="true"
                                                :placeholder="
                                                    $t('select_vehicle')
                                                "
                                                :allow-empty="false"
                                                :can-clear="false"
                                            ></Multiselect>
                                        </div>
                                    </div>
                                    <div
                                        class="mt-3 flex items-center justify-center"
                                    >
                                        <input
                                            id="ref-add-update-checkbox"
                                            type="checkbox"
                                            v-model="formData.SubContractor"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="ref-add-update-checkbox"
                                            class="ml-2 text-sm font-medium"
                                            ><fa-icon
                                                icon="peaople-arrows"
                                                class="mr-2 warning-msg"
                                            ></fa-icon
                                            >{{ $t('subcontractor') }}</label
                                        >
                                        <input
                                            id="onu-checkbox"
                                            type="checkbox"
                                            v-model="formData.ONU"
                                            class="themed-checkbox w-4 h-4 ml-40 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="onu-checkbox"
                                            class="ml-2 text-sm font-medium"
                                            ><fa-icon
                                                icon="biohazard"
                                                class="mr-2 error-msg"
                                            ></fa-icon
                                            >{{ $t('onu_authorized') }}</label
                                        >
                                    </div>
                                    <div class="mt-6 mb-6 flex">
                                        <span class="text-lg">
                                            {{ $t('start_end_pos') }}
                                            <hr />
                                        </span>
                                    </div>
                                    <div class="grid grid-cols-6 gap-2 mt-2">
                                        <div
                                            class="col-span-3 mb-3 flex items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{
                                                    $t('start') +
                                                    ' ' +
                                                    $t('latitude')
                                                }}</label
                                            >
                                            <input
                                                type="text"
                                                v-model="
                                                    formData.GPSPointLatitudeStart
                                                "
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <div
                                            class="col-span-3 flex mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{
                                                    $t('start') +
                                                    ' ' +
                                                    $t('longitude')
                                                }}</label
                                            >
                                            <input
                                                type="text"
                                                v-model="
                                                    formData.GPSPointLongitudeStart
                                                "
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-6 gap-2 mt-2">
                                        <div
                                            class="col-span-3 flex mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{
                                                    $t('end') +
                                                    ' ' +
                                                    $t('latitude')
                                                }}</label
                                            >
                                            <input
                                                type="text"
                                                v-model="
                                                    formData.GPSPointLatitudeEnd
                                                "
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <div
                                            class="col-span-3 flex mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{
                                                    $t('end') +
                                                    ' ' +
                                                    $t('longitude')
                                                }}</label
                                            >
                                            <input
                                                type="text"
                                                v-model="
                                                    formData.GPSPointLongitudeEnd
                                                "
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                    </div>
                                    <div class="mb-6 mt-6 flex">
                                        <span class="text-lg">
                                            {{ $t('last_known_pos') }}
                                            <hr />
                                        </span>
                                    </div>
                                    <div
                                        id="map"
                                        style="width: 100%; height: 500px"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <span
                            class="flex mt-1 italic text-xs items-center justify-center"
                        >
                            {{ $t('required_fields') }}
                        </span>
                        <div class="p-2 mt-4">
                            <div class="flex justify-center">
                                <button
                                    type="button"
                                    @click="back()"
                                    class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                >
                                    {{ $t('cancel') }}
                                </button>
                                <button
                                    type="submit"
                                    :disabled="creating"
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon
                                            icon="spinner"
                                            spin-pulse
                                        ></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{ $t('save') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </main>
</template>
<script>
import BackTo from '@/components/elements/BackTo.vue'
import gmapStyles from '@/mixins/gmapStyles'
import googleScriptLoader from '@/mixins/googleScript'
import keys from '@/resources/keys'
import { useAllUsersStore } from '@/stores/allUsersStore'
import { useAreaStore } from '@/stores/areaStore'
import { useDriverStore } from '@/stores/driverStore'
import { useSupplierStore } from '@/stores/supplierStore'
import { useUserStore } from '@/stores/userStore'
import { useVehicleStore } from '@/stores/vehicleStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { helpers, required, requiredIf } from '@vuelidate/validators'
import { mapActions, mapState, mapWritableState } from 'pinia'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/,
)
const isPhoneNumber = helpers.regex(
    /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
)
export default {
    name: 'CreateOrUpdate',
    components: { Multiselect, BackTo },
    mixins: [googleScriptLoader, gmapStyles],
    setup() {
        const key =
            keys[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.'),
                      )
            ]

        return { key, v$: useVuelidate() }
    },
    created() {
        this.map = null

        this.loadGoogleScript('autocomplete')
    },
    mounted() {
        window.scrollTo(0, 0)

        const usersInterval = setInterval(() => {
            if (this.users) {
                clearInterval(usersInterval)
                this.users.forEach((user) => {
                    const option = {
                        name: `${user.firstname} ${user.lastname} - ${user.email}`,
                        value: user.id,
                    }
                    this.userOptions.push(option)
                })
                this.refreshMs += 1
            }
        }, 200)
        this.supplierOptions = []
        const suppliersPayload = {
            query: [],
            filter: [],
            perPage: 10000,
            page: 1,
            sortField: 'Name',
            sortDirection: 'ASC',
        }
        this.searchSuppliers(suppliersPayload).then((res) => {
            if (res.status === 200) {
                res.data.Records.forEach((supplier) => {
                    const option = {
                        name: supplier.Name,
                        value: supplier.Id,
                    }
                    this.supplierOptions.push(option)
                })
                this.refreshMs += 1
            }
        })
        const vehiclesPayload = {
            query: [],
            filter: [],
            perPage: 10000,
            page: 1,
            sortField: 'Name',
            sortDirection: 'ASC',
        }
        this.searchVehicles(vehiclesPayload).then((res) => {
            if (res.status === 200) {
                res.data.Records.forEach((supplier) => {
                    const option = {
                        name: supplier.Name,
                        value: supplier.Id,
                    }
                    this.vehicleOptions.push(option)
                })
                this.refreshMs += 1
            }
        })
        const areasPayload = {
            query: [],
            filter: [],
            perPage: 10000,
            page: 1,
            sortField: 'Name',
            sortDirection: 'ASC',
        }
        this.searchAreas(areasPayload).then((res) => {
            if (res.status === 200) {
                res.data.Records.forEach((supplier) => {
                    const option = {
                        name: supplier.Name,
                        value: supplier.Id,
                    }
                    this.areaOptions.push(option)
                })
                this.refreshMs += 1
            }
        })
        this.currentDriverInterval = setInterval(() => {
            if (this.currentDriver) {
                clearInterval(this.currentDriverInterval)
                this.formData = {
                    Id: this.currentDriver.id,
                    Address1: this.currentDriver.address,
                    Address2: this.currentDriver.address2,
                    PostalCode: this.currentDriver.postal_code,
                    City: this.currentDriver.city,
                    FirstName: this.currentDriver.firstname,
                    LastName: this.currentDriver.lastname,
                    PhonePro: this.currentDriver.phone_pro,
                    PhonePerso: this.currentDriver.phone_perso,
                    CreationUserId: this.currentDriver.creation_user_id,
                    ModificationUserId: this.currentDriver.modification_user_id,
                    Active: +this.currentDriver.active === 1,
                    ContactToWarnFirstName: this.currentDriver.contact_first,
                    ContactToWarnLastName: this.currentDriver.contact_last,
                    ContactToWarnPhone1: this.currentDriver.contact_phone1,
                    ContactToWarnPhone2: this.currentDriver.contact_phone2,
                    SubContractor: +this.currentDriver.sub_contractor === 1,
                    IdVehicle: this.currentDriver.vehicle,
                    IdUser: this.currentDriver.user,
                    IdSupplier: this.currentDriver.supplier,
                    IdSector: this.currentDriver.area,
                    ContractHour: this.currentDriver.contract_hour,
                    GPSPointLatitudeStart: this.currentDriver.lat_start,
                    GPSPointLatitudeEnd: this.currentDriver.lat_end,
                    GPSPointLongitudeStart: this.currentDriver.long_start,
                    GPSPointLongitudeEnd: this.currentDriver.long_end,
                    CreationDate: this.currentDriver.creation_date,
                    ONU: this.currentDriver.onu,
                }
            }
        }, 1000)
        const googleInterval = setInterval(() => {
            if (google) {
                if (!this.$route.params.id) {
                    clearInterval(googleInterval)
                    this.map = new google.maps.Map(
                        document.getElementById('map'),
                        {
                            center: {
                                lat: this.user.env.latitude,
                                lng: this.user.env.longitude,
                            },
                            zoom: 15,
                        },
                    )
                    this.setGoogleMapStyle(false)
                } else if (this.currentDriver) {
                    clearInterval(googleInterval)
                    const pos = this.currentDriver.last_position
                        ? this.currentDriver.last_position
                        : {
                              lat: this.user.env.latitude,
                              lng: this.user.env.longitude,
                          }

                    this.map = new google.maps.Map(
                        document.getElementById('map'),
                        {
                            center: pos,
                            zoom: 15,
                        },
                    )
                    const mark = new google.maps.Marker({
                        position: pos,
                        title: `${this.currentDriver.firstname} ${this.currentDriver.lastname}`,
                        optimized: false,
                        animation: google.maps.Animation.DROP,
                        clickable: false,
                        draggable: false,
                    })
                    mark.setMap(this.map)
                    this.setGoogleMapStyle(false)
                }
            }
        }, 20)
    },

    data() {
        return {
            supplierOptions: [],
            areaOptions: [],
            vehicleOptions: [],
            createUser: false,
            userAlreadyExists: false,
            currentDriverInterval: null,
            contact: null,
            refreshMs: 0,
            userOptions: [],
            creating: false,
            formData: {
                ONU: false,
                ContractHour: 35,
                Type: 'driver',
                Address1: '',
                Address2: '',
                PostalCode: '',
                Email: '',
                City: '',
                FirstName: '',
                LastName: '',
                PhonePro: '',
                PhonePerso: '',
                CreationUserId: 1,
                ModificationUserId: 1,
                Active: true,
                ContactToWarnFirstName: '',
                ContactToWarnLasttName: '',
                ContactToWarnPhone1: '',
                ContactToWarnPhone2: '',
                SubContractor: false,
                IdVehicle: '',
                IdUser: '',
                IdSupplier: '',
                IdSector: '',
                GPSPointLatitudeStart: null,
                GPSPointLatitudeEnd: null,
                GPSPointLongitudeStart: null,
                GPSPointLongitudeEnd: null,
                CreationDate: '',
                ModificationDate: '',
            },
        }
    },
    validations() {
        return {
            formData: {
                Email: { required: requiredIf(this.createUser === true) },
                LastName: { required },
                Address1: { required },
                FirstName: { required },
                PostalCode: {
                    required,
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode,
                    ),
                },
                City: { required },
                PhonePro: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
                PhonePerso: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
                ContactToWarnPhone1: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
                IdUser: { required: requiredIf(this.createUser === false) },
                ContactToWarnPhone2: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
            },
        }
    },
    computed: {
        ...mapWritableState(useAllUsersStore, {
            users: 'all',
        }),
        ...mapState(useUserStore, { user: 'current' }),
        ...mapWritableState(useDriverStore, {
            currentDriver: 'current',
        }),
    },

    methods: {
        ...mapActions(useUserStore, ['forgotPassword']),
        ...mapActions(useDriverStore, ['update', 'create']),
        ...mapActions(useAllUsersStore, {
            searchUsers: 'search',
            createNewUser: 'create',
        }),
        ...mapActions(useSupplierStore, { searchSuppliers: 'search' }),
        ...mapActions(useAreaStore, ['searchAreas']),
        ...mapActions(useVehicleStore, { searchVehicles: 'search' }),

        back() {
            const backPath = this.$router.options.history.state.back
            this.$router.push(backPath)
            this.currentDriver = null
            this.$emit('back', 'drivers')
        },
        fillInAddress(autocomplete, unique) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            this.formData.Address2 = ''
            this.formData.Address1 = ''

            this.formData.PostalCode = ''
            this.formData.City = ''

            const userInput = this.formData.Address1
            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name
                if (addressType === 'postal_code') {
                    this.formData.PostalCode = val
                } else if (addressType === 'locality') {
                    this.formData.City = val
                } else if (addressType === 'street_number') {
                    this.formData.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.formData.Address1 =
                        this.formData.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.formData.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    this.formData.Address2 = `${val}`
                }
                if (
                    (place.types[0] !== 'street_number' ||
                        place.types[0] !== 'route') &&
                    addressType === 'locality' &&
                    this.formData.Address1 === userInput
                ) {
                    this.formData.Address1 = ` ${val}`
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'premise' &&
                    this.formData.Address2 === ''
                ) {
                    this.formData.Address2 = place.name
                }
                // TODO à voir si on ajoute pour préremplir le nom du client s'il est vide
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    this.formData.Name === ''
                ) {
                    this.formData.Name = place.name
                }
            })
        },
        async createDriver() {
            this.v$.$reset()
            this.userAlreadyExists = false

            this.creating = true
            this.formData.CreationUserId = this.user.id
            this.formData.ModificationUserId = this.user.id
            this.formData.Login = this.formData.Email
            this.formData.Password = ''
            const isFormCorrect = await this.v$.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (isFormCorrect === true) {
                try {
                    if (this.createUser === true) {
                        const userForm = {
                            Login: this.formData.Email,
                            Password: '',
                            Email: this.formData.Email,
                            FirstName: this.formData.FirstName,
                            LastName: this.formData.LastName,
                            Type: 'driver',
                            Phone: this.formData.PhonePro,
                            MobilePhone: this.formData.PhonePerso,
                            CreationUserId: this.formData.CreationUserId,
                            ModificationUserId:
                                this.formData.ModificationUserId,
                        }
                        const response = await this.createNewUser(userForm)

                        if (response.status === 201) {
                            this.forgotPassword({
                                Email: response.data.Email,
                            })
                            this.formData.IdUser = response.data.Id
                            this.create(this.formData)
                                .then(() => {
                                    const backPath =
                                        this.$router.options.history.state.back
                                    this.$router.push(backPath)
                                    this.$toast.success(
                                        this.$t('driver_create_ok'),
                                    )
                                    this.$emit('created', 'drivers')
                                })
                                .catch(() => {
                                    this.creating = false
                                    this.$toast.error(
                                        `${this.$t('driver_create_ko')}`,
                                    )
                                })
                        } else {
                            this.creating = false
                            this.$toast.error(`${this.$t('driver_create_ko')}`)
                        }
                    } else {
                        this.create(this.formData)
                            .then(() => {
                                const backPath =
                                    this.$router.options.history.state.back
                                this.$router.push(backPath)
                                this.$toast.success(this.$t('driver_create_ok'))
                                this.$emit('created', 'drivers')
                            })
                            .catch(() => {
                                this.creating = false
                                this.$toast.error(
                                    `${this.$t('driver_create_ko')}`,
                                )
                            })
                    }
                } catch (err) {
                    this.creating = false

                    if (err.response.status === 422) {
                        this.userAlreadyExists = true
                        this.$toast.error(`${this.$t('user_already_exists')}`)
                    } else {
                        this.$toast.error(`${this.$t('driver_create_ko')}`)
                    }
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateDriver() {
            this.v$.$reset()
            this.userAlreadyExists = false
            this.creating = true
            this.formData.ModificationUserId = +this.user.id

            const isFormCorrect = await this.v$.$validate()

            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (isFormCorrect === true) {
                const params = {
                    id: this.$route.params.id,
                    form: this.formData,
                }
                try {
                    if (this.createUser === true) {
                        const userForm = {
                            Login: this.formData.Email,
                            Password: '',
                            Email: this.formData.Email,
                            FirstName: this.formData.FirstName,
                            LastName: this.formData.LastName,
                            Type: 'driver',
                            Phone: this.formData.PhonePro,
                            MobilePhone: this.formData.PhonePerso,
                            CreationUserId: this.formData.CreationUserId,
                            ModificationUserId:
                                this.formData.ModificationUserId,
                        }
                        const response = await this.createNewUser(userForm)

                        if (response.status === 201) {
                            this.forgotPassword({
                                Email: response.data.Email,
                            })
                            this.formData.IdUser = response.data.Id
                            this.update({
                                id: this.$route.params.id,
                                form: this.formData,
                            })
                                .then(() => {
                                    const backPath =
                                        this.$router.options.history.state.back
                                    this.$router.push(backPath)
                                    this.$toast.success(
                                        this.$t('driver_update_ok'),
                                    )
                                    this.$emit('updated', 'drivers')
                                })
                                .catch(() => {
                                    this.creating = false
                                    this.$toast.error(
                                        `${this.$t('driver_update_ko')}`,
                                    )
                                })
                        } else {
                            this.creating = false
                            this.$toast.error(
                                `${this.$t('user_already_exists')}`,
                            )
                        }
                    } else {
                        const response = this.update(params)
                        if (response.status) {
                            this.$toast.error(this.$t('driver_update_ko'))
                            // this.$toast.error(response.data.message)
                            this.creating = false
                        } else {
                            const backPath =
                                this.$router.options.history.state.back
                            this.$router.push(backPath)
                            this.$toast.success(this.$t('driver_update_ok'))
                            this.$emit('updated', 'drivers')
                        }
                    }
                } catch (err) {
                    this.creating = false

                    if (err.response.status === 422) {
                        this.userAlreadyExists = true
                        this.$toast.error(`${this.$t('user_already_exists')}`)
                    } else {
                        this.creating = false
                        this.$toast.error(`${this.$t('driver_update_ko')}`)
                    }
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
    },
    unmounted() {
        this.currentDriver = null
        this.users = null
    },
}
</script>
