import { defineStore } from 'pinia'

import colors from '@/resources/colors'

const useSimulationStore = defineStore('simulation', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
        tours: null,
        unaffected: null,
        countSimUnaffected: null,
        unaffectedHasIncompleteSteps: false,
    }),
    actions: {
        search(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/toursimulation/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/toursimulation/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            // console.log(res)
                            const simulations = []
                            res.data.Records.forEach((simulation) => {
                                // const totalMs = simulation.Duration * 1000
                                // const formattedDuration = new Date(totalMs)
                                //     .toISOString()
                                //     .slice(11, 19)
                                // formatDuration(duration) {
                                const seconds = Number(simulation.Duration)
                                const d = Math.floor(seconds / (3600 * 24))
                                const h = Math.floor(
                                    (seconds % (3600 * 24)) / 3600
                                )
                                const m = Math.floor((seconds % 3600) / 60)
                                // const s = Math.floor(seconds % 60)

                                const dDisplay =
                                    d > 0
                                        ? d +
                                          (d === 1
                                              ? ` ${this.i18n.global.t(
                                                    'day',
                                                    1
                                                )}, `
                                              : ` ${this.i18n.global.t(
                                                    'day',
                                                    2
                                                )}, `)
                                        : ''
                                const hDisplay =
                                    h > 0
                                        ? h +
                                          (h === 1
                                              ? ` ${this.i18n.global.t(
                                                    'hour',
                                                    1
                                                )}, `
                                              : ` ${this.i18n.global.t(
                                                    'hour',
                                                    2
                                                )}, `)
                                        : ''
                                const mDisplay =
                                    m > 0
                                        ? m +
                                          (m === 1
                                              ? ` ${this.i18n.global.t(
                                                    'minute',
                                                    1
                                                )} `
                                              : ` ${this.i18n.global.t(
                                                    'minute',
                                                    2
                                                )} `)
                                        : ''
                                // const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : ''
                                const formattedDuration =
                                    dDisplay + hDisplay + mDisplay // + sDisplay
                                // const totalMs = duration * 1000
                                // const result = new Date(totalMs).toISOString()

                                // return result
                                // },
                                let formattedDistance = (
                                    simulation.Distance / 1000
                                ).toString()
                                formattedDistance = formattedDistance.replace(
                                    '.',
                                    ','
                                )
                                formattedDistance = formattedDistance.substring(
                                    0,
                                    formattedDistance.indexOf(',') + 3
                                )

                                const formattedSimulation = {
                                    id: +simulation.Id,
                                    name: simulation.Name,
                                    date_begin: simulation.DateBegin,
                                    date_end: simulation.DateEnd,
                                    drivers: simulation.ConstraintDriver,
                                    vehicles: simulation.ConstraintVehicle,
                                    areas: simulation.ConstraintSector,
                                    status: simulation.Status,
                                    steps_count: simulation.NbSteps,
                                    tours_count: simulation.NbTours,
                                    not_affected: simulation.NbStepsUnaffected,
                                    distance: formattedDistance,
                                    duration: formattedDuration,
                                    report: simulation.Report,
                                    creation_date: simulation.CreationDate,
                                }
                                simulations.push(formattedSimulation)
                            })
                            // {
                            //     "Id": "2",
                            //     "Name": "Simulation de tournée du 2022-05-17",
                            //     "DateBegin": "2022-05-17",
                            //     "DateEnd": "2022-05-17",
                            //     "ConstraintDriver": "0",
                            //     "ConstraintVehicle": "0",
                            //     "ConstraintSector": "0",
                            //     "Status": "finished",
                            //     "NbSteps": "20",
                            //     "NbTours": "1",
                            //     "NbStepsUnaffected": "0",
                            //     "Distance": "206321",
                            //     "Duration": "17746",
                            //     "Progression": "0",
                            //     "CalculationBegin": "2022-07-05 09:13:59",
                            //     "CalculationEnd": "2022-07-05 09:14:12",
                            //     "Result": "",
                            //     "Report": "Aucune erreur dans le calcul des tournées",
                            //     "Merged": null,
                            //     "CreationDate": "2022-07-05 09:13:59",
                            //     "CreationUserId": "1",
                            //     "ModificationDate": "2022-07-05 09:14:12",
                            //     "ModificationUserId": "1"
                            // }
                            // const orders = []
                            // res.data.Records.forEach(async (order) => {
                            //     const client = clients
                            //         ? JSON.parse(JSON.stringify(clients)).find(
                            //               (el) => el.id === +order.IdClient
                            //           )
                            //         : state.current

                            //     const formattedOrder = {
                            //         id: +order.Id,
                            //         reference: order.Reference,
                            //         weight: order.Weight,
                            //         client: client.company_name,
                            //         number_of_parcels: +order.PacketNumber,
                            //         delivery_mode: order.Method,
                            //         steps: order.OrderSteps,
                            //         worth: +order.PacketValue,
                            //         status: order.Status,
                            //         creation_date: new Date(
                            //             order.CreationDate
                            //         ).toLocaleDateString(),
                            //     }

                            //     orders.push(formattedOrder)
                            // })

                            this.all = simulations
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        validate(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/toursimulation/${payload}/validTourSimulation`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        relaunch(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/toursimulation/${payload}/calculateRequest`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        generate(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/toursimulation`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        merge(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/toursimulation/merge`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOneSimulation(payload) {
            const universesIds = []
            payload.filter.value.forEach((universe) => {
                universesIds.push(universe.value)
            })
            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/toursimulation/${payload.id}?universes=${universesIds}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        this.unaffected = []
                        this.countSimUnaffected = null
                        this.fetchUnaffectedSim(payload.id, universesIds)

                        this.current = res.data
                        const formattedTours = []
                        let colorIndex = 0
                        if (this.current.Tours) {
                            this.current.Tours.forEach((tour, index) => {
                                let vehicleTypeIcon = tour.TypeVehicle
                                if (tour.TypeVehicle === 'bike') {
                                    vehicleTypeIcon = 'person-biking'
                                }
                                if (tour.TypeVehicle === 'boat') {
                                    vehicleTypeIcon = 'ship'
                                }
                                if (formattedTours.length > 0) {
                                    const lastCard =
                                        formattedTours[
                                            formattedTours.length - 1
                                        ]
                                    const lastColorIndex = colors.indexOf(
                                        lastCard.color
                                    )
                                    colorIndex =
                                        lastColorIndex === colors.length - 1
                                            ? 0
                                            : lastColorIndex + 1
                                }
                                const newTour = {
                                    GeoJSON: JSON.parse(tour.GeoJSON),
                                    driver: {
                                        name: tour.Driver
                                            ? `${tour.Driver.FirstName} ${tour.Driver.LastName}`
                                            : '',
                                        id: +tour.IdDriver,
                                        IdUniverse: tour.Driver
                                            ? tour.Driver.IdUniverse
                                            : null,
                                    },
                                    vehicle: {
                                        name: tour.Vehicle
                                            ? `${tour.Vehicle.Name}`
                                            : '',
                                        type: vehicleTypeIcon,
                                        id: +tour.IdVehicle,
                                    },
                                    co_driver: { id: +tour.IdCoDriver },
                                    start_hour_option: tour.StartHourOption,
                                    date_begin: tour.DateBegin,
                                    distance: tour.Distance,
                                    pts: `${tour.NbPoints} pts`,
                                    // weight: '34kg',
                                    duration: tour.Duration,

                                    id: +tour.Id,
                                    show: !!(
                                        payload.shownTours &&
                                        payload.shownTours.includes(+tour.Id)
                                    ),
                                    open: !!(
                                        payload.openedTours &&
                                        payload.openedTours.includes(+tour.Id)
                                    ),
                                    color: colors[colorIndex],
                                    steps: {
                                        infos: tour.TourDetails,
                                        list: `steps-tour-${index}`,
                                    },
                                }

                                formattedTours.push(newTour)
                            })
                            this.tours = formattedTours
                        }

                        // console.log(this.tours)
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        fetchUnaffectedSim(simId, universes) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/toursimulation/${simId}/unaffected?universes=${universes}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        if (res && res.status === 200) {
                            this.unaffectedHasIncompleteSteps = false

                            res.data.forEach((una) => {
                                if (
                                    this.unaffected.length === 0 ||
                                    this.unaffected[this.unaffected.length - 1]
                                        .IdOrder !== una.IdOrder
                                ) {
                                    if (!una.Latitude && !una.Longitude) {
                                        this.unaffectedHasIncompleteSteps = true
                                        una.hasIncompleteSteps = true
                                        una.forEach((u) => {
                                            u.incomplete = true
                                        })
                                    }
                                    this.unaffected.push(una)
                                } else if (
                                    this.unaffected[this.unaffected.length - 1]
                                        .IdOrder === una.IdOrder
                                ) {
                                    if (!una.Latitude && !una.Longitude) {
                                        this.unaffectedHasIncompleteSteps = true
                                        una.incomplete = true
                                    }
                                    this.unaffected[
                                        this.unaffected.length - 1
                                    ] = [
                                        this.unaffected[
                                            this.unaffected.length - 1
                                        ],
                                        una,
                                    ]
                                }
                                this.countSimUnaffected += 1
                            })
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        //
                        reject(err)
                    })
            })
        },
        moveStepToOtherTour(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/toursimulation/${payload.IdSimulation}/moveOrderStep`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        moveManyStepsToOtherTour(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/toursimulation/${payload.IdSimulation}/moveManyOrderSteps`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        changeStepPositionInTour(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/tour/${payload.IdTour}/setFixedPositionToOrderStep`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                        }
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        deleteTourInSimulation(id) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/tour/${id}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        addTourToSimulation(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(
                        `/api/v1/toursimulation/${payload.IdSimulation}/addTour`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                            signal: this.controller.signal,
                        }
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        delete(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/toursimulation/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
    },
})
export { useSimulationStore }
