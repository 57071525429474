const countries = [
    {
        name: 'Afghanistan',
        code2: 'AF',
        value: 'AFG',
    },
    {
        name: 'Albania',
        code2: 'AL',
        value: 'ALB',
    },
    {
        name: 'Algeria',
        code2: 'DZ',
        value: 'DZA',
    },
    {
        name: 'American Samoa',
        code2: 'AS',
        value: 'ASM',
    },
    {
        name: 'Andorra',
        code2: 'AD',
        value: 'AND',
    },
    {
        name: 'Angola',
        code2: 'AO',
        value: 'AGO',
    },
    {
        name: 'Anguilla',
        code2: 'AI',
        value: 'AIA',
    },
    {
        name: 'Antarctica',
        code2: 'AQ',
        value: 'ATA',
    },
    {
        name: 'Antigua and Barbuda',
        code2: 'AG',
        value: 'ATG',
    },
    {
        name: 'Argentina',
        code2: 'AR',
        value: 'ARG',
    },
    {
        name: 'Armenia',
        code2: 'AM',
        value: 'ARM',
    },
    {
        name: 'Aruba',
        code2: 'AW',
        value: 'ABW',
    },
    {
        name: 'Australia',
        code2: 'AU',
        value: 'AUS',
    },
    {
        name: 'Austria',
        code2: 'AT',
        value: 'AUT',
    },
    {
        name: 'Azerbaijan',
        code2: 'AZ',
        value: 'AZE',
    },
    {
        name: 'Bahamas (the)',
        code2: 'BS',
        value: 'BHS',
    },
    {
        name: 'Bahrain',
        code2: 'BH',
        value: 'BHR',
    },
    {
        name: 'Bangladesh',
        code2: 'BD',
        value: 'BGD',
    },
    {
        name: 'Barbados',
        code2: 'BB',
        value: 'BRB',
    },
    {
        name: 'Belarus',
        code2: 'BY',
        value: 'BLR',
    },
    {
        name: 'Belgium',
        code2: 'BE',
        value: 'BEL',
    },
    {
        name: 'Belize',
        code2: 'BZ',
        value: 'BLZ',
    },
    {
        name: 'Benin',
        code2: 'BJ',
        value: 'BEN',
    },
    {
        name: 'Bermuda',
        code2: 'BM',
        value: 'BMU',
    },
    {
        name: 'Bhutan',
        code2: 'BT',
        value: 'BTN',
    },
    {
        name: 'Bolivia (Plurinational State of)',
        code2: 'BO',
        value: 'BOL',
    },
    {
        name: 'Bonaire, Sint Eustatius and Saba',
        code2: 'BQ',
        value: 'BES',
    },
    {
        name: 'Bosnia and Herzegovina',
        code2: 'BA',
        value: 'BIH',
    },
    {
        name: 'Botswana',
        code2: 'BW',
        value: 'BWA',
    },
    {
        name: 'Bouvet Island',
        code2: 'BV',
        value: 'BVT',
    },
    {
        name: 'Brazil',
        code2: 'BR',
        value: 'BRA',
    },
    {
        name: 'British Indian Ocean Territory (the)',
        code2: 'IO',
        value: 'IOT',
    },
    {
        name: 'Brunei Darussalam',
        code2: 'BN',
        value: 'BRN',
    },
    {
        name: 'Bulgaria',
        code2: 'BG',
        value: 'BGR',
    },
    {
        name: 'Burkina Faso',
        code2: 'BF',
        value: 'BFA',
    },
    {
        name: 'Burundi',
        code2: 'BI',
        value: 'BDI',
    },
    {
        name: 'Cabo Verde',
        code2: 'CV',
        value: 'CPV',
    },
    {
        name: 'Cambodia',
        code2: 'KH',
        value: 'KHM',
    },
    {
        name: 'Cameroon',
        code2: 'CM',
        value: 'CMR',
    },
    {
        name: 'Canada',
        code2: 'CA',
        value: 'CAN',
    },
    {
        name: 'Cayman Islands (the)',
        code2: 'KY',
        value: 'CYM',
    },
    {
        name: 'Central African Republic (the)',
        code2: 'CF',
        value: 'CAF',
    },
    {
        name: 'Chad',
        code2: 'TD',
        value: 'TCD',
    },
    {
        name: 'Chile',
        code2: 'CL',
        value: 'CHL',
    },
    {
        name: 'China',
        code2: 'CN',
        value: 'CHN',
    },
    {
        name: 'Christmas Island',
        code2: 'CX',
        value: 'CXR',
    },
    {
        name: 'Cocos (Keeling) Islands (the)',
        code2: 'CC',
        value: 'CCK',
    },
    {
        name: 'Colombia',
        code2: 'CO',
        value: 'COL',
    },
    {
        name: 'Comoros (the)',
        code2: 'KM',
        value: 'COM',
    },
    {
        name: 'Congo (the Democratic Republic of the)',
        code2: 'CD',
        value: 'COD',
    },
    {
        name: 'Congo (the)',
        code2: 'CG',
        value: 'COG',
    },
    {
        name: 'Cook Islands (the)',
        code2: 'CK',
        value: 'COK',
    },
    {
        name: 'Costa Rica',
        code2: 'CR',
        value: 'CRI',
    },
    {
        name: 'Croatia',
        code2: 'HR',
        value: 'HRV',
    },
    {
        name: 'Cuba',
        code2: 'CU',
        value: 'CUB',
    },
    {
        name: 'Curaçao',
        code2: 'CW',
        value: 'CUW',
    },
    {
        name: 'Cyprus',
        code2: 'CY',
        value: 'CYP',
    },
    {
        name: 'Czechia',
        code2: 'CZ',
        value: 'CZE',
    },
    {
        name: "Côte d'Ivoire",
        code2: 'CI',
        value: 'CIV',
    },
    {
        name: 'Denmark',
        code2: 'DK',
        value: 'DNK',
    },
    {
        name: 'Djibouti',
        code2: 'DJ',
        value: 'DJI',
    },
    {
        name: 'Dominica',
        code2: 'DM',
        value: 'DMA',
    },
    {
        name: 'Dominican Republic (the)',
        code2: 'DO',
        value: 'DOM',
    },
    {
        name: 'Ecuador',
        code2: 'EC',
        value: 'ECU',
    },
    {
        name: 'Egypt',
        code2: 'EG',
        value: 'EGY',
    },
    {
        name: 'El Salvador',
        code2: 'SV',
        value: 'SLV',
    },
    {
        name: 'Equatorial Guinea',
        code2: 'GQ',
        value: 'GNQ',
    },
    {
        name: 'Eritrea',
        code2: 'ER',
        value: 'ERI',
    },
    {
        name: 'Estonia',
        code2: 'EE',
        value: 'EST',
    },
    {
        name: 'Eswatini',
        code2: 'SZ',
        value: 'SWZ',
    },
    {
        name: 'Ethiopia',
        code2: 'ET',
        value: 'ETH',
    },
    {
        name: 'Falkland Islands (the) [Malvinas]',
        code2: 'FK',
        value: 'FLK',
    },
    {
        name: 'Faroe Islands (the)',
        code2: 'FO',
        value: 'FRO',
    },
    {
        name: 'Fiji',
        code2: 'FJ',
        value: 'FJI',
    },
    {
        name: 'Finland',
        code2: 'FI',
        value: 'FIN',
    },
    {
        name: 'France',
        code2: 'FR',
        value: 'FRA',
    },
    {
        name: 'French Guiana',
        code2: 'GF',
        value: 'GUF',
    },
    {
        name: 'French Polynesia',
        code2: 'PF',
        value: 'PYF',
    },
    {
        name: 'French Southern Territories (the)',
        code2: 'TF',
        value: 'ATF',
    },
    {
        name: 'Gabon',
        code2: 'GA',
        value: 'GAB',
    },
    {
        name: 'Gambia (the)',
        code2: 'GM',
        value: 'GMB',
    },
    {
        name: 'Georgia',
        code2: 'GE',
        value: 'GEO',
    },
    {
        name: 'Germany',
        code2: 'DE',
        value: 'DEU',
    },
    {
        name: 'Ghana',
        code2: 'GH',
        value: 'GHA',
    },
    {
        name: 'Gibraltar',
        code2: 'GI',
        value: 'GIB',
    },
    {
        name: 'Greece',
        code2: 'GR',
        value: 'GRC',
    },
    {
        name: 'Greenland',
        code2: 'GL',
        value: 'GRL',
    },
    {
        name: 'Grenada',
        code2: 'GD',
        value: 'GRD',
    },
    {
        name: 'Guadeloupe',
        code2: 'GP',
        value: 'GLP',
    },
    {
        name: 'Guam',
        code2: 'GU',
        value: 'GUM',
    },
    {
        name: 'Guatemala',
        code2: 'GT',
        value: 'GTM',
    },
    {
        name: 'Guernsey',
        code2: 'GG',
        value: 'GGY',
    },
    {
        name: 'Guinea',
        code2: 'GN',
        value: 'GIN',
    },
    {
        name: 'Guinea-Bissau',
        code2: 'GW',
        value: 'GNB',
    },
    {
        name: 'Guyana',
        code2: 'GY',
        value: 'GUY',
    },
    {
        name: 'Haiti',
        code2: 'HT',
        value: 'HTI',
    },
    {
        name: 'Heard Island and McDonald Islands',
        code2: 'HM',
        value: 'HMD',
    },
    {
        name: 'Holy See (the)',
        code2: 'VA',
        value: 'VAT',
    },
    {
        name: 'Honduras',
        code2: 'HN',
        value: 'HND',
    },
    {
        name: 'Hong Kong',
        code2: 'HK',
        value: 'HKG',
    },
    {
        name: 'Hungary',
        code2: 'HU',
        value: 'HUN',
    },
    {
        name: 'Iceland',
        code2: 'IS',
        value: 'ISL',
    },
    {
        name: 'India',
        code2: 'IN',
        value: 'IND',
    },
    {
        name: 'Indonesia',
        code2: 'ID',
        value: 'IDN',
    },
    {
        name: 'Iran (Islamic Republic of)',
        code2: 'IR',
        value: 'IRN',
    },
    {
        name: 'Iraq',
        code2: 'IQ',
        value: 'IRQ',
    },
    {
        name: 'Ireland',
        code2: 'IE',
        value: 'IRL',
    },
    {
        name: 'Isle of Man',
        code2: 'IM',
        value: 'IMN',
    },
    {
        name: 'Israel',
        code2: 'IL',
        value: 'ISR',
    },
    {
        name: 'Italy',
        code2: 'IT',
        value: 'ITA',
    },
    {
        name: 'Jamaica',
        code2: 'JM',
        value: 'JAM',
    },
    {
        name: 'Japan',
        code2: 'JP',
        value: 'JPN',
    },
    {
        name: 'Jersey',
        code2: 'JE',
        value: 'JEY',
    },
    {
        name: 'Jordan',
        code2: 'JO',
        value: 'JOR',
    },
    {
        name: 'Kazakhstan',
        code2: 'KZ',
        value: 'KAZ',
    },
    {
        name: 'Kenya',
        code2: 'KE',
        value: 'KEN',
    },
    {
        name: 'Kiribati',
        code2: 'KI',
        value: 'KIR',
    },
    {
        name: "Korea (the Democratic People's Republic of)",
        code2: 'KP',
        value: 'PRK',
    },
    {
        name: 'Korea (the Republic of)',
        code2: 'KR',
        value: 'KOR',
    },
    {
        name: 'Kuwait',
        code2: 'KW',
        value: 'KWT',
    },
    {
        name: 'Kyrgyzstan',
        code2: 'KG',
        value: 'KGZ',
    },
    {
        name: "Lao People's Democratic Republic (the)",
        code2: 'LA',
        value: 'LAO',
    },
    {
        name: 'Latvia',
        code2: 'LV',
        value: 'LVA',
    },
    {
        name: 'Lebanon',
        code2: 'LB',
        value: 'LBN',
    },
    {
        name: 'Lesotho',
        code2: 'LS',
        value: 'LSO',
    },
    {
        name: 'Liberia',
        code2: 'LR',
        value: 'LBR',
    },
    {
        name: 'Libya',
        code2: 'LY',
        value: 'LBY',
    },
    {
        name: 'Liechtenstein',
        code2: 'LI',
        value: 'LIE',
    },
    {
        name: 'Lithuania',
        code2: 'LT',
        value: 'LTU',
    },
    {
        name: 'Luxembourg',
        code2: 'LU',
        value: 'LUX',
    },
    {
        name: 'Macao',
        code2: 'MO',
        value: 'MAC',
    },
    {
        name: 'Madagascar',
        code2: 'MG',
        value: 'MDG',
    },
    {
        name: 'Malawi',
        code2: 'MW',
        value: 'MWI',
    },
    {
        name: 'Malaysia',
        code2: 'MY',
        value: 'MYS',
    },
    {
        name: 'Maldives',
        code2: 'MV',
        value: 'MDV',
    },
    {
        name: 'Mali',
        code2: 'ML',
        value: 'MLI',
    },
    {
        name: 'Malta',
        code2: 'MT',
        value: 'MLT',
    },
    {
        name: 'Marshall Islands (the)',
        code2: 'MH',
        value: 'MHL',
    },
    {
        name: 'Martinique',
        code2: 'MQ',
        value: 'MTQ',
    },
    {
        name: 'Mauritania',
        code2: 'MR',
        value: 'MRT',
    },
    {
        name: 'Mauritius',
        code2: 'MU',
        value: 'MUS',
    },
    {
        name: 'Mayotte',
        code2: 'YT',
        value: 'MYT',
    },
    {
        name: 'Mexico',
        code2: 'MX',
        value: 'MEX',
    },
    {
        name: 'Micronesia (Federated States of)',
        code2: 'FM',
        value: 'FSM',
    },
    {
        name: 'Moldova (the Republic of)',
        code2: 'MD',
        value: 'MDA',
    },
    {
        name: 'Monaco',
        code2: 'MC',
        value: 'MCO',
    },
    {
        name: 'Mongolia',
        code2: 'MN',
        value: 'MNG',
    },
    {
        name: 'Montenegro',
        code2: 'ME',
        value: 'MNE',
    },
    {
        name: 'Montserrat',
        code2: 'MS',
        value: 'MSR',
    },
    {
        name: 'Morocco',
        code2: 'MA',
        value: 'MAR',
    },
    {
        name: 'Mozambique',
        code2: 'MZ',
        value: 'MOZ',
    },
    {
        name: 'Myanmar',
        code2: 'MM',
        value: 'MMR',
    },
    {
        name: 'Namibia',
        code2: 'NA',
        value: 'NAM',
    },
    {
        name: 'Nauru',
        code2: 'NR',
        value: 'NRU',
    },
    {
        name: 'Nepal',
        code2: 'NP',
        value: 'NPL',
    },
    {
        name: 'Netherlands (Kingdom of the)',
        code2: 'NL',
        value: 'NLD',
    },
    {
        name: 'New Caledonia',
        code2: 'NC',
        value: 'NCL',
    },
    {
        name: 'New Zealand',
        code2: 'NZ',
        value: 'NZL',
    },
    {
        name: 'Nicaragua',
        code2: 'NI',
        value: 'NIC',
    },
    {
        name: 'Niger (the)',
        code2: 'NE',
        value: 'NER',
    },
    {
        name: 'Nigeria',
        code2: 'NG',
        value: 'NGA',
    },
    {
        name: 'Niue',
        code2: 'NU',
        value: 'NIU',
    },
    {
        name: 'Norfolk Island',
        code2: 'NF',
        value: 'NFK',
    },
    {
        name: 'North Macedonia',
        code2: 'MK',
        value: 'MKD',
    },
    {
        name: 'Northern Mariana Islands (the)',
        code2: 'MP',
        value: 'MNP',
    },
    {
        name: 'Norway',
        code2: 'NO',
        value: 'NOR',
    },
    {
        name: 'Oman',
        code2: 'OM',
        value: 'OMN',
    },
    {
        name: 'Pakistan',
        code2: 'PK',
        value: 'PAK',
    },
    {
        name: 'Palau',
        code2: 'PW',
        value: 'PLW',
    },
    {
        name: 'Palestine, State of',
        code2: 'PS',
        value: 'PSE',
    },
    {
        name: 'Panama',
        code2: 'PA',
        value: 'PAN',
    },
    {
        name: 'Papua New Guinea',
        code2: 'PG',
        value: 'PNG',
    },
    {
        name: 'Paraguay',
        code2: 'PY',
        value: 'PRY',
    },
    {
        name: 'Peru',
        code2: 'PE',
        value: 'PER',
    },
    {
        name: 'Philippines (the)',
        code2: 'PH',
        value: 'PHL',
    },
    {
        name: 'Pitcairn',
        code2: 'PN',
        value: 'PCN',
    },
    {
        name: 'Poland',
        code2: 'PL',
        value: 'POL',
    },
    {
        name: 'Portugal',
        code2: 'PT',
        value: 'PRT',
    },
    {
        name: 'Puerto Rico',
        code2: 'PR',
        value: 'PRI',
    },
    {
        name: 'Qatar',
        code2: 'QA',
        value: 'QAT',
    },
    {
        name: 'Romania',
        code2: 'RO',
        value: 'ROU',
    },
    {
        name: 'Russian Federation (the)',
        code2: 'RU',
        value: 'RUS',
    },
    {
        name: 'Rwanda',
        code2: 'RW',
        value: 'RWA',
    },
    {
        name: 'Réunion',
        code2: 'RE',
        value: 'REU',
    },
    {
        name: 'Saint Barthélemy',
        code2: 'BL',
        value: 'BLM',
    },
    {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        code2: 'SH',
        value: 'SHN',
    },
    {
        name: 'Saint Kitts and Nevis',
        code2: 'KN',
        value: 'KNA',
    },
    {
        name: 'Saint Lucia',
        code2: 'LC',
        value: 'LCA',
    },
    {
        name: 'Saint Martin (French part)',
        code2: 'MF',
        value: 'MAF',
    },
    {
        name: 'Saint Pierre and Miquelon',
        code2: 'PM',
        value: 'SPM',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        code2: 'VC',
        value: 'VCT',
    },
    {
        name: 'Samoa',
        code2: 'WS',
        value: 'WSM',
    },
    {
        name: 'San Marino',
        code2: 'SM',
        value: 'SMR',
    },
    {
        name: 'Sao Tome and Principe',
        code2: 'ST',
        value: 'STP',
    },
    {
        name: 'Saudi Arabia',
        code2: 'SA',
        value: 'SAU',
    },
    {
        name: 'Senegal',
        code2: 'SN',
        value: 'SEN',
    },
    {
        name: 'Serbia',
        code2: 'RS',
        value: 'SRB',
    },
    {
        name: 'Seychelles',
        code2: 'SC',
        value: 'SYC',
    },
    {
        name: 'Sierra Leone',
        code2: 'SL',
        value: 'SLE',
    },
    {
        name: 'Singapore',
        code2: 'SG',
        value: 'SGP',
    },
    {
        name: 'Sint Maarten (Dutch part)',
        code2: 'SX',
        value: 'SXM',
    },
    {
        name: 'Slovakia',
        code2: 'SK',
        value: 'SVK',
    },
    {
        name: 'Slovenia',
        code2: 'SI',
        value: 'SVN',
    },
    {
        name: 'Solomon Islands',
        code2: 'SB',
        value: 'SLB',
    },
    {
        name: 'Somalia',
        code2: 'SO',
        value: 'SOM',
    },
    {
        name: 'South Africa',
        code2: 'ZA',
        value: 'ZAF',
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        code2: 'GS',
        value: 'SGS',
    },
    {
        name: 'South Sudan',
        code2: 'SS',
        value: 'SSD',
    },
    {
        name: 'Spain',
        code2: 'ES',
        value: 'ESP',
    },
    {
        name: 'Sri Lanka',
        code2: 'LK',
        value: 'LKA',
    },
    {
        name: 'Sudan (the)',
        code2: 'SD',
        value: 'SDN',
    },
    {
        name: 'Suriname',
        code2: 'SR',
        value: 'SUR',
    },
    {
        name: 'Svalbard and Jan Mayen',
        code2: 'SJ',
        value: 'SJM',
    },
    {
        name: 'Sweden',
        code2: 'SE',
        value: 'SWE',
    },
    {
        name: 'Switzerland',
        code2: 'CH',
        value: 'CHE',
    },
    {
        name: 'Syrian Arab Republic (the)',
        code2: 'SY',
        value: 'SYR',
    },
    {
        name: 'Taiwan (Province of China)',
        code2: 'TW',
        value: 'TWN',
    },
    {
        name: 'Tajikistan',
        code2: 'TJ',
        value: 'TJK',
    },
    {
        name: 'Tanzania, the United Republic of',
        code2: 'TZ',
        value: 'TZA',
    },
    {
        name: 'Thailand',
        code2: 'TH',
        value: 'THA',
    },
    {
        name: 'Timor-Leste',
        code2: 'TL',
        value: 'TLS',
    },
    {
        name: 'Togo',
        code2: 'TG',
        value: 'TGO',
    },
    {
        name: 'Tokelau',
        code2: 'TK',
        value: 'TKL',
    },
    {
        name: 'Tonga',
        code2: 'TO',
        value: 'TON',
    },
    {
        name: 'Trinidad and Tobago',
        code2: 'TT',
        value: 'TTO',
    },
    {
        name: 'Tunisia',
        code2: 'TN',
        value: 'TUN',
    },
    {
        name: 'Turkmenistan',
        code2: 'TM',
        value: 'TKM',
    },
    {
        name: 'Turks and Caicos Islands (the)',
        code2: 'TC',
        value: 'TCA',
    },
    {
        name: 'Tuvalu',
        code2: 'TV',
        value: 'TUV',
    },
    {
        name: 'Türkiye',
        code2: 'TR',
        value: 'TUR',
    },
    {
        name: 'Uganda',
        code2: 'UG',
        value: 'UGA',
    },
    {
        name: 'Ukraine',
        code2: 'UA',
        value: 'UKR',
    },
    {
        name: 'United Arab Emirates (the)',
        code2: 'AE',
        value: 'ARE',
    },
    {
        name: 'United Kingdom of Great Britain and Northern Ireland (the)',
        code2: 'GB',
        value: 'GBR',
    },
    {
        name: 'United States Minor Outlying Islands (the)',
        code2: 'UM',
        value: 'UMI',
    },
    {
        name: 'United States of America (the)',
        code2: 'US',
        value: 'USA',
    },
    {
        name: 'Uruguay',
        code2: 'UY',
        value: 'URY',
    },
    {
        name: 'Uzbekistan',
        code2: 'UZ',
        value: 'UZB',
    },
    {
        name: 'Vanuatu',
        code2: 'VU',
        value: 'VUT',
    },
    {
        name: 'Venezuela (Bolivarian Republic of)',
        code2: 'VE',
        value: 'VEN',
    },
    {
        name: 'Viet Nam',
        code2: 'VN',
        value: 'VNM',
    },
    {
        name: 'Virgin Islands (British)',
        code2: 'VG',
        value: 'VGB',
    },
    {
        name: 'Virgin Islands (U.S.)',
        code2: 'VI',
        value: 'VIR',
    },
    {
        name: 'Wallis and Futuna',
        code2: 'WF',
        value: 'WLF',
    },
    {
        name: 'Western Sahara*',
        code2: 'EH',
        value: 'ESH',
    },
    {
        name: 'Yemen',
        code2: 'YE',
        value: 'YEM',
    },
    {
        name: 'Zambia',
        code2: 'ZM',
        value: 'ZMB',
    },
    {
        name: 'Zimbabwe',
        code2: 'ZW',
        value: 'ZWE',
    },
    {
        name: 'Åland Islands',
        code2: 'AX',
        value: 'ALA',
    },
]

export default countries
