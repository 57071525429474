import countries from '@/resources/countries.js'
import { defineStore } from 'pinia'

const useHarborStore = defineStore('harbor', {
    state: () => ({
        all: null,
        docks: null,
        countDocks: null,
        docksTotalPages: 0,
        defenses: null,
        countDefenses: null,
        defensesTotalPages: 0,
        currentDock: null,
        currentDefense: null,
        inspections: null,
        inspectionsCount: null,
        inspectionsTotalPages: 0,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
    }),

    actions: {
        searchHarbors(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/harbor/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            } else {
                apiUrl = `/api/v1/harbor/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages,
                            )
                                ? 0
                                : +res.data.TotalPages
                            const harbors = []
                            if (count > 0) {
                                res.data.Records.forEach((harbor) => {
                                    const formattedHarbor = {
                                        id: +harbor.Id,
                                        name: harbor.Name,
                                        description: harbor.Description,
                                        address: `${harbor.Address1} ${harbor.Address2} ${harbor.PostalCode} ${harbor.City}`,
                                        country: countries.find(
                                            (country) =>
                                                country.value ===
                                                harbor.Country,
                                        ).name,
                                        docks: harbor.Docks,
                                        latitude: harbor.Latitude,
                                        longitude: harbor.Longitude,
                                        inspections: harbor.Inspections,
                                        creation_date: harbor.CreationDate,
                                    }
                                    harbors.push(formattedHarbor)
                                })
                            }

                            this.all = harbors
                            this.count = count
                            this.totalPages = totalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchDocks(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/dock/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            } else {
                apiUrl = `/api/v1/dock/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages,
                            )
                                ? 0
                                : +res.data.TotalPages
                            const docks = []
                            if (count > 0) {
                                res.data.Records.forEach((dock) => {
                                    const formattedDock = {
                                        id: +dock.Id,
                                        name: dock.Name,
                                        description: dock.Description,
                                        harborId: dock.IdHarbor,
                                        harbor: `${dock.Harbor.Name} - ${dock.Harbor.Description} - (${
                                            countries.find(
                                                (country) =>
                                                    country.value ===
                                                    dock.Harbor.Country,
                                            ).name
                                        })`,
                                        last_inspection:
                                            dock.Inspections.length > 0
                                                ? `${new Date(
                                                      dock.Inspections[0].CreationDate,
                                                  ).toLocaleTimeString(
                                                      this.i18n.global.locale,
                                                      {
                                                          year: 'numeric',
                                                          month: '2-digit',
                                                          day: '2-digit',
                                                          hour: '2-digit',
                                                          minute: '2-digit',
                                                      },
                                                  )} - ${this.i18n.global.t(dock.Inspections[0].Status)}`
                                                : 'No inspections',
                                        inspections: dock.Inspections,
                                        defenses: dock.Defenses,
                                        creation_date: dock.CreationDate,
                                    }
                                    docks.push(formattedDock)
                                })
                            }

                            this.docks = docks
                            this.countDocks = count
                            this.docksTotalPages = totalPages
                            this.loading = false
                        } else {
                            this.docks = null
                            this.countDocks = 0
                            this.docksTotalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchDefenses(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/defense/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            } else {
                apiUrl = `/api/v1/defense/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages,
                            )
                                ? 0
                                : +res.data.TotalPages
                            const defenses = []
                            if (count > 0) {
                                res.data.Records.forEach((defense) => {
                                    const lastInspection = defense.Dock.Inspections &&
                                        defense.Dock.Inspections.length > 0
                                            ? defense.Dock.Inspections[0].Entities.find(
                                                  (inspection) =>
                                                      inspection.IdEntity ===
                                                      defense.Id,
                                              )
                                            : null
                                    const formattedDefense = {
                                        id: +defense.Id,
                                        name: defense.Name,
                                        description: defense.Description,
                                        harborId: defense.IdHarbor,
                                        harbor: `${defense.Harbor.Name} - ${defense.Harbor.Description} - (${
                                            countries.find(
                                                (country) =>
                                                    country.value ===
                                                    defense.Harbor.Country,
                                            ).name
                                        })`,
                                        dockId: defense.IdDock,
                                        dock: `${defense.Dock.Name} ${
                                            defense.Dock.Description
                                        }`,

                                        last_inspection: lastInspection
                                            ? `${new Date(
                                                  lastInspection.CreationDate,
                                              ).toLocaleTimeString(
                                                  this.i18n.global.locale,
                                                  {
                                                      year: 'numeric',
                                                      month: '2-digit',
                                                      day: '2-digit',
                                                      hour: '2-digit',
                                                      minute: '2-digit',
                                                  },
                                              )} - ${this.i18n.global.t(lastInspection.Status)}`
                                            : 'No inspections',

                                        creation_date: defense.CreationDate,
                                    }
                                    defenses.push(formattedDefense)
                                })
                            }

                            this.defenses = defenses
                            this.countDefenses = count
                            this.defensesTotalPages = totalPages
                            this.loading = false
                        } else {
                            this.defenses = null
                            this.countDefenses = 0
                            this.defensesTotalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchInspections(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/inspection/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            } else {
                apiUrl = `/api/v1/inspection/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages,
                            )
                                ? 0
                                : +res.data.TotalPages
                            const inspections = []
                            if (count > 0) {
                                res.data.Records.forEach((inspection) => {
                                    const formattedInspection = {
                                        id: +inspection.Id,
                                        name: inspection.Name,
                                        description: inspection.Description,
                                        status: this.i18n.global.t(
                                            inspection.Status,
                                        ),
                                        harborId: inspection.IdHarbor,
                                        harbor: `${inspection.Harbor.Name} - ${inspection.Harbor.Description} - (${inspection.Harbor.Country})`,
                                        dockId: inspection.IdDock,
                                        dock: `${inspection.Dock.Name} ${
                                            inspection.Dock.Description
                                        }`,
                                        defenses: inspection.Entities,
                                        creation_date: inspection.CreationDate,
                                    }
                                    inspections.push(formattedInspection)
                                })
                            }

                            this.inspections = inspections
                            this.inspectionsCount = count
                            this.inspectionsTotalPages = totalPages
                            this.loading = false
                        } else {
                            this.inspections = null
                            this.inspectionsCount = 0
                            this.inspectionsTotalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        create(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/harbor', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/harbor/${payload.Id}`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteHarbor(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/harbor/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteManyHarbors(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/harbor/deleteMany`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        fetchOne(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/harbor/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        createDefense(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/defense', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateDefense(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/defense/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteDefense(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/defense/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteManyDefenses(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/defense/deleteMany`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOneDefense(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/defense/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        createDock(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/dock', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateDock(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/dock/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteDock(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/dock/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteManyDocks(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/dock/deleteMany`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOneDock(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/dock/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        inspect(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/inspection`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
})

export { useHarborStore }
