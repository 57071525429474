import { defineStore } from 'pinia'

const useLogStore = defineStore('log', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
    }),

    actions: {
        search(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            let hasEntityObjectQuery = false
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        if (q.dbField === 'EntityObject') {
                            query.push(
                                `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                            )
                            hasEntityObjectQuery = true
                        } else {
                            query.push(
                                `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                            )
                        }
                    }
                })
            }
            // if (hasEntityObjectQuery === false) {
            //     query.push(
            //         `{"EntityObject":[{"operator":"NOT LIKE","value":"/log/search"}]}`
            //     )
            // }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id' || type.dbField === 'Active') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/log/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/log/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages
                            )
                                ? 0
                                : +res.data.TotalPages
                            const logs = []
                            if (count > 0) {
                                // res.data.Records.forEach((log) => {
                                //     log.EntityObject = JSON.parse(
                                //         JSON.stringify(log.EntityObject)
                                //     )
                                //     logs.push(log)
                                // })
                                this.all = res.data.Records
                            }
                            this.count = count
                            this.totalPages = totalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },

        // fetchOne(payload) {
        //     return new Promise((resolve, reject) => {
        //         this.axios
        //             .get(`/api/v1/log/${payload}`, {
        //                 headers: {
        //                     Authorization: `Bearer ${this.cookies.get(
        //                         'user_session'
        //                     )}`,
        //                 },
        //             })
        //             .then((res) => {
        //                 this.current = res.data

        //                 resolve(res)
        //             })
        //             .catch((err) => {
        //                 err.toJSON()
        //                 const error = {
        //                     data: {
        //                         status: err.response.status,
        //                         message: err.response.data.message,
        //                     },
        //                 }
        //                 reject(err)
        //             })
        //     })
        // },
    },
})

export { useLogStore }
