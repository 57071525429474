<template>
    <div class="border-2 rounded p-2 mt-3">
        <h1 class="text-2xl justify-start flex font-bold mt-2">
            {{ $t('events') }}
        </h1>
        <filtrable-table
            id="emailSentList"
            :smallMT="true"
            :items="fEvents"
            :columns="columns"
            :searchQuery="searchQuery"
            :storeToSort="'emailSent'"
            :count="fEvents.length"
            :noPagination="true"
            :refresh="refresh"
            :canAddNew="false"
            @searched="checkSearchIsValidEmail($event)"
        >
        </filtrable-table>
    </div>
</template>

<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import copyToClipboard from '@/mixins/copyToClipboard.js'
import { useUserStore } from '@/stores/userStore'
import { mapState } from 'pinia'

export default {
    name: 'EventsTable',
    components: {
        FiltrableTable,
    },
    mixins: [copyToClipboard],
    data() {
        return {
            refresh: 0,
            columns: [],
            sortableFields: [],
        }
    },
    props: {
        events: Array,
        searchQuery: String,
    },
    mounted() {
        this.columns = [
            { name: 'event_date', dbField: 'date' },
            {
                name: 'email',
                filtrable: true,
                routeName: 'Email',
                dbField: 'Name',
                type: 'search',
                sortable: false,
                canCopy: true,
            },
            { name: 'subject', dbField: 'subject' },
            { name: 'event', dbField: 'event' },
            { name: 'details', dbField: 'detail' },
            {
                name: 'messageId',
                dbField: 'messageId',
                hide: this.user.type !== 'superadmin',
                canCopy: true,
            },
            {
                name: 'templateId',
                dbField: 'templateId',
                hide: this.user.type !== 'superadmin',
            },
        ]
    },
    computed: {
        ...mapState(useUserStore, { user: 'current' }),
        fEvents() {
            const fEvents = structuredClone(this.events)
            if (!fEvents) {
                return []
            }
            fEvents.forEach((event) => {
                const evt = `email_status.${event.event}.short`
                const detail = `email_status.${event.event}.info`
                event.event = this.$t(evt)
                event.details = this.$t(detail)
                event.event_date = event.date
            })
            return fEvents
        },
    },

    methods: {
        isOdd(num) {
            return num % 2
        },
        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                )
        },
        checkSearchIsValidEmail(event) {
            if (this.validateEmail(event.term) || event.term === '') {
                this.refresh += 1
                this.$emit('searched', event)
            }
        },
    },
}
</script>
