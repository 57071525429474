<template>
    <modal
        :headerText="isUpdate ? $t('update_contract') : $t('add_contract')"
        :buttonText="isUpdate ? 'update' : 'save'"
        :spin="creatingContract"
        :creating="creatingContract"
        id="addModal"
        :mxWidth="'max-w-2xl'"
        :mxHeight="'max-h-screen'"
        :buttonAlign="'justify-center'"
        @closeModal="resetContractForm()"
        @sendFromModal="isUpdate ? updateCurrentContract() : saveContract()"
    >
        <template #body>
            <div class="mt-2 p-4 items-center flex justify-center">
                <Multiselect
                    :key="refreshMS"
                    :disabled="isUpdate === true"
                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                    v-model="contractFormData.IdClient"
                    :options="clientOptions"
                    label="name"
                    track-by="name"
                    :searchable="true"
                    :placeholder="$t('select_client_info')"
                    :allow-empty="false"
                    :can-clear="false"
                    @search-change="$emit('searchChanged', $event)"
                ></Multiselect>
                <div
                    class="text-xs italic mt-1 mb-2"
                    v-for="error of v$.contractFormData.IdClient.$errors"
                    :key="error.$uid"
                >
                    <div class="error-msg">
                        {{ error.$message }}
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-4 gap-2 mt-2 px-4">
                <div class="col-span-2 mb-3 items-center">
                    <label
                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                        >{{ $t('name') + ' *' }}</label
                    >
                    <input
                        type="text"
                        v-model="contractFormData.Name"
                        @blur="v$.contractFormData.Name.$touch"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.contractFormData.Name.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <div class="col-span-2 mb-3 items-center">
                    <label
                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                        >{{ $t('contract_type') + ' *' }}</label
                    >
                    <div class="items-center mt-1 flex justify-center">
                        <Multiselect
                            :key="refreshMS"
                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                            v-model="contractFormData.Type"
                            :options="contractTypeOptions"
                            :loading="contractTypeOptions.length === 0"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :placeholder="$t('contract_type')"
                            :allow-empty="false"
                            :can-clear="false"
                        ></Multiselect>
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.contractFormData.Type.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-6 gap-2 mt-2 px-4">
                <div class="col-span-2 mb-3 items-center">
                    <label
                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                        >{{ $t('signature_date') + ' *' }}</label
                    >
                    <input
                        type="date"
                        :min="new Date().toISOString().substr(0, 10)"
                        v-model="contractFormData.SignatureDate"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                    <div class="text-xs italic mt-1 mb-2" v-if="badTimeRange">
                        <div class="error-msg">
                            {{ $t('bad_time_range') }}
                        </div>
                    </div>
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.contractFormData.SignatureDate
                            .$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <div class="col-span-2 mb-3 items-center">
                    <label
                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                        >{{ $t('contract_duration') + ' *' }}</label
                    >
                    <div class="items-center mt-1 flex justify-center">
                        <Multiselect
                            :key="refreshMS"
                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                            v-model="contractFormData.Duration"
                            :options="monthOptions"
                            :loading="monthOptions.length === 0"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :placeholder="$t('contract_duration')"
                            :allow-empty="false"
                            :can-clear="false"
                        ></Multiselect>
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.contractFormData.Duration
                                .$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 mb-3 items-center">
                    <label
                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                        >{{ $t('end_date') }}</label
                    >
                    <!-- :disabled="contractFormData.Duration !== 'none'" -->
                    <input
                        type="date"
                        :min="contractFormData.SignatureDate"
                        v-model="contractFormData.EndDate"
                        :disabled="contractFormData.Duration === 'openEnded'"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                    <!-- <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.contractFormData.EndDate.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div> -->
                </div>
            </div>
            <div class="grid grid-cols-6 gap-2 mt-2 px-4">
                <div class="col-span-2 mb-3 items-center">
                    <label class="font-semibold text-gray-600 basis-1/4 mr-2">{{
                        $t('auto_renew')
                    }}</label>
                    <div class="mt-1 items-center flex justify-center">
                        <Multiselect
                            :key="refreshMS"
                            :disabled="
                                contractFormData.Duration === 'openEnded'
                            "
                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                            v-model="contractFormData.AutomaticRenewal"
                            :options="renewalOptions"
                            :loading="renewalOptions.length === 0"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :placeholder="$t('auto_renew')"
                            :allow-empty="false"
                            :can-clear="false"
                        ></Multiselect>
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.contractFormData.AutomaticRenewal
                                .$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 mb-3 items-center">
                    <label class="font-semibold text-gray-600 basis-1/4 mr-2">{{
                        $t('notice')
                    }}</label>
                    <div class="mt-1 items-center flex justify-center">
                        <Multiselect
                            :key="refreshMS"
                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                            v-model="contractFormData.NoticePeriod"
                            :options="weekOptionsNotice"
                            :loading="weekOptionsNotice.length === 0"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :placeholder="$t('notice')"
                            :allow-empty="false"
                            :can-clear="false"
                        ></Multiselect>
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.contractFormData.NoticePeriod
                                .$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 mb-3 items-center">
                    <label class="font-semibold text-gray-600 basis-1/4 mr-2">{{
                        $t('alert_before_end')
                    }}</label>
                    <div class="mt-1 items-center flex justify-center">
                        <Multiselect
                            :key="refreshMS"
                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                            :disabled="
                                contractFormData.Duration === 'openEnded'
                            "
                            v-model="contractFormData.AlertBeforeEnd"
                            :options="weekOptionsAlert"
                            :loading="weekOptionsAlert.length === 0"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :placeholder="$t('alert_before_end')"
                            :allow-empty="false"
                            :can-clear="false"
                        ></Multiselect>

                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.contractFormData.AlertBeforeEnd
                                .$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span class="flex mt-1 italic text-xs items-center justify-center">
                {{ $t('required_fields') }}
            </span>
        </template>
    </modal>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

import Modal from '@/components/atoms/Modal.vue'
import contractOptions from '@/mixins/contractOptions'
import { useContractStore } from '@/stores/contractStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
    name: 'ContractModal',
    components: { Modal, Multiselect },
    setup() {
        return { v$: useVuelidate() }
    },
    mixins: [contractOptions],

    mounted() {},
    data() {
        return {
            contractFormData: structuredClone(this.formData),
            badTimeRange: false,
            isUpdate: this.update,
            creatingContract: false,
        }
    },
    props: {
        clientOptions: {
            type: Array,
            default: () => [],
        },
        formData: {
            type: Object,
            default: () => {},
        },
        update: {
            type: Boolean,
            default: false,
        },
    },
    validations() {
        return {
            contractFormData: {
                Name: { required },
                SignatureDate: { required },
                AutomaticRenewal: { required },
                Type: { required },
                Duration: { required },
                AlertBeforeEnd: { required },
                NoticePeriod: { required },
                IdClient: { required },
            },
        }
    },
    computed: {
        ...mapState(useUniverseStore, {
            universes: 'all',
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),

        ...mapWritableState(useContractStore, {
            currentContract: 'current',
            contracts: 'all',
        }),
        ...mapState(useUserStore, {
            user: 'current',
        }),
        ...mapStores(useUserStore),
    },
    watch: {
        'contractFormData.Duration': function () {
            if (this.contractFormData.Duration === 'openEnded') {
                this.contractFormData.EndDate = ''
                this.contractFormData.AutomaticRenewal = 'notApplicable'
                this.contractFormData.AlertBeforeEnd = 'none'
            } else {
                this.contractFormData.EndDate = new Date(
                    new Date(this.contractFormData.SignatureDate).setMonth(
                        new Date(
                            this.contractFormData.SignatureDate,
                        ).getMonth() + this.contractFormData.Duration,
                    ),
                )
                    .toISOString()
                    .substr(0, 10)
            }
        },
    },
    methods: {
        ...mapActions(useContractStore, {
            deleteContract: 'delete',
            create: 'create',
            updateContract: 'update',
        }),

        checkTimes() {
            if (
                this.contractFormData.EndDate === '' ||
                this.contractFormData.EndDate <
                    this.contractFormData.SignatureDate ||
                this.contractFormData.EndDate ===
                    this.contractFormData.SignatureDate
            ) {
                this.badTimeRange = true
                return true
            }
            return false
        },

        async saveContract() {
            this.v$.contractFormData.$reset()

            this.creatingContract = true
            this.badTimeRange = false
            this.contractFormData.CreationUserId = this.user.id
            this.contractFormData.ModificationUserId = this.user.id
            const formIsValid = await this.v$.contractFormData.$validate()
            if (formIsValid === true && !this.checkTimes()) {
                this.create(this.contractFormData)
                    .then(() => {
                        this.resetContractForm()
                        this.$toast.success(this.$t('contract_create_ok'))
                    })
                    .catch((err) => {
                        console.log(err)
                        this.creatingContract = false
                        this.$toast.error(this.$t('contract_create_ko'))
                    })
            } else {
                this.creatingContract = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateCurrentContract() {
            this.v$.contractFormData.$reset()

            this.creatingContract = true
            this.badTimeRange = false

            this.contractFormData.ModificationUserId = this.user.id

            const formIsValid = await this.v$.contractFormData.$validate()
            if (formIsValid === true && !this.checkTimes()) {
                this.updateContract({
                    id: this.contractFormData.Id,
                    form: this.contractFormData,
                })
                    .then(() => {
                        this.resetContractForm()

                        this.$toast.success(this.$t('contract_update_ok'))
                    })
                    .catch((err) => {
                        console.log(err)
                        this.creatingContract = false
                        this.$toast.error(this.$t('contract_update_ko'))
                    })
            } else {
                this.creatingContract = false

                this.$toast.error(this.$t('form_errors'))
            }
        },

        resetContractForm() {
            this.v$.contractFormData.$reset()

            this.creatingContract = false
            this.badTimeRange = false
            this.contractFormData = {}

            this.$emit('resetContractForm')
        },
    },
}
</script>
