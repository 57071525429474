<template>
    <modal
        :headerText="'upload_file'"
        :tcChoice="2"
        :mxWidth="'w-1/2'"
        :showSubmit="false"
        @closeModal="closeModal()"
    >
        <template v-slot:body>
            <div class="flex justify-center m-4">
                <span class="text-base italic">
                    {{ $t('upload_file_from_user_info') }}
                </span>
            </div>
            <drop-zone
                class="mx-4"
                :fileExt="fileExt"
                :withTitle="false"
                @uploadFiles="uploadFiles($event)"
                @fileRemoved="removeFileFromOk($event)"
                :okFilesToRemove="okFilesToRemove"
                :directAdd="false"
                :wait="wait"
                :lightLoading="true"
                :clearFileList="clearFileList"
                @resetClearFileList="clearFileList = false"
                @resetWrongFiles="wrongFileTypes = []"
                :filesInError="filesInError"
            >
                <template #errors>
                    <div
                        v-if="filesInError.length > 0"
                        class="flex flex-col items-center m-4"
                    >
                        <span class="text-sm font-semibold">
                            {{ $t('files_in_error') }}
                        </span>
                        <ul class="flex flex-col items-center">
                            <li
                                v-for="(file, index) in filesInError"
                                :key="index"
                                class="col-span-1 overflow-auto"
                            >
                                <span class="text-red-500 text-sm">
                                    {{ file.file.name }}
                                </span>
                                <!-- <button
                                    @click="removeFromWrong(file)"
                                    class="themed-button transition duration-200 focus:shadow-sm focus:ring-opacity-50 text-white w-fit px-2 py-2.5 rounded text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
                                >
                                    {{ $t('remove') }}
                                </button> -->
                            </li>
                        </ul>
                    </div>
                </template>
            </drop-zone>
        </template>
    </modal>
</template>
<script>
import { useOrderStore } from '@/stores/orderStore'
import { mapActions } from 'pinia'

import Modal from '@/components/atoms/Modal.vue'
import DropZone from '@/components/elements/DropZone.vue'

export default {
    name: 'DropzoneModal',
    components: { Modal, DropZone },
    data() {
        return {
            wrongFileTypes: [],

            wait: false,
            okFilesToRemove: [],
            filesInError: [],
        }
    },
    props: {
        fileExt: { type: String, default: '.csv' },
        deliveryId: { type: Number, required: true },
        societyId: { type: Number, required: true },
        clientId: { type: Number, required: true },
    },

    methods: {
        ...mapActions(useOrderStore, ['uploadUserFiles']),
        closeModal() {
            this.wait = false
            this.$emit('close')
        },
        resetUpload(clearFiles = false) {
            if (clearFiles === true) {
                this.clearFileList = true
                this.wrongFileTypes = []
                this.filesInError = []
            } else if (this.wrongFileTypes.length === 0) {
                this.filesInError = []
            }
        },
        removeFileFromOk(file) {
            this.okFilesToRemove.splice(this.okFilesToRemove.indexOf(file))
        },
        removeFromWrong(file) {
            URL.revokeObjectURL(file.url)
            this.wrongFileTypes.splice(this.wrongFileTypes.indexOf(file))
        },
        async uploadFiles(files) {
            this.wrongFileTypes = []
            this.filesInError = []

            this.wait = true

            const res = await Promise.all(
                files.map((file) => this.uploadFile(file)),
            )
            if (res) {
                this.closeModal()
                this.$toast.success(this.$t('files_uploaded'))
            }
        },
        async uploadFile(file) {
            // NAME PATH EXTENSION
            // SI AJOUT IDCONTACTCLIENTHISTORY
            // si creaation denote on ajoute à noteformfiles
            // si non on charge directemenbt le fichier via upload direct
            // on récupère l'extension du filchier qui se trouve après le point
            const extension = file.file.name.split('.').pop()
            const response = false
            const blob = await fetch(file.url).then((r) => r.blob())
            URL.revokeObjectURL(file.url)
            const reader = new FileReader()
            reader.readAsBinaryString(blob)
            reader.onloadend = async () => {
                const fileToUpload = {
                    FileContent: btoa(reader.result), // btoa(encodeURI(reader.result).replace('20%', ' ')),
                    Name: file.file.name,
                    Extension: extension,
                    IdClient: this.clientId,
                    IdSociety: this.societyId,
                    IdDelivery: this.deliveryId,
                }

                this.uploadUserFiles(fileToUpload)
                    .then((res) => {
                        if (response && response.status === 201) {
                            file.status = true
                            setTimeout(() => {
                                this.okFilesToRemove.push(file)
                                this.resetUpload()
                                this.$emit('newFileUploaded', response.data.Id)
                                // this.noteForm.Files.push(fileToUpload)
                            }, 2000)
                        }
                    })
                    .catch((err) => {
                        this.filesInError.push(file)
                        file.status = false
                    })
            }

            return response
        },
    },
}
</script>
