<!-- eslint-disable no-void -->
<template>
    <div
        id="create-B2C-order"
        class="shadow rounded-md xs:p-0 mx-auto md:w-full h-fit p-2"
        :class="processing === true ? 'cursor-progress' : ''"
    >
        <div class="px-5">
            <div>
                <div class="mb-2">
                    <alert-info
                        v-if="success !== null"
                        class="mt-2"
                        :class="
                            success === true
                                ? 'bg-green-100 text-green-700'
                                : 'bg-red-100 text-red-700'
                        "
                        :color="success === true ? 'green' : 'red'"
                        :canClose="true"
                        @close="success = null"
                    >
                        <template #body>
                            <div v-if="success === true" class="text-green-700">
                                <div>
                                    {{ $t('payment_success') }}
                                </div>
                                <div>{{ $t('payment_success_2') }}</div>
                            </div>
                            <div v-else class="text-red-700">
                                <div>
                                    {{ $t('payment_failed') }}
                                </div>
                                <div>{{ $t('payment_failed_2') }}</div>
                            </div>
                            <!-- <p
                                :class="'text-' + color + '-700'"
                                class="text-base"
                            >
                                {{ $t(text) }}
                            </p> -->
                        </template>
                    </alert-info>
                    <span
                        class="text-3xl flex justify-between items-start mb-5 border-b-2 themed-border"
                    >
                        <span>
                            <!-- <span class="mr-2 mt-5">
                                {{
                                    $t(
                                        $route.params.id
                                            ? 'update_order_B2C'
                                            : 'new_order_B2C'
                                    )
                                }}
                            </span> -->
                            <span
                                v-if="
                                    $route.params.id &&
                                    currentDelivery &&
                                    editDeliveryName === false &&
                                    currentDelivery.name
                                "
                                class="items-center inline-flex custom-link mt-5 mb-1"
                            >
                                {{ currentDelivery.name }}
                                <icon-button
                                    :title="$t('edit')"
                                    class="text-zinc-600"
                                    :icon="'fa-pen'"
                                    @click="makeNameEditable()"
                                ></icon-button>
                            </span>
                            <span
                                v-else-if="
                                    $route.params.id &&
                                    currentDelivery &&
                                    (editDeliveryName === true ||
                                        !currentDelivery.name)
                                "
                                class="items-center inline-flex custom-link mt-5 mb-1"
                            >
                                -
                                <input
                                    type="name"
                                    :placeholder="$t('delivery_name')"
                                    v-model="newDeliveryName"
                                    class="border-2 rounded px-3 w-full"
                                />
                                <icon-button
                                    :icon="'floppy-disk'"
                                    :title="$t('save')"
                                    class="text-zinc-600"
                                    @click="saveDeliveryName()"
                                ></icon-button>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.newDeliveryName.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </span>
                        </span>

                        <div
                            v-if="
                                webPaymentAllowed === true &&
                                hasUnpaidOrders &&
                                currentDelivery &&
                                (currentDelivery.checkout === null ||
                                    (currentDelivery.checkout &&
                                        (currentDelivery.checkout.Status ===
                                            'waiting' ||
                                            currentDelivery.checkout.Status ===
                                                'full_refund')))
                            "
                            class="flex flex-row flex-wrap items-center p-3 antialiased"
                        >
                            <button
                                type="button"
                                :disabled="adding"
                                @click="payUnpaidOrders(currentDelivery)"
                                class="stripe-bg-color text-base ml-2 focus:outline-none flex items-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                            >
                                <span>
                                    {{ `${$t('pay')} ${globalPrice} €` }}
                                    <fa-icon
                                        :title="$t('pay')"
                                        :icon="['fab', 'stripe']"
                                        class="ml-2"
                                    ></fa-icon>
                                </span>
                            </button>
                        </div>
                        <!-- !hasUnpaidOrders && -->
                        <div
                            v-else-if="
                                currentDelivery &&
                                currentDelivery.checkout !== null &&
                                currentDelivery.checkout.Status !== 'waiting' &&
                                currentDelivery.checkout.Status !==
                                    'full_refund' &&
                                currentDelivery.checkout.OrderClients.length > 0
                            "
                            class="flex flex-row flex-wrap items-center p-3 antialiased"
                        >
                            <button
                                type="button"
                                :disabled="adding"
                                @click="
                                    showRefundModal(
                                        null,
                                        currentDelivery.checkout.Id,
                                    )
                                "
                                class="themed-button text-base ml-2 focus:outline-none flex items-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                            >
                                <span>
                                    <fa-icon
                                        :title="$t('refund')"
                                        icon="hand-holding-dollar"
                                        class="mr-2"
                                    ></fa-icon>
                                    {{ $t('refund') }}
                                </span>
                            </button>
                        </div>

                        <!-- saveDeliveryName() -->
                    </span>
                </div>
                <div class="grid grid-cols-3 gap-2" :key="refresh">
                    <div
                        v-for="(card, index) in cards"
                        :key="index"
                        class="col-span-1"
                    >
                        <span class="flex-row flex-wrap">
                            <div class="w-full">
                                <!-- Profile Card -->
                                <div
                                    class="rounded card-dark shadow-lg flex flex-row flex-wrap px-3 pt-3 antialiased"
                                >
                                    <span class="text-xs mt-1">
                                        <span
                                            :id="`sub-icon-recurrent`"
                                            :title="$t('recurrent')"
                                            class="fa-fw"
                                            v-if="card.recurrent"
                                        >
                                            <fa-layer>
                                                <fa-icon
                                                    :icon="'cart-shopping'"
                                                ></fa-icon>

                                                <fa-icon
                                                    style="font-weight: 200"
                                                    transform="shrink-6 up-10 right-14"
                                                    :icon="'repeat'"
                                                ></fa-icon>
                                                <!-- transform="shrink-6" -->
                                            </fa-layer>
                                            <span class="ml-2">
                                                {{ $t('recurrent') }}
                                            </span>
                                        </span>
                                    </span>
                                    <div
                                        class="grid grid-cols-6 w-full px-2 items-center"
                                    >
                                        <div class="col-span-2">
                                            <img
                                                alt="package"
                                                class="w-auto sm"
                                                :src="`/img/picto_livrez_64.png`"
                                            />
                                        </div>
                                        <div
                                            class="col-span-3 justify-start flex"
                                        >
                                            <span
                                                class="justify-start whitespace-nowrap"
                                            >
                                                <span
                                                    class="font-semibold text-base"
                                                >
                                                    <!-- :class="card.status.color" -->
                                                    <fa-icon
                                                        :title="
                                                            card.status
                                                                .status ===
                                                            'waitingforpayment'
                                                                ? this.$t(
                                                                      'pay_pack_only',
                                                                  )
                                                                : ''
                                                        "
                                                        :icon="card.status.icon"
                                                        :class="
                                                            card.status.color
                                                        "
                                                        class="fa-lg mr-2"
                                                    ></fa-icon>
                                                    {{
                                                        `${card.status.status}`
                                                    }}
                                                    <fa-icon
                                                        v-if="card.isInvoiced"
                                                        icon="file-invoice-dollar"
                                                        class="hover:font-semibold text-gray-400 fa-lg mx-2"
                                                        @mouseenter="
                                                            showTooltip(
                                                                'tooltip-right-invoiced' +
                                                                    card.id,
                                                                true,
                                                            )
                                                        "
                                                        @mouseleave="
                                                            showTooltip(
                                                                'tooltip-right-invoiced' +
                                                                    card.id,
                                                                false,
                                                            )
                                                        "
                                                    ></fa-icon>
                                                    <span
                                                        :id="
                                                            'tooltip-right-invoiced' +
                                                            card.id
                                                        "
                                                        role="tooltip"
                                                        class="absolute invisible w-32 mb-3 z-10 inline-block px-2 py-2 text-sm font-medium text-white bg-gray-900 rounded shadow-sm ml-6 tooltip dark:bg-gray-700"
                                                    >
                                                        {{
                                                            $t(
                                                                'invoice_available',
                                                            )
                                                        }}
                                                    </span>
                                                    <span
                                                        v-if="
                                                            (card.trackings
                                                                .length > 0 ||
                                                                card.pictures
                                                                    .length >
                                                                    0 ||
                                                                card.signatures
                                                                    .length >
                                                                    0) &&
                                                            card.status
                                                                .status !==
                                                                'waitingforpayment'
                                                        "
                                                    >
                                                        <button
                                                            @click="
                                                                showPopover(
                                                                    card.id,
                                                                )
                                                            "
                                                            class="inline-block cursor-help px-2 text-zinc font-medium text-base leading-tight uppercase rounded hover:bg-zinc-300 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-zinc-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                                                        >
                                                            <div
                                                                class="py-0.5 relative w-fit"
                                                            >
                                                                <fa-icon
                                                                    :title="
                                                                        $t(
                                                                            'trackings_and_proofs',
                                                                        )
                                                                    "
                                                                    icon="eye"
                                                                    class="fa-lg"
                                                                />
                                                            </div>
                                                        </button>
                                                        <!-- <button
                                                            @click="
                                                                $emit(
                                                                    'packageTracking',
                                                                    {
                                                                        packageRef:
                                                                            card
                                                                                .package
                                                                                .reference,
                                                                        orderClientId:
                                                                            card.orderClientId,
                                                                        deliveryId:
                                                                            currentDelivery.id,
                                                                    }
                                                                )
                                                            "
                                                            class="inline-block cursor-pointer px-2 text-zinc font-medium text-base leading-tight uppercase rounded hover:bg-zinc-300 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-zinc-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                                                        >
                                                            <span
                                                                class="py-0.5 relative w-fit text-base"
                                                            >
                                                                <fa-icon
                                                                    :title="
                                                                        $t(
                                                                            'map'
                                                                        )
                                                                    " 
                                                                    icon="map"
                                                                    class="fa-lg"
                                                                />
                                                            </span>
                                                        </button>-->
                                                    </span>

                                                    <div
                                                        class="text-xs mb-1 font-normal"
                                                        v-else-if="
                                                            card.status.real !==
                                                            'waitingforpayment'
                                                        "
                                                    >
                                                        {{
                                                            $t(
                                                                'no_tracking_data_available',
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        class="justify-start whitespace-nowrap my-1"
                                                    >
                                                        <span
                                                            :style="
                                                                methodStyle(
                                                                    card.method,
                                                                )
                                                            "
                                                        >
                                                            <fa-icon
                                                                :icon="
                                                                    card.method ===
                                                                    'express'
                                                                        ? 'rocket'
                                                                        : card.method ===
                                                                            'normal'
                                                                          ? 'gauge'
                                                                          : 'gauge-high'
                                                                "
                                                                :title="
                                                                    $t(
                                                                        card.method ===
                                                                            'express'
                                                                            ? 'express'
                                                                            : card.method ===
                                                                                'normal'
                                                                              ? 'normal'
                                                                              : 'fast',
                                                                    )
                                                                "
                                                                class="fa-md mr-3"
                                                            ></fa-icon>
                                                        </span>
                                                        <span
                                                            class="text-xs mb-1"
                                                        >
                                                            {{
                                                                `${$t(
                                                                    'distance_nokm',
                                                                )}
                                                        ${card.distance} km -
                                                        ${$t('duration')}
                                                        ${card.duration}`
                                                            }}
                                                        </span>
                                                    </div>
                                                </span>
                                                <popover
                                                    v-if="showPop === card.id"
                                                    :item="setPopoverData(card)"
                                                    :headerText="card.reference"
                                                    :id="`popover-${card.id}`"
                                                    @packageTracking="
                                                        $emit(
                                                            'packageTracking',
                                                            $event,
                                                        )
                                                    "
                                                    @closePop="
                                                        showPopover(
                                                            card.id,
                                                            true,
                                                        )
                                                    "
                                                ></popover>
                                                <span
                                                    v-for="(
                                                        gPack, indGPack
                                                    ) in card.package
                                                        .groupedPacks"
                                                    :key="indGPack"
                                                >
                                                    <div
                                                        class="text-sm mb-1 font-semibold items-center flex"
                                                    >
                                                        <fa-layer
                                                            :title="
                                                                $t(
                                                                    'no_temp_specified',
                                                                )
                                                            "
                                                            v-if="
                                                                gPack
                                                                    .temperature
                                                                    .noTemp
                                                            "
                                                            class="fa-2xl mr-2"
                                                        >
                                                            <fa-icon
                                                                icon="ban"
                                                                style="
                                                                    color: grey;
                                                                "
                                                            ></fa-icon>
                                                            <fa-icon
                                                                :icon="
                                                                    gPack
                                                                        .temperature
                                                                        .icon
                                                                "
                                                                transform="shrink-6"
                                                            ></fa-icon>
                                                        </fa-layer>
                                                        <span
                                                            v-else
                                                            class="mr-2"
                                                            :style="`${gPack.temperature.color}`"
                                                        >
                                                            <fa-icon
                                                                :title="
                                                                    $t(
                                                                        gPack
                                                                            .temperature
                                                                            .title,
                                                                    )
                                                                "
                                                                :icon="
                                                                    gPack
                                                                        .temperature
                                                                        .icon
                                                                "
                                                                class="fa-xl"
                                                            ></fa-icon>
                                                        </span>
                                                        <span
                                                            v-if="
                                                                gPack.container.includes(
                                                                    '_',
                                                                ) ||
                                                                gPack.container ===
                                                                    'pallet' ||
                                                                gPack.container ===
                                                                    'envelope'
                                                            "
                                                            class="whitespace-nowrap"
                                                        >
                                                            {{
                                                                `${+gPack.quantity} ${$t(
                                                                    gPack.container,
                                                                    +gPack.quantity ===
                                                                        1
                                                                        ? 1
                                                                        : 2,
                                                                )}`
                                                            }}
                                                        </span>
                                                        <span
                                                            v-else
                                                            class="whitespace-nowrap"
                                                        >
                                                            {{
                                                                `${gPack.quantity} ${gPack.container}`
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div class="text-xs">
                                                        {{
                                                            `${gPack.size}  - 
                                                        
                                                        ${
                                                            gPack.weight
                                                        } kg - ${$t(
                                                            'value',
                                                        )}: ${gPack.worth}`
                                                        }}
                                                    </div>
                                                </span>
                                                <span
                                                    class="items-center text-sm flex justify-center mt-1"
                                                    v-if="card.comments"
                                                >
                                                    {{
                                                        `${$t('comments')} :  ${
                                                            card.comments
                                                        }`
                                                    }}
                                                </span>
                                                <span
                                                    class="items-center text-sm flex justify-center mt-1"
                                                >
                                                    {{
                                                        `${$t(
                                                            'demo_price',
                                                        )} :  ${
                                                            card.price
                                                        } ${$t(
                                                            'demo_price_info',
                                                        )}`
                                                    }}
                                                </span>
                                                <span
                                                    :id="`sub-icon-round`"
                                                    :title="$t('round_trip')"
                                                    class="fa-fw theme-color text-base font-semibold"
                                                    v-if="card.roundTrip"
                                                >
                                                    <fa-layer class="mt-2 mr-2">
                                                        <fa-icon
                                                            :icon="'route'"
                                                        ></fa-icon>

                                                        <fa-icon
                                                            style="
                                                                font-weight: 200;
                                                            "
                                                            transform="shrink-6 up-10 right-14"
                                                            :icon="'rotate'"
                                                        ></fa-icon>
                                                        <!-- transform="shrink-6" -->
                                                    </fa-layer>
                                                    <span class="ml-2">
                                                        {{ $t('round_trip') }}
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                        <div
                                            class="col-span-1 justify-end flex"
                                        >
                                            <dropdown-menu
                                                ref="packageActionDD"
                                                class="inline-flex"
                                                :position="''"
                                                :items="packageActions(card)"
                                                @picked="
                                                    packageAction($event, card)
                                                "
                                            >
                                                <template #buttonContent>
                                                    <fa-icon
                                                        :title="$t('action')"
                                                        icon="ellipsis-vertical"
                                                        class="fa-lg"
                                                    />
                                                </template>
                                            </dropdown-menu>
                                        </div>
                                    </div>
                                    <div
                                        class="w-full bg-contain bg-center bg-no-repeat h-60"
                                        style="
                                            background-image: url('/img/ecran3_map_2.png');
                                        "
                                    >
                                        <div
                                            class="grid grid-cols-6 gap-2 w-full"
                                        >
                                            <div
                                                class="col-span-2 px-2 pt-4 darky justify-start"
                                            >
                                                <div class="bg-white">
                                                    <span
                                                        class="text-sm mt-4 italic"
                                                    >
                                                        <fa-icon
                                                            icon="house"
                                                            class="fa-sm"
                                                        ></fa-icon>
                                                        <fa-icon
                                                            v-if="
                                                                card.roundTrip
                                                            "
                                                            icon="flag-checkered"
                                                            class="fa-sm ml-1"
                                                        ></fa-icon>
                                                        {{ card.pick_up.date }}
                                                    </span>
                                                    <div class="text-xs">
                                                        {{
                                                            card.pick_up.moment
                                                        }}
                                                    </div>
                                                    <div class="text-sm">
                                                        {{
                                                            card.pick_up.address
                                                        }}
                                                    </div>
                                                    <div class="text-sm">
                                                        {{
                                                            card.pick_up.zipCity
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- </div>
                                        <div
                                            class="grid grid-cols-6 pl-10 gap-2 w-full"
                                        > -->
                                            <div
                                                class="col-start-5 pt-20 ml-3 col-span-2 darky px-1"
                                            >
                                                <div class="bg-white">
                                                    <span
                                                        class="text-sm italic"
                                                    >
                                                        <fa-icon
                                                            :icon="
                                                                card.roundTrip
                                                                    ? 'rotate'
                                                                    : 'flag-checkered'
                                                            "
                                                            class="fa-sm"
                                                        ></fa-icon>
                                                        {{ card.delivery.date }}
                                                    </span>
                                                    <div class="text-xs">
                                                        {{
                                                            card.delivery.moment
                                                        }}
                                                    </div>
                                                    <div class="text-sm">
                                                        {{
                                                            card.delivery
                                                                .address
                                                        }}
                                                    </div>
                                                    <div class="text-sm">
                                                        {{
                                                            card.delivery
                                                                .zipCity
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- End Profile Card -->
                            </div>
                        </span>
                    </div>
                    <div
                        v-if="
                            $route.name === 'new_order' ||
                            (currentDelivery &&
                                currentDelivery.checkout &&
                                (currentDelivery.checkout === null ||
                                    currentDelivery.checkout.status ===
                                        'full_refund')) ||
                            (currentDelivery && !currentDelivery.checkout)
                        "
                        class="col-span-1"
                    >
                        <span class="flex flex-row flex-wrap p-3">
                            <div
                                class="w-full justify-center flex"
                                :class="
                                    cards.length === 0 ? 'pb-3' : 'pt-32 pb-32'
                                "
                            >
                                <div
                                    class="flex flex-row flex-wrap p-3 antialiased"
                                >
                                    <button
                                        type="button"
                                        :disabled="adding"
                                        @click="showAddModal()"
                                        class="themed-button text-base ml-2 focus:outline-none flex items-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                    >
                                        <span>
                                            <fa-icon
                                                icon="plus"
                                                class="mr-2"
                                            ></fa-icon>

                                            {{ $t('add_package') }}
                                        </span>
                                    </button>
                                </div>
                            </div></span
                        >
                    </div>
                </div>
            </div>
            <modal
                v-if="show"
                :headerText="isEditPackage ? 'update_package' : 'add_package'"
                :buttonText="isEditPackage ? 'update' : 'save'"
                :secondSubmit="true"
                :secondSubmitText="
                    isEditPackage ? 'update_and_copy' : 'save_and_copy'
                "
                :spin="creating"
                :creating="
                    creating ||
                    stepDatePickup > stepDateDeliv ||
                    stepDatePickup < date.toISOString().substr(0, 10)
                "
                id="addModal"
                :mxWidth="'max-w-8xl'"
                :mxHeight="'max-h-screen'"
                :buttonAlign="'justify-center'"
                @closeModal="closeModal()"
                @sendFromModal="submitModal($event)"
            >
                <template #body>
                    <span v-if="isCopying === true" class="py-40">
                        <fa-icon
                            icon="spinner"
                            class="fa-2xl py-40"
                            spin-pulse
                        ></fa-icon>
                    </span>

                    <div v-else>
                        <div class="flex items-center justify-between px-6">
                            <div>
                                <div
                                    v-if="$route.name === 'new_order'"
                                    class="text-sm italic custom-link items-center pt-2 my-1 flex justify-center"
                                >
                                    <fa-icon
                                        icon="fa-lightbulb"
                                        class="mr-2"
                                    ></fa-icon>
                                    {{ $t('delivery_name_info') }}
                                </div>
                                <div
                                    v-if="$route.name === 'new_order'"
                                    class="items-center pl-6 mb-1 flex justify-center"
                                >
                                    <label
                                        class="font-semibold custom-link text-2xl text-gray-600 pb-1 mr-2"
                                        >{{ $t('delivery_name') + ' *' }}</label
                                    >
                                    <input
                                        type="name"
                                        v-model="newDeliveryName"
                                        class="border rounded py-2 px-2 w-[400px]"
                                    />
                                    <div
                                        class="text-xs italic mt-1 ml-2 mb-2"
                                        v-for="error of v$.newDeliveryName
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span class="pt-2 my-1 flex items-center">
                                <label
                                    for="priceCharged"
                                    class="font-semibold text-gray-600 pb-1 mr-2"
                                    >{{ $t('price_charged') }}</label
                                >
                                <div
                                    class="flex items-center justify-center"
                                    v-if="
                                        canRefreshPrice === true ||
                                        canLiveRefreshPrice === true
                                    "
                                >
                                    <input
                                        :key="refreshPrice"
                                        id="priceCharged"
                                        type="number"
                                        step="0.01"
                                        disabled
                                        v-model="formData.Price"
                                        placeholder="0 €"
                                        autocomplete="number"
                                        class="border rounded px-3 py-2"
                                    />

                                    <button
                                        type="button"
                                        @click.prevent="calculatePrice()"
                                        class="themed-button text-sm ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                    >
                                        <span v-if="creating">
                                            <fa-icon
                                                icon="spinner"
                                                spin-pulse
                                            ></fa-icon
                                        ></span>
                                        <span v-else>{{
                                            $t('update_price')
                                        }}</span>
                                    </button>
                                </div>
                            </span>
                        </div>

                        <hr v-if="$route.name === 'new_order'" />
                        <div class="grid grid-cols-6 gap-2 w-full">
                            <div class="col-span-2 px-2">
                                <div class="text-lg py-4">
                                    {{ $t('pick_up_label') }}
                                </div>
                                <hr />
                                <div class="mt-5">
                                    <div class="mt-2 rounded-md p-4 border-2">
                                        <div
                                            v-if="
                                                pickUpAddressOptions.length > 0
                                            "
                                            class="mb-5 flex"
                                        >
                                            <span class="text-lg">
                                                {{
                                                    $t(
                                                        'can_select_address_v1',
                                                    ) + ' *'
                                                }}
                                                <hr />
                                            </span>
                                        </div>
                                        <div
                                            v-if="
                                                pickUpAddressOptions.length == 0
                                            "
                                        >
                                            <div class="mb-5 flex">
                                                <span class="text-lg">
                                                    {{ $t('create_my_pickup') }}
                                                    <hr />
                                                </span>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('address_name') +
                                                        ' *'
                                                    }}</label
                                                >
                                                <input
                                                    v-model="
                                                        pickUpStep.AddressName
                                                    "
                                                    @blur="
                                                        v$.pickUpStep.AddressName.$touch()
                                                    "
                                                    autocomplete="name"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.pickUpStep
                                                    .AddressName.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('address') + ' 1 *'
                                                    }}
                                                </label>
                                                <input
                                                    v-model="
                                                        pickUpStep.Address1
                                                    "
                                                    id="autocompleteAddStep"
                                                    @change="liveRefresh()"
                                                    @blur="
                                                        v$.pickUpStep.Address1.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.pickUpStep
                                                    .Address1.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('address')
                                                    }}
                                                    2</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="
                                                        pickUpStep.Address2
                                                    "
                                                    @change="liveRefresh()"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('postal_code') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    id="postal_code"
                                                    autocomplete="postal-code"
                                                    v-model="
                                                        pickUpStep.PostalCode
                                                    "
                                                    @blur="
                                                        v$.pickUpStep.PostalCode.$touch()
                                                    "
                                                    @change="liveRefresh()"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.pickUpStep
                                                    .PostalCode.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('city') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="pickUpStep.City"
                                                    id="locality"
                                                    @blur="
                                                        v$.pickUpStep.City.$touch()
                                                    "
                                                    @change="liveRefresh()"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.pickUpStep
                                                    .City.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-else-if="
                                                pickUpAddressOptions.length > 10
                                            "
                                            class="mb-3 mt-3 items-center flex flex-row"
                                        >
                                            <Multiselect
                                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                v-model="pickUpAddressId"
                                                :options="pickUpAddressOptions"
                                                label="name"
                                                track-by="name"
                                                :searchable="true"
                                                :placeholder="
                                                    $t('can_select_address_v1')
                                                "
                                                :allow-empty="false"
                                                :can-clear="false"
                                                @select="
                                                    setPickUp(pickUpAddressId)
                                                "
                                            ></Multiselect>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-if="
                                                    pickUpAddressId !== 0 &&
                                                    (pickUpStep.Address1 ===
                                                        '' ||
                                                        pickUpStep.PostalCode ===
                                                            '' ||
                                                        pickUpStep.PostalCode ===
                                                            '00000' ||
                                                        pickUpStep.City === '')
                                                "
                                            >
                                                <div class="error-msg">
                                                    {{
                                                        $t(
                                                            'address_data_missing',
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-else
                                            class="grid-cols-2 grid gap-2"
                                        >
                                            <div
                                                class="flex col-span-1 justify-center items-center"
                                                v-for="(
                                                    pickupOption, index
                                                ) in pickUpAddressOptions"
                                                :key="index"
                                            >
                                                <button
                                                    type="button"
                                                    class="text-sm ml-2 focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                    :class="
                                                        pickUpAddressId ===
                                                        pickupOption.value
                                                            ? 'secondary-themed-button'
                                                            : 'themed-button'
                                                    "
                                                    @click="
                                                        setPickUp(
                                                            pickupOption.value,
                                                        )
                                                    "
                                                >
                                                    <fa-icon
                                                        icon="house"
                                                        class="mr-2"
                                                    ></fa-icon
                                                    >{{ pickupOption.name }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="pickUpStep" class="mt-4">
                                    <div class="mt-2 rounded-md p-4 border-2">
                                        <div class="font-semibold mb-2">
                                            {{ pickUpStep.AddressName }}
                                        </div>

                                        <div class="mb-2">
                                            {{ pickUpStep.Address1 }}
                                        </div>

                                        <div
                                            v-if="
                                                pickUpStep.Address2 !==
                                                    'null' &&
                                                pickUpStep.Address2 !== null &&
                                                pickUpStep.Address2 !== ''
                                            "
                                        >
                                            {{ pickUpStep.Address2 }}
                                        </div>
                                        <div class="mb-2">
                                            {{
                                                `${pickUpStep.PostalCode} ${pickUpStep.City}`
                                            }}
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-if="
                                                pickUpAddressId !== 0 &&
                                                (pickUpStep.Address1 === '' ||
                                                    pickUpStep.PostalCode ===
                                                        '' ||
                                                    pickUpStep.PostalCode ===
                                                        '00000' ||
                                                    pickUpStep.City === '')
                                            "
                                        >
                                            <div class="error-msg">
                                                {{ $t('address_data_missing') }}
                                            </div>
                                        </div>

                                        <div
                                            class="mb-3 mt-3 items-center flex flex-row"
                                        >
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                >{{ $t('on') + ' *' }}</label
                                            >
                                            <input
                                                type="date"
                                                :max="
                                                    stepDateDeliv !== ''
                                                        ? stepDateDeliv
                                                        : ''
                                                "
                                                :min="
                                                    date
                                                        .toISOString()
                                                        .substr(0, 10)
                                                "
                                                v-model="stepDatePickup"
                                                @change="liveRefresh()"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.pickUpStep
                                                .stepDate.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                stepDatePickup <
                                                date.toISOString().substr(0, 10)
                                            "
                                            class="text-xs italic mt-1 mb-3"
                                        >
                                            <div class="error-msg">
                                                {{ $t('past_date') }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                stepDateDeliv !== '' &&
                                                stepDatePickup > stepDateDeliv
                                            "
                                            class="text-xs italic mt-1 mb-3"
                                        >
                                            <div class="error-msg">
                                                {{ $t('date_error_after', 2) }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                pickUpStep.Hours &&
                                                openingHoursForDay(
                                                    pickUpStep.Hours,
                                                    findStepDay(stepDatePickup),
                                                ).length > 0
                                            "
                                            class="mb-3"
                                        >
                                            <div
                                                class="mb-1 justify-center flex"
                                            >
                                                {{
                                                    `${$t(
                                                        'opening_hours',
                                                    )} ${$t('for')} ${$t(
                                                        days[
                                                            findStepDay(
                                                                stepDatePickup,
                                                            )
                                                        ],
                                                    )} ${stepDatePickup} `
                                                }}
                                            </div>
                                            <div
                                                v-for="(
                                                    hour, index
                                                ) in openingHoursForDay(
                                                    pickUpStep.Hours,
                                                    findStepDay(stepDatePickup),
                                                )"
                                                :key="index"
                                                class="justify-center flex"
                                            >
                                                {{
                                                    `${hour.start} - ${hour.end}`
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="grid grid-cols-3 gap-1 pl-4 mb-3"
                                        >
                                            <div
                                                class="col-span-1 flex items-center"
                                            >
                                                <span class="mr-2 text-lg">
                                                    *
                                                </span>
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="pickupMoment"
                                                    :options="stepMomentOptions"
                                                    label="name"
                                                    track-by="name"
                                                    :allow-empty="false"
                                                    :can-clear="false"
                                                ></Multiselect>
                                            </div>
                                            <div
                                                v-if="
                                                    pickupMoment ===
                                                        'between' ||
                                                    pickupMoment === 'from'
                                                "
                                                :class="
                                                    pickupMoment === 'between'
                                                        ? 'col-span-1'
                                                        : 'col-span-2'
                                                "
                                            >
                                                <input
                                                    type="time"
                                                    v-model="fromTimePickup"
                                                    @input="resetBadTimes()"
                                                    class="border text-sm rounded px-3 py-2 w-full"
                                                />
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$
                                                        .pickUpStep.fromTime
                                                        .$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    pickupMoment ===
                                                        'between' ||
                                                    pickupMoment === 'before'
                                                "
                                                :class="
                                                    pickupMoment === 'between'
                                                        ? 'col-span-1'
                                                        : 'col-span-2'
                                                "
                                            >
                                                <input
                                                    type="time"
                                                    v-model="beforeTimePickup"
                                                    @input="resetBadTimes()"
                                                    class="border text-sm rounded px-3 py-2 w-full"
                                                />
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$
                                                        .pickUpStep.beforeTime
                                                        .$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-if="badPickupTimeRange"
                                        >
                                            <div class="error-msg">
                                                {{ $t('bad_time_range') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-2 px-2">
                                <div class="text-lg py-4">
                                    {{ $t('package_info') }}
                                </div>
                                <hr />
                                <div class="mt-2 rounded-md p-4 border-2 mt-5">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('delivery_mode') + ' *' }}
                                            <hr />
                                        </span>
                                    </div>
                                    <Multiselect
                                        class="darky"
                                        v-model="selectedMethod"
                                        :options="methodOptions"
                                        :key="refreshMS"
                                        label="name"
                                        track-by="name"
                                        :searchable="false"
                                        :can-clear="false"
                                        :can-deselect="false"
                                        :allow-empty="false"
                                        :object="true"
                                    >
                                        <template #singlelabel="{ value }">
                                            <div
                                                class="multiselect-single-label"
                                            >
                                                <div
                                                    class="select-label-icon-wrap"
                                                    :style="value.style"
                                                >
                                                    <fa-icon
                                                        :title="value.name"
                                                        :icon="value.icon"
                                                        class="fa-sm"
                                                    ></fa-icon>
                                                    <span class="ml-1">
                                                        {{ $t(value.name) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                        <template #option="{ option }">
                                            <div
                                                class="select-option-icon-wrap"
                                                :style="option.style"
                                            >
                                                <fa-icon
                                                    :title="option.name"
                                                    :icon="option.icon"
                                                    class="fa-sm"
                                                ></fa-icon
                                                ><span class="ml-1">
                                                    {{ $t(option.name) }}
                                                </span>
                                            </div>
                                        </template></Multiselect
                                    >
                                    <div class="my-5 flex">
                                        <span class="text-lg">
                                            {{ $t('vehicle_type') + ' *' }}
                                            <hr />
                                        </span>
                                    </div>
                                    <Multiselect
                                        id="vehicleTypeMS"
                                        class="darky"
                                        v-model="selectedVehicleType"
                                        :options="vehicleTypeOptions"
                                        :key="refreshMS"
                                        label="name"
                                        track-by="name"
                                        :searchable="false"
                                        :can-clear="false"
                                        :can-deselect="false"
                                        :allow-empty="false"
                                        :object="true"
                                    >
                                    </Multiselect>
                                </div>

                                <div class="mt-5 rounded-md p-4 border-2">
                                    <div>
                                        <div class="mb-5 flex">
                                            <span class="text-lg">
                                                {{
                                                    containerOptions.length ===
                                                    0
                                                        ? $t(
                                                              'create_my_container',
                                                          )
                                                        : $t(
                                                              'select_package_format',
                                                          ) + ' *'
                                                }}
                                                <hr />
                                            </span>
                                        </div>
                                        <div
                                            v-if="containerOptions.length === 0"
                                            class="flex justify-center"
                                        >
                                            <button
                                                @click.stop="
                                                    setContainerCreation(
                                                        formData.OrderPackages
                                                            .length - 1,
                                                    )
                                                "
                                                class="themed-button items-center my-3 text-xs mr-2 focus:outline-none flex justify-center px-4 py-2 rounded cursor-pointer text-white duration-200 ease-in-out transition"
                                            >
                                                <!-- class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-xs rounded text-gray-500" -->
                                                <span
                                                    class="inline-block ml-1 font-bold"
                                                    >{{
                                                        $t(
                                                            'create_my_container',
                                                        )
                                                    }}</span
                                                >
                                            </button>
                                        </div>
                                        <span v-else>
                                            <span
                                                v-for="(
                                                    oPack, packIndex
                                                ) in formData.OrderPackages"
                                                :key="packIndex"
                                            >
                                                <package-form
                                                    :packToEdit="oPack"
                                                    :packIndex="packIndex"
                                                    :idClient="
                                                        +formData.IdClient
                                                    "
                                                    :containerOptions="
                                                        containerOptions
                                                    "
                                                    :packsLength="
                                                        formData.OrderPackages
                                                            .length
                                                    "
                                                    :isDuplicate="duplication"
                                                    :isUpdate="
                                                        $route.params.id
                                                            ? true
                                                            : false
                                                    "
                                                    :globalRefresh="refreshMS"
                                                    :idContDefault="
                                                        +idContDefault
                                                    "
                                                    @setPack="setPack($event)"
                                                    @addPackage="
                                                        addPackage(false)
                                                    "
                                                    @removePackage="
                                                        removePackage($event)
                                                    "
                                                    @newContainer="
                                                        setContainerCreation(
                                                            $event,
                                                        )
                                                    "
                                                ></package-form>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="mt-2 rounded-md p-4 border-2">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('comments') }}
                                            <hr />
                                        </span>
                                    </div>

                                    <textarea
                                        type="textarea"
                                        v-model="formData.CommentClient"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                            </div>

                            <div class="col-span-2 px-2">
                                <div class="text-lg py-4">
                                    {{ $t('delivery_label') }}
                                </div>
                                <hr />
                                <div class="rounded-md p-4 border-2 mt-5">
                                    <div class="px-3" :key="refreshMs">
                                        <span
                                            v-if="
                                                addressOptions.length > 16 &&
                                                addressCreation === false
                                            "
                                        >
                                            <div class="mb-5 flex">
                                                <span class="text-lg">
                                                    {{
                                                        $t(
                                                            'can_select_address_v1',
                                                        ) + ' *'
                                                    }}
                                                    <hr />
                                                </span>
                                            </div>
                                            <div class="mb-3 items-center">
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="deliveryAddressId"
                                                    :options="addressOptions"
                                                    label="name"
                                                    track-by="name"
                                                    :searchable="true"
                                                    :placeholder="
                                                        $t(
                                                            'can_select_address_v1',
                                                        )
                                                    "
                                                    :allow-empty="false"
                                                    :can-clear="false"
                                                    @select="
                                                        setAddressInfo(
                                                            deliveryAddressId,
                                                        )
                                                    "
                                                ></Multiselect>
                                                <div
                                                    v-if="
                                                        deliveryStep.AddressName
                                                    "
                                                    class="text-sm font-semibold whitespace-nowrap mt-2 mb-2"
                                                >
                                                    <span class="mb-2">
                                                        {{
                                                            deliveryStep.Address1
                                                        }}
                                                    </span>

                                                    <span
                                                        v-if="
                                                            deliveryStep.Address2 !==
                                                                'null' &&
                                                            deliveryStep.Address2 !==
                                                                null &&
                                                            deliveryStep.Address2 !==
                                                                ''
                                                        "
                                                    >
                                                        {{
                                                            deliveryStep.Address2
                                                        }}
                                                    </span>
                                                    <span class="ml-2">
                                                        {{
                                                            `${deliveryStep.PostalCode} ${deliveryStep.City}`
                                                        }}
                                                    </span>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .deliveryStep
                                                            .PostalCode.$errors"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-if="
                                                            deliveryAddressId !==
                                                                0 &&
                                                            (deliveryStep.Address1 ===
                                                                '' ||
                                                                deliveryStep.PostalCode ===
                                                                    '' ||
                                                                deliveryStep.PostalCode ===
                                                                    '00000' ||
                                                                deliveryStep.City ===
                                                                    '')
                                                        "
                                                    >
                                                        <div class="error-msg">
                                                            {{
                                                                $t(
                                                                    'address_data_missing',
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <span v-else>
                                                    <div
                                                        class="italic mb-2 mt-2"
                                                    >
                                                        {{
                                                            $t(
                                                                'no_address_selected',
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .deliveryStep
                                                            .AddressName
                                                            .$errors"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </span>
                                        <div
                                            v-else-if="addressCreation === true"
                                        >
                                            <div class="mb-5 flex">
                                                <span class="text-lg">
                                                    {{
                                                        $t('create_my_address')
                                                    }}
                                                    <hr />
                                                </span>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('address_name') +
                                                        ' *'
                                                    }}</label
                                                >
                                                <input
                                                    v-model="
                                                        deliveryStep.AddressName
                                                    "
                                                    @blur="
                                                        v$.deliveryStep.AddressName.$touch()
                                                    "
                                                    autocomplete="name"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.deliveryStep
                                                    .AddressName.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('address') + ' 1 *'
                                                    }}
                                                </label>
                                                <input
                                                    v-model="
                                                        deliveryStep.Address1
                                                    "
                                                    id="autocompleteAddStep"
                                                    @change="liveRefresh()"
                                                    @blur="
                                                        v$.deliveryStep.Address1.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.deliveryStep
                                                    .Address1.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('address')
                                                    }}
                                                    2</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="
                                                        deliveryStep.Address2
                                                    "
                                                    @change="liveRefresh()"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('postal_code') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    id="postal_code"
                                                    autocomplete="postal-code"
                                                    v-model="
                                                        deliveryStep.PostalCode
                                                    "
                                                    @blur="
                                                        v$.deliveryStep.PostalCode.$touch()
                                                    "
                                                    @change="liveRefresh()"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.deliveryStep
                                                    .PostalCode.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('city') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="deliveryStep.City"
                                                    id="locality"
                                                    @blur="
                                                        v$.deliveryStep.City.$touch()
                                                    "
                                                    @change="liveRefresh()"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.deliveryStep
                                                    .City.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else :key="refreshMS">
                                            <div class="mb-5 flex">
                                                <span class="text-lg">
                                                    {{
                                                        $t(
                                                            'can_select_address_v1',
                                                        ) + ' *'
                                                    }}
                                                    <hr />
                                                </span>
                                            </div>
                                            <div
                                                class="grid-cols-2 grid gap-2 mb-2"
                                                :key="refreshMS"
                                            >
                                                <div
                                                    class="flex col-span-1 items-center"
                                                    v-for="(
                                                        deliveryOption, index
                                                    ) in addressOptions"
                                                    :key="index"
                                                >
                                                    <button
                                                        type="button"
                                                        class="text-sm ml-2 focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                        :class="
                                                            deliveryAddressId ===
                                                            deliveryOption.value
                                                                ? 'secondary-themed-button'
                                                                : 'themed-button'
                                                        "
                                                        @click="
                                                            setAddressInfo(
                                                                deliveryOption.value,
                                                            )
                                                        "
                                                    >
                                                        <fa-icon
                                                            icon="flag-checkered"
                                                            class="mr-2"
                                                        ></fa-icon
                                                        >{{
                                                            deliveryOption.name
                                                        }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="mb-3 items-center flex flex-row"
                                        >
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                >{{ $t('on') + ' *' }}</label
                                            >
                                            <input
                                                type="date"
                                                :min="stepDatePickup"
                                                v-model="stepDateDeliv"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                @change="liveRefresh()"
                                            />
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.deliveryStep
                                                .stepDate.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                stepDateDeliv !== '' &&
                                                stepDateDeliv <
                                                    date
                                                        .toISOString()
                                                        .substr(0, 10)
                                            "
                                            class="text-xs italic mt-1 mb-3"
                                        >
                                            <div class="error-msg">
                                                {{ $t('past_date') }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                stepDateDeliv !== '' &&
                                                stepDateDeliv < stepDatePickup
                                            "
                                            class="text-xs italic mt-1 mb-3"
                                        >
                                            <div class="error-msg">
                                                {{ $t('date_error_before', 2) }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                deliveryStep.Hours &&
                                                openingHoursForDay(
                                                    deliveryStep.Hours,
                                                    findStepDay(stepDateDeliv),
                                                ).length > 0
                                            "
                                            class="mb-3"
                                        >
                                            <div
                                                class="mb-1 justify-center flex"
                                            >
                                                {{
                                                    `${$t(
                                                        'opening_hours',
                                                    )} ${$t('for')} ${$t(
                                                        days[
                                                            findStepDay(
                                                                stepDateDeliv,
                                                            )
                                                        ],
                                                    )} ${stepDateDeliv} `
                                                }}
                                            </div>
                                            <div
                                                v-for="(
                                                    hour, index
                                                ) in openingHoursForDay(
                                                    deliveryStep.Hours,
                                                    findStepDay(stepDateDeliv),
                                                )"
                                                :key="index"
                                                class="justify-center flex"
                                            >
                                                {{
                                                    `${hour.start} - ${hour.end}`
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="grid grid-cols-3 gap-1 pl-4 mb-3"
                                        >
                                            <div
                                                class="col-span-1 flex items-center"
                                            >
                                                <span class="mr-2 text-lg">
                                                    *
                                                </span>
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="deliveryMoment"
                                                    :options="stepMomentOptions"
                                                    label="name"
                                                    track-by="name"
                                                    :allow-empty="false"
                                                    :can-clear="false"
                                                ></Multiselect>
                                            </div>
                                            <div
                                                v-if="
                                                    deliveryMoment ===
                                                        'between' ||
                                                    deliveryMoment === 'from'
                                                "
                                                :class="
                                                    deliveryMoment === 'between'
                                                        ? 'col-span-1'
                                                        : 'col-span-2'
                                                "
                                            >
                                                <input
                                                    type="time"
                                                    v-model="fromTimeDeliv"
                                                    @input="resetBadTimes()"
                                                    class="border text-sm rounded px-3 py-2 w-full"
                                                />
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$
                                                        .deliveryStep.fromTime
                                                        .$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    deliveryMoment ===
                                                        'between' ||
                                                    deliveryMoment === 'before'
                                                "
                                                :class="
                                                    deliveryMoment === 'between'
                                                        ? 'col-span-1'
                                                        : 'col-span-2'
                                                "
                                            >
                                                <input
                                                    type="time"
                                                    v-model="beforeTimeDeliv"
                                                    @input="resetBadTimes()"
                                                    class="border text-sm rounded px-3 py-2 w-full"
                                                />
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$
                                                        .deliveryStep.beforeTime
                                                        .$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-if="badTimeRange"
                                        >
                                            <div class="error-msg">
                                                {{ $t('bad_time_range') }}
                                            </div>
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-if="badTimeRangeSteps"
                                        >
                                            <div class="error-msg">
                                                {{
                                                    `${$t(
                                                        'bad_time_range_steps',
                                                    )} : ${previousPickUpTimes(
                                                        1,
                                                    )}`
                                                }}
                                            </div>
                                        </div>
                                        <button
                                            v-if="addressCreation === true"
                                            @click="addressCreation = false"
                                            class="transition duration-200 mx-5 mt-5 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                        >
                                            <span
                                                class="inline-block ml-1 font-bold"
                                                >{{
                                                    $t(
                                                        'back_to_predefined_addresses',
                                                    )
                                                }}</span
                                            >
                                        </button>
                                        <button
                                            v-else
                                            @click="createNewAddress()"
                                            class="transition duration-200 mx-5 mt-5 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                        >
                                            <span
                                                class="inline-block ml-1 font-bold"
                                                >{{
                                                    $t('create_address')
                                                }}</span
                                            >
                                        </button>
                                        <div
                                            v-if="
                                                deliveryAddressId !== null &&
                                                canSetRoundTrip
                                            "
                                            class="flex items-center justify-center mt-4"
                                        >
                                            <input
                                                id="round-trip-checkbox"
                                                type="checkbox"
                                                :disabled="
                                                    roundTrip &&
                                                    pickUpStepToUpdate !== null
                                                "
                                                v-model="roundTrip"
                                                @change="
                                                    setRoundtripSteps([
                                                        deliveryStep,
                                                        pickUpStep,
                                                    ])
                                                "
                                                class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                :class="'cursor-pointer'"
                                            />
                                            <label
                                                for="round-trip-checkbox"
                                                class="ml-2 text-base font-medium theme-color"
                                                >{{ $t('round_trip') }}</label
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <div
                                        v-if="
                                            roundTripSteps.length > 0 &&
                                            roundTrip === true &&
                                            deliveryAddressId !== null
                                        "
                                        class="rounded-md p-4 border-2"
                                    >
                                        <div class="mb-5 flex">
                                            <span class="text-lg">
                                                {{ $t('round_trip_label') }}
                                                <hr />
                                            </span>
                                        </div>
                                        <div
                                            class="px-3 mb-6"
                                            v-for="(
                                                roundStep, index
                                            ) in roundTripSteps"
                                            :key="index"
                                        >
                                            <div
                                                class="flex items-center theme-color justify-between mr-4"
                                            >
                                                {{
                                                    $t(
                                                        roundStep.Type ===
                                                            'pickup'
                                                            ? 'pick_up_label'
                                                            : 'delivery_label',
                                                    )
                                                }}
                                                <hr />
                                            </div>

                                            <div class="font-semibold">
                                                {{ roundStep.AddressName }}
                                            </div>
                                            <p
                                                v-if="
                                                    roundStep.IdClientAddress !==
                                                    0
                                                "
                                                class="text-xs flex mb-2 justify-center font-normal"
                                            >
                                                {{
                                                    `${roundStep.Address1} ${
                                                        roundStep.Address2 !==
                                                            'null' &&
                                                        roundStep.Address2 !==
                                                            null &&
                                                        roundStep.Address2 !==
                                                            ''
                                                            ? roundStep.Address2
                                                            : ''
                                                    } 
                                                            ${
                                                                roundStep.PostalCode
                                                            } ${
                                                                roundStep.City
                                                            } `
                                                }}
                                            </p>
                                            <div
                                                class="mb-3 items-center justify-center flex flex-row"
                                            >
                                                {{
                                                    new Date(
                                                        roundStep.stepDate,
                                                    ).toLocaleDateString(
                                                        $i18n.locale,
                                                    )
                                                }}
                                            </div>

                                            <div
                                                v-if="
                                                    roundStep.Hours &&
                                                    openingHoursForDay(
                                                        roundStep.Hours,
                                                        findStepDay(
                                                            roundStep.stepDate,
                                                        ),
                                                    ).length > 0
                                                "
                                                class="mb-3"
                                            >
                                                <div
                                                    class="mb-1 justify-center flex"
                                                >
                                                    {{
                                                        `${$t(
                                                            'opening_hours',
                                                        )} ${$t('for')} ${$t(
                                                            days[
                                                                findStepDay(
                                                                    roundStep.stepDate,
                                                                )
                                                            ],
                                                        )} ${
                                                            roundStep.stepDate
                                                        } `
                                                    }}
                                                </div>
                                                <div
                                                    v-for="(
                                                        hour, index
                                                    ) in openingHoursForDay(
                                                        roundStep.Hours,
                                                        findStepDay(
                                                            roundStep.stepDate,
                                                        ),
                                                    )"
                                                    :key="index"
                                                    class="justify-center flex"
                                                >
                                                    {{
                                                        `${hour.start} - ${hour.end}`
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                class="grid grid-cols-3 gap-1 pl-4 mb-3"
                                            >
                                                <div
                                                    class="col-span-1 flex items-center"
                                                >
                                                    <span class="mr-2 text-lg">
                                                        *
                                                    </span>
                                                    <Multiselect
                                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                        v-model="
                                                            roundStep.Moment
                                                        "
                                                        :options="
                                                            stepMomentOptions
                                                        "
                                                        label="name"
                                                        track-by="name"
                                                        :allow-empty="false"
                                                        :can-clear="false"
                                                    ></Multiselect>
                                                </div>
                                                <div
                                                    v-if="
                                                        roundStep.Moment ===
                                                            'between' ||
                                                        roundStep.Moment ===
                                                            'from'
                                                    "
                                                    :class="
                                                        roundStep.Moment ===
                                                        'between'
                                                            ? 'col-span-1'
                                                            : 'col-span-2'
                                                    "
                                                >
                                                    <input
                                                        type="time"
                                                        v-model="
                                                            roundStep.fromTime
                                                        "
                                                        @input="resetBadTimes()"
                                                        class="border text-sm rounded px-3 py-2 w-full"
                                                    />
                                                </div>
                                                <div
                                                    v-if="
                                                        roundStep.Moment ===
                                                            'between' ||
                                                        roundStep.Moment ===
                                                            'before'
                                                    "
                                                    :class="
                                                        roundStep.Moment ===
                                                        'between'
                                                            ? 'col-span-1'
                                                            : 'col-span-2'
                                                    "
                                                >
                                                    <input
                                                        type="time"
                                                        v-model="
                                                            roundStep.beforeTime
                                                        "
                                                        @input="resetBadTimes()"
                                                        class="border text-sm rounded px-3 py-2 w-full"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-if="badTimeRange"
                                            >
                                                <div class="error-msg">
                                                    {{ $t('bad_time_range') }}
                                                </div>
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-if="badTimeRangeSteps"
                                            >
                                                <div class="error-msg">
                                                    {{
                                                        `${$t(
                                                            'bad_time_range_steps',
                                                        )} : ${previousPickUpTimes(
                                                            1,
                                                        )}`
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        class="flex mt-1 italic text-xs items-center justify-center"
                    >
                        {{ $t('required_fields') }}
                    </span>
                </template>
            </modal>
            <modal
                v-if="showRefundOrderModal"
                :headerText="'refund_request_modal_header'"
                :buttonText="'close'"
                :canCancel="false"
                id="refundModal"
                :mxWidth="'w-2/5'"
                :buttonAlign="'justify-center'"
                @closeModal="showRefundOrderModal = false"
                @sendFromModal="showRefundOrderModal = false"
            >
                <template #body>
                    <div class="mx-8">
                        <div
                            class="flex text-sm items-center justify-center mt-4"
                        >
                            {{ $t('contact_support_cancel_refund') }}
                        </div>
                        <div
                            class="flex items-center text-sm justify-center mt-4"
                        >
                            {{ $t('ref_to_give') }}
                            <fa-icon
                                :icon="'hashtag'"
                                class="ml-2 fa-md"
                            ></fa-icon>
                            <strong class="ml-2 text-lg">
                                {{ orderToRefund.checkout }}
                            </strong>
                        </div>
                        <div
                            class="flex text-lg items-center font-semibold justify-center mt-4 mb-2"
                        >
                            <fa-icon
                                :icon="'phone'"
                                class="mr-2 fa-md"
                            ></fa-icon>
                            01 47 20 00 01
                        </div>
                        <hr />
                        <div
                            class="flex items-center text-sm justify-center my-2"
                        >
                            {{ $t('refund_info_text') }}
                        </div>
                        <hr class="my-2" />

                        <span
                            v-for="(toRefund, iToRefund) in orderToRefund.info"
                            :key="iToRefund"
                        >
                            <span
                                v-for="(pack, indexPack) in toRefund.packs"
                                :key="indexPack"
                            >
                                <div
                                    class="flex items-center text-sm justify-center"
                                >
                                    {{ pack.quantity }}

                                    {{ $t(pack.container) }} PRIX
                                </div>
                            </span>
                            <div
                                class="items-center text-sm justify-center mt-2 grid grid-cols-2"
                            >
                                <span class="col-span-1">
                                    {{ toRefund.pickUp.date }}<br />
                                    {{ toRefund.pickUp.address }}<br />
                                    {{ toRefund.pickUp.zipCity }}
                                </span>
                                <span class="col-span-1">
                                    {{ toRefund.delivery.date }}<br />
                                    {{ toRefund.delivery.address }}<br />
                                    {{ toRefund.delivery.zipCity }}
                                </span>
                            </div>
                            <hr class="my-2" />
                        </span></div></template
            ></modal>
            <create-container-modal
                v-if="containerCreation === true"
                :idClient="idClientForContainer"
                @containerCreated="containerCreated($event)"
                @closeContainerModal="unsetContainerCreation"
            ></create-container-modal>
        </div>
    </div>
</template>
<script>
import AlertInfo from '@/components/atoms/AlertInfo.vue'
import DropdownMenu from '@/components/atoms/DropdownMenu.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import Modal from '@/components/atoms/Modal.vue'
import CreateContainerModal from '@/components/elements/CreateContainerModal.vue'
import PackageForm from '@/components/elements/PackageForm.vue'
import Popover from '@/components/elements/Popover.vue'
import converter from '@/mixins/converter'
import googleScriptLoader from '@/mixins/googleScript'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import keys from '@/resources/keys'
import prefixes from '@/resources/prefixes'
import { useClientStore } from '@/stores/clientStore'
import { useOrderStore } from '@/stores/orderStore'
import { usePaymentStore } from '@/stores/paymentStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { helpers, required, requiredIf } from '@vuelidate/validators'
import { mapActions, mapState, mapStores } from 'pinia'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/,
)

const timeIsValid = helpers.regex(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)

const dateIsValid = helpers.regex(
    /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
)

export default {
    name: 'B2CCreateOrUpdate',
    components: {
        Multiselect,
        Modal,
        DropdownMenu,
        IconButton,
        Popover,
        AlertInfo,
        PackageForm,
        CreateContainerModal,
    },
    mixins: [googleScriptLoader, converter],
    setup() {
        const key =
            keys[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.'),
                      )
            ]
        const prefix =
            prefixes[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.'),
                      )
            ]
        const date = new Date()
        return { key, prefix, date, v$: useVuelidate() }
    },
    watch: {
        $route(to, from) {
            if (to.name === 'new_order') {
                this.cards = []
            }
        },
        selectedMethod() {
            this.formData.Method = this.selectedMethod.value
            this.refreshMS += 1

            this.liveRefresh()
        },
        selectedVehicleType() {
            this.formData.VehicleType = this.selectedVehicleType
                ? this.selectedVehicleType.value
                : ''
            this.refreshMS += 1

            this.liveRefresh()
        },
        addressCreation() {
            if (this.addressCreation === true) {
                this.loadGoogleScript('autocompleteAddStep')
            }
        },
        pickUpAddressId() {
            if (this.pickUpAddressId !== null) {
                this.setPickUp(this.pickUpAddressId)
            }
        },

        currentDelivery(newVal, oldVal) {
            if (newVal === null && this.user) {
                const date = new Date()

                this.formData = {
                    Name: '',
                    IdClient: this.isB2C === false ? '' : this.user.idClient,
                    IdUserContact: this.user.id,
                    IdDriver: '',
                    IdInvoice: '',
                    Reference: '',
                    Status:
                        this.currentClient.payment_type === 'web'
                            ? 'waitingforpayment'
                            : 'new',
                    Method: 'normal',
                    VehicleType: '',
                    OrderClientType:
                        this.currentClient.client_type ?? 'occasional',
                    PaymentType: this.currentClient.payment_type,
                    IdUniverse: this.currentClient.universeId,
                    WithVAT: this.currentClient.with_vat,
                    Latitude: '',
                    Longitude: '',
                    CommentInternal: '',
                    CommentClient: '',
                    OrderStepsHTML: null,
                    CreationUserId: 1,
                    OrderSteps: [],
                    OrderPackages: [],
                    DateBegin: '',
                    Price: 0,
                    DateEnd: '',
                    OrderOptions: null,
                    // InvoiceNumber: `${
                    //     this.prefix.invoice
                    // }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
                    OrderOption: [],
                    ModificationUserId: '',
                    ModificationDate: '',
                    PacketNumber: 0,
                }
            }
            if (this.show === false) {
                this.setCards()
            }
        },
    },
    mounted() {
        window.scrollTo(0, 0)
        this.success = null
        this.clientStore.$patch({ containers: null })
        this.containerOptions = []
        this.formData.IdClient = this.user.idClient
        this.clientStore.fetchOne(this.user.idClient)
        if (this.$route.query && this.$route.query.session) {
            this.checkoutSessionResult({
                session: this.$route.query.session,
                ModificationUserId: this.user.id,
            })
                .then((res) => {
                    this.success = true
                    this.fetchOneDelivery(this.$route.params.id)

                    this.orderStore.searchUnpaidOrdersByClient({
                        clientId: this.user.idClient,
                    })
                })
                .catch((err) => {
                    this.success = false
                })
        } else {
            this.orderStore.searchUnpaidOrdersByClient({
                clientId: this.user.idClient,
            })
        }
        // const currentClientInterval = setInterval(() => {
        //     if (this.currentClient) {
        //         clearInterval(currentClientInterval)
        //         this.formData.IdClient = this.user.idClient
        //     }
        // }, 500)
    },
    props: {
        reference: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            originAddressCreation: false,
            alreadyRefreshing: false,
            refreshPrice: 0,
            selectedVehicleType: {
                name: this.$t('not_specified'),
                value: '',
            },
            vehicleTypeOptions: [
                { name: this.$t('not_specified'), value: '' },
                { name: this.$t('bike'), value: 'bike' },
                { name: this.$t('electric_bike'), value: 'electricBike' },
                { name: this.$t('cargo_bike'), value: 'cargoBike' },
                {
                    name: this.$t('delivery_tricycle'),
                    value: 'deliveryTricycle',
                },
                { name: this.$t('car'), value: 'car' },
                { name: this.$t('electric_car'), value: 'electricCar' },

                { name: this.$t('van'), value: 'van' },
                { name: this.$t('truck'), value: 'truck' },
                { name: this.$t('boat'), value: 'boat' },
            ],
            idContDefault: null,
            idClientForContainer: null,
            success: null,
            isDefault: false,
            loading: false,
            roundTrip: false,
            roundTripSteps: [],
            showRefundOrderModal: false,
            orderToRefund: {},
            days: [
                'noday',
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday',
            ],
            showPop: null,
            badTimeRangeSteps: false,
            containerNameError: false,
            processing: false,
            newDeliveryName: '',
            editDeliveryName: false,
            orderIndexInUpdate: null,
            beforeTimeDeliv: '18:00',
            fromTimeDeliv: '08:00',
            beforeTimePickup: '18:00',
            fromTimePickup: '08:00',
            stepDateDeliv: new Date().toISOString().substr(0, 10),
            stepDatePickup: new Date().toISOString().substr(0, 10),
            pickupMoment: 'between',
            deliveryMoment: 'between',
            containerCreation: false,
            addressCreation: false,
            isCopying: false,
            duplication: false,
            containerOptions: [],
            adding: false,
            isEditPackage: false,
            packageInCreationIndex: null,
            container: 'Palette',
            noContactForClient: false,
            price: null,
            // vehicleType: this.prefix.defaultDeliveryMode,
            creating: false,
            show: false,
            deliveryStep: null,
            pickUpStep: null,
            noClientError: false,
            noDriverError: false,
            noContactError: false,
            refresh: 0,
            refreshDName: 0,
            refreshMS: 0,

            cards: [],
            badTimeRange: false,
            badPickupTimeRange: false,
            deliveryStepToUpdate: null,
            pickUpStepToUpdate: null,
            stepMomentOptions: [
                { name: this.$t('between'), value: 'between' },
                { name: this.$t('before'), value: 'before' },
                { name: this.$t('from'), value: 'from' },
            ],
            formData: {},

            typeOptions: [
                { name: this.$t('destination'), value: 'destination' },
                { name: this.$t('origin'), value: 'origin' },
            ],

            addressOptions: [],
            pickUpAddressOptions: [],
            // temperatureOptions: [
            //     {
            //         name: 'no_temp_specified',
            //         value: 'none',
            //         icon: 'temperature-half',
            //         noTemp: true,
            //     },
            //     {
            //         name: 'cool',
            //         value: 'fresh',
            //         icon: 'temperature-low',
            //         color: 'color:dodgerblue;',
            //     },
            //     {
            //         name: 'frozen',
            //         value: 'frozen',
            //         icon: 'temperature-arrow-down',
            //         color: 'color:cyan;',
            //     },
            //     {
            //         name: 'hot',
            //         value: 'warm',
            //         icon: 'temperature-high',
            //         color: 'color:firebrick;',
            //     },
            // ],
            // selectedTemp: 'none',
            deliveryAddressId: null,
            pickUpAddressId: null,
            pickedContainerId: null,

            currentDeliveryInterval: null,
            // packageTypeErrors: [],
            // packageReferenceErrors: [],
            // packageWeightErrors: [],
            // packageHeightErrors: [],
            // packageWidthErrors: [],
            // packageLengthErrors: [],
            // packageQuantityErrors: [],
            selectedMethod: {
                name: 'normal',
                value: 'normal',
                icon: 'gauge',
                style: 'color:green;',
            },
            methodOptions: [
                {
                    name: 'normal',
                    value: 'normal',
                    icon: 'gauge',
                    style: 'color:green;',
                },
                {
                    name: 'fast',
                    value: 'fast',
                    icon: 'gauge-high',
                    style: 'color:orange;',
                },
                {
                    name: 'express',
                    value: 'express',
                    icon: 'rocket',
                    style: 'color: red;',
                },
            ],
        }
    },
    validations() {
        return {
            newDeliveryName: { required },
            formData: {
                IdClient: {
                    required,
                },
                IdDriver: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false
                    }),
                },
                // Reference: { required },
            },
            deliveryStep: {
                AddressName: { required },
                Address1: { required },
                PostalCode: {
                    required,
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode,
                    ),
                },

                City: { required },
                fromTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.deliveryStep.Moment === 'between' ||
                            this.deliveryStep.Moment === 'from'
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
                beforeTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.deliveryStep.Moment === 'between' ||
                            this.deliveryStep.Moment === 'before'
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
                stepDate: {
                    required,
                    dateIsValid: helpers.withMessage(
                        this.$t('bad_date_format'),
                        dateIsValid,
                    ),
                },
            },
            pickUpStep: {
                AddressName: { required },
                Address1: { required },
                PostalCode: {
                    required,
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode,
                    ),
                },

                City: { required },
                fromTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.pickUpStep.Moment === 'between' ||
                            this.pickUpStep.Moment === 'from'
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
                beforeTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.pickUpStep.Moment === 'between' ||
                            this.pickUpStep.Moment === 'before'
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
                stepDate: {
                    required,
                    dateIsValid: helpers.withMessage(
                        this.$t('bad_date_format'),
                        dateIsValid,
                    ),
                },
            },
        }
    },

    computed: {
        ...mapStores(useClientStore, useOrderStore, useUserStore),
        ...mapState(useOrderStore, {
            currentDelivery: 'current',
        }),
        ...mapState(useClientStore, {
            currentClient: 'current',
            clientAddresses: 'addresses',
            currentDeliveryAddress: 'currentAddress',
            currentClientPickup: 'currentClientPickup',
            currentClientPickupAddresses: 'currentClientPickupAddresses',
            containers: 'containers',
            currentContainer: 'currentContainer',
        }),

        ...mapState(useUserStore, {
            user: 'current',
            isB2C: 'isB2C',
            mapMarker: 'mapMarker',
        }),
        canSetRoundTrip() {
            return checkAccess(this.userStore, actionsAccess.roundTripOrder)
        },
        webPaymentAllowed() {
            return checkAccess(this.userStore, actionsAccess.orders.webPayment)
        },
        canLiveRefreshPrice() {
            return checkAccess(this.userStore, actionsAccess.orders.livePrice)
        },
        canRefreshPrice() {
            return checkAccess(this.userStore, actionsAccess.orders.updatePrice)
        },
        globalPrice() {
            let price = 0
            if (this.currentDelivery !== null) {
                this.currentDelivery.orderClient.forEach((orderClient) => {
                    orderClient.Orders.forEach((realOrder) => {
                        if (realOrder.Status === 'waitingforpayment') {
                            price += +orderClient.Price
                        }
                    })
                })
            }
            return price
        },
        hasUnpaidOrders() {
            let hasUnpaidOrders = false
            if (this.currentDelivery !== null) {
                this.currentDelivery.orderClient.forEach((orderClient) => {
                    orderClient.Orders.forEach((realOrder) => {
                        if (realOrder.Status === 'waitingforpayment') {
                            hasUnpaidOrders = true
                        }
                    })
                })
            }

            return hasUnpaidOrders
        },
    },
    methods: {
        ...mapActions(usePaymentStore, [
            'createStripeCheckoutSession',
            'createRefund',
            'checkoutSessionResult',
        ]),
        ...mapActions(useOrderStore, ['fetchOneDelivery']),
        liveRefresh() {
            if (this.canLiveRefreshPrice === true) {
                this.refreshPrice += 1
            }
            this.calculatePrice()
        },
        async calculatePrice() {
            this.resetFormsValidations()

            const packages = []
            this.formData.OrderPackages.forEach((pack) => {
                const packageForm = {
                    IdOrder: '',
                    // IdVehicleType:
                    //     this.formData.OrderPackages[0].IdVehicleType,
                    Reference: pack.Reference,
                    PackageNumber: pack.PackageNumber,

                    Width: pack.Width,
                    Length: pack.Length,
                    Height: pack.Height,
                    Weight: pack.Weight,
                    Value: pack.Value,
                    Price: pack.Price,
                    Id: pack.Id,
                    IdContainer: pack.IdContainer,
                    LabelFile: pack.LabelFile,
                    LastDeliveryCode: pack.LastDeliveryCode,
                    LastDeliveryCodeDate: pack.LastDeliveryCodeDate,
                    TemperatureOption: pack.TemperatureOption,
                    ModificationUserId: this.user.id,
                    CreationUserId: pack.CreationUserId,
                    Quantity: pack.Quantity,
                }

                packages.push(packageForm)

                if (pack.Quantity !== pack.SameOpIndexes.length + 1) {
                    if (pack.Quantity > pack.SameOpIndexes.length + 1) {
                        const diff =
                            pack.Quantity - (pack.SameOpIndexes.length + 1)
                        for (let index = 0; index < diff; index += 1) {
                            const newPackage = {
                                IdOrder: pack.IdOrder,
                                // IdVehicleType: pack.IdVehicleType,
                                Width: pack.Width,
                                Length: pack.Length,
                                Height: pack.Height,
                                Weight: pack.Weight,
                                Value: pack.Value,
                                Price: pack.Price,
                                IdContainer: pack.IdContainer,
                                LabelFile: pack.LabelFile,
                                LastDeliveryCode: pack.LastDeliveryCode,
                                LastDeliveryCodeDate: pack.LastDeliveryCodeDate,
                                TemperatureOption: pack.TemperatureOption,
                                ModificationUserId: this.user.id,
                                CreationUserId: this.user.id,
                            }
                            packages.push(newPackage)
                        }
                    } else {
                        const diff =
                            pack.SameOpIndexes.length + 1 - pack.Quantity
                        for (let index = 0; index < diff; index += 1) {
                            packages.pop()
                        }
                    }
                }
            })

            // const refreshPriceInterval = setInterval(() => {
            //     // eslint-disable-next-line no-void
            //     void (async () => {
            if (
                this.alreadyRefreshing === false &&
                packages.length > 0 &&
                packages[0].Height !== 0 &&
                (this.currentDeliveryAddress !== null ||
                    this.addressCreation === true ||
                    this.originAddressCreation === true ||
                    this.isEditPackage === true)
            ) {
                this.alreadyRefreshing = true
                this.creating = true
                // clearInterval(refreshPriceInterval)
                const areStepsValid = await this.areStepsValid()
                if (areStepsValid === true) {
                    this.resetFormsValidations()

                    if ((await this.arePackagesValid()) === true) {
                        this.resetFormsValidations()

                        this.formData.CreationUserId = this.user.id

                        // this.formData.PacketNumber = this.formData.OrderPackages.length
                        // todo voir ici car step date va cahnger a chaque création de new step faire un tableau ? mais pb si update du step avec un tableau à remettre a jour si le step cahnge de place

                        const clone = await this.setValidatedSteps(true)
                        const cloneInterval = setInterval(() => {
                            // eslint-disable-next-line no-void
                            void (async () => {
                                if (clone.length > 0) {
                                    clearInterval(cloneInterval)

                                    this.formData.DateBegin = clone[0].stepDate
                                    this.formData.DateEnd =
                                        clone[clone.length - 1].stepDate

                                    clone.forEach((step) => {
                                        step.CreationUserId = this.user.id
                                        step.ModificationUserId = this.user.id

                                        step.Price = this.price
                                        // if (this.recurrentOrder === false) {
                                        const from = `${step.stepDate} ${step.FromDateTime}`

                                        const before = `${step.stepDate} ${step.BeforeDateTime}`
                                        if (step.Moment === 'between') {
                                            step.FromDateTime = from
                                            step.BeforeDateTime = before
                                        } else if (step.Moment === 'from') {
                                            step.FromDateTime = from
                                            step.BeforeDateTime = ''
                                        } else if (step.Moment === 'before') {
                                            step.FromDateTime = ''
                                            step.BeforeDateTime = before
                                        }
                                        if (
                                            step.TypeVehicle === '' ||
                                            step.TypeVehicle === null
                                        ) {
                                            step.TypeVehicle =
                                                this.formData.VehicleType === ''
                                                    ? null
                                                    : this.formData.VehicleType
                                        }
                                        // }
                                        delete step.stepDate
                                    })
                                    if (this.isB2C === true) {
                                        this.formData.IdClient =
                                            this.currentClient.id
                                    }
                                    //  this.v$.formData.$touch()
                                    const isValid =
                                        await this.v$.formData.$validate()

                                    // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                                    if (isValid) {
                                        this.formData.Status =
                                            this.formData.PaymentType === 'web'
                                                ? 'waitingforpayment'
                                                : 'new'

                                        // you can show some extra alert to the user or just leave the each field to show it's `$errors`.

                                        const deliveryForm = {
                                            // Reference: `${
                                            //     this.prefix.delivery
                                            // }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
                                            CreationUserId: this.user.id,
                                            IdClient: this.formData.IdClient,
                                            Name: this.newDeliveryName,
                                            CreationDate: '',
                                            OrdersClient: [
                                                {
                                                   
                                                    OrderClientType:
                                                        this.formData
                                                            .OrderClientType,
                                                    IdUniverse:
                                                        this.formData
                                                            .IdUniverse,
                                                    PaymentStatus:
                                                        this.formData
                                                            .PaymentType ===
                                                        'web'
                                                            ? 'waiting'
                                                            : 'none',
                                                    PaymentType:
                                                        this.formData
                                                            .PaymentType,
                                                    Recurrent: 0,
                                                    RoundTrip:
                                                        this.roundTrip === true
                                                            ? 1
                                                            : 0,
                                                    IdExternal:
                                                        this.formData
                                                            .IdExternal,
                                                    Price: this.formData.Price,
                                                    VehicleType:
                                                        this.formData
                                                            .VehicleType === ''
                                                            ? null
                                                            : this.formData
                                                                  .VehicleType,
                                                    WithVAT:
                                                        this.formData.WithVAT,

                                                    Orders: [
                                                        {
                                                            IdClient:
                                                                this.formData
                                                                    .IdClient,
                                                            IdUserContact:
                                                                this.formData
                                                                    .IdUserContact,
                                                            IdExternal:
                                                                this.formData
                                                                    .IdExternal,
                                                            IdDriver:
                                                                this.formData
                                                                    .IdDriver ??
                                                                '',
                                                            IdInvoice:
                                                                this.formData
                                                                    .IdInvoice ??
                                                                '',
                                                            Reference:
                                                                this.formData
                                                                    .Reference,
                                                            Status: this
                                                                .formData
                                                                .Status,
                                                            Method: this
                                                                .formData
                                                                .Method,

                                                            
                                                            CommentInternal:
                                                                this.formData
                                                                    .CommentInternal ??
                                                                '',
                                                            CommentClient:
                                                                this.formData
                                                                    .CommentClient ??
                                                                '',
                                                            OrderStepsHTML:
                                                                null,
                                                            CreationUserId:
                                                                this.user.id,
                                                            OrderSteps: clone,
                                                            OrderPackages:
                                                                packages,

                                                            DateBegin:
                                                                this.formData
                                                                    .DateBegin,
                                                            DateEnd:
                                                                this.formData
                                                                    .DateEnd,
                                                            OrderOptions: null,
                                                            OrderOption: [],
                                                        },
                                                    ],
                                                },
                                            ],
                                        }
                                        this.orderStore
                                            .updateDeliveryPrice(deliveryForm)
                                            .then((response) => {
                                                if (response) {
                                                    this.creating = false
                                                    this.formData.Price =
                                                        response.data.OrdersClient[0].Price
                                                    this.refreshPrice += 1
                                                    this.alreadyRefreshing = false

                                                    // this.refresh += 1
                                                }
                                            })
                                            .catch(() => {
                                                this.creating = false
                                                this.$toast.error(
                                                    this.$t(
                                                        'error_updating_price',
                                                    ),
                                                )
                                                this.alreadyRefreshing = false
                                            })
                                    } else {
                                        this.creating = false

                                        this.$toast.error(
                                            this.$t('form_errors'),
                                        )
                                        this.alreadyRefreshing = false

                                        return false
                                    }
                                }
                            })()
                        }, 1200)
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('bad_packages'))
                        this.alreadyRefreshing = false

                        return false
                    }
                } else {
                    this.alreadyRefreshing = false
                    this.creating = false
                    // this.$toast.error(this.$t('form_errors'))

                    // return false
                }
            }
            //     })()
            this.alreadyRefreshing = false
            // }, 1500)
            return true
        },
        unsetContainerCreation() {
            this.containerCreation = false
            this.idClientForContainer = null
            this.creating = false
            this.refreshMS += 1
            this.liveRefresh()
        },
        setContainerCreation(packIndex = null) {
            this.idClientForContainer = this.formData.IdClient
            if (packIndex === null) {
                this.addPackage()
            }

            this.containerCreation = true
        },
        containerCreated(container) {
            const clientId = this.currentClient.id ?? this.currentClient.Id

            // this.idContDefault = +container.Id
            this.formData.OrderPackages[
                this.formData.OrderPackages.length - 1
            ] = {
                PackageNumber:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].PackageNumber,
                Width: +container.Width,
                Length: +container.Length,
                Height: +container.Height,
                Weight: +container.Weight,
                SameOpIndexes: [],
                Value: +this.formData.OrderPackages[
                    this.formData.OrderPackages.length - 1
                ].Value,
                Quantity:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].Quantity,
                TemperatureOption:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].TemperatureOption,
                IdContainer: +container.Id,
                Id: this.formData.OrderPackages[
                    this.formData.OrderPackages.length - 1
                ].Id,
                CreationUserId:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].CreationUserId,
                ModificatioUserId:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].ModificationUserId,
                packageTypeErrors: [],
                packageValueErrors: [],
                packageWeightErrors: [],
                packageReferenceErrors: [],
                packageWidthErrors: [],
                packageLengthErrors: [],
                packageHeightErrors: [],
                packageQuantityErrors: [],

                containerNameError: false,
            }
            this.containerOptions = []

            this.clientStore.fetchContainers(clientId).then((resContainers) => {
                resContainers.data.Records.forEach((cnt) => {
                    this.containerOptions.push({
                        name:
                            cnt.Name.includes('_') ||
                            cnt.Name === 'pallet' ||
                            cnt.Name === 'envelope'
                                ? this.$t(cnt.Name)
                                : cnt.Name,
                        value: +cnt.Id,
                        icon: cnt.Name.includes('pallet')
                            ? 'pallet'
                            : cnt.Name.includes('envelope') ||
                                cnt.Name.includes('enveloppe')
                              ? 'envelope'
                              : cnt.Name.includes('box')
                                ? 'box'
                                : 'star',
                    })
                })

                this.unsetContainerCreation()
            })
        },
        showTooltip(elId, hovered) {
            const tooltip = document.getElementById(elId)
            if (hovered === false) {
                tooltip.classList.add('invisible')
            } else {
                tooltip.classList.remove('invisible')
            }
        },
        payUnpaidOrders(order) {
            this.success = null

            order.Price = order.IdDelivery
                ? order.OrderClient.Price
                : this.globalPrice
            this.createStripeCheckoutSession(order)
                .then((response) => {
                    if (
                        response.status === 201 &&
                        response.data !== 'already_paid'
                    ) {
                        window.open(response.data, '_self')
                    } else {
                        // window.location.reload()
                        this.$toast.info(this.$t('already_paid'))
                        this.fetchOneDelivery(this.$route.params.id).then(
                            (res) => {
                                this.refresh += 1
                            },
                        )
                    }
                })
                .catch((err) => {
                    this.success = false

                    this.$toast.info(this.$t(err.response.data.entity.msg))
                })
        },
        setPack(newPack) {
            this.formData.OrderPackages[newPack.index] = newPack.pack
            this.liveRefresh()
        },
        showRefundModal(order, deliveryCheckoutId = null) {
            if (order !== null) {
                const packs = []
                order.groupedPackages.forEach((pck) => {
                    const container =
                        pck.IdContainer && +pck.IdContainer !== 0
                            ? this.containers.find(
                                  (cntr) => cntr.Id === pck.IdContainer,
                              ).Name
                            : 'no_package_selected'

                    packs.push({
                        quantity: pck.Quantity,
                        container,
                    })
                })
                order.info = [
                    {
                        pickUp: order.pick_up,
                        delivery: order.delivery,
                        packs,
                    },
                ]
                // order.pickUps = [order.pick_up]
                // order.delivs = [order.delivery]
                // order.pack = {
                //     quantity: order.package.quantity,
                //     container: order.package.container,
                // }

                this.orderToRefund = order
                // this.fetchOne(order).then((res) => {
                //     this.orderToRefund = res.data.checkoutSession
                this.showRefundOrderModal = true
            } else {
                this.orderToRefund.checkout = deliveryCheckoutId
                this.orderToRefund.info = []
                this.currentDelivery.orderClient.forEach((oc) => {
                    if (
                        this.currentDelivery.checkout.OrderClients.includes(
                            oc.Id,
                        )
                    ) {
                        let pickUp = {}
                        let delivery = {}

                        const stepsWoTechSteps = []
                        oc.Orders[0].OrderSteps.forEach((step, index) => {
                            if (step.Step !== 'technical') {
                                stepsWoTechSteps.push(step)
                            }
                        })
                        this.formatcurrentDeliveryDateTimes(oc)

                        pickUp = {
                            date: new Date(
                                oc.PickupStepDate,
                            ).toLocaleDateString(this.$i18n.locale),
                            address: `${oc.PickupAddress1} ${
                                oc.PickupAddress2 !== 'null' &&
                                oc.PickupAddress2 !== ''
                                    ? oc.PickupAddress2
                                    : ''
                            }`,
                            zipCity: `${oc.PickupPostalCode} ${oc.PickupCity}`,
                        }

                        delivery = {
                            date:
                                +oc.RoundTrip === 0
                                    ? new Date(
                                          oc.DeliveryStepDate,
                                      ).toLocaleDateString(this.$i18n.locale)
                                    : new Date(
                                          stepsWoTechSteps[1].stepDate,
                                      ).toLocaleDateString(this.$i18n.locale),
                            address:
                                +oc.RoundTrip === 0
                                    ? `${oc.DeliveryAddress1} ${
                                          oc.DeliveryAddress2 !== 'null' &&
                                          oc.DeliveryAddress2 !== ''
                                              ? oc.DeliveryAddress2
                                              : ''
                                      }`
                                    : `${stepsWoTechSteps[1].Address1} ${
                                          stepsWoTechSteps[1].Address2 !==
                                              'null' &&
                                          stepsWoTechSteps[1].Address2 !== ''
                                              ? stepsWoTechSteps[1].Address2
                                              : ''
                                      }`,
                            zipCity:
                                +oc.RoundTrip === 0
                                    ? `${oc.DeliveryPostalCode} ${oc.DeliveryCity}`
                                    : `${stepsWoTechSteps[1].PostalCode} ${stepsWoTechSteps[1].City}`,
                        }
                        // TODO pousser les packs toous
                        const packs = []
                        oc.groupedPackages.forEach((pck) => {
                            const container =
                                pck.IdContainer && +pck.IdContainer !== 0
                                    ? this.containers.find(
                                          (cntr) => cntr.Id === pck.IdContainer,
                                      ).Name
                                    : 'no_package_selected'

                            packs.push({
                                quantity: pck.Quantity,
                                container,
                            })
                        })
                        this.orderToRefund.info.push({
                            pickUp,
                            delivery,
                            packs,
                        })
                    }
                })

                this.showRefundOrderModal = true
            }
            // })
        },

        setRoundtripSteps(steps) {
            if (this.roundTrip === true) {
                const roundPickUp = structuredClone(steps[0])
                const roundDeliv = structuredClone(steps[1])

                roundPickUp.Type = 'pickup'
                roundPickUp.Id = ''
                roundPickUp.IdVehicle = ''
                roundPickUp.IdDriver = ''
                roundPickUp.StepNumber = +roundPickUp.StepNumber + 1
                roundPickUp.stepDate = this.stepDateDeliv

                roundDeliv.Type = 'delivery'
                roundDeliv.Id = ''
                roundDeliv.IdVehicle = ''
                roundDeliv.IdDriver = ''
                roundDeliv.StepNumber = +roundPickUp.StepNumber + 1

                this.roundTripSteps.push(roundPickUp)
                this.roundTripSteps.push(roundDeliv)
            }
            if (this.roundTrip === false) {
                if (this.formData.OrderSteps.length > 2) {
                    this.formData.OrderSteps.pop()
                    this.formData.OrderSteps.pop()
                }
                if (this.roundTripSteps.length) {
                    this.roundTripSteps.pop()
                    this.roundTripSteps.pop()
                }
            }
            this.liveRefresh()
        },
        packageActions(card) {
            const actions = []

            if (card.status.real !== 'waitingforpayment') {
                if (
                    card.status.real === 'inprogress' ||
                    card.status.real === 'executed' ||
                    card.step_status === 'affected'
                ) {
                    actions.push({
                        name: 'print_label',
                        action: 'printLabel',
                        icon: 'download',
                    })

                    if (this.currentDelivery.checkout === null) {
                        // {
                        //     name: 'edit',
                        //     action: 'update',
                        //     icon: 'pen',
                        // },
                        actions.push({
                            name: 'duplicate',
                            action: 'duplicate',
                            icon: 'copy',
                        })
                    }
                } else {
                    actions.push({
                        name: 'print_label',
                        action: 'printLabel',
                        icon: 'download',
                    })
                    if (this.currentDelivery.checkout === null) {
                        // {
                        //     name: 'edit',
                        //     action: 'update',
                        //     icon: 'pen',
                        // },
                        actions.push({
                            name: 'duplicate',
                            action: 'duplicate',
                            icon: 'copy',
                        })
                    }
                    // si le orderclient/ card à un checkout et non la session
                    if (
                        card.payment_type === 'web' &&
                        card.checkout !== null &&
                        this.webPaymentAllowed === true
                    ) {
                        // (this.currentDelivery.checkout === null ||
                        //     (this.currentDelivery.checkout &&
                        //         !this.currentDelivery.checkout.OrderClients.includes(
                        //             card.orderClientId
                        //         )))
                        actions.push({
                            name: 'refund',
                            action: 'refund',
                            icon: 'hand-holding-dollar',
                        })
                    }
                }
            } else {
                if (
                    card.payment_type === 'web' &&
                    this.webPaymentAllowed === true
                ) {
                    // et que le orderclient n'est pas contenu dans la checkout session de la delivery
                    actions.push({
                        name: 'pay_pack_only',
                        action: 'pay',
                        icon: ['fab', 'cc-stripe'],

                        color: 'stripe-color',
                    })
                }
                actions.push(
                    // {
                    //     name: 'pay_pack_only',
                    //     action: 'pay',
                    //     icon: ['fab', 'cc-stripe'],

                    //     color: 'stripe-color',
                    // },
                    {
                        name: 'edit',
                        action: 'update',
                        icon: 'pen',
                    },
                    {
                        name: 'duplicate',
                        action: 'duplicate',
                        icon: 'copy',
                    },
                    {
                        name: 'delete',
                        action: 'delete',
                        icon: 'trash-can',
                    },
                )
            }

            return actions
        },
        createNewOrigin() {
            this.originAddressCreation = true

            // Type: 'delivery',
            // Status: 'new',
            this.pickUpStep = {
                Type: 'pickup',
                AddressType: 'origin',
                Status: 'new',
                StepNumber: 1,
                IdClientAddress: 0,
                AddressName: '',
                Id: '',
                Address1: '',
                Address2: '',
                Address3: '',
                Address4: '',
                PostalCode: '',
                City: '',
                Country: 'FRA',
                Latitude: '',
                Longitude: '',

                CreationUserId: +this.user.id,
                ModificationUserId: +this.user.id,

                TypeVehicle: '',
                IdDriver: '',
            }

            this.pickUpAddressId = null
        },
        createNewAddress() {
            this.addressCreation = true

            // Type: 'delivery',
            // Status: 'new',
            this.deliveryStep.StepNumber = 2
            this.deliveryStep.IdClientAddress = 0
            this.deliveryStep.AddressName = ''
            this.deliveryStep.Id = ''
            this.deliveryStep.Address1 = ''
            this.deliveryStep.Address2 = ''
            this.deliveryStep.Address3 = ''
            this.deliveryStep.Address4 = ''
            this.deliveryStep.PostalCode = ''
            this.deliveryStep.City = ''
            this.deliveryStep.Country = 'FRA'
            this.deliveryStep.Latitude = ''
            this.deliveryStep.Longitude = ''
            this.deliveryStep.CreationUserId = +this.user.id
            this.deliveryStep.ModificationUserId = +this.user.id
            this.deliveryStep.TypeVehicle = ''
            this.deliveryStep.IdDriver = ''
            this.deliveryStep.IdClientAddress = ''
            this.deliveryAddressId = null
        },
        findStepDay(date) {
            return new Date(date).getDay()
        },
        openingHoursForDay(businessHours, dayNumber) {
            const hours = []
            businessHours.forEach((bHour) => {
                if (+bHour.Day === dayNumber && +bHour.Open === 1) {
                    hours.push({
                        start: bHour.HourBegin.substring(0, 5),
                        end: bHour.HourEnd.substring(0, 5),
                    })
                }
            })
            return hours
        },
        resetBadTimes() {
            this.badTimeRange = false
            this.badPickupTimeRange = false
            this.badTimeRangeSteps = false
        },
        isOdd(num) {
            return num % 2
        },
        previousPickUpTimes(update = null) {
            let times = null

            times = `${this.$t('from')} ${this.fromTimePickup}`
            if (this.pickupMoment === 'between') {
                times = `${this.$t('short_from')} ${
                    this.fromTimePickup
                } ${this.$t('short_before')} ${this.beforeTimePickup}`
            } else if (this.pickupMoment === 'before') {
                times = `${this.$t('before')} ${this.beforeTimePickup}`
            }

            return times
        },
        methodStyle(method) {
            return method === 'express'
                ? 'color: red;'
                : method === 'normal'
                  ? 'color: green;'
                  : 'color: orange;'
        },
        showPopover(elId, close = false) {
            this.$cookies.set('currentDeliveryId', this.$route.params.id)

            if (close === false) {
                this.showPop = elId
                if (this.$route.params.id)
                    localStorage.setItem('popoverOpen', true)
            } else {
                this.showPop = null
                localStorage.removeItem('popoverOpen')
            }
        },
        setPopoverData(card) {
            const popoverData = structuredClone(card.full)

            popoverData.packages = popoverData.OrderPackages

            popoverData.groupedPackages = card.groupedPackages

            return popoverData
        },
        makeNameEditable() {
            this.editDeliveryName = true
            this.newDeliveryName = this.currentDelivery.name
        },
        orderStatus(order) {
            // TODO WAITING FOR PAYMENT
            let status = {
                status: this.$t('in_progress'),
                real: 'inprogress',
                color: 'theme-color',
                icon: 'person-biking',
            }
            if (
                order.Orders[0].Status === 'finished' ||
                +order.Orders[0].ExecutedSteps ===
                    order.Orders[0].OrderSteps.length ||
                order.Orders[0].OrderSteps[
                    order.Orders[0].OrderSteps.length - 1
                ].Status === 'executed'
            ) {
                status = {
                    status: this.$t('delivery_finished'),
                    real: 'executed',
                    color: 'success-msg',
                    icon: 'flag-checkered',
                }
            }
            if (order.Orders[0].Status === 'new') {
                status = {
                    status: this.$t('delivery_new'),
                    real: 'new',
                    color: 'theme-color',
                    icon: 'hourglass-half',
                }
            }
            if (order.Orders[0].Status === 'waitingforpayment') {
                status = {
                    status: this.$t('waiting_for_payment'),
                    real: 'waitingforpayment',
                    color: 'stripe-color cursor-pointer',
                    icon: ['fab', 'cc-stripe'],
                }
            }
            return status
        },
        async saveDeliveryName() {
            const isValid = await this.v$.newDeliveryName.$validate()
            if (isValid) {
                this.orderStore
                    .updateDeliveryName({
                        id: this.currentDelivery.id,
                        form: {
                            Name: this.newDeliveryName,
                            ModificationUserId: this.user.id,
                        },
                    })
                    .then(() => {
                        this.orderStore
                            .fetchOneDelivery(this.$route.params.id)
                            .then(() => {
                                this.newDeliveryName = ''
                                this.editDeliveryName = false
                                this.refreshDName += 1
                            })
                    })
            }
        },
        async areStepsValid(copyToNext = false) {
            if (
                this.addressCreation &&
                this.deliveryStep.IdClientAddress === 0
            ) {
                this.$toast.warning(this.$t('delivery_step_incomplete'))
                this.creating = false

                return false
            }
            if (
                this.deliveryStep.IdClientAddress ===
                    this.pickUpStep.IdClientAddress &&
                this.originAddressCreation === false
            ) {
                this.$toast.warning(this.$t('same_address'))
                this.creating = false
                return false
            }
            this.isCopying = copyToNext
            this.badPickupTimeRange = false
            this.pickUpStep.Moment = this.pickupMoment
            this.pickUpStep.beforeTime = this.beforeTimePickup
            this.pickUpStep.fromTime = this.fromTimePickup
            this.pickUpStep.stepDate = this.stepDatePickup

            if (
                (this.pickUpStep.fromTime > this.pickUpStep.beforeTime ||
                    this.pickUpStep.fromTime === this.pickUpStep.beforeTime) &&
                this.pickUpStep.Moment === 'between'
            ) {
                this.badPickupTimeRange = true
            }

            this.deliveryStep.Moment = this.deliveryMoment
            this.deliveryStep.beforeTime = this.beforeTimeDeliv
            this.deliveryStep.fromTime = this.fromTimeDeliv
            this.deliveryStep.stepDate = this.stepDateDeliv

            if (
                (this.deliveryStep.fromTime > this.deliveryStep.beforeTime ||
                    this.deliveryStep.fromTime ===
                        this.deliveryStep.beforeTime) &&
                this.deliveryStep.Moment === 'between'
            ) {
                this.badTimeRange = true
            }

            const isValidPickup = await this.v$.pickUpStep.$validate()
            const isValidDelivery = await this.v$.deliveryStep.$validate()

            return (
                isValidDelivery &&
                this.badTimeRange === false &&
                isValidPickup &&
                this.badPickupTimeRange === false
            )
        },

        packageAction(event, card) {
            this.editDeliveryName = false

            this.orderIndexInUpdate = card.order_index

            if (event.action === 'printLabel') {
                this.printLabel(card.package)
            } else if (event.action === 'pay') {
                this.payUnpaidOrders(card.full)
            } else if (event.action === 'tracking') {
                // this.trackParcel(card.package)
                if (card.trackings.length > 0) {
                    this.$emit('packageTracking', {
                        packageRef: card.package.reference,
                        orderClientId: card.orderClientId,
                        deliveryId: this.currentDelivery.id,
                    })
                } else {
                    this.$toast.info(this.$t('no_tracking_data_available'))
                }
            } else if (event.action === 'duplicate') {
                if (+card.recurrent === 1) {
                    this.$toast.info(this.$t('cannot_duplicate_recurrent'))
                    return
                }
                this.isEditPackage = false
                this.setFormData(card.order_index)

                this.duplicate(
                    card.pick_up.index,
                    card.delivery.index,
                    card.package.index,
                    card.order_index,
                )
            } else if (event.action === 'delete') {
                if (+card.recurrent === 1) {
                    this.$toast.info(this.$t('cannot_delete_recurrent'))
                    return
                }
                this.deletePackage(
                    this.currentDelivery.orderClient[card.order_index].Id,
                )
            } else if (event.action === 'update') {
                if (+card.recurrent === 1) {
                    this.$toast.info(this.$t('cannot_update_recurrent'))
                    return
                }
                this.isEditPackage = true
                this.setFormData(card.order_index)
                this.updatePackage(card)
            } else if (event.action === 'refund') {
                this.showRefundModal(card)
            }
        },
        setFormData(orderIndex) {
            this.formData = {
                CommentClient:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .CommentClient,
                CommentInternal:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .CommentInternal,
                CreationDate:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .CreationDate,
                CreationUserId:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .CreationUserId,
                DateBegin:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .DateBegin,
                DateEnd:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .DateEnd,
                DateReception:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .DateReception,
                Duration:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .Duration,
                Id: this.currentDelivery.orderClient[orderIndex].Orders[0].Id,
                IdClient:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .IdClient,
                IdDriver:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .IdDriver,
                IdInvoice:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .IdInvoice,

                IdExternal: this.currentClient.id_external,
                IdUserContact:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .IdUserContact,
                Method: this.currentDelivery.orderClient[orderIndex].Orders[0]
                    .Method,
                Price: this.currentDelivery.orderClient[orderIndex].Price,
                VehicleType:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .VehicleType,
                ModificationDate:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .ModificationDate,
                ModificationUserId:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .ModificationUserId,
                OrderPackages:
                    this.currentDelivery.orderClient[orderIndex]
                        .groupedPackages,
                OrderSteps:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .OrderSteps,
                OrderOptions:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .OrderOptions,
                PacketNumber:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .PacketNumber,
                PacketValue:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .PacketValue,
                Reference:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .Reference,
                Status: this.currentDelivery.orderClient[orderIndex].Orders[0]
                    .Status,
                OrderClientType:
                    this.currentDelivery.orderClient[orderIndex]
                        .OrderClientType,
                PaymentType:
                    this.currentDelivery.orderClient[orderIndex].PaymentType,
                IdUniverse:
                    this.currentDelivery.orderClient[orderIndex].IdUniverse,
                
                Weight: this.currentDelivery.orderClient[orderIndex].Orders[0]
                    .Weight,
            }
        },
        duplicate(pickUpIndex, deliveryIndex, packageIndex, orderIndex) {
            const date = new Date()
            this.roundTripSteps = []
            this.formData = {
                IdClient: this.user.idClient,
                IdUserContact: this.user.id,
                IdDriver: null,
                IdInvoice: null,
                Reference: '',
                Status:
                    this.currentClient.payment_type === 'web'
                        ? 'waitingforpayment'
                        : 'new',
                Method: 'normal',
                Price: this.currentDelivery.orderClient[orderIndex].Price,
                VehicleType: '',
                IdExternal: this.currentClient.id_external,
                OrderClientType: this.currentClient.client_type ?? 'occasional',
                PaymentType: this.currentClient.payment_type,
                IdUniverse: this.currentClient.universeId,

                CommentInternal: '',
                CommentClient: '',
                OrderStepsHTML: null,
                CreationUserId: this.user.id,
                OrderSteps: [],
                OrderPackages: [],
                DateBegin: '',
                DateEnd: '',
                OrderOptions: null,
                OrderOption: [],
                ModificationUserId: '',
                ModificationDate: '',
                PacketNumber:
                    this.currentDelivery.orderClient[orderIndex].NbPackages,
            }
            this.addPackage(true, packageIndex, orderIndex)
            const currentDeliveryInterv = setInterval(() => {
                if (
                    this.currentDelivery &&
                    this.currentDelivery.orderClient &&
                    this.currentDelivery.orderClient[orderIndex]
                ) {
                    clearInterval(currentDeliveryInterv)
                    this.roundTrip =
                        +this.currentDelivery.orderClient[orderIndex]
                            .RoundTrip === 1
                    this.duplication = true
                    this.pickUpAddressId =
                        +this.currentDelivery.orderClient[orderIndex].Orders[0]
                            .OrderSteps[pickUpIndex].IdClientAddress
                    this.deliveryAddressId =
                        +this.currentDelivery.orderClient[orderIndex].Orders[0]
                            .OrderSteps[deliveryIndex].IdClientAddress
                    this.containerOptions = []
                    this.clientStore
                        .fetchContainers(this.user.idClient)
                        .then(() => {
                            this.containers.forEach((container) => {
                                this.containerOptions.push({
                                    name:
                                        container.Name.includes('_') ||
                                        container.Name === 'pallet' ||
                                        container.Name === 'envelope'
                                            ? this.$t(container.Name)
                                            : container.Name,
                                    value: container.Id,
                                    icon: container.Name.includes('pallet')
                                        ? 'pallet'
                                        : container.Name === 'envelope' ||
                                            container.Name.includes('enveloppe')
                                          ? 'envelope'
                                          : container.Name.includes('box')
                                            ? 'box'
                                            : 'star',
                                })
                                if (
                                    container.Id ===
                                    this.currentDelivery.orderClient[orderIndex]
                                        .OrderPackages[packageIndex].IdContainer
                                ) {
                                    this.pickedContainerId = container.Id
                                }
                            })
                        })

                    const pickupPayload = {
                        clientId: this.user.idClient,
                        query: [],
                        filter: [],
                        perPage: 100000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                        origin: true,
                    }

                    this.pickUpAddressOptions = []
                    this.addressOptions = []
                    this.addressCreation = false
                    this.originAddressCreation = false

                    this.clientStore.fetchPickUpAddresses(pickupPayload)

                    const clientAddressesPayload = {
                        clientId: this.user.idClient,
                        query: [],
                        filter: [],
                        perPage: 100000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                        origin: false,
                    }
                    this.clientStore.fetchAddresses(clientAddressesPayload)

                    const currentClientPickupAddressesInterval = setInterval(
                        () => {
                            if (
                                this.currentClientPickupAddresses &&
                                this.clientAddresses
                            ) {
                                clearInterval(
                                    currentClientPickupAddressesInterval,
                                )
                                this.currentClientPickupAddresses.forEach(
                                    (address) => {
                                        this.pickUpAddressOptions.push({
                                            name: address.name,
                                            value: address.id,
                                            address: `${address.address} ${address.postal_code} ${address.city}`,
                                        })
                                    },
                                )

                                this.clientAddresses.forEach((address) => {
                                    const option = {
                                        name: address.name,
                                        value: address.id,
                                    }
                                    this.addressOptions.push(option)
                                    this.refreshMS += 1
                                })
                                this.pickUpStep = structuredClone(
                                    this.currentDelivery.orderClient[orderIndex]
                                        .Orders[0].OrderSteps[pickUpIndex],
                                )
                                this.pickUpStep.StepNumber = 1
                                this.pickUpStep.Id = ''

                                this.formatDateTimes(this.pickUpStep)

                                const stepsWoTechSteps = []
                                this.currentDelivery.orderClient[
                                    orderIndex
                                ].Orders[0].OrderSteps.forEach(
                                    (step, index) => {
                                        if (step.Step !== 'technical') {
                                            stepsWoTechSteps.push(step)
                                        }
                                    },
                                )
                                this.deliveryStep = structuredClone(
                                    stepsWoTechSteps[deliveryIndex],
                                )
                                this.deliveryStep.StepNumber = 2
                                this.deliveryStep.Id = ''
                                this.formatDateTimes(this.deliveryStep)
                                this.deliveryAddressId =
                                    +this.deliveryStep.IdClientAddress

                                // this.packageInCreationIndex =
                                //     this.formData.OrderPackages.length - 1
                                // this.packageQuantity =
                                //     this.currentDelivery.orderClient[
                                //         orderIndex
                                //     ].NbPackages
                                this.adding = false
                                this.creating = false
                                this.isCopying = false
                                // this.selectedTemp =
                                //     this.currentDelivery.orderClient[
                                //         orderIndex
                                //     ].OrderPackages[
                                //         packageIndex
                                //     ].TemperatureOption
                                this.pickUpStepToUpdate = null
                                this.deliveryStepToUpdate = null
                                this.pickupMoment = this.pickUpStep.Moment
                                this.beforeTimePickup =
                                    this.pickUpStep.beforeTime
                                this.fromTimePickup = this.pickUpStep.fromTime
                                this.stepDatePickup = this.pickUpStep.stepDate

                                this.deliveryMoment = this.deliveryStep.Moment
                                this.beforeTimeDeliv =
                                    this.deliveryStep.beforeTime
                                this.fromTimeDeliv = this.deliveryStep.fromTime
                                this.stepDateDeliv = this.deliveryStep.stepDate
                                this.selectedMethod = this.methodOptions.find(
                                    (option) =>
                                        option.name ===
                                        this.currentDelivery.orderClient[
                                            orderIndex
                                        ].Orders[0].Method,
                                )

                                this.selectedVehicleType =
                                    this.vehicleTypeOptions.find(
                                        (option) =>
                                            option.value ===
                                            this.currentDelivery.orderClient[
                                                orderIndex
                                            ].VehicleType,
                                    )
                                if (this.roundTrip) {
                                    const roundPickUp = structuredClone(
                                        this.currentDelivery.orderClient[
                                            orderIndex
                                        ].Orders[0].OrderSteps[2],
                                    )
                                    const roundDelivery = structuredClone(
                                        this.currentDelivery.orderClient[
                                            orderIndex
                                        ].Orders[0].OrderSteps[3],
                                    )
                                    roundPickUp.Id = ''
                                    roundDelivery.Id = ''
                                    this.formatDateTimes(roundPickUp)
                                    this.formatDateTimes(roundDelivery)
                                    this.roundTripSteps.push(roundPickUp)
                                    this.roundTripSteps.push(roundDelivery)
                                }
                                this.show = true

                                // this.refreshMS += 1
                            }
                        },
                        200,
                    )
                }
            }, 200)
        },
        formatcurrentDeliveryDateTimes(orderClient) {
            if (orderClient.DeliveryMoment === 'between') {
                orderClient.DeliveryStepDate =
                    orderClient.DeliveryFromDateTime.substring(
                        0,
                        orderClient.DeliveryFromDateTime.indexOf(' '),
                    )
                const stepFromDateTime =
                    orderClient.DeliveryFromDateTime.substring(
                        orderClient.DeliveryFromDateTime.indexOf(' ') + 1,
                    )
                orderClient.DeliveryFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3,
                )

                const stepBeforeDateTime =
                    orderClient.DeliveryBeforeDateTime.substring(
                        orderClient.DeliveryBeforeDateTime.indexOf(' ') + 1,
                    )
                orderClient.DeliveryBeforeDateTime =
                    stepBeforeDateTime.substring(
                        0,
                        stepBeforeDateTime.length - 3,
                    )
            } else if (orderClient.DeliveryMoment === 'from') {
                orderClient.DeliveryStepDate =
                    orderClient.DeliveryFromDateTime.substring(
                        0,
                        orderClient.DeliveryFromDateTime.indexOf(' '),
                    )
                const stepFromDateTime =
                    orderClient.DeliveryFromDateTime.substring(
                        orderClient.DeliveryFromDateTime.indexOf(' ') + 1,
                    )
                orderClient.DeliveryFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3,
                )
                orderClient.DeliveryBeforeDateTime = ''
            } else if (orderClient.DeliveryMoment === 'before') {
                orderClient.DeliveryStepDate =
                    orderClient.DeliveryBeforeDateTime.substring(
                        0,
                        orderClient.DeliveryBeforeDateTime.indexOf(' '),
                    )

                const stepBeforeDateTime =
                    orderClient.DeliveryBeforeDateTime.substring(
                        orderClient.DeliveryBeforeDateTime.indexOf(' ') + 1,
                    )
                orderClient.DeliveryBeforeDateTime =
                    stepBeforeDateTime.substring(
                        0,
                        stepBeforeDateTime.length - 3,
                    )
                orderClient.DeliveryFromDateTime = ''
            }
            if (orderClient.PickupMoment === 'between') {
                orderClient.PickupStepDate =
                    orderClient.PickupFromDateTime.substring(
                        0,
                        orderClient.PickupFromDateTime.indexOf(' '),
                    )
                const stepFromDateTime =
                    orderClient.PickupFromDateTime.substring(
                        orderClient.PickupFromDateTime.indexOf(' ') + 1,
                    )
                orderClient.PickupFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3,
                )

                const stepBeforeDateTime =
                    orderClient.PickupBeforeDateTime.substring(
                        orderClient.PickupBeforeDateTime.indexOf(' ') + 1,
                    )
                orderClient.PickupBeforeDateTime = stepBeforeDateTime.substring(
                    0,
                    stepBeforeDateTime.length - 3,
                )
            } else if (orderClient.PickupMoment === 'from') {
                orderClient.PickupStepDate =
                    orderClient.PickupFromDateTime.substring(
                        0,
                        orderClient.PickupFromDateTime.indexOf(' '),
                    )
                const stepFromDateTime =
                    orderClient.PickupFromDateTime.substring(
                        orderClient.PickupFromDateTime.indexOf(' ') + 1,
                    )
                orderClient.PickupFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3,
                )
                orderClient.PickupBeforeDateTime = ''
            } else if (orderClient.PickupMoment === 'before') {
                orderClient.PickupStepDate =
                    orderClient.PickupBeforeDateTime.substring(
                        0,
                        orderClient.PickupBeforeDateTime.indexOf(' '),
                    )

                const stepBeforeDateTime =
                    orderClient.PickupBeforeDateTime.substring(
                        orderClient.PickupBeforeDateTime.indexOf(' ') + 1,
                    )
                orderClient.PickupBeforeDateTime = stepBeforeDateTime.substring(
                    0,
                    stepBeforeDateTime.length - 3,
                )
                orderClient.PickupFromDateTime = ''
            }
        },
        setCards() {
            const interval = setInterval(() => {
                if (this.currentDelivery && this.containers) {
                    clearInterval(interval)
                    this.cards = []

                    const orderClientClone = structuredClone(
                        this.currentDelivery.orderClient,
                    )
                    orderClientClone.forEach(
                        (orderClient, orderClientIndex) => {
                            const full = orderClient.Orders[0]
                            full.packages = full.OrderPackages

                            this.formatcurrentDeliveryDateTimes(orderClient)
                            const trackings = []
                            const signatures = []
                            const pictures = []
                            full.OrderPackages.forEach((pack) => {
                                if (
                                    pack.Trackings &&
                                    pack.Trackings.length > 0
                                ) {
                                    trackings.push(pack.Trackings)
                                }
                                if (
                                    pack.Signatures &&
                                    pack.Signatures.length > 0
                                ) {
                                    signatures.push(pack.Signatures)
                                }
                                if (pack.Pictures && pack.Pictures.length > 0) {
                                    pictures.push(pack.Pictures)
                                }
                            })
                            trackings.forEach((tracking, index) => {
                                trackings[index] = tracking.filter(
                                    (track) => track.TypeTracking === 'client',
                                )
                                if (trackings[index].length === 0) {
                                    trackings.splice(index, 1)
                                }
                            })

                            const groupedPacks = []
                            orderClient.groupedPackages.forEach((pck) => {
                                const temperatureInfos =
                                    pck.TemperatureOption === 'warm'
                                        ? {
                                              icon: 'temperature-high',
                                              color: 'color:firebrick;',
                                              title: 'hot',
                                          }
                                        : pck.TemperatureOption === 'fresh'
                                          ? {
                                                icon: 'temperature-low',
                                                color: 'color:dodgerblue;',

                                                title: 'cool',
                                            }
                                          : pck.TemperatureOption === 'frozen'
                                            ? {
                                                  icon: 'temperature-arrow-down',
                                                  color: 'color:cyan;',

                                                  title: 'frozen',
                                              }
                                            : {
                                                  icon: 'temperature-half',
                                                  noTemp: true,
                                              }
                                const container =
                                    pck.IdContainer && +pck.IdContainer !== 0
                                        ? this.containers.find(
                                              (cntr) =>
                                                  cntr.Id === pck.IdContainer,
                                          ).Name
                                        : 'no_package_selected'
                                const oPack = {
                                    container,
                                    temperature: temperatureInfos,
                                    quantity: pck.Quantity,
                                    size:
                                        `${pck.Length} cm x ${pck.Width} cm x ${
                                            pck.Height
                                        } cm ${this.$t('lxwxh')} ` ?? null,
                                    weight: pck.Weight ?? null,
                                    worth: `${pck.Value} €` ?? null,
                                }
                                groupedPacks.push(oPack)
                            })
                            const stepsWoTechSteps = []
                            orderClient.Orders[0].OrderSteps.forEach(
                                (step, index) => {
                                    if (step.Step !== 'technical') {
                                        stepsWoTechSteps.push(step)
                                    }
                                },
                            )
                            const roundTripSteps = []
                            if (+orderClient.RoundTrip === 1) {
                                this.formatDateTimes(stepsWoTechSteps[1])

                                this.formatDateTimes(stepsWoTechSteps[2])
                                this.formatDateTimes(stepsWoTechSteps[3])

                                roundTripSteps.push(stepsWoTechSteps[2])
                                roundTripSteps.push(stepsWoTechSteps[3])
                            }
                            // const container =
                            //     orderClient.OrderPackages[0].IdContainer &&
                            //     +orderClient.OrderPackages[0].IdContainer !== 0
                            //         ? this.containers.find(
                            //               (cntr) =>
                            //                   cntr.Id ===
                            //                   orderClient.OrderPackages[0]
                            //                       .IdContainer
                            //           ).Name
                            //         : 'no_package_selected'
                            let isInvoiced = false
                            orderClient.Orders[0].OrderSteps.forEach((step) => {
                                if (step.IdInvoice !== null) {
                                    isInvoiced = true
                                }
                            })

                            this.cards.push({
                                groupedPackages: orderClient.groupedPackages,
                                isInvoiced,
                                distance: this.formatDistance(
                                    orderClient.Distance,
                                ),
                                duration: this.formatDuration(
                                    orderClient.Duration,
                                ),
                                roundTripSteps,
                                comments: orderClient.Orders[0].CommentClient,
                                method: orderClient.Orders[0].Method,
                                trackings,
                                pictures,
                                signatures,
                                orderClientId: orderClient.Id,
                                id: orderClient.Orders[0].Id,
                                reference: orderClient.Reference,
                                full,
                                status: this.orderStatus(orderClient),
                                payment_type: orderClient.PaymentType,
                                step_status:
                                    orderClient.Orders[0].OrderSteps[0].Status,
                                checkout: orderClient.CheckoutSession ?? null,
                                recurrent: +orderClient.Recurrent === 1,
                                price: orderClient.Price,
                                roundTrip: +orderClient.RoundTrip === 1,
                                vehicle_type: !orderClient.VehicleType
                                    ? ''
                                    : orderClient.VehicleType,
                                order_index: orderClientIndex,
                                pick_up: {
                                    date: new Date(
                                        orderClient.PickupStepDate,
                                    ).toLocaleDateString(this.$i18n.locale),
                                    moment: ` ${this.$t(
                                        orderClient.PickupMoment,
                                    )} ${
                                        orderClient.PickupMoment === 'between'
                                            ? `${
                                                  orderClient.PickupFromDateTime
                                              } ${this.$t('and')} ${
                                                  orderClient.PickupBeforeDateTime
                                              }`
                                            : orderClient.PickupMoment ===
                                                'before'
                                              ? orderClient.PickupBeforeDateTime
                                              : orderClient.PickupFromDateTime
                                    }`,
                                    address: `${orderClient.PickupAddress1} ${
                                        orderClient.PickupAddress2 !== 'null' &&
                                        orderClient.PickupAddress2 !== ''
                                            ? orderClient.PickupAddress2
                                            : ''
                                    }`,
                                    zipCity: `${orderClient.PickupPostalCode} ${orderClient.PickupCity}`,
                                    index: 0,
                                },
                                delivery: {
                                    date:
                                        +orderClient.RoundTrip === 0
                                            ? new Date(
                                                  orderClient.DeliveryStepDate,
                                              ).toLocaleDateString(
                                                  this.$i18n.locale,
                                              )
                                            : new Date(
                                                  stepsWoTechSteps[1].stepDate,
                                              ).toLocaleDateString(
                                                  this.$i18n.locale,
                                              ),
                                    index:
                                        +orderClient.RoundTrip === 0
                                            ? stepsWoTechSteps.length - 1
                                            : 1,
                                    moment:
                                        +orderClient.RoundTrip === 0
                                            ? ` ${this.$t(
                                                  orderClient.DeliveryMoment,
                                              )} ${
                                                  orderClient.DeliveryMoment ===
                                                  'between'
                                                      ? `${
                                                            orderClient.DeliveryFromDateTime
                                                        } ${this.$t('and')} ${
                                                            orderClient.DeliveryBeforeDateTime
                                                        }`
                                                      : orderClient.DeliveryMoment ===
                                                          'before'
                                                        ? orderClient.DeliveryBeforeDateTime
                                                        : orderClient.DeliveryFromDateTime
                                              }`
                                            : ` ${this.$t(
                                                  stepsWoTechSteps[1].Moment,
                                              )} ${
                                                  stepsWoTechSteps[1].Moment ===
                                                  'between'
                                                      ? `${
                                                            stepsWoTechSteps[1]
                                                                .fromTime
                                                        } ${this.$t('and')} ${
                                                            stepsWoTechSteps[1]
                                                                .beforeTime
                                                        }`
                                                      : stepsWoTechSteps[1]
                                                              .Moment ===
                                                          'before'
                                                        ? stepsWoTechSteps[1]
                                                              .beforeTime
                                                        : stepsWoTechSteps[1]
                                                              .fromTime
                                              }`,
                                    name:
                                        +orderClient.RoundTrip === 0
                                            ? orderClient.DeliveryAddressName
                                            : stepsWoTechSteps[1].AddressName,
                                    address:
                                        +orderClient.RoundTrip === 0
                                            ? `${
                                                  orderClient.DeliveryAddress1
                                              } ${
                                                  orderClient.DeliveryAddress2 !==
                                                      'null' &&
                                                  orderClient.DeliveryAddress2 !==
                                                      ''
                                                      ? orderClient.DeliveryAddress2
                                                      : ''
                                              }`
                                            : `${
                                                  stepsWoTechSteps[1].Address1
                                              } ${
                                                  stepsWoTechSteps[1]
                                                      .Address2 !== 'null' &&
                                                  stepsWoTechSteps[1]
                                                      .Address2 !== ''
                                                      ? stepsWoTechSteps[1]
                                                            .Address2
                                                      : ''
                                              }`,
                                    zipCity:
                                        +orderClient.RoundTrip === 0
                                            ? `${orderClient.DeliveryPostalCode} ${orderClient.DeliveryCity}`
                                            : `${stepsWoTechSteps[1].PostalCode} ${stepsWoTechSteps[1].City}`,
                                },
                                package: {
                                    groupedPacks,
                                    index: 0,
                                    signatures:
                                        orderClient.OrderPackages[0].Signatures,
                                    trackings:
                                        orderClient.OrderPackages[0].Trackings,

                                    packageId:
                                        orderClient.OrderPackages[0].Id ?? null,
                                    reference:
                                        orderClient.OrderPackages[0]
                                            .Reference ?? null,
                                },
                            })
                        },
                    )
                    this.refreshMS += 1
                }
            }, 200)
        },

        printLabel(label) {
            this.processing = true

            this.axios
                .get(`/api/v1/orderPackage/${label.packageId}/getlabel`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get(
                            'user_session',
                        )}`,
                    },
                    signal: this.$controller.signal,
                })
                .then((res) => {
                    // const url = URL.createObjectURL(res.data)
                    // const link = document.createElement('a')
                    // link.href = url
                    // link.setAttribute('download', `label- ${label.reference}`)
                    // document.body.appendChild(link)
                    // link.click()
                    // document.body.removeChild(link)
                    // URL.revokeObjectURL(url)

                    // window.open(window.URL.createObjectURL(res.data)).print()

                    const iframe = document.createElement('iframe')
                    // Hide the IFrame.
                    iframe.style.visibility = 'hidden'
                    // Define the source.
                    iframe.setAttribute(
                        'src',
                        window.URL.createObjectURL(res.data),
                    )
                    // Add the IFrame to the web page.
                    document.body.appendChild(iframe)
                    // iframe.focus()
                    // iframe.contentWindow.print()
                    iframe.onload = () => {
                        iframe.focus()
                        iframe.contentWindow.print()
                        this.processing = false
                    }
                })
        },
        trackParcel(parcel) {
            const env =
                window.location.hostname === 'localhost' ||
                window.location.host.includes('dev')
                    ? 'dev'
                    : window.location.host.includes('lium')
                      ? 'lium'
                      : window.location.host.substring(
                            0,
                            window.location.host.indexOf('.'),
                        )
            const dot = env === 'beaujolyon' ? 'fr' : 'com'
            window
                .open(
                    `https://${env}.biiim-solutions.${dot}/api/v1/tracking/form/live?reference=${parcel.reference}`,
                    '_blank',
                )
                .focus()
        },
        async arePackagesValid() {
            let result = true
            this.formData.OrderPackages.forEach((pack) => {
                if (result === true) {
                    if (
                        pack.IdContainer === '' ||
                        pack.IdContainer === null ||
                        +pack.IdContainer === 0 ||
                        pack.IdContainer === undefined
                    ) {
                        pack.packageTypeErrors.push('package error container')
                        result = false
                    }
                    // if (pack.Reference === '' || pack.Reference === null) {
                    //     pack.packageReferenceErrors.push(
                    //         'package error reference'
                    //     )

                    //     result = false
                    // }
                    // if (pack.Quantity === 0) {
                    //     pack.packageQuantityErrors.push('package error value')

                    // }
                    if (
                        pack.Weight === 0 ||
                        pack.Weight === null ||
                        pack.Weight === '' ||
                        pack.Weight === undefined
                    ) {
                        pack.packageWeightErrors.push('package error weight')

                        result = false
                    }
                    if (
                        pack.Width === 0 ||
                        pack.Width === null ||
                        pack.Width === '' ||
                        pack.Width === undefined
                    ) {
                        pack.packageWidthErrors.push('package error width')

                        result = false
                    }
                    if (
                        pack.Length === 0 ||
                        pack.Length === null ||
                        pack.Length === '' ||
                        pack.Length === undefined
                    ) {
                        pack.packageLengthErrors.push('package error length')

                        result = false
                    }
                    if (
                        pack.Quantity === 0 ||
                        pack.Quantity === null ||
                        pack.Quantity === '' ||
                        pack.Quantity === undefined
                    ) {
                        pack.packageQuantityErrors.push(
                            'package error quantity',
                        )

                        result = false
                    }
                    if (
                        pack.Height === 0 ||
                        pack.Height === null ||
                        pack.Height === '' ||
                        pack.Height === undefined
                    ) {
                        pack.packageHeightErrors.push('package error height')

                        result = false
                    }
                }
            })
            this.refreshMS += 1

            // this.packageValueErrors.length === 0 &&
            return result
        },

        showAddModal() {
            this.editDeliveryName = false
            this.processing = true
            this.roundTripSteps = []
            this.roundTrip = false
            this.containerOptions = []
            this.newDeliveryName = `${this.$t('delivery_title')} ${new Date()
                .toISOString()
                .substr(0, 10)}`
            this.copyToNext = false
            this.isEditPackage = false
            const date = new Date()
            this.formData = {
                IdClient: this.user.idClient,
                IdUserContact: this.user.id,
                IdDriver: '',
                IdInvoice: '',
                // Reference: `${
                //     this.prefix.order
                // }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
                Status:
                    this.currentClient.payment_type === 'web'
                        ? 'waitingforpayment'
                        : 'new',
                Method: 'normal',
                VehicleType: '',
                OrderClientType: this.currentClient.client_type ?? 'occasional',
                PaymentType: this.currentClient.payment_type,
                IdUniverse: this.currentClient.universeId,
                CommentInternal: '',
                CommentClient: '',
                OrderStepsHTML: null,
                CreationUserId: this.user.id,
                OrderSteps: [],
                OrderPackages: [],
                DateBegin: '',
                IdExternal: this.currentClient
                    ? this.currentClient.id_external
                    : '',
                DateEnd: '',
                OrderOptions: null,
                OrderOption: [],
                ModificationUserId: '',
                ModificationDate: '',
                PacketNumber: 1,
                Price: 0,
            }

            this.idContDefault =
                this.currentClient.default_container > 0
                    ? +this.currentClient.default_container
                    : +this.user.env.defaultContainer
            this.addPackage()
            this.refreshMS += 1
            const pickupPayload = {
                clientId: this.currentClient
                    ? this.currentClient.id
                    : this.user.idClient,
                origin: true,
                query: [],
                filter: [],
                perPage: 100000,
                page: 1,
                sortField: 'Name',
                sortDirection: 'ASC',
            }
            this.clientStore.fetchPickUpAddresses(pickupPayload).then(() => {
                const clientAddressesPayload = {
                    clientId: this.currentClient
                        ? this.currentClient.id
                        : this.user.idClient,
                    query: [],
                    filter: [],
                    perPage: 100000000000,
                    page: 1,
                    sortField: 'Name',
                    sortDirection: 'ASC',
                    origin: false,
                }
                this.clientStore
                    .fetchAddresses(clientAddressesPayload)
                    .then(() => {
                        this.clientStore
                            .fetchContainers(this.user.idClient)
                            .then(() => {
                                if (
                                    this.currentClientPickupAddresses &&
                                    this.currentClientPickupAddresses.length > 0
                                ) {
                                    this.currentClientPickupAddresses.forEach(
                                        (address) => {
                                            this.pickUpAddressOptions.push({
                                                name: address.name,
                                                value: address.id,
                                                address: `${address.address} ${address.postal_code} ${address.city}`,
                                            })
                                        },
                                    )
                                    this.pickUpAddressId =
                                        this.pickUpAddressOptions.length &&
                                        this.pickUpAddressOptions[0]
                                            ? this.pickUpAddressOptions[0].value
                                            : 0
                                    this.setPickUp(
                                        this.currentClientPickupAddresses[0].id,
                                    )
                                } else {
                                    this.createNewOrigin()
                                }

                                this.clientAddresses.forEach((address) => {
                                    const option = {
                                        name: address.name,
                                        value: address.id,
                                    }
                                    this.addressOptions.push(option)
                                })

                                this.deliveryStep = {
                                    Type: 'delivery',
                                    Status: 'new',
                                    StepNumber: 2,
                                    IdClientAddress: 0,
                                    AddressName: '',
                                    Id: '',
                                    Address1: '',
                                    Address2: '',
                                    Address3: '',
                                    Address4: '',
                                    PostalCode: '',
                                    City: '',
                                    Country: 'FRA',
                                    Latitude: '',
                                    Longitude: '',
                                    Moment: 'between',
                                    FromDateTime: '08:00',
                                    BeforeDateTime: '18:00',
                                    beforeTime: '18:00',
                                    CreationUserId: +this.user.id,
                                    ModificationUserId: +this.user.id,
                                    fromTime: '08:00',
                                    TypeVehicle: '',
                                    IdDriver: '',
                                    stepDate: new Date()
                                        .toISOString()
                                        .substr(0, 10),
                                }

                                this.containers.forEach((container) => {
                                    this.containerOptions.push({
                                        name:
                                            container.Name.includes('_') ||
                                            container.Name === 'pallet' ||
                                            container.Name === 'envelope'
                                                ? this.$t(container.Name)
                                                : container.Name,
                                        value: container.Id,
                                        icon: container.Name.includes('pallet')
                                            ? 'pallet'
                                            : container.Name === 'envelope' ||
                                                container.Name.includes(
                                                    'enveloppe',
                                                )
                                              ? 'envelope'
                                              : container.Name.includes('box')
                                                ? 'box'
                                                : 'star',
                                    })
                                })

                                this.show = true
                                this.adding = false
                                this.processing = false
                            })
                    })
            })
        },
        updatePackage(card) {
            this.processing = true
            this.containerOptions = []
            this.roundTripSteps = card.roundTripSteps
            this.containers.forEach((container) => {
                const icon = container.Name.includes('pallet')
                    ? 'pallet'
                    : container.Name === 'envelope' ||
                        container.Name.includes('enveloppe')
                      ? 'envelope'
                      : container.Name.includes('box')
                        ? 'box'
                        : 'star'
                this.containerOptions.push({
                    name:
                        container.Name.includes('_') ||
                        container.Name === 'pallet' ||
                        container.Name === 'envelope'
                            ? this.$t(container.Name)
                            : container.Name,
                    value: container.Id,
                    icon,
                })
            })

            const orderInterval = setInterval(() => {
                if (this.currentDelivery) {
                    clearInterval(orderInterval)
                    // this.formData.IdExternal = this.currentClient ? this.currentClient.id_external : '',

                    this.packageInCreationIndex = card.package.index
                    const pickupPayload = {
                        clientId: this.user.idClient,
                        query: [],
                        filter: [],
                        perPage: 100000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                        origin: true,
                    }

                    this.clientStore.fetchPickUpAddresses(pickupPayload)
                    const clientAddressesPayload = {
                        clientId: this.user.idClient,
                        query: [],
                        perPage: 100000000000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                        origin: false,
                    }
                    this.clientStore.fetchAddresses(clientAddressesPayload)

                    const addressesInterval = setInterval(() => {
                        if (
                            this.currentClientPickupAddresses &&
                            this.clientAddresses
                        ) {
                            clearInterval(addressesInterval)
                            this.currentClientPickupAddresses.forEach(
                                (address) => {
                                    this.pickUpAddressOptions.push({
                                        name: address.name,
                                        value: address.id,
                                        address: `${address.address} ${address.postal_code} ${address.city}`,
                                    })
                                },
                            )

                            this.clientAddresses.forEach((address) => {
                                const option = {
                                    name: address.name,
                                    value: address.id,
                                }
                                this.addressOptions.push(option)
                            })

                            const stepsWoTechSteps = []
                            this.formData.OrderSteps.forEach((step, index) => {
                                if (step.Step !== 'technical') {
                                    stepsWoTechSteps.push(step)
                                }
                            })

                            this.deliveryStep = structuredClone(
                                stepsWoTechSteps[card.delivery.index],
                            )

                            this.formatDateTimes(this.deliveryStep)
                            this.pickUpStep = structuredClone(
                                stepsWoTechSteps[card.pick_up.index],
                            )

                            this.formatDateTimes(this.pickUpStep)

                            const container =
                                this.formData.OrderPackages[card.package.index]
                                    .IdContainer &&
                                +this.formData.OrderPackages[card.package.index]
                                    .IdContainer !== 0
                                    ? this.containers.find(
                                          (el) =>
                                              el.Id ===
                                              this.formData.OrderPackages[
                                                  card.package.index
                                              ].IdContainer,
                                      ).Name
                                    : 'no_package_selected'
                            this.containerName = container
                            this.pickedContainerId =
                                this.formData.OrderPackages[
                                    card.package.index
                                ].IdContainer
                            this.packageQuantity =
                                this.currentDelivery.orderClient[
                                    card.order_index
                                ].NbPackages
                            this.roundTrip =
                                +this.currentDelivery.orderClient[
                                    card.order_index
                                ].RoundTrip === 1
                            this.packageHeight =
                                this.formData.OrderPackages[
                                    card.package.index
                                ].Height
                            this.selectedTemp =
                                this.currentDelivery.orderClient[
                                    card.order_index
                                ].OrderPackages[
                                    card.package.index
                                ].TemperatureOption
                            this.pickupMoment = this.pickUpStep.Moment
                            this.beforeTimePickup = this.pickUpStep.beforeTime
                            this.fromTimePickup = this.pickUpStep.fromTime
                            this.stepDatePickup = this.pickUpStep.stepDate

                            this.deliveryMoment = this.deliveryStep.Moment
                            this.beforeTimeDeliv = this.deliveryStep.beforeTime
                            this.fromTimeDeliv = this.deliveryStep.fromTime
                            this.stepDateDeliv = this.deliveryStep.stepDate

                            this.deliveryAddressId =
                                +this.deliveryStep.IdClientAddress
                            this.pickUpAddressId =
                                +this.pickUpStep.IdClientAddress
                            this.deliveryStepToUpdate =
                                this.roundTrip === true
                                    ? this.formData.OrderSteps.length[1]
                                    : this.formData.OrderSteps.length - 1
                            this.pickUpStepToUpdate = card.pick_up.index
                            this.packageInCreationIndex = card.package.index
                            this.orderIndexInUpdate = card.order_index

                            this.selectedMethod = this.methodOptions.find(
                                (option) =>
                                    option.name === this.formData.Method,
                            )
                            // console.log(card)
                            this.selectedVehicleType =
                                this.vehicleTypeOptions.find(
                                    (option) =>
                                        option.value === card.vehicle_type,
                                )
                            this.processing = false
                            this.show = true
                        }
                    }, 300)
                }
            }, 100)
        },
        formatDateTimes(step) {
            if (step.Moment === 'between') {
                step.stepDate = step.FromDateTime.substring(
                    0,
                    step.FromDateTime.indexOf(' '),
                )
                step.fromTime = step.FromDateTime.substring(
                    step.FromDateTime.indexOf(' ') + 1,
                )
                step.fromTime = step.fromTime.substring(
                    0,
                    step.fromTime.length - 3,
                )
                step.beforeTime = step.BeforeDateTime.substring(
                    step.BeforeDateTime.indexOf(' ') + 1,
                )
                step.beforeTime = step.beforeTime.substring(
                    0,
                    step.beforeTime.length - 3,
                )
            } else if (step.Moment === 'from') {
                step.stepDate = step.FromDateTime.substring(
                    0,
                    step.FromDateTime.indexOf(' '),
                )
                step.fromTime = step.FromDateTime.substring(
                    step.FromDateTime.indexOf(' ') + 1,
                )
                step.fromTime = step.fromTime.substring(
                    0,
                    step.fromTime.length - 3,
                )
                step.beforeTime = ''
            } else if (step.Moment === 'before') {
                step.stepDate = step.BeforeDateTime.substring(
                    0,
                    step.BeforeDateTime.indexOf(' '),
                )

                step.beforeTime = step.BeforeDateTime.substring(
                    step.BeforeDateTime.indexOf(' ') + 1,
                )
                step.beforeTime = step.beforeTime.substring(
                    0,
                    step.beforeTime.length - 3,
                )
                step.fromTime = ''
            }
        },
        setAddressInfo(addressId) {
            this.deliveryAddressId = addressId
            this.clientStore.fetchOneAddress({ id: addressId }).then((re) => {
                const currentAddressInterval = setInterval(() => {
                    if (this.currentDeliveryAddress) {
                        clearInterval(currentAddressInterval)

                        this.deliveryStep = {
                            Type: 'delivery',
                            Id: this.formData.OrderSteps.length
                                ? this.formData.OrderSteps[1].Id
                                : '',
                            Status: this.formData.OrderSteps.length
                                ? this.formData.OrderSteps[1].Status
                                : 'new',
                            StepNumber:
                                this.roundTrip === true
                                    ? 2
                                    : this.isEditPackage === true
                                      ? this.formData.OrderSteps.length
                                      : 2, // +this.pickUpStep.StepNumber + 1, // ne va pas marcher
                            IdClientAddress: this.currentDeliveryAddress.id,
                            AddressName: this.currentDeliveryAddress.name,
                            Address1: this.currentDeliveryAddress.address,
                            Address2:
                                this.currentDeliveryAddress.address2 || '',
                            Address3:
                                this.currentDeliveryAddress.address3 || '',
                            Address4:
                                this.currentDeliveryAddress.address4 || '',
                            PostalCode: this.currentDeliveryAddress.postal_code,
                            City: this.currentDeliveryAddress.city,
                            Country: 'FRA',
                            Latitude: this.currentDeliveryAddress.lat,
                            Longitude: this.currentDeliveryAddress.lng,
                            IdDriver: this.currentDeliveryAddress.IdDriver,
                            CreationUserId:
                                this.formData.OrderSteps.length &&
                                this.formData.OrderSteps[1]
                                    ? this.formData.OrderSteps[1].CreationUserId
                                    : +this.user.id,
                            ModificationUserId: +this.user.id,
                            TypeVehicle:
                                this.formData.OrderSteps.length &&
                                this.formData.OrderSteps[1]
                                    ? this.formData.OrderSteps[1].TypeVehicle
                                    : this.formData.VehicleType,
                            Hours: this.currentDeliveryAddress.hours ?? null,
                            Moment: this.deliveryMoment,
                            FromDateTime: '',
                            BeforeDateTime: '',
                            beforeTime: this.beforeTimeDeliv,
                            fromTime: this.fromTimeDeliv,
                            stepDate: this.stepDateDeliv,
                        }
                        if (this.roundTrip === true) {
                            this.roundTripSteps[0].IdClientAddress =
                                this.currentDeliveryAddress.id
                            this.roundTripSteps[0].AddressName =
                                this.currentDeliveryAddress.name
                            this.roundTripSteps[0].Address1 =
                                this.currentDeliveryAddress.address
                            this.roundTripSteps[0].Address2 =
                                this.currentDeliveryAddress.address2 ?? ''
                            this.roundTripSteps[0].Address3 =
                                this.currentDeliveryAddress.address3 ?? ''
                            this.roundTripSteps[0].Address4 =
                                this.currentDeliveryAddress.address4 ?? ''
                            this.roundTripSteps[0].PostalCode =
                                this.currentDeliveryAddress.postal_code
                            this.roundTripSteps[0].Hours =
                                this.currentDeliveryAddress.hours

                            this.roundTripSteps[0].City =
                                this.currentDeliveryAddress.city
                            this.roundTripSteps[0].Country = 'FRA'
                            this.roundTripSteps[0].Latitude =
                                this.currentDeliveryAddress.lat
                            this.roundTripSteps[0].Longitude =
                                this.currentDeliveryAddress.lng
                            this.roundTripSteps[0].ModificatioUserId =
                                +this.user.id
                            this.roundTripSteps[0].CreationUserId =
                                this.formData.OrderSteps.length &&
                                this.formData.OrderSteps[2]
                                    ? this.formData.OrderSteps[2].CreationUserId
                                    : +this.user.id
                            this.roundTripSteps[0].TypeVehicle =
                                this.formData.OrderSteps.length &&
                                this.formData.OrderSteps[2]
                                    ? this.formData.OrderSteps[2].TypeVehicle
                                    : this.formData.VehicleType
                        }
                        this.liveRefresh()
                    }
                }, 1000)
            })
        },
        setPackageContainer(container) {
            if (+container.Height !== 0 && this.isEditPackage === false) {
                this.packageHeight = +container.Height
            }
            if (
                this.isEditPackage === true &&
                this.formData.OrderPackages[this.packageInCreationIndex]
                    .IdContainer !== container.Id
            ) {
                this.packageHeight = +container.Height
            }

            if (
                (this.duplication === true || this.copyToNext === true) &&
                +this.formData.OrderPackages[this.packageInCreationIndex]
                    .IdContainer !== +container.Id
            ) {
                this.packageHeight = +container.Height
            }
            const weight = +container.Weight
            const value = !Number.isNaN(+container.Value) ? +container.Value : 0
            this.formData.OrderPackages[
                this.packageInCreationIndex
            ].IdContainer = container.Id
            this.formData.OrderPackages[this.packageInCreationIndex].Width =
                +container.Width
            this.formData.OrderPackages[this.packageInCreationIndex].Length =
                +container.Length
            this.formData.OrderPackages[this.packageInCreationIndex].Height =
                this.packageHeight
            this.formData.OrderPackages[this.packageInCreationIndex].Weight =
                weight
            this.formData.OrderPackages[this.packageInCreationIndex].Value =
                value
        },
        setPickUp(addressId) {
            this.pickUpAddressId = addressId

            const payload = {
                id: addressId,
                origin: true,
            }
            this.clientStore.fetchOneAddress(payload)

            const currentPickupInterval = setInterval(() => {
                if (this.currentClientPickup) {
                    clearInterval(currentPickupInterval)

                    this.pickUpStep = {
                        Id: this.formData.OrderSteps.length
                            ? this.formData.OrderSteps[0].Id
                            : '',
                        Type: 'pickup',
                        Status: this.formData.OrderSteps.length
                            ? this.formData.OrderSteps[0].Status
                            : 'new',
                        StepNumber: 1,
                        IdClientAddress: this.currentClientPickup.id,
                        AddressName: this.currentClientPickup.name,
                        Address1: this.currentClientPickup.address,
                        Address2: this.currentClientPickup.address2 ?? '',
                        Address3: this.currentClientPickup.address3 ?? '',
                        Address4: this.currentClientPickup.address4 ?? '',
                        PostalCode: this.currentClientPickup.postal_code,
                        Hours: this.currentClientPickup.hours,

                        City: this.currentClientPickup.city,
                        Country: 'FRA',
                        Latitude: this.currentClientPickup.lat,
                        Longitude: this.currentClientPickup.lng,
                        CreationUserId:
                            this.formData.OrderSteps.length &&
                            this.formData.OrderSteps[0]
                                ? this.formData.OrderSteps[0].CreationUserId
                                : +this.user.id,

                        ModificationUserId: +this.user.id,
                        IdDriver: null,
                        TypeVehicle:
                            this.formData.OrderSteps.length &&
                            this.formData.OrderSteps[0]
                                ? this.formData.OrderSteps[0].TypeVehicle
                                : this.formData.VehicleType,
                        FromDateTime: '',
                        Moment: this.pickupMoment,
                        BeforeDateTime: '',
                        beforeTime: this.beforeTimePickup,
                        fromTime: this.fromTimePickup,
                        stepDate: this.stepDatePickup,
                    }
                    if (this.roundTrip === true) {
                        this.roundTripSteps[1].IdClientAddress =
                            this.currentClientPickup.id
                        this.roundTripSteps[1].AddressName =
                            this.currentClientPickup.name
                        this.roundTripSteps[1].Address1 =
                            this.currentClientPickup.address
                        this.roundTripSteps[1].Address2 =
                            this.currentClientPickup.address2 ?? ''
                        this.roundTripSteps[1].Address3 =
                            this.currentClientPickup.address3 ?? ''
                        this.roundTripSteps[1].Address4 =
                            this.currentClientPickup.address4 ?? ''
                        this.roundTripSteps[1].PostalCode =
                            this.currentClientPickup.postal_code
                        this.roundTripSteps[1].Hours =
                            this.currentClientPickup.hours

                        this.roundTripSteps[1].City =
                            this.currentClientPickup.city
                        this.roundTripSteps[1].Country = 'FRA'
                        this.roundTripSteps[1].Latitude =
                            this.currentClientPickup.lat
                        this.roundTripSteps[1].Longitude =
                            this.currentClientPickup.lng
                        this.roundTripSteps[1].CreationUserId =
                            this.formData.OrderSteps.length &&
                            this.formData.OrderSteps[3]
                                ? this.formData.OrderSteps[3].CreationUserId
                                : +this.user.id
                        this.roundTripSteps[1].ModificationUserId =
                            +this.user.id
                        this.roundTripSteps[1].TypeVehicle =
                            this.formData.OrderSteps.length &&
                            this.formData.OrderSteps[3]
                                ? this.formData.OrderSteps[3].TypeVehicle
                                : this.formData.VehicleType
                    }
                }
                this.liveRefresh()
            }, 1000)
        },
        async submitModal(copyToNext) {
            // if (!this.isOdd(this.newStep.StepNumber)) {
            this.badTimeRangeSteps = false

            if (
                ((this.pickupMoment === 'from' ||
                    this.pickupMoment === 'between') &&
                    this.deliveryMoment === 'before' &&
                    this.fromTimePickup >= this.beforeTimeDeliv) ||
                (this.pickupMoment === 'from' &&
                    this.deliveryMoment === 'between' &&
                    this.fromTimePickup >= this.beforeTimeDeliv) ||
                (this.pickupMoment === 'between' &&
                    this.deliveryMoment === 'between' &&
                    ((this.fromTimePickup >= this.beforeTimeDeliv &&
                        this.fromTimePickup >= this.fromTimeDeliv) ||
                        this.fromTimePickup >= this.beforeTimeDeliv))
            ) {
                this.badTimeRangeSteps = true
            }
            if (this.badTimeRangeSteps === false) {
                this.resetFormsValidations()

                // if (this.containerCreation === true) {
                //     this.containerNameError = this.containerName === ''
                //     if (this.containerNameError === false) {
                //         this.createNewContainer(
                //             this.formData.OrderPackages[
                //                 this.packageInCreationIndex
                //             ]
                //         )
                //     } else {
                //         this.isCopying = false
                //         this.creating = false

                //         this.$toast.error(this.$t('bad_packages'))
                //         return false
                //     }
                // }
                // const interval = setInterval(() => {
                //     if (this.containerCreation === false) {
                //         clearInterval(interval)

                //         this.formData.OrderPackages[
                //             this.packageInCreationIndex
                //         ].IdContainer = +this.pickedContainerId
                if (this.isEditPackage === false && this.currentDelivery) {
                    this.saveNewOrderToDelivery(copyToNext)
                } else if (
                    !this.currentDelivery ||
                    this.currentDelivery.orderClient[this.orderIndexInUpdate]
                        .OrderPackages.length === 0
                ) {
                    this.createDelivery(copyToNext)
                } else {
                    this.updateOrderClient(copyToNext)
                }
                //     }
                // }, 100)
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },
        closeModal(fromSave = false, copyToNext = false) {
            if (copyToNext === false) {
                this.deliveryStepToUpdate = null
                this.pickUpStepToUpdate = null
                this.pickUpAddressOptions = []
                this.deliveryStep = null
                this.pickUpStep = null
                this.creating = false
                if (this.isEditPackage === false && fromSave === false) {
                    this.formData.OrderPackages.pop()
                }
                this.pickUpAddressId = null
                this.deliveryAddressId = null
                this.pickedContainerId = null
                this.packageInCreationIndex = null
                this.addressOptions = []
                this.isEditPackage = false
                this.orderIndexInUpdate = null
                this.containerName = ''
                this.clientStore.$patch({
                    currrentContainer: null,
                    currentAddress: null,
                })

                this.packageQuantity = 1
                this.packageHeight = 0
                this.formData = {}
                this.beforeTimeDeliv = '18:00'
                this.fromTimeDeliv = '08:00'
                this.beforeTimePickup = '18:00'
                this.fromTimePickup = '08:00'
                this.stepDateDeliv = new Date().toISOString().substr(0, 10)
                this.stepDatePickup = new Date().toISOString().substr(0, 10)
                this.roundTripSteps = []
                this.roundTrip = false
            } else if (copyToNext === true && this.duplication === true) {
                this.deliveryStep = null
                this.pickUpStep = null
            } else if (copyToNext === true) {
                this.isCopying = true
            }
            this.isEditPackage = false
            this.addressCreation = false
            this.originAddressCreation = false
            this.show = false
            this.adding = false
            this.duplication = false
            this.resetFormsValidations()
            if (this.$route.params.id) {
                this.fetchOneDelivery(this.$route.params.id).then((res) => {
                    this.refresh += 1
                })
            }
        },
        resetFormsValidations() {
            this.packageTypeErrors = []
            this.packageWeightErrors = []
            this.packageReferenceErrors = []
            this.packageWidthErrors = []
            this.packageLengthErrors = []
            this.packageHeightErrors = []
            this.containerNameError = false

            this.packageQuantityErrors = []
            this.v$.deliveryStep.$reset()
            this.v$.pickUpStep.$reset()
            this.v$.formData.$reset()
        },
        addPackage(
            copyToNext = false,
            packageToDuplicateIndex = null,
            orderIndex = null,
        ) {
            this.adding = true
            this.resetFormsValidations()
            const date = new Date()

            if (copyToNext === false) {
                this.formData.OrderPackages.push({
                    PackageNumber: this.formData.OrderPackages.length + 1,
                    Width: 0,
                    Length: 0,
                    Height: 0,
                    Weight: 0,
                    SameOpIndexes: [],
                    Value: 0,
                    Quantity: 1,
                    TemperatureOption: 'none',
                    IdContainer:
                        this.idContDefault > 0
                            ? this.idContDefault
                            : this.currentClient.default_container > 0
                              ? +this.currentClient.default_container
                              : +this.user.env.defaultContainer,
                    Id: '',
                    CreationUserId: this.user.id,
                    ModificatioUserId: this.user.id,
                    packageTypeErrors: [],
                    packageValueErrors: [],
                    packageWeightErrors: [],
                    packageReferenceErrors: [],
                    packageWidthErrors: [],
                    packageLengthErrors: [],
                    packageHeightErrors: [],
                    packageQuantityErrors: [],

                    containerNameError: false,
                })
                this.refreshMS += 1
                this.packageInCreationIndex =
                    this.formData.OrderPackages.length - 1
            } else {
                // const indexToUse =
                //     packageToDuplicateIndex !== null
                //         ? packageToDuplicateIndex
                //         : this.packageInCreationIndex

                // this.packageQuantity =
                //     this.currentDelivery.orderClient[orderIndex].NbPackages
                // this.packageHeight =
                //     +this.currentDelivery.orderClient[orderIndex].OrderPackages[
                //         indexToUse
                //     ].Height
                this.currentDelivery.orderClient[
                    orderIndex
                ].groupedPackages.forEach((pack, index) => {
                    this.formData.OrderPackages.push({
                        PackageNumber: pack.PackageNumber,
                        // Reference: realRef,
                        Width: pack.Width,
                        Length: pack.Length,
                        Height: pack.Height,
                        Weight: pack.Weight,
                        Value: pack.Value,
                        Quantity: +pack.Quantity,
                        IdContainer: +pack.IdContainer,
                        TemperatureOption: pack.TemperatureOption,
                        Id: '',
                        CreationUserId: this.user.id,
                        ModificatioUserId: this.user.id,
                        packageTypeErrors: [],
                        packageValueErrors: [],
                        packageWeightErrors: [],
                        packageReferenceErrors: [],
                        packageWidthErrors: [],
                        packageLengthErrors: [],
                        packageHeightErrors: [],
                        packageQuantityErrors: [],
                        SameOpIndexes: [],

                        containerNameError: false,
                    })
                    // this.refreshMS += 1
                })
            }
        },

        back() {
            this.adding = false

            this.resetFormsValidations()

            if (this.isB2C === false) {
                this.$router.push(this.$router.options.history.state.back)
            } else {
                this.$router.push({ name: 'orders' })
            }
            this.$emit('back', 'orders')
        },
        fillInAddress(autocomplete, modal = false) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address

            this.deliveryStep.Address2 = ''
            this.deliveryStep.PostalCode = ''
            this.deliveryStep.City = ''

            const userInput = this.deliveryStep.Address1
            this.deliveryStep.Latitude = place.geometry.location.lat()
            this.deliveryStep.Longitude = place.geometry.location.lng()
            this.deliveryStep.Address1 = ''

            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name
                if (addressType === 'postal_code') {
                    this.deliveryStep.PostalCode = val
                } else if (addressType === 'locality') {
                    this.deliveryStep.City = val
                } else if (addressType === 'street_number') {
                    this.deliveryStep.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.deliveryStep.Address1 =
                        this.deliveryStep.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.deliveryStep.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    this.deliveryStep.Address2 = `${val}`
                }
                // if (
                //     (place.types[0] !== 'street_number' ||
                //         place.types[0] !== 'route') &&
                //     addressType === 'locality' &&
                //     this.deliveryStep.Address1 === userInput
                // ) {
                //     this.deliveryStep.Address1 = ` ${val}`
                // }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    this.deliveryStep.Address2 === '' &&
                    place.types[0] !== 'premise'
                ) {
                    this.deliveryStep.Address2 = place.name
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    this.deliveryStep.Name === ''
                ) {
                    if (!modal) {
                        this.deliveryStep.Name = place.name
                    }
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    this.deliveryStep.AddressName === ''
                ) {
                    if (modal) {
                        this.deliveryStep.AddressName = place.name
                    }
                }
            })
        },
        setValidatedSteps(tempSteps = false) {
            // TODO VA PETER SI on peut modifier les round trip et si le user ajoute des steps
            // if (
            //     this.roundTrip === false &&
            //     this.formData.OrderSteps.length > 2
            // ) {
            //     this.formData.OrderSteps.pop()
            //     this.formData.OrderSteps.pop()
            // }

            let steps = []
            let from = `${this.pickUpStep.stepDate} ${this.pickUpStep.fromTime}`

            let before = `${this.pickUpStep.stepDate} ${this.pickUpStep.beforeTime}`

            if (this.pickUpStep.Moment === 'between') {
                this.pickUpStep.FromDateTime = from
                this.pickUpStep.BeforeDateTime = before
            } else if (this.pickUpStep.Moment === 'from') {
                this.pickUpStep.FromDateTime = from
                this.pickUpStep.BeforeDateTime = ''
            } else if (this.pickUpStep.Moment === 'before') {
                this.pickUpStep.FromDateTime = ''
                this.pickUpStep.BeforeDateTime = before
            }
            if (
                this.pickUpStep.TypeVehicle === '' ||
                this.pickUpStep.TypeVehicle === null
            ) {
                this.pickUpStep.TypeVehicle =
                    this.formData.VehicleType === ''
                        ? null
                        : this.formData.VehicleType
            }
            // delete this.pickUpStep.stepDate
            // delete this.pickUpStep.fromTime
            // delete this.pickUpStep.beforeTime

            if (this.pickUpStepToUpdate !== null) {
                steps = structuredClone(this.formData.OrderSteps)
                // this.pickUpStep.Id =
                //     +this.formData.OrderSteps[this.pickUpStepToUpdate].Id
                steps[this.pickUpStepToUpdate] = this.pickUpStep
            } else {
                steps.push(this.pickUpStep)
            }
            if (steps.length === 1) {
                this.formData.DateBegin = this.pickUpStep.stepDate
                this.formData.DateEnd = this.pickUpStep.stepDate
            }

            from = `${this.deliveryStep.stepDate} ${this.deliveryStep.fromTime}`

            before = `${this.deliveryStep.stepDate} ${this.deliveryStep.beforeTime}`

            if (this.deliveryStep.Moment === 'between') {
                this.deliveryStep.FromDateTime = from
                this.deliveryStep.BeforeDateTime = before
            } else if (this.deliveryStep.Moment === 'from') {
                this.deliveryStep.FromDateTime = from
                this.deliveryStep.BeforeDateTime = ''
            } else if (this.deliveryStep.Moment === 'before') {
                this.deliveryStep.FromDateTime = ''
                this.deliveryStep.BeforeDateTime = before
            }
            if (this.deliveryStepToUpdate !== null) {
                // this.deliveryStep.Id =
                //     +this.formData.OrderSteps[this.deliveryStepToUpdate].Id
                steps = structuredClone(this.formData.OrderSteps)

                steps[this.deliveryStepToUpdate] = this.deliveryStep
            } else {
                steps.push(this.deliveryStep)
            }
            if (steps.length === 1) {
                this.formData.DateBegin = this.deliveryStep.stepDate
                this.formData.DateEnd = this.deliveryStep.stepDate
            } else {
                this.formData.DateEnd = this.deliveryStep.stepDate
            }
            if (
                this.deliveryStep.TypeVehicle === '' ||
                this.deliveryStep.TypeVehicle === null
            ) {
                this.deliveryStep.TypeVehicle =
                    this.formData.VehicleType === ''
                        ? null
                        : this.formData.VehicleType
            }
            if (this.roundTrip === true) {
                // TODO reformater les dates
                this.roundTripSteps.forEach((element) => {
                    from = `${element.stepDate} ${element.fromTime}`

                    before = `${element.stepDate} ${element.beforeTime}`

                    if (element.Moment === 'between') {
                        element.FromDateTime = from
                        element.BeforeDateTime = before
                    } else if (element.Moment === 'from') {
                        element.FromDateTime = from
                        element.BeforeDateTime = ''
                    } else if (element.Moment === 'before') {
                        element.FromDateTime = ''
                        element.BeforeDateTime = before
                    }
                    if (
                        element.TypeVehicle === '' ||
                        element.TypeVehicle === null
                    ) {
                        element.TypeVehicle =
                            this.formData.VehicleType === ''
                                ? null
                                : this.formData.VehicleType
                    }
                })
                const roundPickup = structuredClone(this.roundTripSteps[0])
                const roundDelivery = structuredClone(this.roundTripSteps[1])

                steps[2] = roundPickup
                steps[3] = roundDelivery
            }
            if (tempSteps === false) {
                this.formData.OrderSteps = steps
            } else {
                return steps
            }
            return true
        },
        async createDelivery(copyToNext = false) {
            this.creating = true
            this.resetFormsValidations()

            this.formData.IdClient = this.currentClient
                ? this.currentClient.id
                : this.user.idClient
            // this.formData.OrderPackages[this.packageInCreationIndex].Quantity =
            //     this.packageQuantity
            if ((await this.arePackagesValid()) === true) {
                this.formData.CreationUserId = this.user.id
                this.formData.PacketNumber = this.formData.OrderPackages.length
                // todo voir ici car step date va cahnger a chaque création de new step faire un tableau ? mais pb si update du step avec un tableau à remettre a jour si le step cahnge de place
                const areStepsValid = await this.areStepsValid(copyToNext)
                if (areStepsValid === true) {
                    this.setValidatedSteps()
                    const isValid = await this.v$.formData.$validate()
                    const hasName = await this.v$.newDeliveryName.$validate()
                    // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                    if (isValid && hasName) {
                        const deliveryForm = {
                            // Reference: `${
                            //     this.prefix.delivery
                            // }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
                            CreationUserId: this.user.id,
                            IdClient: this.formData.IdClient,
                            Name: this.newDeliveryName,
                            CreationDate: '',

                            OrdersClient: [
                                {
                                    RoundTrip: this.roundTrip,
                                    Recurrent: false,
                                    PaymentStatus:
                                        this.currentClient.payment_type ===
                                        'web'
                                            ? 'waiting'
                                            : 'none',
                                    PaymentType: this.formData.PaymentType,
                                    IdUniverse: this.formData.IdUniverse,
                                    OrderClientType:
                                        this.formData.OrderClientType,

                                    IdExternal: this.formData.IdExternal,
                                    VehicleType:
                                        this.formData.VehicleType === ''
                                            ? null
                                            : this.formData.VehicleType,
                                    Orders: [
                                        {
                                            acceptUnassigned: false,
                                            force: false,
                                            IdExternal:
                                                this.formData.IdExternal,

                                            IdClient: this.formData.IdClient,
                                            IdUserContact:
                                                this.formData.IdUserContact ??
                                                this.user.id,
                                            IdDriver:
                                                this.formData.IdDriver ?? '',
                                            IdInvoice:
                                                this.formData.IdInvoice ?? '',
                                            Reference: this.formData.Reference,
                                            Status: this.formData.Status,
                                            Method: this.formData.Method,

                                            Type: this.formData.Type,
                                           
                                            CommentInternal:
                                                this.formData.CommentInternal ??
                                                '',
                                            CommentClient:
                                                this.formData.CommentClient ??
                                                '',
                                            OrderStepsHTML: null,
                                            CreationUserId: this.user.id,
                                            OrderSteps:
                                                this.formData.OrderSteps,
                                            OrderPackages:
                                                this.formData.OrderPackages,

                                            DateBegin: this.formData.DateBegin,
                                            DateEnd: this.formData.DateEnd,
                                            OrderOptions: null,
                                            OrderOption: [],
                                        },
                                    ],
                                },
                            ],
                        }
                        try {
                            const response =
                                await this.orderStore.createDelivery(
                                    deliveryForm,
                                )
                            if (response) {
                                this.orderStore
                                    .fetchOneDelivery(+response.data.Id)
                                    .then(() => {
                                        this.$router
                                            .push({
                                                name: 'update_order',
                                                params: {
                                                    id: +response.data.Id,
                                                },
                                            })
                                            .then(() => {
                                                if (copyToNext === true) {
                                                    this.duplication = false
                                                    this.duplicate(0, 1, 0, 0)
                                                } else {
                                                    this.closeModal(
                                                        true,
                                                        copyToNext,
                                                    )
                                                    this.orderStore.fetchOneDelivery(
                                                        +response.data.Id,
                                                    )
                                                }

                                                this.orderStore.searchUnpaidOrdersByClient(
                                                    {
                                                        clientId:
                                                            this.currentClient
                                                                .id,
                                                    },
                                                )

                                                this.$toast.success(
                                                    this.$t(
                                                        'delivery_create_ok',
                                                    ),
                                                )
                                            })
                                    })
                            }
                        } catch (err) {
                            console.log(err)
                            this.creating = false
                            this.isCopying = false

                            this.$toast.error(
                                `${this.$t('delivery_create_ko')}`,
                            )
                        }
                    } else {
                        this.creating = false
                        this.isCopying = false

                        this.$toast.error(this.$t('form_errors'))
                    }
                } else {
                    console.log('invalid steps')
                    console.log(this.deliveryStep)
                    console.log(this.v$.deliveryStep.$errors)
                    this.creating = false
                    this.isCopying = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.isCopying = false
                this.creating = false

                this.$toast.error(this.$t('bad_packages'))
            }
        },
        async saveNewOrderToDelivery(copyToNext = false) {
            this.creating = true
            this.resetFormsValidations()

            if ((await this.arePackagesValid()) === true) {
                const areStepsValid = await this.areStepsValid(copyToNext)
                if (areStepsValid === true) {
                    this.setValidatedSteps()
                    this.formData.ModificationUserId = this.user.id
                    if (this.formData.IdClient === '') {
                        this.formData.IdClient = this.user.idClient
                    }
                    this.formData.PacketNumber = this.packageQuantity
                    const isValid = await this.v$.formData.$validate()

                    if (isValid === true) {
                        const OrderClientForm = {
                            CreationUserId: this.user.id,
                            IdDelivery: this.currentDelivery.id,
                            IdClient: this.formData.IdClient,
                            IdExternal: this.formData.IdExternal,
                            Recurrent: false,
                            RoundTrip: this.roundTrip,
                            WithVAT: this.formData.WithVAT,

                            PaymentStatus:
                                this.currentClient.payment_type === 'web'
                                    ? 'waiting'
                                    : 'none',
                            PaymentType: this.formData.PaymentType,
                            IdUniverse: this.formData.IdUniverse,
                            OrderClientType: this.formData.OrderClientType,
                            Price: this.formData.Price,
                            CreationDate: '',
                            VehicleType:
                                this.formData.VehicleType === ''
                                    ? null
                                    : this.formData.VehicleType,
                            Orders: [
                                {
                                    acceptUnassigned: false,
                                    force: false,
                                    IdExternal: this.formData.IdExternal,

                                    IdClient: this.formData.IdClient,
                                    IdUserContact:
                                        this.formData.IdUserContact ??
                                        this.user.id,
                                    IdDriver: this.formData.IdDriver ?? '',
                                    IdInvoice: this.formData.IdInvoice ?? '',
                                    Reference: this.formData.Reference,
                                    Status:
                                        this.currentClient.payment_type ===
                                        'web'
                                            ? 'waitingforpayment'
                                            : 'new',
                                    Method: this.formData.Method,

                                    OrderClientType:
                                        this.formData.OrderClientType,
                                    PaymentType: this.formData.PaymentType,
                                    IdUniverse: this.formData.IdUniverse,
                                    CommentInternal:
                                        this.formData.CommentInternal ?? '',
                                    CommentClient:
                                        this.formData.CommentClient ?? '',
                                    OrderStepsHTML: null,
                                    CreationUserId: this.user.id,
                                    OrderSteps: this.formData.OrderSteps,
                                    OrderPackages: this.formData.OrderPackages,

                                    DateBegin: this.formData.DateBegin,
                                    DateEnd: this.formData.DateEnd,
                                    OrderOptions: null,
                                    OrderOption: [],
                                },
                            ],
                        }
                        try {
                            const response =
                                await this.orderStore.createOrderClient(
                                    OrderClientForm,
                                )
                            if (response.status === 201) {
                                this.orderStore
                                    .fetchOneDelivery(this.$route.params.id)
                                    .then((res) => {
                                        if (copyToNext === true) {
                                            this.duplication = false
                                            this.orderIndexInUpdate =
                                                res.data.OrdersClient.length - 1

                                            this.duplicate(
                                                this.pickUpStep.StepNumber - 1,
                                                this.deliveryStep.StepNumber -
                                                    1,
                                                this.packageInCreationIndex,
                                                this.orderIndexInUpdate,
                                            )
                                        } else {
                                            this.closeModal(true, copyToNext)
                                            this.orderStore.fetchOneDelivery(
                                                this.$route.params.id,
                                            )
                                        }
                                        this.creating = false
                                        this.orderIndexInUpdate = null
                                        this.orderStore.searchUnpaidOrdersByClient(
                                            {
                                                clientId: this.currentClient.id,
                                            },
                                        )
                                        this.$toast.success(
                                            this.$t('delivery_create_ok'),
                                        )
                                    })
                            } else {
                                this.$toast.error(this.$t('delivery_create_ko'))
                                this.$toast.error(response.data.message)
                                this.isCopying = false
                                this.creating = false
                            }
                        } catch (err) {
                            this.$toast.error(
                                `${this.$t('delivery_create_ko')}`,
                            )
                            this.$toast.error(err)
                            this.isCopying = false
                            this.creating = false
                        }
                    } else {
                        this.creating = false
                        this.isCopying = false

                        this.$toast.error(this.$t('form_errors'))
                    }
                } else {
                    this.creating = false
                    this.isCopying = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.creating = false
                this.isCopying = false

                this.$toast.error(this.$t('bad_packages'))
            }
        },
        async updateOrderClient(copyToNext = false) {
            this.creating = true
            this.resetFormsValidations()

            if ((await this.arePackagesValid()) === true) {
                const areStepsValid = await this.areStepsValid(copyToNext)
                if (areStepsValid === true) {
                    this.setValidatedSteps()
                    this.formData.ModificationUserId = this.user.id
                    this.formData.PacketNumber =
                        this.formData.OrderPackages.Quantity

                    const isValid = await this.v$.formData.$validate()
                    if (isValid === true) {
                        const order =
                            this.currentDelivery.orderClient[
                                this.orderIndexInUpdate
                            ].Orders[0]
                        // const form = []
                        // this.currentDelivery.orderClient[
                        //     this.orderIndexInUpdate
                        // ].Orders.forEach((order) => {
                        this.deliveryStep.IdOrder = this.formData.Id
                        this.pickUpStep.IdOrder = this.formData.Id
                        const packages = []

                        this.formData.OrderPackages.forEach((pack) => {
                            let packageForm = {
                                IdOrder: pack.IdOrder,
                                // IdVehicleType:
                                //     this.formData.OrderPackages[0].IdVehicleType,
                                Reference: pack.Reference,
                                PackageNumber: pack.PackageNumber,

                                Width: pack.Width,
                                Length: pack.Length,
                                Height: pack.Height,
                                Weight: pack.Weight,
                                Value: pack.Value,
                                Price: pack.Price,
                                Id: pack.Id,
                                IdContainer: pack.IdContainer,
                                LabelFile: pack.LabelFile,
                                LastDeliveryCode: pack.LastDeliveryCode,
                                LastDeliveryCodeDate: pack.LastDeliveryCodeDate,
                                TemperatureOption: pack.TemperatureOption,
                                ModificationUserId: this.user.id,
                                CreationUserId: pack.CreationUserId,
                                Quantity: pack.Quantity,
                            }

                            packages.push(packageForm)

                            pack.SameOpIndexes.forEach((sameToUpdate) => {
                                const realCopy =
                                    this.currentOrder.real_order_packages[
                                        sameToUpdate
                                    ]
                                packageForm = {
                                    IdOrder: pack.IdOrder,
                                    // IdVehicleType:
                                    //     this.formData.OrderPackages[0].IdVehicleType,
                                    Reference: realCopy.Reference,
                                    PackageNumber: realCopy.PackageNumber,

                                    Width: pack.Width,
                                    Length: pack.Length,
                                    Height: pack.Height,
                                    Weight: pack.Weight,
                                    Value: pack.Value,
                                    Price: pack.Price,
                                    Id: realCopy.Id,
                                    IdContainer: pack.IdContainer,
                                    LabelFile: realCopy.LabelFile,
                                    LastDeliveryCode: realCopy.LastDeliveryCode,
                                    LastDeliveryCodeDate:
                                        realCopy.LastDeliveryCodeDate,
                                    TemperatureOption: pack.TemperatureOption,
                                    ModificationUserId: this.user.id,
                                    CreationUserId: realCopy.CreationUserId,
                                }
                                packages.push(packageForm)
                            })

                            if (
                                pack.Quantity !==
                                pack.SameOpIndexes.length + 1
                            ) {
                                if (
                                    pack.Quantity >
                                    pack.SameOpIndexes.length + 1
                                ) {
                                    const diff =
                                        pack.Quantity -
                                        (pack.SameOpIndexes.length + 1)
                                    for (
                                        let index = 0;
                                        index < diff;
                                        index += 1
                                    ) {
                                        const newPackage = {
                                            IdOrder: pack.IdOrder,
                                            // IdVehicleType: pack.IdVehicleType,
                                            Width: pack.Width,
                                            Length: pack.Length,
                                            Height: pack.Height,
                                            Weight: pack.Weight,
                                            Value: pack.Value,
                                            Price: pack.Price,
                                            IdContainer: pack.IdContainer,
                                            LabelFile: pack.LabelFile,
                                            LastDeliveryCode:
                                                pack.LastDeliveryCode,
                                            LastDeliveryCodeDate:
                                                pack.LastDeliveryCodeDate,
                                            TemperatureOption:
                                                pack.TemperatureOption,
                                            ModificationUserId: this.user.id,
                                            CreationUserId: this.user.id,
                                        }
                                        packages.push(newPackage)
                                    }
                                } else {
                                    const diff =
                                        pack.SameOpIndexes.length +
                                        1 -
                                        pack.Quantity
                                    for (
                                        let index = 0;
                                        index < diff;
                                        index += 1
                                    ) {
                                        packages.pop()
                                    }
                                }
                            }
                        })
                        const form = {
                            IdDelivery: this.currentDelivery.id,
                            ModificationUserId: this.user.id,
                            IdExternal: this.formData.IdExternal,
                            RoundTrip: this.roundTrip,
                            Recurrent: order.OrderClient.Recurrent,
                            PaymentStatus: order.OrderClient.PaymentStatus,
                            PaymentType: this.formData.PaymentType,
                            IdUniverse: this.formData.IdUniverse,
                            OrderClientType: this.formData.OrderClientType,
                            Price: this.formData.Price,
                            WithVAT: this.formData.WithVAT,

                            VehicleType:
                                this.formData.VehicleType === ''
                                    ? null
                                    : this.formData.VehicleType,
                            Orders: [
                                {
                                    acceptUnassigned: false,
                                    force: false,
                                    RoundTrip: false,
                                    IdExternal: this.formData.IdExternal,

                                    Id: this.formData.Id,
                                    OrderOptions: [],

                                    IdDelivery: this.currentDelivery.id,
                                    IdOrderClient:
                                        this.currentDelivery.orderClient[
                                            this.orderIndexInUpdate
                                        ].Id,
                                    IdClient: this.formData.IdClient,
                                    IdUserContact: order.IdUserContact,
                                    IdDriver: order.IdDriver,
                                    IdInvoice: order.IdInvoice,
                                    DateBegin: this.formData.DateBegin,
                                    DateEnd: this.formData.DateEnd,
                                    DateReception: order.DateReception,
                                    Reference: order.Reference,
                                    Status: order.Status,
                                    Method: this.formData.Method,

                                    Type: order.Type,
                                    CommentInternal: order.CommentInternal,
                                    CommentClient: this.formData.CommentClient,
                                    Duration: order.Duration,
                                    PacketNumber: null,
                                    Weight: order.Weight,
                                    PacketValue: null,
                                    ModificationUserId: this.user.id,

                                    OrderPackages: packages,
                                    OrderSteps: this.formData.OrderSteps,
                                },
                            ],
                        }
                        const params = {
                            id: this.currentDelivery.orderClient[
                                this.orderIndexInUpdate
                            ].Id,
                            form,
                        }
                        try {
                            const response =
                                await this.orderStore.updateOrderClient(params)
                            if (response.status === 200) {
                                this.orderStore
                                    .fetchOneDelivery(this.$route.params.id)
                                    .then(() => {
                                        if (copyToNext === true) {
                                            this.duplication = false
                                            this.isEditPackage = false
                                            this.duplicate(
                                                this.pickUpStep.StepNumber - 1,
                                                this.deliveryStep.StepNumber -
                                                    1,
                                                this.packageInCreationIndex,
                                                this.orderIndexInUpdate,
                                            )
                                        } else {
                                            this.closeModal(true, copyToNext)
                                            this.orderStore.fetchOneDelivery(
                                                this.$route.params.id,
                                            )
                                        }
                                        this.orderStore.searchUnpaidOrdersByClient(
                                            {
                                                clientId: this.currentClient.id,
                                            },
                                        )
                                        this.creating = false
                                        this.orderIndexInUpdate = null
                                        this.$toast.success(
                                            this.$t('delivery_update_ok'),
                                        )
                                    })
                            } else {
                                this.isCopying = false
                                this.$toast.error(this.$t('delivery_update_ko'))
                                this.$toast.error(response.data.message)
                                this.creating = false
                            }
                        } catch (err) {
                            this.isCopying = false

                            this.$toast.error(
                                `${this.$t('delivery_update_ko')}`,
                            )
                            this.$toast.error(err.data.message)
                            this.creating = false
                        }
                    } else {
                        this.isCopying = false

                        this.$toast.error(this.$t('form_errors'))
                    }
                } else {
                    this.creating = false
                    this.isCopying = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.creating = false
                this.isCopying = false

                this.$toast.error(this.$t('bad_packages'))
            }
        },
        async deletePackage(id) {
            const response = await this.orderStore.deleteOrderClient(id)
            if (response.status === 200) {
                this.orderStore.fetchOneDelivery(this.$route.params.id)
                this.$toast.success(this.$t('delivery_delete_ok'))
            } else {
                this.$toast.error(this.$t('delivery_delete_ko'))
            }
        },
    },
    unmounted() {
        clearInterval(this.currentDeliveryInterval)
    },
}
</script>
