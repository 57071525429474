<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'defenses'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="defensesList"
                :items="defenses"
                :columns="columns"
                :storeToSort="'defenses'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @clear="clearFilter($event)"
                @create="openModal(null, true)"
                @update="openModal($event)"
                @delete="doDelete($event)"
                @deleteMany="doDeleteMany($event)"
            >
            </filtrable-table>
        </div>
        <modal
            v-if="show"
            id="addModal"
            :mxWidth="'w-2/3'"
            :canCancel="false"
            :showSubmit="false"
            :mxHeight="'max-h-screen'"
            :buttonAlign="'justify-center'"
        >
            <template #body
                ><create-or-update-slider
                    :isUpdate="isUpdate"
                    :dockId="dockId"
                    :harborId="harborId"
                    :defenseId="defenseId"
                    :newFromDef="newFromDef"
                    @cancel="openModal()"
                    @save="openModal()"
                ></create-or-update-slider></template
        ></modal>

        <modal
            v-if="showConfirmModal"
            :headerText="confirmModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :buttonAlign="'justify-center'"
            @closeModal="actionConfirmation(false)"
            @sendFromModal="actionConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center font-semibold mt-4">
                        {{ $t(confirmModalText) }}
                    </div>
                    <div class="flex justify-center font-semibold mt-4">
                        {{
                            $t(
                                entityTypeToDelete,
                                elementsToConfirm.length === 1 ? 1 : 2,
                            )
                        }}
                    </div>
                    <div
                        v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                        :key="iToConfirm"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span v-if="toConfirm.name" class="mr-1">
                                    {{
                                        `${toConfirm.name} - ${toConfirm.description}`
                                    }}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div
                        class="flex text-xs italic items-center error-msg justify-center mt-4"
                    >
                        <fa-icon
                            :icon="'triangle-exclamation'"
                            class="mr-2 fa-md"
                        ></fa-icon>
                        {{ $t(confirmModalText2) }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useHarborStore } from '@/stores/harborStore'
import { useUserStore } from '@/stores/userStore'
import CreateOrUpdateSlider from '@/views/harbors/CreateOrUpdateSlider.vue'
import { mapActions, mapStores, mapWritableState } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Defenses',
    mixins: [filterList],
    components: {
        FiltrableTable,
        FetchDataError,
        CreateOrUpdateSlider,
        Modal,
    },
    data() {
        return {
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            defensesInterval: null,
            filter: [],
            columns: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
            isUpdate: false,
            show: false,
            entityTypeToDelete: 'defense_tc',
            dockId: null,
            harborId: null,
            defenseId: null,
            showConfirmModal: false,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModalText: 'confirm_modal_text',
            confirmModalText2: 'confirm_modal_text',
            newFromDef: false,
        }
    },
    watch: {
        defenses() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filteredDefenses')
        this.columns = [
            {
                name: 'name',
                filtrable: true,
                dbField: 'Name',
                type: 'search',
                sortable: true,
                isLink: checkAccess(
                    this.userStore,
                    this.defensesActionsAccess.update,
                ),
            },

            {
                name: 'description',
                filtrable: false,

                dbField: 'Description',
                sortable: false,
            },
            {
                name: 'harbor',
                filtrable: true,
                dbField: 'Harbor.Name',
                type: 'search',
                sortable: true,
            },
            {
                name: 'dock',
                filtrable: true,
                dbField: 'Dock.Name',
                type: 'search',
                sortable: true,
            },
            {
                name: 'last_inspection',
                filtrable: false,
                sortable: false,
            },
            {
                name: 'creation_date',
                sortable: true,
                dbField: 'CreationDate',
            },
        ]

        if (filteredList) {
            this.search = filteredList.query

            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        this.setResults()
    },

    unmounted() {
        clearInterval(this.defensesInterval)
    },
    computed: {
        defensesActionsAccess() {
            return actionsAccess.harbors.defenses
        },
        ...mapWritableState(useHarborStore, {
            currentDefense: 'current',
            defenses: 'defenses',
            count: 'countDefenses',
            fetchError: 'fetchError',
            totalPages: 'defensesTotalPages',
            loading: 'loading',
        }),

        ...mapStores(useUserStore),

        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.defensesActionsAccess.update)
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(this.userStore, this.defensesActionsAccess.delete)
            ) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.defensesActionsAccess.delete)
            ) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteMany',
                    icon: 'trash-can',
                })
            }
            return actions
        },
    },
    methods: {
        ...mapActions(useHarborStore, [
            'searchDefenses',
            'fetchOneDefense',
            'deleteDefense',
            'deleteManyDefenses',
        ]),
        upFilter(event) {
            clearInterval(this.defensesInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.defensesActionsAccess.create,
            )
        },
        openModal(id = null, create = false) {
            this.isUpdate = false
            this.newFromDef = false
            this.defenseId = null
            this.harborId = null
            this.dockId = null
            clearInterval(this.defensesInterval)
            if (id) {
                this.defenseId = id
                this.harborId = this.defenses.find((d) => d.id === id).harborId
                this.dockId = this.defenses.find((d) => d.id === id).dockId
                this.isUpdate = true
            } else {
                this.currentDefense = null
                this.newFromDef = true
                if (create === false) {
                    this.setResults()
                }
            }
            this.show = !this.show
        },
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.showConfirmModal = false
        },
        doDelete(id) {
            clearInterval(this.defensesInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            this.entityTypeToDelete = 'defense_tc'

            const defense = this.defenses.find((d) => d.id === id)
            this.elementsToConfirm.push(defense)
            this.showConfirmModal = true

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteDefense(id)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        doDeleteMany(ids) {
            clearInterval(this.defensesInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.entityTypeToDelete = 'defense_tc'

            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text_plural'
            this.confirmModalText2 = 'irreversible_action'
            this.showConfirmModal = true

            ids.forEach((id) => {
                const defense = this.defenses.find((h) => h.id === id)
                this.elementsToConfirm.push(defense)
            })

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteManyDefenses(ids)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },

        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.defensesInterval)
            this.$cookies.remove('filteredDefenses')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.defensesInterval)
            this.$cookies.remove('filteredDefenses')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.searchDefenses(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filteredDefenses', search)
            this.defensesInterval = setInterval(() => {
                this.searchDefenses(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.defensesInterval)
            this.$cookies.remove('filteredDefenses')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
