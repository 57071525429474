export default {
    data() {
        return {
            copyTitle: this.$t('copy_to_clipboard'),
        }
    },
    methods: {
        copyToClipboard(link, withToast = true) {
            navigator.clipboard.writeText(link)
            if (withToast === true) {
                this.$toast.info(this.$t('copied_to_clipboard'))
            }
        },
    },
}
