export default [
    {
        main: { bg: 'bg-red-300', hex: '#fca5a5' },
        secondary: { bg: 'bg-red-500', hex: '#ef4444' },
    }, // 300 HEX #fca5a5

    {
        main: { bg: 'bg-amber-400', hex: '#fbbf24' },
        secondary: { bg: 'bg-amber-600', hex: '#d97706' },
    },
    {
        main: { bg: 'bg-indigo-300', hex: '#a5b4fc' },
        secondary: { bg: 'bg-indigo-500', hex: '#6366f1' },
    },

    {
        main: { bg: 'bg-emerald-300', hex: '#6ee7b7' },
        secondary: { bg: 'bg-emerald-500', hex: '#10b981' },
    },

    {
        main: { bg: 'bg-[#DC7633]', hex: '#DC7633 ' },
        secondary: { bg: 'bg-[#6E2C00]', hex: '#6E2C00' },
    },
    {
        main: { bg: 'bg-[#A569BD]', hex: '#A569BD' },
        secondary: { bg: 'bg-[#4A235A]', hex: '#4A235A' },
    },
    {
        main: { bg: 'bg-orange-300', hex: '#fdba74' },
        secondary: { bg: 'bg-orange-500', hex: '#f97316' },
    },
    {
        main: { bg: 'bg-fuchsia-300', hex: '#f0abfc' },
        secondary: { bg: 'bg-fuchsia-500', hex: '#d946ef' },
    },
    {
        main: { bg: 'bg-cyan-300', hex: '#67e8f9' },
        secondary: { bg: 'bg-cyan-500', hex: '#06b6d4' },
    },
    {
        main: { bg: 'bg-[#A93226] ', hex: '#A93226' },
        secondary: { bg: 'bg-[#641E16]', hex: '#641E16' },
    },
    {
        main: { bg: 'bg-[#884EA0]', hex: '#884EA0' },
        secondary: { bg: 'bg-[#512E5F]', hex: '#512E5F' },
    },
    {
        main: { bg: 'bg-[#2980B9]', hex: '#2980B9' },
        secondary: { bg: 'bg-[#154360]', hex: '#154360' },
    },
    {
        main: { bg: 'bg-[#229954]', hex: '#229954' },
        secondary: { bg: 'bg-[#145A32]', hex: '#145A32' },
    },
    {
        main: { bg: 'bg-[#CA6F1E]', hex: '#CA6F1E' },
        secondary: { bg: 'bg-[#9C640C]', hex: '#9C640C' },
    },
    {
        main: { bg: 'bg-green-400', hex: '#4ade80' },
        secondary: { bg: 'bg-green-600', hex: '#16a34a' },
    },
    {
        main: { bg: 'bg-violet-300', hex: '#c4b5fd' },
        secondary: { bg: 'bg-violet-500', hex: '#8b5cf6' },
    },
    {
        main: { bg: 'bg-rose-300', hex: '#fda4af' },
        secondary: { bg: 'bg-rose-500', hex: '#f43f5e' },
    },
    {
        main: { bg: 'bg-lime-400', hex: '#a3e635' },
        secondary: { bg: 'bg-lime-600', hex: '#65a30d' },
    },

    {
        main: { bg: 'bg-pink-300', hex: '#f9a8d4' },
        secondary: { bg: 'bg-pink-500', hex: '#ec4899' },
    },
    {
        main: { bg: 'bg-blue-300', hex: '#93c5fd' },
        secondary: { bg: 'bg-blue-500', hex: '#3b82f6' },
    },

    {
        main: { bg: 'bg-teal-300', hex: '#5eead4' },
        secondary: { bg: 'bg-teal-500', hex: '#14b8a6' },
    },
    {
        main: { bg: 'bg-rose-300', hex: '#fda4af' },
        secondary: { bg: 'bg-rose-500', hex: '#f43f5e' },
    },

    {
        main: { bg: 'bg-[#CB4335]', hex: '#CB4335' },
        secondary: { bg: 'bg-[#78281F]', hex: '#78281F' },
    },
    {
        main: { bg: 'bg-[#F5CBA7]', hex: '#F5CBA7' },
        secondary: { bg: 'bg-[#CA6F1E]', hex: '#CA6F1E' },
    },
    {
        main: { bg: 'bg-[#2ECC71]', hex: '#2ECC71' },
        secondary: { bg: 'bg-[#186A3B]', hex: '#186A3B' },
    },
    {
        main: { bg: 'bg-[#17A589]', hex: '#17A589' },
        secondary: { bg: 'bg-[#0E6251]', hex: '#0E6251' },
    },
    {
        main: { bg: 'bg-[#E67E22]', hex: '#E67E22' },
        secondary: { bg: 'bg-[#784212]', hex: '#784212' },
    },
    {
        main: { bg: 'bg-purple-300', hex: '#d8b4fe' },
        secondary: { bg: 'bg-purple-500', hex: '#a855f7' },
    },
    {
        main: { bg: 'bg-[#5D6D7E]', hex: '#5D6D7E' },
        secondary: { bg: 'bg-[#1B2631]', hex: '#1B2631' },
    },
    {
        main: { bg: 'bg-sky-300', hex: '#7dd3fc' },
        secondary: { bg: 'bg-sky-500', hex: '#0ea5e9' },
    },
]
