import { defineStore, getActivePinia } from 'pinia'

// map through that list and use the **$reset** fn to reset the state
import { useClientStore } from './clientStore'
import { useDriverStore } from './driverStore'
import { useOrderStore } from './orderStore'

// eslint-disable-next-line no-nested-ternary
const env =
    window.location.hostname === 'localhost' ||
    window.location.host.includes('dev')
        ? 'dev'
        : window.location.host.includes('lium')
          ? 'lium'
          : window.location.host.substring(0, window.location.host.indexOf('.'))
// const this.controller = new this.controller()
const accessToken =
    env === 'becycle'
        ? 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZGV2LmJpaWltLXNvbHV0aW9ucy5jb21cL2FwaSIsImF1ZCI6Imh0dHBzOlwvXC9kZXYuYmlpaW0tc29sdXRpb25zLmNvbVwvYXBpIiwiZXhwIjoxODgzMDUwNjM5LCJpYXQiOjE3MjUzNzA2MzksImRhdGEiOnsiaWQiOjAsImZpcnN0TmFtZSI6IiIsImxhc3ROYW1lIjoiIiwiZW1haWwiOiIifX0.rPvYBbKo5C45PWbAFjX4spiNBjrpAmjtScXOAExytOk'
        : env === 'rushlogistic'
          ? 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZGV2LmJpaWltLXNvbHV0aW9ucy5jb21cL2FwaSIsImF1ZCI6Imh0dHBzOlwvXC9kZXYuYmlpaW0tc29sdXRpb25zLmNvbVwvYXBpIiwiZXhwIjoxODgzMDUxMDU4LCJpYXQiOjE3MjUzNzEwNTgsImRhdGEiOnsiaWQiOjAsImZpcnN0TmFtZSI6IiIsImxhc3ROYW1lIjoiIiwiZW1haWwiOiIifX0.rZR7RAGE_A_JFSl1fgmLkloo2yoXapAMoSZOmg3Z-fo'
          : env === 'harboreport'
            ? 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZGV2LmJpaWltLXNvbHV0aW9ucy5jb21cL2FwaSIsImF1ZCI6Imh0dHBzOlwvXC9kZXYuYmlpaW0tc29sdXRpb25zLmNvbVwvYXBpIiwiZXhwIjoxODYyNTEyMDkzLCJpYXQiOjE3MDQ4MzIwOTMsImRhdGEiOnsiaWQiOjAsImZpcnN0TmFtZSI6IiIsImxhc3ROYW1lIjoiIiwiZW1haWwiOiIifX0.48vF6hqp7Vr2utO54GyDke1vVb9uuj8UOAhn5dPs3k0'
            : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZGV2LmJpaWltLXNvbHV0aW9ucy5jb21cL2FwaSIsImF1ZCI6Imh0dHBzOlwvXC9kZXYuYmlpaW0tc29sdXRpb25zLmNvbVwvYXBpIiwiZXhwIjoxODYyNTEyMDkzLCJpYXQiOjE3MDQ4MzIwOTMsImRhdGEiOnsiaWQiOjAsImZpcnN0TmFtZSI6IiIsImxhc3ROYW1lIjoiIiwiZW1haWwiOiIifX0.48vF6hqp7Vr2utO54GyDke1vVb9uuj8UOAhn5dPs3k0'
const useUserStore = defineStore('user', {
    state: () => ({
        current: null,
        isLoggedIn: false,
        loginError: null,
        logo: null,
        locale: 'Fr',
        nightMode: false,
        mapMarker: null,
        userAuthorisations: [],
        isB2C: null,
        userUniverses: [],
    }),
    actions: {
        login(payload) {
            this.controller = new AbortController()
            for (let i = 0; i < 100; i += 1) {
                window.clearInterval(i)
            }
            const activepinia = getActivePinia()
            if (activepinia) {
                Object.entries(activepinia.state.value).forEach(
                    ([storeName, state]) => {
                        if (storeName !== 'user') {
                            const storeDefinition = defineStore(
                                storeName,
                                state,
                            )
                            const store = storeDefinition(activepinia)
                            store.$reset()
                        }
                    },
                )
            }
            const clientStore = useClientStore()
            const orderStore = useOrderStore()

            const driverStore = useDriverStore()

            // this.logout(false)
            this.cookies.keys().forEach((cookie) => {
                if (
                    cookie !== 'logo' &&
                    cookie !== 'nightMode' &&
                    cookie !== 'locale'
                ) {
                    this.cookies.remove(cookie)
                }
            })
            this.loginError = null
            payload.Software = 'biiimFront'

            this.axios
                .post('/api/login', payload)
                .then((res) => {
                    this.cookies.set(
                        'user_session',
                        res.data.jwt ? res.data.jwt : res.data.JWT,
                    )
                    // const auth = []
                    // res.data.Authorizations.forEach((a) => auth.push(a))

                    this.current = {
                        id: res.data.id ? res.data.id : res.data.Id,
                        email: res.data.email ? res.data.email : res.data.Email,
                        type: res.data.type ? res.data.type : res.data.Type,
                        firstName: res.data.firstName
                            ? res.data.firstName
                            : res.data.FirstName,
                        lastName: res.data.lastName
                            ? res.data.lastName
                            : res.data.LastName,
                        idClient: res.data.idClient
                            ? res.data.idClient
                            : res.data.IdClient,
                        idDriver: res.data.idDriver
                            ? res.data.idDriver
                            : res.data.IdDriver,
                        env: res.data.Society,
                        login: res.data.login ? res.data.login : res.data.Login,
                    }
                    this.cookies.set('user', this.current)

                    if (res.data.Universes) {
                        res.data.Universes.forEach((universe) => {
                            this.userUniverses.push({
                                name: universe.Name,
                                value: universe.Id,
                            })
                        })
                        this.cookies.set(
                            'universes',
                            JSON.stringify(this.userUniverses),
                        )
                    }
                    if (res.data.type === 'client') {
                        this.isB2C = true
                        this.cookies.set('is_B2C', true)

                        clientStore.fetchOne(res.data.idClient)
                        orderStore.searchUnpaidOrdersByClient({
                            clientId: res.data.idClient,
                        })
                    } else {
                        this.isB2C = false

                        this.cookies.set('is_B2C', false)
                        if (res.data.type === 'driver') {
                            driverStore.fetchOne(res.data.idDriver)
                        }
                    }
                    if (res.data.Authorizations) {
                        res.data.Authorizations.forEach((auth) => {
                            this.userAuthorisations.push(auth.Code)
                        })
                        this.cookies.set(
                            'authorisations',
                            JSON.stringify(this.userAuthorisations),
                        )
                    }
                    // console.log(this.userAuthorisations)
                    this.isLoggedIn = true
                    // if (this.isB2C === true) {
                    //     this.router.push({ name: 'orders' })
                    // } else {
                    //     this.router.push({ name: 'dashboard' })
                    // }
                    if (
                        this.userAuthorisations.includes('FULL_ACCESS') ||
                        this.userAuthorisations.includes(
                            'DASHBOARD_OPERATOR',
                        ) ||
                        this.userAuthorisations.includes('DASHBOARD_CLIENT') ||
                        this.userAuthorisations.includes('DASHBOARD_HARBOR')
                    ) {
                        this.router.push({ name: 'dashboard' })
                    } else if (this.current.env.Name !== 'HarboReport') {
                        this.router.push({ name: 'orders' })
                    } else {
                        this.router.push({ name: 'harbors' })
                    }
                })
                .catch((err) => {
                    let { message } = err
                    if (err.response.status === 401) {
                        message = 'bad_credentials' // this.i18n.global.t('bad_credentials')
                    }
                    this.loginError = message
                })
        },
        forgotPassword(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/user/requestResetPassword', payload, {
                        // signal: this.controller.signal,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    })
                    .then((res) => {
                        // console.log(res)
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                        console.log(err)
                        console.log('error reset pass mail')
                    })
            })
        },
        doResetPassword(payload) {
            this.axios
                .post('/api/v1/user/setNewPassword', payload, {
                    // signal: this.controller.signal,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                .then((res) => {
                    this.router.push({ name: 'login' })
                })
                .catch((err) => {
                    console.log(err)
                    console.log('error rp form')
                })
        },
        logout(redirect = true) {
            // getActivePinia()._s.forEach((store) => store.$reset())
            // if (resetPinia === true) {
            const activepinia = getActivePinia()
            if (activepinia) {
                Object.entries(activepinia.state.value).forEach(
                    ([storeName, state]) => {
                        if (storeName !== 'user') {
                            const storeDefinition = defineStore(
                                storeName,
                                state,
                            )
                            const store = storeDefinition(activepinia)
                            store.$reset()
                        }
                    },
                )
            }
            // }
            // this.axios
            //     .get('/api/user/logout', {
            //         signal: this.controller.signal,
            //     })
            //     .then(() => {
            //         console.log('logout')
            // this.controller.abort()

            this.current = null
            this.isB2C = null
            this.isLoggedIn = false
            this.userAuthorisations = []
            this.userUniverses = []
            this.cookies.keys().forEach((cookie) => {
                if (
                    cookie !== 'logo' &&
                    cookie !== 'nightMode' &&
                    cookie !== 'locale'
                ) {
                    this.cookies.remove(cookie)
                }
            })
            if (redirect === true) {
                this.router.push({ name: 'logout' })
                // document.location.href = '/login' // todo to remove when cookie issue is fixed
            }
            // }).catch((err) => {
            //     console.log(err)
            // })
        },
        userFromStorage() {
            const clientStore = useClientStore()

            if (this.cookies.get('user_session')) {
                this.current = this.cookies.get('user')
                this.isB2C = this.cookies.get('is_B2C')
                this.userUniverses = JSON.parse(this.cookies.get('universes'))

                const authorisations = JSON.parse(
                    this.cookies.get('authorisations'),
                )
                if (authorisations.length) {
                    this.userAuthorisations = authorisations

                    this.isLoggedIn = true
                    this.logo = this.cookies.get('logo')
                    if (this.isB2C === true) {
                        // console.log(this.current)
                        clientStore.fetchOne(this.current.idClient)
                    }
                } else {
                    this.logout()
                }
            }
        },
        refreshConnectedUser(
            auth = false,
            universe = false,
            id = this.current.id,
        ) {
            this.fetchOne(id).then((res) => {
                if (auth === true) {
                    this.userAuthorisations = []
                    this.cookies.remove('authorisations')

                    if (res.data.Authorisations) {
                        res.data.Authorisations.forEach((role) => {
                            this.userAuthorisations.push(role.Code)
                        })
                        this.cookies.set(
                            'authorisations',
                            JSON.stringify(this.userAuthorisations),
                        )
                    }
                }
                if (universe === true) {
                    this.userUniverses = []
                    this.cookies.remove('universes')

                    if (res.data.Universes) {
                        res.data.Universes.forEach((userUniverse) => {
                            this.userUniverses.push({
                                name: userUniverse.Name,
                                value: userUniverse.Id,
                            })
                        })
                        this.cookies.set(
                            'universes',
                            JSON.stringify(this.userUniverses),
                        )
                    }
                }
            })
        },
        fetchOne(payload, setContact = true) {
            const clientStore = useClientStore()

            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/user/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const user = res.data
                        // dispatch('fetchClientContacts', payload).then(
                        // (contactsRes) => {
                        //     const contacts = contactsRes.data
                        const current = {
                            id: +user.Id,
                            firstname: user.FirstName,
                            lastname: user.LastName,
                            mobile: user.MobilePhone,
                            phone: user.Phone,
                            type: user.Type,
                            login: user.Login,
                            authorisations: user.Authorizations,
                            email_address: user.Email,
                            creation_date: user.CreationDate,
                            creation_user_id: +user.CreationUserId,
                            client: user.Client,
                        }
                        if (setContact === true) {
                            clientStore.$patch({ currentContact: current })
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOneByEmail(payload) {
            const clientStore = useClientStore()
            return new Promise((resolve, reject) => {
                this.axios
                    .get(
                        `/api/v1/user/getByEmail?email=${encodeURIComponent(
                            payload,
                        )}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        if (res && res.status === 200) {
                            const user = res.data
                            const current = {
                                id: +user.Id,
                                firstname: user.FirstName,
                                lastname: user.LastName,
                                mobile: user.MobilePhone,
                                phone: user.Phone,
                                type: user.Type,
                                login: user.Login,
                                email_address: user.Email,
                                creation_date: user.CreationDate,
                                creation_user_id: +user.CreationUserId,
                            }
                            clientStore.$patch({ currentContact: current })
                            resolve(res)
                        } else {
                            resolve(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/user/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        // this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateUserAndClient(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .patch(`/api/v1/user/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        create(payload) {
            // à revoir après tileout session api
            let headers = {}
            if (this.cookies.get('user_session') && !payload.fromNew) {
                headers = {
                    Authorization: `Bearer ${this.cookies.get('user_session')}`,
                }
            } else if (payload.fromNew) {
                headers = {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/userclient`, payload, {
                        headers,
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        // if (res && res.status === 200 && typeof res.data !== 'string') {
                        resolve(res)
                        //     } else {
                        //         resolve({
                        //             status: 422,
                        //             message: 'unprocessable entity',
                        //         })
                        //     }
                        //     resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
})
export { useUserStore }
