<!-- eslint-disable prefer-template -->
<template>
    <div
        class="rounded-8xs bg-white overflow-auto flex flex-row items-start justify-start px-5 text-left text-13xl text-darkslategray-100 font-circular-std border-[1px] border-dashed border-blueviolet h-full"
    >
        <div
            class="new-note w-[861px] bg-white box-border flex flex-col items-start justify-start"
        >
            <form
                @submit.prevent="
                    contactClientHistoryStore.current === null
                        ? createNote()
                        : updateNote()
                "
            >
                <div
                    class="self-stretch flex flex-col items-start justify-start pt-[20px] pb-2.5 pr-[30px] pl-10"
                >
                    <div
                        class="w-[791px] flex flex-col items-start justify-end gap-[20px]"
                    >
                        <div
                            class="self-stretch flex flex-row items-center justify-start gap-[20px]"
                        >
                            <div
                                class="flex-1 flex flex-row items-center justify-start"
                            >
                                <div
                                    class="flex-1 relative font-medium overflow-hidden text-ellipsis whitespace-nowrap"
                                >
                                    {{
                                        contactClientHistoryStore.current ===
                                        null
                                            ? $t('new_note')
                                            : $t('update_note')
                                    }}
                                </div>
                            </div>
                            <!-- <div
                                v-if="contactClientHistoryStore.current"
                                class="w-[30px] rounded-[3.87px] bg-lightsteelblue h-[30px] flex flex-row items-center justify-center"
                            >
                                <img
                                    class="w-[19.4px] relative h-[19.4px] overflow-hidden shrink-0"
                                    alt=""
                                    src="/img/generated/icon.svg"
                                    @click.prevent="deleteNote()"
                                />
                            </div> -->
                        </div>

                        <div
                            class="self-stretch flex flex-col items-start justify-start gap-[10px] text-sm text-darkslategray-400"
                        >
                            <div
                                class="self-stretch flex flex-row items-start justify-start gap-[10px]"
                            >
                                <div
                                    class="flex-1 rounded-lg bg-white box-border h-9 py-0.5 pr-[17px] pl-[3px] gap-[15px] border-[1px] border-solid border-gainsboro-100"
                                >
                                    <div
                                        class="rounded selectMS -8xs bg-darkslategray-600 h-[30px] flex flex-row items-center justify-start py-0 px-[7px] box-border gap-[5px]"
                                    >
                                        <img
                                            class="w-4 relative h-4 overflow-hidden shrink-0"
                                            alt=""
                                            src="/img/generated/iconlabel.svg"
                                        />
                                        <div class="relative">
                                            {{ $t('client') }}
                                        </div>
                                        <Multiselect
                                            v-if="
                                                !currentClient && !isNoteUpdate
                                            "
                                            id="clientMS"
                                            class="darky"
                                            :class="
                                                isUpdate === true
                                                    ? 'cursor-not-allowed'
                                                    : 'cursor-pointer'
                                            "
                                            v-model="noteForm.IdClient"
                                            :options="clientOptions"
                                            label="name"
                                            track-by="name"
                                            :disabled="isUpdate === true"
                                            :searchable="true"
                                            :placeholder="
                                                $t('select_client_info')
                                            "
                                            :allow-empty="false"
                                            :can-clear="false"
                                            @select="refetchContacts($event)"
                                            @search-change="
                                                searchClientsQuery($event)
                                            "
                                        ></Multiselect>
                                        <div
                                            v-else-if="currentClient"
                                            class="relative"
                                        >
                                            <input
                                                :key="refreshMS"
                                                type="text"
                                                class="bg-none"
                                                disabled
                                                :placeholder="`${currentClient.company_name}`"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.noteForm.IdClient
                                        .$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                                <div
                                    class="flex-1 rounded-lg bg-white box-border h-9 py-0.5 pr-2.5 pl-[3px] border-[1px] border-solid border-gainsboro-100"
                                >
                                    <div
                                        class="rounded-12xs selectMS h-[30px] flex flex-row items-center justify-start py-0 px-[7px] box-border gap-[5px]"
                                    >
                                        <fa-icon
                                            class="w-4 relative h-4 overflow-hidden shrink-0"
                                            icon="address-card"
                                        />
                                        <div class="relative">
                                            {{ $t('contact') }}
                                        </div>
                                        <Multiselect
                                            id="contactMS"
                                            class="darky"
                                            :disabled="disableForm"
                                            v-model="noteForm.IdUserContact"
                                            :options="contactOptions"
                                            label="name"
                                            :key="refreshMS"
                                            track-by="name"
                                            :searchable="true"
                                            :placeholder="
                                                $t('select_contact_info')
                                            "
                                            :allow-empty="false"
                                            :can-clear="false"
                                        ></Multiselect>
                                    </div>
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.noteForm.IdUserContact
                                        .$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="self-stretch rounded-lg bg-white box-border h-9 py-0.5 pr-2.5 pl-[3px] border-[1px] border-solid border-gainsboro-100"
                            >
                                <div
                                    class="rounded-12xs selectMS h-[30px] flex flex-row items-center justify-start py-0 px-[7px] box-border gap-[5px]"
                                >
                                    <fa-icon
                                        class="w-4 relative h-4 overflow-hidden shrink-0"
                                        icon="comments"
                                    />
                                    <div class="relative whitespace-nowrap">
                                        {{ $t('followed_by') }}
                                    </div>
                                    <Multiselect
                                        id="salesMS"
                                        class="darky"
                                        v-model="noteForm.IdUserSalesPerson"
                                        :options="salesPersonOptions"
                                        :loading="
                                            salesPersonOptions.length === 0
                                        "
                                        :disabled="disableForm"
                                        label="name"
                                        :key="refreshMS"
                                        track-by="name"
                                        :searchable="true"
                                        :placeholder="
                                            $t('select_salesperson_info')
                                        "
                                        :allow-empty="false"
                                        :can-clear="false"
                                        @select="setSalesPersonEmail($event)"
                                    ></Multiselect>
                                </div>
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.noteForm.IdUserSalesPerson
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>

                            <div
                                class="self-stretch rounded-lg bg-white box-border h-9 flex flex-row items-center justify-start py-0 pr-[17px] pl-[3px] border-[1px] border-solid border-gainsboro-100"
                            >
                                <div
                                    class="rounded-12xs h-[30px] flex flex-row items-center justify-start py-0 px-[7px] box-border gap-[5px]"
                                >
                                    <fa-icon
                                        class="w-4 relative h-4 overflow-hidden shrink-0"
                                        icon="calendar-day"
                                    />
                                    <div class="relative">
                                        {{ $t('meeting_date') }}
                                    </div>
                                    <div class="relative">
                                        <input
                                            type="datetime-local"
                                            :disabled="disableForm"
                                            v-model="noteForm.MeetingDate"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.noteForm.MeetingDate.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div
                                class="self-stretch rounded-lg bg-white box-border h-9 flex flex-row items-center justify-start py-0 pr-[17px] pl-[3px] gap-[15px] border-[1px] border-solid border-gainsboro-100"
                            >
                                <div
                                    class="rounded-8xs bg-darkslategray-600 h-[30px] flex flex-row items-center justify-start py-0 px-[7px] box-border gap-[5px]"
                                >
                                    <fa-icon
                                        class="w-4 relative h-4 overflow-hidden shrink-0"
                                        icon="calendar-plus"
                                    />
                                    <div class="relative">
                                        {{ $t('creation_date') }}
                                    </div>
                                </div>
                                <div
                                    class="flex-1 relative text-darkslategray-100 overflow-hidden text-ellipsis whitespace-nowrap"
                                >
                                    {{ `${noteForm.CreationDate}` }}
                                </div>
                                <div
                                    v-if="noteForm.CreationUser"
                                    class="relative"
                                >
                                    {{ noteForm.CreationUser.Email }}
                                </div>
                            </div>
                            <div
                                class="self-stretch rounded-lg bg-white box-border h-9 flex flex-row items-center justify-start py-0 pr-[17px] pl-[3px] gap-[15px] border-[1px] border-solid border-gainsboro-100"
                            >
                                <div
                                    class="rounded-8xs bg-darkslategray-600 h-[30px] flex flex-row items-center justify-start py-0 px-[7px] box-border gap-[5px]"
                                >
                                    <fa-icon
                                        class="w-4 relative h-4 overflow-hidden shrink-0"
                                        icon="calendar-check"
                                    />
                                    <div class="relative">
                                        {{ $t('modification_date') }}
                                    </div>
                                </div>
                                <div
                                    class="flex-1 relative text-darkslategray-100 overflow-hidden text-ellipsis whitespace-nowrap"
                                >
                                    {{ `${noteForm.ModificationDate}` }}
                                </div>
                                <div
                                    v-if="noteForm.ModificationUser"
                                    class="relative"
                                >
                                    {{ noteForm.ModificationUser.Email }}
                                </div>
                            </div>
                            <div
                                class="self-stretch rounded-lg bg-white box-border h-9 py-0.5 pr-2.5 pl-[3px] border-[1px] border-solid border-gainsboro-100"
                            >
                                <div
                                    class="rounded-12xs selectMS h-[30px] flex flex-row items-center justify-start py-0 px-[7px] box-border gap-[5px]"
                                >
                                    <fa-icon
                                        class="w-4 relative h-4 overflow-hidden shrink-0"
                                        icon="tag"
                                    />
                                    <div class="relative whitespace-nowrap">
                                        {{ $t('note_type') }}
                                    </div>
                                    <Multiselect
                                        id="salesMS"
                                        class="darky"
                                        :disabled="disableForm"
                                        v-model="noteForm.Type"
                                        :options="noteTypeOptions"
                                        :loading="noteTypeOptions.length === 0"
                                        label="name"
                                        :key="refreshMS"
                                        track-by="name"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :can-clear="false"
                                    ></Multiselect>
                                </div>
                            </div>

                            <!-- <div
                                    class="w-[30px] rounded-[3.87px] bg-lightsteelblue h-[30px] flex flex-row items-center justify-center"
                                >
                                    <img
                                        class="w-[27.4px] h-[27.4px] object-contain"
                                        alt=""
                                        src="/img/generated/component-19@2x.png"
                                    />
                                </div> -->
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.noteForm.Type.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start gap-[20px] text-sm text-darkslategray-700"
                >
                    <div
                        class="self-stretch flex flex-row items-start justify-start py-0 pr-[30px] pl-10"
                    >
                        <div
                            class="flex-1 relative box-border h-[0.5px] border-t-[0.5px] border-solid border-darkslategray-300"
                        />
                    </div>
                    <div
                        class="self-stretch flex flex-row items-start justify-start py-0 pr-[30px] pl-10"
                    >
                        <div
                            class="self-stretch w-full text-darkslategray-400 rounded-lg bg-white box-border h-9 flex flex-row items-center justify-start py-0 px-[7px] border-[1px] border-solid border-gainsboro-100"
                        >
                            <div
                                class="rounded-12xs h-[30px] flex w-full items-center justify-start py-0 px-[7px] box-border gap-[5px]"
                            >
                                <fa-icon
                                    class="w-4 relative h-4 overflow-hidden shrink-0"
                                    icon="hashtag"
                                />
                                <div class="relative">
                                    {{ $t('title') }}
                                </div>
                                <input
                                    class="w-full"
                                    type="text"
                                    :disabled="disableForm"
                                    v-model="noteForm.Title"
                                    :placeholder="$t('title_info')"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.noteForm.Title.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                    <div
                        class="self-stretch w-[791px] darky flex-1 flex flex-row items-start justify-start py-0 pr-[30px] pl-10"
                    >
                        <div
                            class="self-stretch min-h-24 darky flex-1 flex flex-row items-start justify-start"
                        >
                            <quill-editor-custom
                                :readOnly="disableForm"
                                :prevContent="noteForm.Description"
                                @setDescription="setDescription($event)"
                            ></quill-editor-custom>
                        </div>
                    </div>
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.noteForm.Description.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                    <div
                        class="self-stretch flex flex-row items-start justify-start py-0 pr-[30px] pl-10"
                    >
                        <div
                            class="flex-1 relative box-border h-[0.5px] border-t-[0.5px] border-solid border-darkslategray-300"
                        />
                    </div>
                    <div
                        class="flex flex-col items-start justify-start text-darkslategray-100"
                    >
                        <div
                            class="w-[861px] flex flex-col items-start justify-start py-0 pr-[30px] pl-10 box-border relative gap-[10px]"
                        >
                            <!-- <div
                                class="w-[30px] !m-[0] absolute top-[-8px] left-[601px] rounded-[3.87px] bg-lightsteelblue h-[30px] flex flex-row items-center justify-center z-[0]"
                            >
                                <img
                                    class="w-[19.4px] relative h-[19.4px] overflow-hidden shrink-0"
                                    alt=""
                                    src="/img/generated/icon.svg"
                                />
                            </div> -->
                            <div
                                class="self-stretch flex flex-col items-start justify-start z-[1]"
                            >
                                <div class="relative">
                                    {{
                                        $t(
                                            'attachement',
                                            noteForm.Files.length <= 1 ? 1 : 2,
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                v-if="disableForm === false"
                                class="self-stretch h-fit pt-0 px-0 box-border z-[2] text-center theme-color over-flow-auto"
                            >
                                <!-- :class="
                                    noteForm.Files.length > 0
                                        ? 'col-span-3'
                                        : 'col-span-6'
                                " -->
                                <!-- attachement sur le cote classes à ajouter flex flex-col items-end justify-start-->
                                <drop-zone
                                    :withTitle="false"
                                    :fileExt="''"
                                    :okFilesToRemove="okFilesToRemove"
                                    @uploadFiles="uploadFiles($event)"
                                    @fileRemoved="removeFileFromOk($event)"
                                    :directAdd="true"
                                    :wait="wait"
                                    :lightLoading="true"
                                    :clearFileList="clearFileList"
                                    @resetClearFileList="clearFileList = false"
                                    @resetWrongFiles="wrongFileTypes = []"
                                    @removeFile="removeFromWrong"
                                ></drop-zone>
                            </div>
                            <!-- <div class="grid grid-cols-6 gap-2 h-44"> -->
                            <div
                                v-if="noteForm.Files.length"
                                class="grid grid-cols-3 gap-2 pb-3 h-fit max-h-40 overflow-y-auto"
                            >
                                <div
                                    v-for="(file, index) in noteForm.Files"
                                    :key="index"
                                    class="col-span-1"
                                >
                                    <div
                                        class="grid grid-cols-8 items-center justify-start gap-1 text-darkslategray-100"
                                    >
                                        <span class="col-span-1">
                                            <img
                                                class="w-6 relative h-6 object-contain"
                                                alt=""
                                                :src="`/img/fileExt/${file.Extension}.png`"
                                            />
                                        </span>
                                        <span
                                            class="col-span-6 overflow-auto text-xs"
                                            @click.prevent="
                                                contactClientHistoryStore.current
                                                    ? viewDocument(file)
                                                    : ''
                                            "
                                            :class="
                                                contactClientHistoryStore.current
                                                    ? 'cursor-pointer'
                                                    : 'cursor-default'
                                            "
                                        >
                                            {{ file.Name }}
                                        </span>
                                        <span class="col-span-1">
                                            <fa-icon
                                                icon="trash-can"
                                                class="ml-2 fa-xs error-msg cursor-pointer"
                                                @click.prevent="
                                                    disableForm === false
                                                        ? removeFile(
                                                              file.Id
                                                                  ? file.Id
                                                                  : null,
                                                              index,
                                                          )
                                                        : ''
                                                "
                                            ></fa-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- </div> -->
                        </div>
                        <div
                            class="self-stretch bg-gray flex flex-row items-center justify-between py-[15px] px-10 text-darkslategray-400 border-t-[1px] border-solid border-darkslategray-300"
                        >
                            <!-- <div
                                class="rounded-lg bg-white box-border h-9 flex flex-row items-center justify-start py-0 px-3 border-[1px] border-solid border-gainsboro-100"
                            >  -->
                            <!-- <div
                                    class="rounded-12xs h-[30px] flex flex-row items-center justify-start py-0 px-[7px] box-border gap-[5px]"
                                >
                                    <fa-icon
                                        class="fa-xs relative overflow-hidden shrink-0"
                                        alt=""
                                        icon="bell"
                                    />
                                    <div class="relative">
                                        {{ $t('set_reminder') }}
                                    </div>
                                </div> -->
                            <button
                                type="button"
                                :disabled="
                                    salesPersonEmail === '' &&
                                    disableForm === false
                                "
                                @click.prevent="openReminderModal()"
                                :class="
                                    salesPersonEmail === ''
                                        ? 'cursor-not-allowed '
                                        : 'cursor-pointer'
                                "
                                class="bg-white border-[1px] border-solid font-semibold border-gainsboro-100 text-xs ml-2 focus:outline-none flex items-center justify-center px-2 py-2 rounded text-gray-400 duration-200 ease-in-out transition"
                            >
                                <fa-icon
                                    icon="bell"
                                    class="fa-sm mr-2"
                                ></fa-icon>

                                {{ $t('set_reminder') }}
                            </button>
                            <!-- </div> -->
                            <div
                                class="flex flex-row items-center justify-center gap-[10px] text-xs text-white"
                            >
                                <button
                                    type="button"
                                    @click="closeNoteForm()"
                                    class="text-xs focus:outline-none flex justify-center px-4 py-2 rounded font-semibold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                >
                                    {{ $t('close') }}
                                </button>
                                <button
                                    type="submit"
                                    :disabled="creating || disableForm"
                                    class="themed-button text-xs ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-semibold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon
                                            icon="spinner"
                                            spin-pulse
                                        ></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{ $t('save') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <reminder-modal
                v-if="showReminderModal && disableForm === false"
                :eventDate="noteForm.MeetingDate"
                :eventEntityType="'ContactClientHistory'"
                :eventId="noteForm.Id"
                :eventDefautlEmail="salesPersonEmail"
                @closeReminderModal="openReminderModal()"
                @reminderToCreate="createNewReminderOnSave($event)"
                @reminderCreated="openReminderModal()"
            ></reminder-modal>
        </div>
    </div>
</template>
<script>
import DropZone from '@/components/elements/DropZone.vue'
import QuillEditorCustom from '@/components/elements/QuillEditorCustom.vue'
import ReminderModal from '@/components/elements/ReminderModal.vue'
import { useAllUsersStore } from '@/stores/allUsersStore'
import { useClientStore } from '@/stores/clientStore'
import { useContactClientHistoryStore } from '@/stores/contactClientHistoryStore'
import { useReminderStore } from '@/stores/reminderStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'
import clientMultiselectDebouncer from '../../../mixins/clientMultiselectDebouncer'

export default defineComponent({
    name: 'NewNote',
    components: {
        DropZone,
        Multiselect,
        QuillEditorCustom,
        ReminderModal,
    },
    mixins: [clientMultiselectDebouncer],
    setup() {
        return { v$: useVuelidate() }
    },
    computed: {
        ...mapStores(useContactClientHistoryStore, useUserStore),
        ...mapState(useClientStore, { currentClient: 'current' }),
    },
    data() {
        return {
            reminderToCreate: null,
            showReminderModal: false,
            salesPersonEmail: '',
            clientName: '',
            meetingDate: '',
            universeOptions: [],
            noContactForClient: false,
            note: null,
            isUpdate: false,

            creating: false,
            contactOptions: [],
            salesPersonOptions: [],
            noteForm: {
                Id: '',
                IdClient: '',
                IdUserContact: '',
                IdUserSalesPerson: '',
                Title: '',
                Files: [],
                Type: '',
                Pinned: false,
                Active: true,
                MeetingDate: new Date(),

                // ReminderDate: new Date(),

                Description: '',
                CreationDate: new Date().toISOString().substr(0, 10),
                CreationUserId: '',
                ModificationDate: new Date().toISOString().substr(0, 10),
                ModificationUserId: '',
            },
            noteTypeOptions: [
                { name: this.$t('email_note'), value: 'email' },
                { name: this.$t('reunion'), value: 'reunion' },
                { name: this.$t('other'), value: 'other' },
            ],
            okFilesToRemove: [],
            wrongFileTypes: [],
            wait: false,
            clearFileList: false,
        }
    },
    props: {
        disableForm: {
            type: Boolean,
            default: false,
        },
        isNoteUpdate: {
            type: Boolean,
            default: false,
        },
    },

    validations() {
        return {
            noteForm: {
                IdClient: { required },
                IdUserContact: { required },
                IdUserSalesPerson: { required },
                Title: { required },

                Type: { required },

                MeetingDate: { required },

                Description: { required },
                CreationDate: { required },
            },
        }
    },
    updated() {
        if (this.contactClientHistoryStore.current) {
            this.noteForm = this.contactClientHistoryStore.current
            this.isUpdate = true
        }
    },

    mounted() {
        this.reminderToCreate = null
        // eslint-disable-next-line prettier/prettier, prefer-template
        this.noteForm.MeetingDate =
            // eslint-disable-next-line prefer-template
            this.noteForm.MeetingDate.getFullYear() +
            '-' +
            // eslint-disable-next-line prefer-template
            ('0' + (this.noteForm.MeetingDate.getMonth() + 1)).slice(-2) +
            '-' +
            // eslint-disable-next-line prefer-template
            ('0' + this.noteForm.MeetingDate.getDate()).slice(-2) +
            'T' +
            // eslint-disable-next-line prefer-template
            ('0' + this.noteForm.MeetingDate.getHours()).slice(-2) +
            ':' +
            // eslint-disable-next-line prefer-template
            ('0' + this.noteForm.MeetingDate.getMinutes()).slice(-2)

        this.darkMode = this.userStore.nightMode

        if (this.contactClientHistoryStore.current) {
            this.noteForm = this.contactClientHistoryStore.current
            this.isUpdate = true
        }
        if (
            this.userStore.current.type === 'admin' ||
            this.userStore.current.type === 'superadmin'
        ) {
            const universeSearch = {
                sortDirection: 'ASC',
                sortField: 'Name',
                perPage: 10000,
                page: 1,
                query: [],
                filter: [],
            }
            this.searchUniverses(universeSearch).then((resUniv) => {
                this.universeOptions = []

                resUniv.data.Records.forEach((universe) => {
                    const name = universe.Name
                    this.universeOptions.push({
                        name,
                        value: +universe.Id,
                        dbField: 'IdUniverse',
                        realName: universe.Name,
                    })
                })
                // this.refreshMS += 1
            })
        } else {
            this.userStore.userUniverses.forEach((universe) => {
                this.universeOptions = []
                const name = universe.Name

                this.universeOptions.push({
                    name,
                    value: +universe.value,
                    dbField: 'IdUniverse',
                    realName: universe.name,
                })
            })

            // this.refreshMS += 1
        }
        this.universeFilterForClientSearch = {
            value: this.universeOptions,
            dbField: 'IdUniverse',
        }

        const search = {
            sortDirection: 'ASC',
            sortField: 'LastName',
            perPage: 1000,
            page: 1,
            query: [],
            filter: [
                { value: this.universeOptions, dbField: 'universes' },
                { dbField: 'salesPerson', value: 'notClient' },
            ],
        }

        this.searchUsers(search)
            .then((res) => {
                this.salesPersonOptions = []
                res.data.Records.forEach((user) => {
                    this.salesPersonOptions.push({
                        name: `${user.FirstName} ${user.LastName}`,
                        email: user.Email,
                        value: +user.Id,
                    })
                })
                this.refreshMS += 1
            })
            .then(() => {
                if (
                    this.salesPersonOptions.length &&
                    this.contactClientHistoryStore.current
                ) {
                    this.salesPersonEmail = this.salesPersonOptions.find(
                        (sales) =>
                            +sales.value === +this.noteForm.IdUserSalesPerson,
                    ).email
                }
            })

        if (this.$route.params.id) {
            this.noteForm.IdClient = +this.$route.params.id
            this.refreshMS += 1
        }
        if (this.noteForm.IdClient) {
            this.noContactForClient = false

            this.contactOptions = []
            this.fetchClientContacts({
                clientId: this.noteForm.IdClient,
            }).then((resContact) => {
                if (resContact && resContact.status === 200) {
                    if (
                        resContact.data.Records &&
                        resContact.data.Records.length
                    ) {
                        resContact.data.Records.forEach((contact) => {
                            this.noContactForClient = false
                            const option = {
                                name: `${contact.FirstName}  ${contact.LastName}`,
                                value: +contact.IdUser,
                            }
                            this.contactOptions.push(option)
                        })
                        this.noteForm.IdUserContact =
                            this.noteForm.IdUserContact === ''
                                ? this.contactOptions[0].value
                                : this.noteForm.IdUserContact
                        this.refreshMS += 1
                    } else {
                        this.noContactForClient = true
                    }
                } else {
                    this.noContactForClient = true
                }
            })
        }
    },
    methods: {
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
        }),
        ...mapActions(useReminderStore, {
            createReminder: 'create',
        }),
        ...mapActions(useAllUsersStore, {
            searchUsers: 'search',
        }),
        ...mapActions(useClientStore, {
            searchClients: 'search',
            fetchOneClient: 'fetchOne',
            fetchClientContacts: 'fetchClientContacts',
        }),
        setSalesPersonEmail(id) {
            this.salesPersonEmail = this.salesPersonOptions.find(
                (sales) => sales.value === id,
            ).email
        },
        openReminderModal() {
            if (
                new Date(this.noteForm.MeetingDate)
                    .toISOString()
                    .substring(0, 10) <
                new Date().toISOString().substring(0, 10)
            ) {
                this.$toast.error(this.$t('past_event'))
                return
            }
            this.showReminderModal = !this.showReminderModal
        },
        createNewReminderOnSave(reminder) {
            this.reminderToCreate = reminder
            this.openReminderModal()
        },
        setDescription(desc) {
            this.noteForm.Description = desc
        },
        refetchContacts(clientId) {
            this.noContactForClient = false
            // this.clientName = this.clientOptions.find(
            //     (client) => client.value === clientId
            // ).name
            this.contactOptions = []
            this.fetchClientContacts({
                clientId,
            }).then((resContact) => {
                if (resContact && resContact.status === 200) {
                    if (
                        resContact.data.Records &&
                        resContact.data.Records.length
                    ) {
                        resContact.data.Records.forEach((contact) => {
                            this.noContactForClient = false
                            const option = {
                                name: `${contact.FirstName}  ${contact.LastName}`,
                                value: +contact.IdUser,
                            }
                            this.contactOptions.push(option)
                        })
                        this.noteForm.IdUserContact =
                            this.noteForm.IdUserContact === ''
                                ? this.contactOptions[0].value
                                : this.noteForm.IdUserContact
                        this.refreshMS += 1
                    } else {
                        this.noContactForClient = true
                    }
                } else {
                    this.noContactForClient = true
                }
            })
        },
        resetUpload(clearFiles = false) {
            if (clearFiles === true) {
                this.clearFileList = true
                this.wrongFileTypes = []
                this.selectedFileType = ''
                this.selectedClient = null
            } else if (this.wrongFileTypes.length === 0) {
                this.selectedFileType = ''
                this.selectedClient = null
            }
        },
        removeFileFromOk(file) {
            this.okFilesToRemove.splice(this.okFilesToRemove.indexOf(file))
        },
        removeFromWrong(file) {
            URL.revokeObjectURL(file.url)
            this.wrongFileTypes.splice(this.wrongFileTypes.indexOf(file))
        },
        async uploadFile(file) {
            // NAME PATH EXTENSION
            // SI AJOUT IDCONTACTCLIENTHISTORY
            // si creaation denote on ajoute à noteformfiles
            // si non on charge directemenbt le fichier via upload direct
            // on récupère l'extension du filchier qui se trouve après le point
            const extension = file.file.name.split('.').pop()
            let response = false
            const blob = await fetch(file.url).then((r) => r.blob())
            URL.revokeObjectURL(file.url)
            const reader = new FileReader()
            reader.readAsBinaryString(blob)
            reader.onloadend = async () => {
                const fileToUpload = {
                    FileContent: btoa(reader.result), // btoa(encodeURI(reader.result).replace('20%', ' ')),
                    Name: file.file.name,
                    Extension: extension,
                    IdContactClientHistory: this.contactClientHistoryStore
                        ?.current
                        ? this.contactClientHistoryStore.current.Id
                        : null,
                    IdSociety: this.contactClientHistoryStore.current
                        ? this.contactClientHistoryStore.current.IdSociety
                        : null,
                    CreationUserId: this.userStore.current.id,
                    ModificationUserId: this.userStore.current.id,
                }
                if (!this.contactClientHistoryStore.current) {
                    this.noteForm.Files.push(fileToUpload)
                    file.status = true
                    setTimeout(() => {
                        this.okFilesToRemove.push(file)
                        this.resetUpload()
                    }, 2000)
                } else {
                    file.status = 'loading'
                    response =
                        await this.contactClientHistoryStore.uploadFile(
                            fileToUpload,
                        )

                    if (response && response.status === 201) {
                        file.status = true
                        setTimeout(() => {
                            this.okFilesToRemove.push(file)
                            this.resetUpload()
                            this.$emit('newFileUploaded', response.data.Id)
                            // this.noteForm.Files.push(fileToUpload)
                        }, 2000)
                    }
                }
            }

            return response
        },
        async uploadFiles(files) {
            this.wrongFileTypes = []
            this.wait = true

            const res = await Promise.all(
                files.map((file) => this.uploadFile(file)),
            )
            if (res) this.wait = false
        },
        async createNote() {
            if (this.noteForm.IdClient === '') {
                this.$toast.error(this.$t('client_required'))
                return
            }
            const isFormCorrect = await this.v$.$validate()

            if (isFormCorrect === true) {
                this.creating = true
                this.noteForm.CreationUserId = this.userStore.current.id
                this.noteForm.ModificationUserId = this.userStore.current.id

                this.contactClientHistoryStore
                    .create(this.noteForm)
                    .then((res) => {
                        if (res && res.status === 201) {
                            if (this.reminderToCreate) {
                                this.reminderToCreate.IdEvent = res.data.Id
                                this.createReminder(this.reminderToCreate)
                                    .then((resReminder) => {
                                        this.creating = false

                                        this.closeNoteForm()
                                    })
                                    .catch((err) => {
                                        this.creating = false

                                        this.$toast.error(
                                            this.$t(
                                                'error_occured_setting_reminder',
                                            ),
                                        )
                                    })
                            } else {
                                this.closeNoteForm()
                            }
                        } else {
                            this.creating = false
                            this.$toast.error(this.$t('error_occured'))
                        }
                    })
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateNote() {
            this.creating = true
            this.noteForm.ModificationUserId = this.userStore.current.id
            const isFormCorrect = await this.v$.$validate()

            if (isFormCorrect === true) {
                this.contactClientHistoryStore
                    .update(this.noteForm)
                    .then((res) => {
                        if (res && res.status === 200) {
                            if (this.reminderToCreate) {
                                this.reminderToCreate.IdEntity =
                                    this.noteForm.Id
                                this.createReminder(this.reminderToCreate)
                                    .then((resReminder) => {
                                        this.creating = false

                                        this.closeNoteForm()
                                    })
                                    .catch((err) => {
                                        this.creating = false

                                        this.$toast.error(
                                            this.$t(
                                                'error_occured_setting_reminder',
                                            ),
                                        )
                                    })
                            } else {
                                this.creating = false

                                this.closeNoteForm()
                            }
                        } else {
                            this.creating = false
                            this.$toast.error(this.$t('error_occured'))
                        }
                    })
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        closeNoteForm() {
            this.noteForm = {
                IdClient: '',
                IdUserContact: '',
                IdUserSalesPerson: '',
                Title: '',
                Files: [],
                Type: '',
                Pinned: false,
                MeetingDate: new Date(),

                // ReminderDate: new Date(),

                Description: '',
                CreationDate: new Date().toISOString().substr(0, 10),
                CreationUserId: '',
                ModificationDate: new Date().toISOString().substr(0, 10),
                ModificationUserId: '',
            }
            this.contactClientHistoryStore.$patch({
                current: null,
            })
            this.reminderToCreate = null
            this.creating = false
            this.$emit('closeNoteForm')
        },
        removeFile(id, index) {
            if (id) {
                this.contactClientHistoryStore
                    .deleteFile(id)
                    .then((res) => {
                        if (res && res.status === 200) {
                            this.noteForm.Files.splice(index, 1)
                        }
                    })
                    .catch((err) => {
                        this.$toast.error(this.$t('error_occured'))
                    })
            } else {
                this.noteForm.Files.splice(index, 1)
            }
        },
        viewDocument(file) {
            this.axios
                .get(`/uploads/contactClientHistoryFile/${file.Name}`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get(
                            'user_session',
                        )}`,
                    },
                    signal: this.$controller.signal,
                })
                .then((res) => {
                    const url = URL.createObjectURL(res.data)
                    const link = document.createElement('a')
                    link.href = url
                    // if (isDownload === true) {
                    //     link.setAttribute('download', file.FileName)
                    // } else {
                    link.target = '_external'
                    // }
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    URL.revokeObjectURL(url)
                })
        },
    },
})
</script>
<style lang="scss" scoped>
.selectMS::v-deep(.multiselect) {
    border: unset !important;
    min-height: unset !important;
    font-size: small !important;
    box-shadow: unset !important;

    .is-active {
        border: unset !important;
        box-shadow: unset !important;
    }
    .multiselect-placeholder {
        font-size: small !important;
        white-space: nowrap;
        // overflow: hidden;
    }
    .is-disabled {
        background-color: unset !important;
        cursor: not-allowed !important;
    }
    .multiselect-single-label-text {
        white-space: nowrap;
        text-overflow: unset;
        font-size: small !important;
        padding-right: calc(2.3rem + var(--ms-px, 0.875rem));
    }

    // width: 6rem;
    .multiselect-dropdown .multiselect-options .multiselect-option {
        font-size: small !important;
    }
    .multiselect-tag {
        font-size: small !important;
    }
    .multiselect-wrapper .multiselect-caret {
        z-index: unset !important;
    }
}
@media (min-width: 1920px) and (max-width: 2560px) {
    .new-note {
        height: 100vh !important;
    }
}
@media (max-width: 1919px) {
    .new-note {
        height: 974px !important;
    }
}
</style>
