<template>
    <main
        style="width: 96vw"
        class="pl-4 pr-6 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div
            v-if="
                env === 'harboreport' && userStore.current.type !== 'superadmin'
            "
            class="flex items-center text-zinc-500 justify-center pb-6 mt-32"
        >
            <fa-icon icon="person-digging" class="fa-2xl mr-2"></fa-icon>
            {{ $t('coming_soon') }}
        </div>
        <fetch-data-error
            v-else-if="dashboardStore.fetchError"
            :dataType="'dashboard'"
        />
        <div
            v-else-if="dashboardStore.loading && !dashboardStore.count"
            class="flex items-center text-zinc-500 justify-center pb-6 mt-32"
        >
            <fa-icon class="fa-2xl" icon="spinner" spin-pulse></fa-icon>
        </div>
        <div
            v-else
            class="grid grid-cols-10 gap-2 mt-16"
            :class="searchingCursor === true ? 'cursor-progress' : ''"
        >
            <div class="col-span-8">
                <dashboard-cards :counts="dashboardStore.counts" />
                <div class="rounded border-2 mt-6 p-2 h-auto">
                    <div
                        v-if="dashboardStore.orderGlobalCount"
                        class="flex items-center p-2 mt-3"
                    >
                        <span
                            class="rounded-md py-2 px-3 w-fit light"
                            :class="dashboardStore.orderGlobalCount.bgColor"
                        >
                            <fa-icon
                                class="fa-lg"
                                :class="dashboardStore.orderGlobalCount.color"
                                :icon="dashboardStore.orderGlobalCount.icon"
                            />
                        </span>
                        <span class="text-5xl font-semibold mx-8">{{
                            dashboardStore.orderGlobalCount.value
                        }}</span>

                        <span class="text-2xl font-semibold">{{
                            $t(
                                dashboardStore.orderGlobalCount.name,
                                +dashboardStore.orderGlobalCount.value <= 1
                                    ? 1
                                    : 2,
                            )
                        }}</span>
                    </div>
                    <dashboard-cards
                        :counts="
                            dashboardStore.ordersCounts
                                ? dashboardStore.ordersCounts
                                : []
                        "
                        :coloredText="true"
                    />
                </div>
                <div
                    v-if="dashboardStore.billsCounts"
                    class="rounded border-2 mt-6 p-2 h-auto"
                >
                    <div class="flex items-center p-2 mt-3">
                        <span
                            class="rounded-md py-2 px-3 w-fit light bg-zinc-100"
                        >
                            <fa-icon
                                class="fa-lg text-black"
                                icon="file-invoice-dollar"
                            />
                        </span>

                        <span class="text-2xl mx-8 font-semibold">{{
                            $t('your_invoices')
                        }}</span>
                    </div>
                    <dashboard-cards
                        :counts="
                            dashboardStore.billsCounts
                                ? dashboardStore.billsCounts
                                : []
                        "
                    />
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <div
                        v-if="dashboardStore.vehiclesMaintenancesCounts"
                        class="rounded col-span-1 border-2 mt-6 p-2 h-auto"
                    >
                        <div class="flex items-center p-2 mt-3">
                            <span
                                class="rounded-md py-2 px-3 w-fit light bg-blue-100 flex"
                            >
                                <fa-icon
                                    class="fa-lg mr-2 text-blue-600"
                                    icon="truck-arrow-right"
                                />
                                <fa-icon
                                    class="fa-lg text-blue-600"
                                    icon="wrench"
                                />
                            </span>
                            <span class="text-3xl font-semibold mx-8">{{
                                dashboardStore.vehiclesMaintenancesCounts.count
                            }}</span>
                            <span class="text-2xl font-semibold">{{
                                $t(
                                    'vehicle_maintenances_plus_30',
                                    +dashboardStore.vehiclesMaintenancesCounts
                                        .count <= 1
                                        ? 1
                                        : 2,
                                )
                            }}</span>
                        </div>
                    </div>
                    <div
                        v-if="dashboardStore.lateVehiclesMaintenancesCounts"
                        class="rounded border-2 col-span-1 mt-6 p-2 h-auto"
                    >
                        <div class="flex items-center p-2 mt-3">
                            <span
                                class="rounded-md py-2 px-3 w-fit flex light bg-red-100"
                            >
                                <fa-icon
                                    class="fa-lg mr-2 text-red-600"
                                    icon="triangle-exclamation"
                                />
                                <fa-icon
                                    class="fa-lg text-red-600"
                                    icon="truck-fast"
                                />
                            </span>
                            <span class="text-3xl font-semibold mx-8">{{
                                dashboardStore.lateVehiclesMaintenancesCounts
                                    .count
                            }}</span>
                            <span class="text-2xl font-semibold">{{
                                $t(
                                    'late_vehicle_maintenance',
                                    +dashboardStore
                                        .lateVehiclesMaintenancesCounts.count <=
                                        1
                                        ? 1
                                        : 2,
                                )
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-2">
                <div class="grid grid-cols-4 gap-2">
                    <div
                        class="rounded-md col-span-4 h-[25.5rem] border-2 justify-center p-2"
                    >
                        <div class="flex items-center justify-between mb-3">
                            <span
                                class="rounded-md py-2 px-3 w-fit light bg-zinc-100"
                            >
                                <fa-icon
                                    class="fa-lg"
                                    icon="people-carry-box"
                                ></fa-icon>
                            </span>
                            <span class="text-2xl font-semibold">{{
                                $t('drivers')
                            }}</span>
                            <span v-if="dashboardStore.drivers" class="ml-2">
                                {{
                                    dashboardStore.clockedInCount +
                                    ' / ' +
                                    dashboardStore.drivers.length
                                }}</span
                            >
                        </div>
                        <div class="py-3 px-1 h-[84%] overflow-x-auto">
                            <div
                                v-for="(
                                    driver, index
                                ) in dashboardStore.drivers"
                                :key="index"
                                class="text-sm mb-2 grid grid-cols-4 gap-2 items-center"
                            >
                                <span class="col-span-2 flex justify-start">
                                    {{ driver.name }}
                                </span>
                                <span class="col-span-1 flex justify-end">
                                    {{
                                        driver.clockedTime === null
                                            ? ' - '
                                            : driver.clockedTime
                                    }}</span
                                >
                                <span class="col-span-1 flex justify-end">
                                    <fa-icon
                                        icon="circle"
                                        class="fa-xs"
                                        :class="
                                            driver.clockedIn === true
                                                ? 'success-msg'
                                                : driver.clockedIn === false &&
                                                    driver.clockedTime !== null
                                                  ? 'error-msg'
                                                  : 'text-zinc-200'
                                        "
                                        :title="
                                            driver.clockedIn === true
                                                ? $t('clocked_in')
                                                : driver.clockedIn === false &&
                                                    driver.clockedTime !== null
                                                  ? $t('clocked_out')
                                                  : $t('not_clocked_in')
                                        "
                                    ></fa-icon
                                ></span>
                            </div>
                        </div>
                    </div>
                    <div
                        class="rounded-md col-span-4 h-[25.5rem] border-2 justify-center p-2 overflow-x-auto"
                    >
                        <div class="flex items-center mb-3">
                            <span
                                class="rounded-md py-2 px-3 w-fit light bg-zinc-100"
                            >
                                <fa-icon class="fa-lg" icon="bell"></fa-icon>
                            </span>
                            <span class="text-2xl font-semibold ml-2">{{
                                $t('notifications')
                            }}</span>
                        </div>
                        <dashboard-cards
                            :counts="
                                dashboardStore.notifications
                                    ? dashboardStore.notifications
                                    : []
                            "
                            :colsForGrid="'2'"
                        />
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import DashboardCards from '@/components/elements/DashboardCards.vue'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useDashboardStore } from '@/stores/dashboardStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import { mapState, mapStores } from 'pinia'

export default {
    name: 'Dashboard',
    inject: ['eventBus'],

    components: {
        DashboardCards,
        FetchDataError,
    },
    data() {
        return {
            env: '',
            dashboardInterval: null,
            searchingCursor: false,
        }
    },
    computed: {
        ...mapStores(useDashboardStore, useUserStore),
        ...mapState(useUniverseStore, {
            universes: 'all',
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
        canAccessDashBoard() {
            return (
                checkAccess(this.userStore, actionsAccess.dashboard.operator) ||
                checkAccess(this.userStore, actionsAccess.dashboard.client) ||
                checkAccess(this.userStore, actionsAccess.dashboard.harbor)
            )
        },
    },
    created() {
        this.env = window.location.host.substring(
            0,
            window.location.host.indexOf('.'),
        )
    },
    mounted() {
        if (this.canAccessDashBoard && this.env !== 'harboreport') {
            window.scrollTo(0, 0)

            clearInterval(this.dashboardInterval)
            this.eventBus.on('trigger-universe-search', (param) => {
                this.searchCustomUniverses(param)
            })
            this.eventBus.on('remove-from-selected-universe', (index) => {
                this.removeFromSelectedUniverses(index)
            })
            const universesFilter = []
            let universesToUse = []
            const universesInterval = setInterval(() => {
                if (this.universesOptions.length > 0) {
                    clearInterval(universesInterval)
                    universesToUse =
                        this.selectedUniverses.length > 0
                            ? this.selectedUniverses
                            : this.universesOptions
                    universesToUse.forEach((universe) => {
                        universesFilter.push(universe.value)
                    })
                    if (this.userStore.current) {
                        this.dashboardStore
                            .getDashboard(
                                this.userStore.current.type,
                                universesFilter.join(),
                            )
                            .then(() => {
                                this.dashboardInterval = setInterval(() => {
                                    this.dashboardStore.getDashboard(
                                        this.userStore.current.type,
                                        universesFilter.join(),
                                    )
                                }, 30000)
                            })
                    }
                }
            }, 10)
        }
    },
    unmounted() {
        clearInterval(this.dashboardInterval)
        this.eventBus.all.clear()
    },
    methods: {
        searchCustomUniverses(clear = false) {
            clearInterval(this.dashboardInterval)

            let filter = null
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                // this.selectedUniverses = []
                filter = this.universesOptions // TDO universOptions?
            } else {
                filter = this.selectedUniverses
            }
            const universesFilter = []

            filter.forEach((universe) => {
                universesFilter.push(universe.value)
            })
            if (this.userStore.current) {
                this.dashboardStore
                    .getDashboard(
                        this.userStore.current.type,
                        universesFilter.join(),
                    )
                    .then(() => {
                        this.searchingCursor = false
                        if (this.userStore.current) {
                            this.dashboardInterval = setInterval(() => {
                                this.dashboardStore.getDashboard(
                                    this.userStore.current.type,
                                    universesFilter.join(),
                                )
                            }, 30000)
                        } else {
                            clearInterval(this.dashboardInterval)
                        }
                    })
            } else {
                clearInterval(this.dashboardInterval)
            }
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.dashboardInterval)

            this.searchCustomUniverses()
        },
    },
}
</script>
