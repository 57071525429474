<template>
    <div class="grid grid-cols-4 gap-2">
        <div
            class="px-5 col-start-2 col-span-2 darky py-5 bg-white flex flex-col xs:flex-row items-center xs:justify-between"
        >
            <div class="inline-flex mt-2 xs:mt-0">
                <button
                    :disabled="page === 1"
                    class="themed-button text-sm text-white transition duration-150 font-semibold py-2 px-4 rounded-l"
                    @click.prevent="$emit('back')"
                >
                    {{ $t('previous') }}
                </button>
                <p
                    class="flex text-xs xs:text-sm text-zinc-900 items-center mx-3"
                >
                    {{
                        page === 1
                            ? 1 +
                              ' ' +
                              $t('to') +
                              ' ' +
                              (numPerPage * page > count
                                  ? count
                                  : numPerPage * page)
                            : (page - 1) * numPerPage +
                              1 +
                              ' ' +
                              $t('to') +
                              ' ' +
                              (numPerPage * page > count
                                  ? count
                                  : numPerPage * page)
                    }}

                    {{ $t('of') + ' ' + $t('results', count) }}
                    <br />

                    {{ $t('page') }}
                    {{ page }} {{ $t('of') }}
                    {{ totalPages }}
                </p>
                <button
                    :disabled="page === totalPages"
                    class="themed-button text-sm text-white transition duration-150 font-semibold py-2 px-4 rounded-r"
                    @click.prevent="$emit('next')"
                >
                    {{ $t('next') }}
                </button>
            </div>
        </div>
        <div class="grid grid-cols-5 gap-1 items-center">
            <label
                class="col-span-3 text-xs font-semibold basis-1/4 text-gray-600 pb-1 mr-1 block"
            >
                {{ $t('items_per_page') }}</label
            >
            <Multiselect
                class="multiselect-no-pr col-span-2 darky"
                v-model="numPerPage"
                :options="itemsPerPage"
                :searchable="false"
                :allow-empty="false"
                :can-clear="false"
                :open-direction="openDirection"
                @select="$emit('perPageChanged', numPerPage)"
            ></Multiselect>
        </div>
    </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'

export default {
    name: 'ListPagination',
    components: { Multiselect },

    data() {
        return {
            numPerPage: this.perPage,
        }
    },
    watch: {
        perPage() {
            this.numPerPage = this.perPage
        },
    },
    props: {
        itemsPerPage: {
            type: Array,
            default: () => [5, 10, 25, 50, 100],
        },
        perPage: {
            type: Number,
            default: 10,
        },
        totalPages: {
            type: Number,
            default: 10,
        },
        count: {
            type: Number,
            default: 10,
        },
        page: {
            type: Number,
            default: 1,
        },
        openDirection: {
            type: String,
            default: 'auto',
        },
    },
}
</script>
<style lang="scss" scoped>
.multiselect {
    min-height: 2em;
    width: 5em;
}
</style>
