<template>
    <div
        :class="
            wait === true && withTitle
                ? 'cursor-wait border-2 rounded-md p-auto py-2 px-2'
                : withTitle
                  ? 'border-2 rounded-md p-auto py-2 px-2'
                  : ''
        "
    >
        <div v-if="withTitle" class="flex justify-start mb-1">
            <span class="text-2xl whitespace-nowrap">
                <fa-icon :icon="'file-export'" class="fa-md mr-2"></fa-icon>
                {{ $t('upload_file', 1) }}
            </span>
        </div>

        <slot name="extraHeader"></slot>
        <label for="file-input">
            <div
                v-if="showZone"
                :data-active="active"
                @dragenter.prevent="setActive"
                @dragover.prevent="setActive"
                @dragleave.prevent="setInactive"
                @drop.prevent="onDrop"
                :class="wait === true ? 'cursor-wait' : 'cursor-pointer'"
                class="drop-area my-2 mx-auto rounded-md border-4 border-dashed"
            >
                <slot name="zone" :dropZoneActive="active">
                    <span v-if="active" class="font-semibold italic">
                        <span>{{ $t('active_file_drop_zone_1') }}</span>
                        <span class="smaller">{{
                            $t('active_file_drop_zone_2')
                        }}</span>
                    </span>
                    <span v-else class="font-semibold italic">
                        {{ $t('inactive_file_drop_zone_1') }}
                    </span>

                    <input
                        type="file"
                        id="file-input"
                        :accept="fileExt"
                        multiple
                        @change="onInputChange"
                    />
                    <!-- TODO faire une GRID -->
                    <ul
                        class="text-sm mt-8 grid grid-cols-6 gap-1"
                        v-show="files.length"
                    >
                        <file-preview
                            v-for="file of files"
                            class="col-span-1 overflow-auto"
                            :key="file.id"
                            :file="file"
                            :directAdd="directAdd"
                            :show="showZone"
                            @remove="
                                $emit('removeFile', $event) & removeFile($event)
                            "
                        />
                    </ul>
                </slot>

                <!-- </drop-zone> -->
            </div>
        </label>
        <slot name="errors"></slot>
        <button
            v-if="files.length > 0 && directAdd === false"
            :class="wait === true ? 'cursor-wait' : 'cursor-pointer'"
            @click.prevent="$emit('uploadFiles', files)"
            class="themed-button transition duration-200 focus:shadow-sm focus:ring-opacity-50 text-white w-fit px-2 py-2.5 rounded text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
        >
            {{ $t('upload_file', files.length) }}
        </button>
    </div>
</template>

<script>
import FilePreview from '@/components/elements/FilePreview.vue'
import useFileList from '@/resources/fileList'
import { useUserStore } from '@/stores/userStore'
import { mapState } from 'pinia'
import { ref } from 'vue'

export default {
    name: 'DropZone',
    components: { FilePreview },
    props: {
        showZone: { type: Boolean, default: true },
        wait: { type: Boolean, default: false },
        clearFileList: { type: Boolean, default: false },
        okFilesToRemove: { type: Array, default: () => [] },
        withTitle: { type: Boolean, default: true },
        fileExt: { type: String, default: '.csv' },
        directAdd: { type: Boolean, default: false },
        filesInError: { type: Array, default: () => [] },
    },
    setup() {
        const { files, addFiles, removeFile, removeAllFiles } = useFileList()

        const active = ref(false)
        const inActiveTimeout = null

        function preventDefaults(e) {
            e.preventDefault()
        }
        return {
            files,
            removeFile,
            removeAllFiles,
            addFiles,
            active,
            inActiveTimeout,
            preventDefaults,
        }
    },
    watch: {
        'okFilesToRemove.length': function () {
            this.okFilesToRemove.forEach((file) => {
                this.removeFile(file)
                this.$emit('fileRemoved', file)
            })
        },
        clearFileList(newVal, oldVal) {
            this.removeAllFiles()
            this.$emit('resetClearFileList')
        },
        files() {
            if (this.files.length === 0) {
                this.$emit('resetWrongFiles')
            }
        },
    },
    data() {
        return {
            events: ['dragenter', 'dragover', 'dragleave', 'drop'],
        }
    },
    mounted() {
        this.events.forEach((eventName) => {
            document.body.addEventListener(eventName, this.preventDefaults)
        })
    },
    computed: {
        ...mapState(useUserStore, { user: 'current' }),
    },
    unmounted() {
        this.events.forEach((eventName) => {
            document.body.removeEventListener(eventName, this.preventDefaults)
        })
    },
    methods: {
        setActive() {
            this.active = true
            clearTimeout(this.inActiveTimeout)
        },
        setInactive() {
            this.inActiveTimeout = setTimeout(() => {
                this.active = false
            }, 50)
        },

        onDrop(e) {
            this.setInactive()
            this.addFiles([...e.dataTransfer.files])

            if (this.directAdd === true) {
                this.$emit('uploadFiles', this.files)
            }
        },
        onInputChange(e) {
            this.addFiles(e.target.files)
            if (this.directAdd === true) {
                this.$emit('uploadFiles', this.files)
            }
            e.target.value = null // reset so that selecting the same file again will still cause it to fire this change
        },
    },
}
</script>

<style lang="scss" scoped>
.drop-area {
    width: 100%;
    padding: 30px;
    background: #f4f4f5;
    transition: 0.2s ease;
    /* &[data-active='true'] {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        background: #ffffffcc;
    } */
}

input[type='file']:not(:focus-visible) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}
</style>
